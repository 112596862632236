const EntryType = Object.freeze({
  VALUE: 'enum.entry_type.value',
  OBJECT: 'enum.entry_type.object',
  LIST: 'enum.entry_type.list',
  KEY_VALUE: 'enum.entry_type.key_value',
  KEY_OBJECT: 'enum.entry_type.key_object',
  KEY_LIST: 'enum.entry_type.key_list',
});

export default EntryType;
