<template>
  <el-popover :class="{ 'popover-visible': popoverVisible }" placement="top" width="220" v-model="popoverVisible" trigger="manual">
    <p>{{ $t('magicVariablesDialog.deleteFunction') }}</p>
    <div style="text-align: right; margin: 0">
      <el-button size="mini" type="text" @click="popoverVisible = false">
        {{ $t('cancel') }}
      </el-button>
      <el-button
        :class="['function-delete-confirm', { 'popover-active': popoverVisible }]"
        type="primary"
        size="mini"
        @click="$emit('delete')"
      >
        {{ $t('confirm') }}
      </el-button>
    </div>
    <i slot="reference" :class="['convo-icon-delete']" @click.stop="popoverVisible = true" @blur="popoverVisible = false" />
  </el-popover>
</template>

<script>
export default {
  name: 'ConvoFunctionTrashbin',
  data() {
    return {
      popoverVisible: false,
    };
  },
};
</script>

<style scoped lang="scss">
.popover-visible {
  .convo-icon-delete {
    display: block !important;
  }
}
</style>
