import Vue from 'vue';

const getDefaultState = () => ({
  de: {},
  en: {},
  preferredLang: 'de',
});

export default function translations() {
  const initialState = getDefaultState();

  const getters = {
    translation: (state) => {
      return (lang, key) => state[lang][key];
    },
    translations: (state) => {
      const copiedState = { ...state };
      delete copiedState.preferredLang;
      return copiedState;
    },
    translationsForElement: (state) => {
      return (lang, elementId) =>
        Object.keys(state[lang])
          .filter((key) => key.startsWith(`${elementId}.`))
          .reduce((acc, key) => {
            acc[key] = state[lang][key];
            return acc;
          }, {});
    },
    preferredLang: (state) => state.preferredLang,
  };

  const mutations = {
    updateTranslation(state, object) {
      Vue.set(state[object.lang], object.key, object.value);
    },
    updateTranslations(state, object) {
      Object.keys(object).forEach((lang) => Vue.set(state, lang, { ...state[lang], ...object[lang] }));
    },
    deleteTranslation(state, object) {
      Vue.delete(state[object.lang], object.key);
    },
    changePreferredLang(state, lang) {
      if (state.preferredLang !== lang) {
        Vue.set(state, 'preferredLang', lang);
      }
    },
    clear(state) {
      // Make it reactive
      Object.entries(getDefaultState()).forEach(([key, value]) => {
        Vue.set(state, key, value);
      });
    },
  };

  return {
    state: initialState,
    getters,
    mutations,
  };
}
