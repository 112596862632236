import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all';
import ConvoProcess from '@ppi/convo-engine-web-classic';
import Vue from 'vue';
import vmodal from 'vue-js-modal';
import axios from 'axios';
import ConvoDraggable from '@/studio/components/canvas/ConvoDraggable.vue';
import store from '@/common/store';
import i18n, { locale } from '@/common/i18n';
import AnalyticsService from '@/common/services/AnalyticsService';
import App from './app/components/App.vue';
// import settings for ui library element-ui
import './app/element_ui';
import router from './app/router';

Vue.config.productionTip = false;

// we have to define these globally because they reference each other
Vue.component('convo-draggable', ConvoDraggable);

const addContextInfo = (error) => {
  const name = error.name || null;
  const time = new Date();
  const path = window.location.pathname;
  const status = error.response ? error.response.status : null;
  const message = error.message || error.toString();

  const { stack } = error;

  return { name, time, path, status, message, stack };
};

// captures vue related errors during component rendering
Vue.config.errorHandler = (err, vm, info) => {
  const error = JSON.stringify(addContextInfo(err));
  const parameter = `Error: ${error}\nVue specific Info: ${info}`;
  console.error(parameter);
  AnalyticsService.commitError(err.name, parameter);
};

// global error handler for all errors that are not handled by vue errorHandler
window.onerror = (msg, url, line, col, err) => {
  const error = addContextInfo(err);
  AnalyticsService.commitError(msg, `Error: ${JSON.stringify(error)}\nUrl: ${url}\nLine: ${line}\nColumn: ${col}`);
};

// error handler for axios calls
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    // Do something with response error
    const { response } = err;
    if (response.status === 401) {
      store.dispatch('oidc/signOutOidc', { post_logout_redirect_uri: `${window.location.origin}/start?error=401` });
      return;
    }
    // eslint-disable-next-line consistent-return
    return Promise.reject(response);
  }
);

Vue.use(ConvoProcess, { store, i18n, locale, vmodal });
new Vue({
  store,
  router,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
