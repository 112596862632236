import jsyaml from 'js-yaml';
import ElementType from '@/common/enums/ElementType';
import colors from '@/common/assets/colors.json';
import ConvoProcessComponentService from '@/common/services/ConvoProcessComponentService';
import ConvoExpressionLanguageService from '@/common/services/ConvoExpressionLanguageService';
import CpdModificationService from '@/common/services/CpdModificationService';
import MagicVariablesService from '@/common/services/MagicVariablesService';
import CpdStoreToYamlService from '@/common/services/CpdStoreToYamlService';
import store from '@/common/store';

const CURRENT_CPD_STRUCTURE_VERSION = '1.2.0';

/**
 * This service encapsulates all functionality required to load CPDs to and from the store.
 */
const CpdYamlToStoreService = (() => {
  /**
   * Puts an object with its id as key in the store.
   * All child objects are replaced by its id and also added recursively to the store.
   *
   * @param {Object} raw the object to put in the store
   */
  function parse(raw) {
    const element = {};
    Object.keys(raw)
      .filter((key) => key !== 'id') // the id is the store key and doesn't need to be a part of the object
      .forEach((key) => {
        const value = raw[key];
        if (Array.isArray(value)) {
          if (typeof value[0] === 'object' && !(value[0] instanceof Date)) {
            // arrays of objects are replaced by arrays of ids and objects are parsed recursively
            element[key] = value.filter((comp) => comp).map((comp) => comp.id);
            value.filter((listEntry) => listEntry).forEach((listEntry) => parse(listEntry));
          } else {
            // arrays of other types don't need to be parsed
            element[key] = value;
            // IMPORTANT: at this moment arrays of plain values can't contain MV refs.
            // MV ref evaluation and cache insert needs to be added here to in the future if the architecture changes.
          }
        } else if (typeof value === 'object' && !(value instanceof Date) && value !== null) {
          // an object is replaced by its id and is parsed recursively
          element[key] = value.id;
          parse(value);
        } else {
          // all other values are taken over without a mapping
          element[key] = value;

          // and are evaluated to build the global magic variable reference state
          MagicVariablesService.updateMvReferencesForElementField(raw.id, key, ConvoExpressionLanguageService.getReferencedMvs(value));
        }
      });
    // the translated object is add to the store using its id as key
    store.commit('createElement', { id: raw.id, element });

    // add exported vars to global magic variable state
    MagicVariablesService.addElement(raw.id);
  }

  return {
    /**
     * Builds the cpd (using buildCpdYamlFromStoreValues()) and returns it as a s.
     *
     * @return {Object} The cpd
     */
    getCpd() {
      return CpdStoreToYamlService.buildCpdYamlFromStoreValues(this.new());
    },

    /**
     * Map a given cpd to its store representation and put it in the store.
     *
     * @param {String} file the yaml as string
     * @param {String} uniqueCpdId the uniqueCpdId of the cpd (cpdId + 'v' + version)
     * @returns the id of the uploaded cpd
     */
    upload(file, uniqueCpdId) {
      store.commit('clear');

      const raw = jsyaml.load(file);
      const cpd = {
        structure_version: '1.2.0', // default value (for old CPDs) is overwritten with the actual one
      };
      // set all first level attributes except id and definition
      Object.keys(raw)
        .filter((key) => key !== 'id' && key !== 'definition')
        .forEach((key) => {
          cpd[key] = raw[key];
        });
      // set the id of the start component as first level attribute, because the store representation doesn't know the definition object
      // the start component itself is put to the store using the parse method
      const controlStart = raw.definition.control_start;
      cpd.control_start = controlStart.id;
      parse(controlStart);
      // set the id of the end component as first level attribute, because the store representation doesn't know the definition object
      // The end component is transferred directly to the store, as it does not need to be flattened. Then the referenced MVs of the end component are registered
      const controlEnd = raw.definition.control_end;
      cpd.control_end = controlEnd.id;
      store.commit('createElement', { id: controlEnd.id, element: controlEnd });
      const getMVs = (obj) => {
        const mvs = [];
        Object.keys(obj).forEach((key) => {
          if (typeof obj[key] === 'object' && obj[key] !== null) {
            getMVs(obj[key]).forEach((mv) => mvs.push(mv));
          } else if (typeof obj[key] === 'string') {
            ConvoExpressionLanguageService.getReferencedMvs(obj[key]).forEach((mv) => mvs.push(mv));
          }
        });
        return mvs;
      };
      if (controlEnd.body) {
        MagicVariablesService.updateMvReferencesForElementField(controlEnd.id, 'body', getMVs(controlEnd.body));
      }

      // set a list of children ids as first level attribute, because the store representation doesn't know the definition object
      // the corresponding objects are put to the store using the parse method
      const { children } = raw.definition;
      cpd.children = children.filter((c) => c).map((c) => c.id);
      children.filter((c) => c).forEach((c) => parse(c));
      // put the first level object with the cpd id as key in the store
      store.commit('createElement', { id: uniqueCpdId, element: cpd });

      return raw.id;
    },

    new() {
      store.commit('clear');

      const startCreationHandler = ConvoProcessComponentService.getElementCreationHandler(ElementType.CONTROL_START);
      const startId = CpdModificationService.createElement(startCreationHandler);

      const endCreationHandler = ConvoProcessComponentService.getElementCreationHandler(ElementType.CONTROL_END);
      const endId = CpdModificationService.createElement(endCreationHandler);

      const groupCreationHandler = ConvoProcessComponentService.getElementCreationHandler(ElementType.GROUP);
      const groupId = CpdModificationService.createElement(groupCreationHandler);

      const subsectionCreationHandler = (id) => {
        const definition = ConvoProcessComponentService.getElementCreationHandler(ElementType.SUBSECTION)(id);
        definition.children = [groupId];
        return definition;
      };
      const subsectionId = CpdModificationService.createElement(subsectionCreationHandler);

      const sectionCreationHandler = (id) => {
        const definition = ConvoProcessComponentService.getElementCreationHandler(ElementType.SECTION)(id);
        definition.children = [subsectionId];
        return definition;
      };
      const sectionId = CpdModificationService.createElement(sectionCreationHandler);

      const colorsObject = {};
      colors.default.forEach((color) => {
        colorsObject[color.cssClass] = color.value;
      });

      const cpdCreationHandler = () => {
        return {
          structure_version: CURRENT_CPD_STRUCTURE_VERSION,
          control_start: startId,
          control_end: endId,
          children: [sectionId],
          colors: colorsObject,
          isCpd: true,
        };
      };
      return CpdModificationService.createElement(cpdCreationHandler);
    },
  };
})();

export default CpdYamlToStoreService;
