<template>
  <div class="convo-chat">
    <template v-if="isMinimized">
      <convo-open-chat @open-chat="showChat" />
    </template>
    <div v-show="!isMinimized" class="chat-content">
      <convo-chat-header @minimize-chat="showChat" @reload-chat="reload" />
      <convo-chat-container :key="componentKey" />
    </div>
  </div>
</template>

<script>
import ConvoChatContainer from '@/dashboard/components/chat/components/ConvoChatContainer.vue';
import ConvoChatHeader from '@/dashboard/components/chat/components/ConvoChatHeader.vue';
import ConvoOpenChat from '@/dashboard/components/chat/components/ConvoOpenChat.vue';

export default {
  name: 'ConvoChatApp',
  components: { ConvoChatContainer, ConvoChatHeader, ConvoOpenChat },
  data() {
    return {
      componentKey: 0,
      isMinimized: true,
    };
  },
  methods: {
    showChat(yesNo) {
      this.isMinimized = yesNo;
    },
    reload() {
      this.componentKey += 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.convo-chat {
  .chat-content {
    //COLOR
    box-shadow: 4px 4px 4px 0 #00000040;
    //LAYOUT
    bottom: 16px;
    border-radius: 10px;
    position: fixed;
    right: 16px;
    width: $chat-width;
    z-index: 7;
  }
}

@media (max-width: 1280px) {
  .convo-chat {
    .chat-content {
      width: $chat-width-1280;
    }
  }
}
</style>
