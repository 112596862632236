import store from '@/common/store';
import EventBus from '@/common/event-bus';
import ConvoExpressionLanguageService from '@/common/services/ConvoExpressionLanguageService';
import MagicVariablesService from '@/common/services/MagicVariablesService';
import CpdIdService from '@/common/services/CpdIdService';
import MailConfigModificationService from '@/common/services/MailConfigModificationService';
import ElementType from '@/common/enums/ElementType';

/**
 * This service encapsulates all functionality required to manipulate a CPD which is already in the store.
 */
const CpdModificationService = (() => {
  return {
    /**
     * Using the given creation handler to create an element instance and makes it accessible with the returned ID.
     *
     * A element creation handler and not an element itself is expected because the creation process may need the generated id.
     *
     * Note that the element is created but has no logical ancestors until you manually create
     * a parent child relationship with another element which is already part of the CPD by adding
     * the returned ID to the children field of that other CPD element.
     *
     * @param {Object} elementCreationHandler The creation handler which returns an instance of a CPC
     *                                        or an object which is the logical child of a CPC instance
     * @returns The generated ID of the created element.
     */
    createElement(elementCreationHandler) {
      const id = CpdIdService.getId();
      const element = elementCreationHandler(id);
      store.commit('createElement', { id, element });
      MagicVariablesService.addElement(id);

      if (element.type === ElementType.MAIL) {
        MailConfigModificationService.createMailConfig(id);
      }

      return id;
    },

    /**
     * Deletes the element with the given ID. Children are deleted recursively.
     *
     * @param {String} elementId The ID of the element to delete.
     */
    deleteElement(elementId) {
      const deleteElementRecursive = (id) => {
        const children = store.getters.componentById(id).children || [];
        children.forEach(deleteElementRecursive);
        MagicVariablesService.deleteElement(id);
        store.commit('deleteElement', id);
        EventBus.emitElementDeletion(id);
      };
      deleteElementRecursive(elementId);
    },

    /**
     * Gets the element with the given ID.
     *
     * Could theoretically be used for reative bindings to this element. However the
     * reactivity would only apply to reassignments of a new object (element) to the
     * given ID but not to a field of the element. The former is not intened by the
     * applications architecture anyways. For the latter see {@link getElementField}.
     *
     * @param {String} id The Id of the element to get.
     */
    getElement(id) {
      return store.getters.componentById(id);
    },

    /**
     * Gets the field with the given fieldKey of the element with the given ID.
     *
     * This method provides reactivity and can be used to bind values in Vue components to
     * the value of the referenced field of the referenced element.
     *
     * @param {String} id
     * @param {String} fieldKey
     */
    getElementField(id, fieldKey) {
      const element = store.getters.componentById(id);
      return element ? element[fieldKey] : undefined;
    },

    /**
     * Updates the field with the given fieldKey of the element with the given ID by assigning the
     * given new value to it.
     *
     * @param {String} id The ID of the element
     * @param {String} fieldKey The fieldKey of the field to update
     * @param {*} value The new value to assign to the field with the given fieldkey
     */
    updateElementField(id, fieldKey, value) {
      MagicVariablesService.updateMvReferencesForElementField(id, fieldKey, ConvoExpressionLanguageService.getReferencedMvs(value));
      store.commit('updateElementField', { id, fieldKey, value });
    },

    /**
     * Flattens all elements in order of appearance.
     *
     * @param {String} uniqueCpdId The cpd id.
     * @param {ElementType} type Optional element type to filter the list.
     * @returns {Array} A flat list of all element ids in the order of appearance.
     */
    getOrderedElements(uniqueCpdId, type) {
      return store.getters
        .flatElementOrder(uniqueCpdId)
        .filter((elementId) => !type || store.getters.componentById(elementId).type === type);
    },
  };
})();

export default CpdModificationService;
