import axios from 'axios';
import CpdYamlToStoreService from '@/common/services/CpdYamlToStoreService';
import TranslationTransformationService from '@/common/services/TranslationTransformationService';
import MailConfigTransformationService from '@/common/services/MailConfigTransformationService';
import store from '@/common/store';
import { getRequestConfig } from '@/common/oidc/utils';
import CpdStoreToYamlService from '@/common/services/CpdStoreToYamlService';
import EventBus from '@/common/event-bus';
import StateEnum from '@/common/enums/StateEnum';

const CpdApiService = (() => {
  const endpoint = (process.env.VUE_APP_BACKEND || window.backend) + '/v1/process';

  function parseBasicConvoProcess(raw, cpdList) {
    return {
      id: raw.id,
      version: raw.version,
      uniqueCpdId: raw.id + 'v' + raw.version,
      hasOrIsFollowUpVersion: cpdList.filter((c) => c.id === raw.id).length === 2,
      name: raw.name,
      state: raw.state,
      locked: raw.locked,
      modified: new Date(raw.modified),
      modifiedBy: raw.modifiedBy,
      prod: raw.prod,
      lastDeployment: new Date(raw.lastDeployment),
    };
  }

  function startSpinner(spinnerName) {
    EventBus.emitSpinnerStart(spinnerName);
  }

  function stopSpinner(spinnerName) {
    EventBus.emitSpinnerStop(spinnerName);
  }

  return {
    async createCpd(name) {
      const definition = CpdYamlToStoreService.getCpd();
      const de = '{}';
      const en = '{}';
      const translations = { de, en };
      return axios
        .post(
          endpoint,
          {
            name,
            definition,
            translations,
          },
          getRequestConfig()
        )
        .then((response) => response);
    },

    async createFollowUpVersion(id, version, spinnerName) {
      startSpinner(spinnerName);
      return axios
        .post(`${endpoint}/${id}/${version}/followUp`, null, getRequestConfig())
        .then((response) => response)
        .finally(() => stopSpinner(spinnerName));
    },

    async deleteCpd(cpdId, version) {
      return axios.delete(`${endpoint}/${cpdId}/${version}`, getRequestConfig()).then((response) => response);
    },

    async deleteCpdLock(cpdId, version) {
      return axios.delete(`${endpoint}/${cpdId}/${version}/lock`, getRequestConfig()).then((response) => response);
    },

    getConvoProcessDto(uniqueCpdId) {
      const definition = CpdStoreToYamlService.buildCpdYamlFromStoreValues(uniqueCpdId);

      const de = JSON.stringify(TranslationTransformationService.getTranslations(uniqueCpdId, 'de'));
      const en = JSON.stringify(TranslationTransformationService.getTranslations(uniqueCpdId, 'en'));
      const translations = { de, en };

      const mailConfigs = MailConfigTransformationService.getMailConfigsFromStore(uniqueCpdId);

      const { referencedPdfs } = store.getters;

      return { definition, translations, referencedPdfs, mailConfigs };
    },

    async getCpds(spinnerName) {
      startSpinner(spinnerName);
      return axios
        .get(endpoint, getRequestConfig())
        .then((response) => response.data)
        .then((basicConvoProcessList) =>
          basicConvoProcessList.map((item) => parseBasicConvoProcess(item, basicConvoProcessList)).sort((a, b) => b.modified - a.modified)
        )
        .finally(() => stopSpinner(spinnerName));
    },

    async getCpdsWithId(id, spinnerName) {
      startSpinner(spinnerName);
      return axios
        .get(endpoint, getRequestConfig())
        .then((response) => response.data)
        .then((cpds) =>
          cpds
            .filter((cpd) => id === cpd.id)
            .map((item) => parseBasicConvoProcess(item, cpds))
            .sort((a, b) => b.modified - a.modified)
        )
        .finally(() => stopSpinner(spinnerName));
    },

    getState() {
      return store.getters.cpdState;
    },

    hasNonExpiredLock(expirationTime) {
      return expirationTime && new Date().getTime() < new Date(expirationTime).getTime();
    },
    isLockedByCurrentUser(cpd) {
      return !!(
        cpd?.state === StateEnum.DRAFT &&
        this.hasNonExpiredLock(cpd.locked?.expiredAt) &&
        store.getters['oidc/oidcUser'].sub === cpd.locked?.user.id
      );
    },

    async loadCpd(id, version, spinnerName, isDemoUserEditMode) {
      startSpinner(spinnerName);
      return axios
        .get(`${endpoint}/${id}/${version}`, getRequestConfig())
        .then((response) => response.data)
        .then((cpd) => {
          CpdYamlToStoreService.upload(cpd.definition, cpd.id + 'v' + cpd.version);
          TranslationTransformationService.uploadTranslations(cpd.translations.de, cpd.translations.en);
          MailConfigTransformationService.uploadMailConfigs(cpd.mailConfigs);
          store.commit('updateCpdEditable', isDemoUserEditMode || this.isLockedByCurrentUser(cpd));
          store.commit('updateCpdName', cpd.name);
          store.commit('updateCpdState', cpd.state);
          store.commit('updateReferencedPdfs', cpd.referencedPdfs);
          return cpd;
        })
        .finally(() => stopSpinner(spinnerName));
    },

    async lockCpd(id, version, spinnerName) {
      startSpinner(spinnerName);
      return axios
        .post(`${endpoint}/${id}/${version}/lock`, null, getRequestConfig())
        .then((response) => response)
        .finally(() => stopSpinner(spinnerName));
    },

    async overwriteCpd(cpdId, version, spinnerName) {
      startSpinner(spinnerName);
      const uniqueCpdId = cpdId + 'v' + version;
      return axios
        .patch(`${endpoint}/${cpdId}/${version}`, this.getConvoProcessDto(uniqueCpdId), getRequestConfig())
        .then((response) => response)
        .finally(() => stopSpinner(spinnerName));
    },

    async overrideState(cpdId, version, newState) {
      const state = newState;
      return axios
        .patch(
          `${endpoint}/${cpdId}/${version}`,
          {
            state,
          },
          getRequestConfig()
        )
        .then((response) => response);
    },

    setState(newState) {
      store.commit('updateCpdState', newState);
    },

    async updateCpdName(cpdId, version, rename) {
      const name = rename;
      return axios.patch(
        `${endpoint}/${cpdId}/${version}`,
        {
          name,
        },
        getRequestConfig()
      );
    },

    async updateCpdLock(id, version, spinnerName) {
      startSpinner(spinnerName);
      return axios
        .put(`${endpoint}/${id}/${version}/lock`, null, getRequestConfig())
        .then((response) => response)
        .finally(() => stopSpinner(spinnerName));
    },
  };
})();

export default CpdApiService;
