import CpdStoreToYamlService from '@/common/services/CpdStoreToYamlService';
import store from '@/common/store';

/**
 * This service encapsulates all functionality required to load mail configs to and from the store.
 */
const MailConfigTransformationService = (() => {
  return {
    /**
     * Gets all mail configs from the store which are used in the actual cpd.
     * @param {String} uniqueCpdId The cpd to get the mail configs for.
     * @returns {Array} a list of all mail configs.
     */
    getMailConfigsFromStore(uniqueCpdId) {
      const yaml = CpdStoreToYamlService.buildCpdYamlFromStoreValues(uniqueCpdId);
      return store.getters.mailConfigs.filter((mailConfig) => yaml.includes(mailConfig.id));
    },

    /**
     * Put given mail configs into the store.
     *
     * @param {Array} mailConfigs a list of mail configs.
     */
    uploadMailConfigs(mailConfigs) {
      store.commit('setMailConfigs', mailConfigs);
    },
  };
})();

export default MailConfigTransformationService;
