<template>
  <div class="convo-chat-header">
    <!--LEFT-->
    <div class="chat-header-convo" @click="restart">{{ $t('CONVO') }}</div>

    <!--CENTER-->
    <div class="chat-header-text">{{ $t('chatName') }}</div>

    <!--RIGHT-->
    <div class="chat-header-options">
      <convo-chat-minimize @minimize-chat="$emit('minimize-chat', $event)" />
    </div>
  </div>
</template>

<script>
import ConvoChatMinimize from '@/dashboard/components/chat/components/headerOptions/ConvoChatMinimize.vue';

export default {
  name: 'ConvoChatHeader',
  components: { ConvoChatMinimize },
  methods: {
    restart() {
      this.$emit('reload-chat');
    },
  },
};
</script>

<style lang="scss" scoped>
.convo-chat-header {
  //COLOR
  background: $primary-text-color;
  //LAYOUT
  align-items: center;
  border-radius: 10px 10px 0 0;
  display: flex;
  padding: 16px 0; //16px oben+unten, 0 links+rechts

  //LEFT
  .chat-header-convo {
    cursor: pointer;
    //FONT
    font-family: $font-logo;
    font-size: 28px;
    //COLOR
    color: $primary-color;
    //LAYOUT
    margin: 0 8px;
  }

  //CENTER
  .chat-header-text {
    //FONT
    font-family: $primary-font;
    font-size: 20px;
    //COLOR
    color: $chat-header-demo-text;
  }

  //RIGHT
  .chat-header-options {
    //LAYOUT
    align-items: center;
    display: flex;
    flex-grow: 1; //every child will set to an equal size
    justify-content: flex-end;
    margin-right: 16px;
  }
}

@media (max-width: 1280px) {
  .convo-chat-header {
    padding: 8px 0;
    .chat-header-convo {
      font-size: 18px;
    }
    .chat-header-text {
      font-size: 14px;
    }
  }
}
</style>
