<template>
  <div class="convo-mail-detail-input">
    <div class="button-mail-configuration" @click="openDialog">{{ $t(`${i18nPrefix}.configurationButton`) }}</div>
    <convo-mail-configuration-dialog
      :key="dialogKey"
      :disabled="!editable"
      :parent-id="parentId"
      :visible="dialogVisible"
      @close="closeDialog"
    />
  </div>
</template>

<script>
import ConvoInputMixin from '@/studio/mixins/ConvoInputMixin';
import CpdModificationService from '@/common/services/CpdModificationService';
import ConvoMailConfigurationDialog from './ConvoMailConfigurationDialog.vue';

export default {
  name: 'ConvoMailDetailInput',
  components: { ConvoMailConfigurationDialog },
  mixins: [ConvoInputMixin],
  props: {
    i18nPrefix: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dialogVisible: false,
      dialogKey: 0,
    };
  },
  methods: {
    openDialog() {
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
      this.resetDialog();
    },
    resetDialog() {
      // unsaved changes are discarded
      this.dialogKey += 1;
    },
  },
  mounted() {
    CpdModificationService.updateElementField(this.parentId, 'mail_config_id', this.parentId); // the mail config id matches the id of the mail generation element
  },
};
</script>

<style scoped lang="scss">
.convo-mail-detail-input {
  .button-mail-configuration {
    cursor: pointer;
    width: 100%;
    border: 1px solid $primary-color;
    border-radius: 20px;
    padding: 8px;
    text-align: center;
    background-color: $primary-color;
    color: white;
  }
}
</style>
