<template>
  <div :class="['convo-key-list-input', 'convo-ip-input', { 'even-level': isEvenLevel, 'odd-level': !isEvenLevel }]">
    <div class="header">
      <span>{{ $t(`${i18nPrefix}.key_list_header`) }} {{ identifier }}</span>
      <div :class="['remove-button', { 'not-editable': !editable }]" @click="removeIfEditable">
        <i class="convo-icon-delete" />
      </div>
    </div>
    <el-form v-if="!hideKeyInput" class="form" ref="objectform" label-position="top" :model="{}">
      <el-form-item :label="$t(`${i18nPrefix}.key`)" prop="key" :rules="[{ validator, trigger }]">
        <el-input
          :disabled="!editable"
          :id="mask(`key-input-${i18nPrefix}-${identifier}`)"
          :value="valueKey"
          @input="$emit('update:value-key', $event)"
        />
      </el-form-item>
    </el-form>
    <template v-for="(val, index) in copiedValue">
      <convo-key-object-input
        v-if="val.type === EntryType.OBJECT"
        hide-key-input
        :editable="editable"
        :i18n-prefix="i18nPrefix"
        :identifier="`${identifier}.${index + 1}`"
        :key="index"
        :level="level + 1"
        :parent-id="parentId"
        :value-key.sync="val.key"
        :value.sync="val.value"
        @remove="remove(index)"
      />
      <convo-key-list-input
        v-else-if="val.type === EntryType.LIST"
        hide-key-input
        :editable="editable"
        :i18n-prefix="i18nPrefix"
        :identifier="`${identifier}.${index + 1}`"
        :key="index"
        :level="level + 1"
        :parent-id="parentId"
        :value-key.sync="val.key"
        :value.sync="val.value"
        @remove="remove(index)"
      />
      <convo-key-value-input
        v-else
        hide-key-input
        :editable="editable"
        :i18n-prefix="i18nPrefix"
        :identifier="`${identifier}.${index + 1}`"
        :key="index"
        :level="level + 1"
        :parent-id="parentId"
        :value-key.sync="val.key"
        :value.sync="val.value"
        @remove="remove(index)"
      />
    </template>
    <convo-add-entry-dropdown
      :editable="editable"
      :i18n-prefix="i18nPrefix"
      :identifier="identifier"
      @key-list="addKeyList"
      @key-object="addKeyObject"
      @key-value="addKeyValue"
    />
  </div>
</template>

<script>
import ObjectConversionService from '@/studio/services/ObjectConversionService';
import EntryType from '@/studio/enums/EntryType';
import ValidationStrategy from '@/studio/enums/ValidationStrategy';
import ValidationMixin from '@/studio/mixins/ValidationMixin';
import ConvoKeyValueInput from './ConvoKeyValueInput.vue';
import ConvoAddEntryDropdown from './ConvoAddEntryDropdown.vue';
import ConvoKeyObjectInput from './ConvoKeyObjectInput.vue';

export default {
  name: 'ConvoKeyListInput',
  mixins: [ValidationMixin],
  components: { ConvoKeyValueInput, ConvoAddEntryDropdown, ConvoKeyObjectInput },
  data() {
    return {
      copiedValue: this.value,
      EntryType,
    };
  },
  props: {
    parentId: {
      type: String,
      required: true,
    },
    valueKey: {
      required: false,
      type: String,
    },
    value: {
      type: Array,
      default: () => [],
    },
    level: {
      required: true,
      type: Number,
    },
    identifier: {
      required: true,
      type: String,
    },
    i18nPrefix: {
      type: String,
      required: true,
    },
    hideKeyInput: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isEvenLevel() {
      return this.level % 2 === 0;
    },
  },
  methods: {
    addKeyObject() {
      this.copiedValue.push(ObjectConversionService.getEmptyObjectEntry());
    },
    addKeyList() {
      this.copiedValue.push(ObjectConversionService.getEmptyListEntry());
    },
    addKeyValue() {
      this.copiedValue.push(ObjectConversionService.getEmptyValueEntry());
    },
    remove(index) {
      this.copiedValue.splice(index, 1);
    },
    removeIfEditable() {
      if (this.editable) this.$emit('remove');
    },
    validator(rule, value, callback) {
      this.validate(
        { validation_strategy: ValidationStrategy.STATIC, value: true },
        (evaluated) => evaluated && (!this.valueKey || this.valueKey === ''),
        () => this.$t('validationError.mandatory'),
        callback
      );
      callback();
    },
    mask(string) {
      return string.replace(/\./g, '-').replace(/_/g, '-');
    },
  },
  mounted() {
    if (this.$refs.objectform) {
      this.$refs.objectform.validate(() => {});
    }
  },
  watch: {
    copiedValue: {
      deep: true,
      handler() {
        this.$emit('update:value', this.copiedValue);
      },
    },
  },
  beforeCreate() {
    // ConvoKeyListInput uses ConvoKeyObjectInput and creates a circular reference by this. Solution:
    // https://vuejs.org/v2/guide/components-edge-cases.html#Circular-References-Between-Components
    /* eslint-disable-next-line  global-require */
    this.$options.components.ConvoKeyObjectInput = require('./ConvoKeyObjectInput.vue').default;
  },
};
</script>

<style lang="scss" scoped>
@import '@/studio/styles/inputs.scss';
.convo-key-list-input {
  padding: 8px;
  border-radius: 8px;

  &.even-level {
    background-color: $convo-blue-3;
  }
  &.odd-level {
    background-color: $convo-blue-1;
  }

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $primary-color;
    padding-bottom: 4px;
    margin-bottom: 24px;
    color: $primary-color;
    font-size: 16px;
    font-weight: 600;

    .remove-button {
      cursor: pointer;
      &.not-editable {
        cursor: not-allowed;
      }
    }
  }

  .form {
    margin-bottom: 32px;
  }

  .el-input {
    width: calc(100% - 52px);

    ::v-deep.el-input__inner {
      font-family: $primary-font;
      color: $primary-color;
    }
  }
}

@media (max-width: 1280px) {
  .convo-key-list-input {
    &:not(:last-child) {
      margin-bottom: 12px;
    }

    .header {
      font-size: 14px;
      margin-bottom: 12px;
    }

    .form {
      margin-bottom: 24px;
    }
  }
}
</style>
