import CpdStoreToYamlService from '@/common/services/CpdStoreToYamlService';
import store from '@/common/store';

/**
 * This service encapsulates all functionality required to load Translations to and from the store.
 */
const TranslationTransformationService = (() => {
  return {
    /**
     * Clears all unused translations for the given id (cpdId + v + versionNumber) in the store
     * and returns the cleared translation object.
     * @param {String} uniqueCpdId The cpd to get the translations for.
     */
    getUsedMessagesFromStore(uniqueCpdId) {
      const yaml = CpdStoreToYamlService.buildCpdYamlFromStoreValues(uniqueCpdId);
      const { translations } = store.getters;
      Object.keys(translations).forEach((lang) => {
        Object.keys(translations[lang]).forEach((key) => {
          const value = translations[lang][key];
          if (!yaml.includes(key) || !value || value.trim() === '') {
            // delete unused translations
            store.commit('deleteTranslation', { uniqueCpdId, key, lang });
          }
        });
      });

      return store.getters.translations;
    },

    /**
     * @param {String} uniqueCpdId The uniqueCpdId to get the translations for.
     * @param {String} lang The language to get the translations for.
     *
     * @returns {Object} the translation object for a given uniqueCpdId and lang
     */
    getTranslations(uniqueCpdId, lang) {
      const translations = this.getUsedMessagesFromStore(uniqueCpdId);
      return translations[lang];
    },

    /**
     * Put given translations into the store.
     *
     * @param {String} deString The unparsed german translations.
     * @param {String} enString The unparsed english translations.
     */
    uploadTranslations(deString, enString) {
      const de = JSON.parse(deString);
      const en = JSON.parse(enString);

      store.commit('updateTranslations', { de, en });
    },
  };
})();

export default TranslationTransformationService;
