import convoProcessComponents from '@/common/definition/convo_process_components';

/**
 * This service encapsulates all functionality regarding Convo Process Components.
 *
 * Note that the area of responsibility of this service ends *before* the CPD. Thus meaning that
 * this service may provide functionality to get instances of CPCs but not attach/insert them into
 * the CPC within the store. Therefore this service can be seen as a provider of translation between
 * the JSON component definitions and the JS object instances of components as well as providing ad hoc
 * access to the component meta information defined within the JSON defintions.
 *
 * Also note that this service still contains methods which will be removed/relocated with future refactoring.
 */
const ConvoProcessComponentService = (() => {
  return {
    /**
     * @returns a list of all fields which can modified for a given element type
     * @param {String} elementType
     */
    getElementFields(elementType) {
      const { definition } = convoProcessComponents[elementType];
      return Object.keys(definition).map((fieldKey) => ({ key: fieldKey, ...definition[fieldKey] }));
    },
  };
})();

export default ConvoProcessComponentService;
