<template>
  <div class="convo-chat-container">
    <div ref="msg-container" class="div-msg">
      <convo-chat-messages-area :answers="allAnswers" :is-finished="isLastInput" :question="currentQuestionKey" :questions="allQuestions" />
    </div>
    <div class="div-input">
      <convo-chat-input-area
        :chat-data="inputContainerData"
        :is-answer-text-input="isTextInput"
        :is-finished="isLastInput"
        :is-textarea-input="isTextarea"
        @answer="inputAnswer"
        @selected-answer-and-next="saveSelectedInput"
      />
    </div>
  </div>
</template>

<script>
import ConvoChatContentApiService from '@/common/services/ConvoChatContentApiService';
import ConvoChatInputArea from '@/dashboard/components/chat/components/chat_container/ConvoChatInputArea.vue';
import ConvoChatMessagesArea from '@/dashboard/components/chat/components/chat_container/ConvoChatMessagesArea.vue';
import convoChatContent from '@/common/definition/convo_chat/convo_chat_content.json';

export default {
  name: 'ConvoChatContainer',
  components: { ConvoChatInputArea, ConvoChatMessagesArea },
  data() {
    return {
      allAnswers: [],
      allQuestions: [],
      answersDto: {},
      chatMessages: convoChatContent,
      currentBlock: 'ChatStartPoint',
      isLastInput: false, // Boolean for InputContainer and MessagesContainer. Needed for last message in Feedback.
      isTextarea: false,
      isTextInput: false,
    };
  },
  computed: {
    /** Send data to InputContainer (to input an answer || display the options) */
    inputContainerData() {
      let msg = [];
      this.inputContainerType();
      if (this.currentType === 'input') {
        msg.push(this.currentPlaceholder);
        msg.push(this.currentDataType);
      } else {
        msg = this.currentOptions;
      }
      return msg;
    },
    currentType() {
      return this.chatMessages[this.currentBlock].type;
    },
    currentQuestionKey() {
      return this.chatMessages[this.currentBlock].question;
    },
    currentPlaceholder() {
      return this.chatMessages[this.currentBlock].placeholder;
    },
    currentDataDto() {
      return this.chatMessages[this.currentBlock].dataDto;
    },
    currentDataType() {
      return this.chatMessages[this.currentBlock].dataType;
    },
    currentOptions() {
      return this.chatMessages[this.currentBlock].options;
    },
    nextCurrent() {
      return this.chatMessages[this.currentBlock].next;
    },
  },
  methods: {
    /** Collect input data from the user */
    fillAnswerDto(msg) {
      this.answersDto[this.currentDataDto] = msg;
    },

    /** For chat answer input: Update currentBlock. From InputContainer to MessagesContainer. */
    inputAnswer(msg) {
      if (this.currentDataDto) {
        this.fillAnswerDto(msg);
      }
      if (this.nextCurrent) {
        this.allQuestions.push(this.currentQuestionKey);
        this.allAnswers.push({ answerType: this.currentType, answer: msg });
        this.currentBlock = this.nextCurrent;
      } else if (!this.isLastInput) {
        // When last message is a user input (as in Feedback-option)
        this.allQuestions.push(this.currentQuestionKey);
        this.allAnswers.push({ answerType: this.currentType, answer: msg });
        this.isLastInput = true;
        ConvoChatContentApiService.sendUserInput(this.answersDto); // Post to Db when chat has finished && last message is a user input
      }
    },

    /** Set Booleans to display the kind of input needed in InputContainer (Type=input||options; DataType= textarea || text || email || number) */
    inputContainerType() {
      this.isTextInput = this.currentType === 'input';
      this.isTextarea = this.currentDataType === 'textarea';
    },

    /** For chat answer options: Add the selected answer && Update currentBlock && Send to fillAnswerDto the option-value */
    saveSelectedInput({ selectedAnswer, optionNext, optionValue }) {
      if (this.currentDataDto && optionValue !== null) {
        this.fillAnswerDto(optionValue);
      }
      this.allAnswers.push({ answerType: this.currentType, answer: selectedAnswer });
      this.allQuestions.push(this.currentQuestionKey);
      this.currentBlock = optionNext;
      if (!this.currentType)
        // Type != input || option
        ConvoChatContentApiService.sendUserInput(this.answersDto); // Post to Db when chat has finished && last message is a chat message
    },

    scrollToBottom() {
      this.$refs['msg-container'].scrollTop = this.$refs['msg-container'].scrollHeight;
    },
  },
  updated() {
    this.scrollToBottom();
  },
};
</script>

<style lang="scss" scoped>
.convo-chat-container {
  //COLOR
  background: rgba(243, 245, 249, 1);
  //LAYOUT
  border-radius: 0 0 10px 10px;
  display: flex;
  flex-flow: column;
  height: $chat-body-height;

  .div-input {
    flex: 0 1 auto;
  }

  .div-msg {
    flex: 1 1 auto;
    overflow: auto;
  }
}

@media (max-width: 1280px) {
  .convo-chat-container {
    height: $chat-body-height-1280;
  }
}
</style>
