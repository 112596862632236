import Vue from 'vue';

const getDefaultState = () => ({
  expressions: {},
  fieldMapping: {},
});

export default function expressionLanguage() {
  const initialState = getDefaultState();

  const getters = {
    expression: (state) => {
      return (expressionId) => state.expressions[expressionId];
    },
    expressionIdForField: (state) => {
      return (elementId, fieldKey) => state.fieldMapping[`${elementId}.${fieldKey}`];
    },
  };

  const mutations = {
    addExpression(state, { id, expression }) {
      Vue.set(state.expressions, id, expression);
    },
    updateExpressionField(state, { id, field, value }) {
      Vue.set(state.expressions[id], field, value);
    },
    setFieldMapping(state, { elementId, fieldKey, expressionId }) {
      Vue.set(state.fieldMapping, `${elementId}.${fieldKey}`, expressionId);
    },
    clear(state) {
      Object.entries(getDefaultState()).forEach(([key, value]) => {
        Vue.set(state, key, value);
      });
    },
  };

  return {
    state: initialState,
    getters,
    mutations,
  };
}
