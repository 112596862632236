import store from '@/common/store';
import InvalidReferenceType from '@/common/enums/InvalidReferenceType';
import ConvoProcessComponentService from '@/common/services/ConvoProcessComponentService';
import i18n from '@/common/i18n';
import { generateMvNameForListItem } from '@/common/store/modules/magic-variables';

/**
 * This service encapsulates all functionality regarding the handling of MVs.
 */
const MagicVariablesService = (() => {
  function addToMvTypeCache(mvId, type) {
    store.commit('updateMvTypeCache', { mvId, value: type });
  }

  function getFromMvTypeCache(mvId) {
    return store.getters.mvTypeCache(mvId);
  }

  function addToMvNameCache(mvId, name) {
    store.commit('updateMvNameCache', { mvId, value: name });
  }

  function getFromMvNameCache(mvId) {
    const func = store.getters.mvNameCache(mvId);
    return typeof func === 'function' ? func() : func;
  }

  function getMagicVariableReferences() {
    return store.getters.magicVariableReferences;
  }

  function deleteMagicVariableReference(reference) {
    store.commit('deleteMagicVariableReference', reference);
  }

  function addMagicVariableReference(reference) {
    store.commit('addMagicVariableReference', reference);
  }

  function getMagicVariables() {
    return store.getters.magicVariables;
  }

  function addMagicVariable(magicVariableId) {
    store.commit('addMagicVariable', magicVariableId);
  }

  function deleteMagicVariable(magicVariableId) {
    store.commit('deleteMagicVariable', magicVariableId);
  }

  function flatExportedVariableGroup(magicVariable) {
    return magicVariable.structuralRole === 'group' ? magicVariable.children.flatMap(flatExportedVariableGroup) : magicVariable;
  }

  return {
    /**
     * Retrieves the key of the MV with the given ID (e.g. "value").
     *
     * @param {string} mvId The ID of the specific MV, e.g. "9f805893-264f-4dd2-a125-302f8af7f15a.value".
     */
    getKeyOfMv(mvId) {
      return mvId.split('.')[1];
    },

    /**
     * Retrieves the type of a specific MV (e.g. "string").
     *
     * @param {string} mvId The ID of the specific MV, e.g. "9f805893-264f-4dd2-a125-302f8af7f15a.value".
     */
    getTypeOfMv(mvId) {
      const type = getFromMvTypeCache(mvId);
      if (!type) {
        // Update the cache for the component which exports the requested MV
        this.getExportedVars(this.getIdOfComponentExportingMv(mvId));
        return getFromMvTypeCache(mvId);
      }
      return type;
    },

    /**
     * Retrieves the name of a specific MV (e.g. "Eingabewert").
     *
     * @param {string} mvId The ID of the specific MV, e.g. "9f805893-264f-4dd2-a125-302f8af7f15a.value".
     */
    getNameOfMv(mvId) {
      const name = getFromMvNameCache(mvId);
      if (!name) {
        // Update the cache for the component which exports the requested MV
        this.getExportedVars(this.getIdOfComponentExportingMv(mvId));
        return getFromMvNameCache(mvId);
      }
      return name;
    },

    /**
     * Retrieves the ID of the component exporting the MV with the given ID.
     *
     * @param {string} mvId The ID of the specific MV, e.g. "9f805893-264f-4dd2-a125-302f8af7f15a.value".
     */
    getIdOfComponentExportingMv(mvId) {
      return mvId.split('.')[0];
    },

    /**
     * @returns true if a magic variable with the given id exists otherwise false
     * @param {String} mvId magic variable id to be checked
     */
    isMvValid(mvId) {
      return getMagicVariables().includes(mvId);
    },

    /**
     * Updates the mv references of a given element field, i.e. all magic variables used by the field.
     * It does this by deleting all existing references and then adding the given references.
     *
     * @param {String} elementId ID of the element containing the field for which the references are to be updated.
     * @param {String} fieldKey Key of the field for which the references are to be updated.
     * @param {Array} magicVariableIds A list of all magic variables that are currently referenced by the field.
     */
    updateMvReferencesForElementField(elementId, fieldKey, magicVariableIds) {
      getMagicVariableReferences()
        .filter((reference) => reference.elementId === elementId && reference.fieldKey === fieldKey)
        .forEach(deleteMagicVariableReference);
      magicVariableIds.forEach((magicVariableId) => addMagicVariableReference({ elementId, fieldKey, magicVariableId }));
    },

    /**
     * Adds a magic variable to the GlobalMagicVariableState which contains all current magic variables.
     *
     * @param {String} magicVariableId The ID of the magic variable to be added.
     */
    addMagicVariable,

    /**
     * Adds all exported magic variables of a given element to the GlobalMagicVariableState which contains all current magic variables.
     *
     * @param {String} elementId The ID of the element whose exported magic variables are to be added.
     */
    addElement(elementId) {
      this.getExportedVars(elementId)
        .flatMap(flatExportedVariableGroup)
        .map((magicVariable) => magicVariable.id)
        .forEach(addMagicVariable);
    },

    /**
     * Deletes a magic variable from the GlobalMagicVariableState which contains all current magic variables.
     *
     * @param {String} magicVariableId The ID of the magic variable to be deleted.
     */
    deleteMagicVariable,

    /**
     * Deletes all exported magic variables of a given element from the GlobalMagicVariableState which contains all current magic variables.
     * Deletes all references of the element from the GlobalMagicVariableReferenceState which contains all current magic variables references.
     *
     * @param {String} elementId The ID of the element whose exported magic variables and references are to be deleted.
     */
    deleteElement(elementId) {
      this.getExportedVars(elementId)
        .flatMap(flatExportedVariableGroup)
        .map((magicVariable) => magicVariable.id)
        .forEach(deleteMagicVariable);
      getMagicVariableReferences()
        .filter((reference) => reference.elementId === elementId)
        .forEach(deleteMagicVariableReference);
    },

    /**
     * @returns A distinct list of magicVariableIds which are used by the given element.
     * @param {String} elementId The ID of the element to get the imported mvs for.
     */
    getImportedMagicVariables(elementId) {
      return getMagicVariableReferences()
        .filter((reference) => reference.elementId === elementId)
        .map((reference) => reference.magicVariableId)
        .filter((reference, index, self) => self.indexOf(reference) === index);
    },

    /**
     * A reference is invalid if the referenced magic variable doesn't exists (InvalidReferenceType.DELETED_MV)
     * orthe  referenced magic variable is defined after the element which uses it (InvalidReferenceType.INVALID_ORDER).
     *
     * @returns The List of all invalid references ({elementId, fieldKey, magicVariableId, invalidReferenceType}).
     */
    getInvalidReferences(uniqueCpdId) {
      const isMvDeleted = (magicVariableId) => !getMagicVariables().includes(magicVariableId);
      const isOrderInvalid = (exportingElement, importingElement) => {
        const flatElementOrder = store.getters.flatElementOrder(uniqueCpdId);
        return flatElementOrder.indexOf(importingElement) < flatElementOrder.indexOf(exportingElement);
      };
      return getMagicVariableReferences()
        .filter(
          (reference) =>
            isMvDeleted(reference.magicVariableId) ||
            isOrderInvalid(this.getIdOfComponentExportingMv(reference.magicVariableId), reference.elementId)
        )
        .map((reference) => {
          const invalidReferenceType = isMvDeleted(reference.magicVariableId)
            ? InvalidReferenceType.DELETED_MV
            : InvalidReferenceType.INVALID_ORDER;
          return { ...reference, invalidReferenceType };
        });
    },

    /**
     * Retrieves all exported MVs and MV groups for a specific component in the structure and order
     * declared in the respective component.json. Also handles i18n. Also updates the mv type and name cache for
     * the referenced component.
     *
     * @param {string} componentId The key of the specific component in the store
     */
    getExportedVars(componentId) {
      // retrieve component type from the store
      const componentType = store.getters.componentById(componentId).type;
      const { exportedVars } = componentType ? ConvoProcessComponentService.getMetadata(componentType) : {};

      // return empty array in case no exported vars present
      if (!exportedVars) {
        return [];
      }

      const mapExportedVarsRecursive = (element) => {
        // Case 1: element is a list (root list of exportedVars or from a group children field)
        if (Array.isArray(element)) {
          return element.flatMap(mapExportedVarsRecursive);
        }

        // Case 2: element is an object (representing a group or a mv declaration)
        switch (element.structuralRole) {
          case 'group':
            return {
              structuralRole: 'group',
              label: i18n.t(`${componentType}.${element.labelKey}`),
              children: mapExportedVarsRecursive(element.children),
            };
          case 'mv_declaration':
            switch (element.strategy) {
              case 'map_to_list': {
                const list = store.getters.componentById(componentId)[element.listId];
                return Array.isArray(list)
                  ? store.getters.componentById(componentId)[element.listId].map((listItemId) => {
                      const id = `${componentId}.${element.listId}.${listItemId}`;
                      addToMvTypeCache(id, element.type);
                      addToMvNameCache(id, {
                        listItemId,
                        keyOfFieldToUseAsLabel: element.keyOfFieldToUseAsLabel,
                      });
                      const mvName = generateMvNameForListItem(listItemId, element.keyOfFieldToUseAsLabel, store.getters);
                      return {
                        structuralRole: 'mv',
                        label: mvName, // calling mv name cache here would led to infinite loops caused by reactivity fuckups
                        type: element.type,
                        id,
                      };
                    })
                  : [];
              }
              case 'static': {
                const id = `${componentId}.${element.cpdReferenceKey}`;
                const translationKey = `${componentType}.${element.labelKey}`;
                addToMvTypeCache(id, element.type);
                addToMvNameCache(id, { translationKey });
                return {
                  structuralRole: 'mv',
                  label: i18n.t(translationKey), // calling mv name cache here would led to infinite loops caused by reactivity fuckups
                  type: element.type,
                  id,
                };
              }
              default:
                throw new Error(`MV strategy "${element.strategy}" unknown.`);
            }
          default:
            throw new Error(`StructuralRole "${element.structuralRole}" unknown.`);
        }
      };
      return mapExportedVarsRecursive(exportedVars);
    },
  };
})();

export default MagicVariablesService;
