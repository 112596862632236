<template>
  <div class="app" :class="{ 'min-width': !isFullPreview }">
    <template v-if="showHeaders">
      <convo-header />
      <div class="content">
        <router-view />
      </div>
      <convo-toast-holder />
    </template>
    <template v-else>
      <router-view />
    </template>
  </div>
</template>
<script>
import RouterMixin from '@/common/mixins/RouterMixin';
import ConvoToastHolder from '@/common/components/ConvoToastHolder.vue';
import ConvoHeader from './ConvoHeader.vue';

export default {
  name: 'App',
  components: {
    ConvoToastHolder,
    ConvoHeader,
  },
  mixins: [RouterMixin],
  computed: {
    isFullPreview() {
      return this.$route.path.includes('preview-full');
    },
  },
};
</script>

<style></style>

<style lang="scss">
@import 'src/common/styles/fonts';

html,
body {
  font-family: $primary-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  width: 100%;
  margin: 0;
  scrollbar-color: $primary-color #ffffff !important;
  overflow: hidden;
}
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: $primary-color;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}
</style>

<style lang="scss" scoped>
.app {
  min-height: 100%;
  width: 100%;
}

.min-width {
  min-width: $app-min-width;
}

.content {
  display: flex;
}
</style>
