<template>
  <div class="convo-message" :id="`message-${index}`">
    <div class="prefix">
      <div class="previous" v-if="previousMessage" @click="$emit('previous')">
        <i class="convo-icon-left" />
      </div>
    </div>

    <div class="message">
      <div class="title">{{ title }}</div>
      <div class="content">
        {{ message.contentHandler() }}
        <span class="open-element" @click="applyActionHandler">{{ $t('messages.displayElement') }}</span>
      </div>
    </div>

    <div class="suffix">
      <div class="next" v-if="nextMessage" @click="$emit('next')">
        <i class="convo-icon-right" />
      </div>
    </div>
  </div>
</template>

<script>
import { getShortKey } from '@/common/enums/MessageType';

export default {
  name: 'ConvoMessage',
  props: {
    message: {
      required: true,
      type: Object,
    },
    index: {
      required: true,
      type: Number,
    },
    previousMessage: {
      type: Boolean,
      required: true,
    },
    nextMessage: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    title() {
      return this.$t(`messages.${getShortKey(this.message.type)}.title`);
    },
  },
  methods: {
    applyActionHandler() {
      if (this.message.actionHandler) {
        this.message.actionHandler();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.convo-message {
  display: flex;
  padding: 16px;
  border: 2px solid $secondary-color;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.25);

  .prefix,
  .suffix {
    width: 32px;
    display: flex;
    align-items: center;

    .previous,
    .next {
      color: $secondary-color;
      cursor: pointer;
    }
  }

  .message {
    margin: 0 12px;

    .title {
      font-size: 16px;
      color: $secondary-color;
      margin-bottom: 8px;
    }

    .content {
      font-size: 16px;
      color: $primary-color;
      text-align: justify;
      text-justify: inter-word;

      .open-element {
        color: $secondary-color;
        text-decoration: underline;
        margin-left: auto;
        cursor: pointer;
        float: right;
      }
    }
  }
}

@media (max-width: 1280px) {
  .convo-message {
    padding: 10px;

    .message {
      margin: 0 8px;

      .title {
        font-size: 14px;
      }

      .content {
        font-size: 13px;
      }
    }
  }
}
</style>
