<template>
  <convo-dialog
    :button-click-not-required="buttonClickNotRequired"
    :cancel-label="cancelLabel"
    :checkbox-content="checkboxContent"
    :confirm-label="confirmLabel"
    :content="content"
    :has-checkbox="checkbox"
    :has-confirmation="true"
    :is-dialog-visible="visible"
    :title="title"
    @close="$emit('close')"
    @confirm="$emit('confirm')"
    @cancel="$emit('cancel')"
  />
</template>

<script>
import ConvoDialog from '@/common/components/ConvoDialog.vue';

export default {
  name: 'ConvoConfirmationDialog',
  components: { ConvoDialog },
  props: {
    buttonClickNotRequired: {
      type: Boolean,
      default: true,
    },
    cancelLabel: {
      type: String,
      required: true,
    },
    checkbox: {
      type: Boolean,
      default: false,
    },
    checkboxContent: {
      type: String,
      required: false,
    },
    confirmLabel: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
