import Vue from 'vue';

const getDefaultState = () => ({ cpdName: null });

export default function processDefinitions() {
  const initialState = getDefaultState();

  const getters = {
    componentById: (state) => {
      return (id) => state[id];
    },
    cpdEditable: (state) => state.cpdEditable,
    cpdName: (state) => state.cpdName,
    cpdState: (state) => state.cpdState,
    cpdWithoutChanges: (state) => state.cpdWithoutChanges,
    flatElementOrder: (state) => {
      return (uniqueCpdId) => {
        if (!state[uniqueCpdId]) {
          return [];
        }

        const getFlatElementOrderRecursive = (id) => {
          const flatOrder = [id];
          (state[id].children || state[id].choice_items || state[id].fields || [])
            .flatMap(getFlatElementOrderRecursive)
            .forEach((element) => flatOrder.push(element));
          return flatOrder;
        };

        const flatElementOrder = [state[uniqueCpdId].control_start];
        state[uniqueCpdId].children.flatMap(getFlatElementOrderRecursive).forEach((element) => flatElementOrder.push(element));
        flatElementOrder.push(state[uniqueCpdId].control_end);

        return flatElementOrder;
      };
    },
  };

  const mutations = {
    createElement(state, object) {
      Vue.set(state, object.id, object.element);
    },
    deleteElement(state, id) {
      Vue.delete(state, id);
    },
    updateElementField(state, object) {
      Vue.set(state[object.id], object.fieldKey, object.value);
    },
    updateCpdEditable(state, newState) {
      Vue.set(state, 'cpdEditable', newState);
    },
    updateCpdName(state, name) {
      Vue.set(state, 'cpdName', name);
    },
    updateCpdState(state, newState) {
      Vue.set(state, 'cpdState', newState);
    },
    updateCpdWithoutChanges(state, newState) {
      Vue.set(state, 'cpdWithoutChanges', newState);
    },
    clear(state) {
      // Make it reactive
      Object.keys(state).forEach((id) => Vue.delete(state, id));
      Object.entries(getDefaultState()).forEach(([key, value]) => {
        Vue.set(state, key, value);
      });
    },
  };

  return {
    state: initialState,
    getters,
    mutations,
  };
}
