import convoProcessComponents from '@/common/definition/convo_process_components';
import i18n from '@/common/i18n';

/**
 * This service encapsulates all functionality regarding Convo Process Components.
 *
 * Note that the area of responsibility of this service ends *before* the CPD. Thus meaning that
 * this service may provide functionality to get instances of CPCs but not attach/insert them into
 * the CPC within the store. Therefore this service can be seen as a provider of translation between
 * the JSON component definitions and the JS object instances of components as well as providing ad hoc
 * access to the component meta information defined within the JSON defintions.
 *
 * Also note that this service still contains methods which will be removed/relocated with future refactoring.
 */
const ConvoProcessComponentService = (() => {
  return {
    /**
     * @returns a function with can use to creates an element instance of the given element type
     * @param {String} elementType The element type to get creation handler for.
     */
    getElementCreationHandler(elementType) {
      return (id) => {
        const element = {};

        const { definition } = convoProcessComponents[elementType];

        Object.keys(definition).forEach((key) => {
          const fieldDefinition = definition[key];
          const isTranslatableField =
            (fieldDefinition.type === 'String' || fieldDefinition.type === 'RichText') && !fieldDefinition.translation_disabled;
          // initialize with default value or translation key depending whether field is translatable
          element[key] = isTranslatableField ? `$TRANSLATE(${id}.${key})` : fieldDefinition.default;
        });

        if (definition.name) {
          element.name = i18n.t(`${elementType}.defaultName`);
        }

        return element;
      };
    },
    getInspectorPanelDefinition(elementType) {
      return convoProcessComponents[elementType].meta.ip_presentation;
    },
    getFieldDefinition(elementType, fieldKey) {
      return convoProcessComponents[elementType].definition[fieldKey];
    },
    getMetadata(type) {
      return convoProcessComponents[type].meta;
    },
    getDefaultValue(elementType, fieldKey) {
      return convoProcessComponents[elementType].definition[fieldKey].default;
    },
  };
})();

export default ConvoProcessComponentService;
