<template>
  <div class="convo-options-select">
    <div class="activator" @click.stop="handleActivatorToggle">
      <el-button id="questionmark"><i class="convo-icon-questionmark-circle-alt" /></el-button>
      <i class="convo-icon-down" />
    </div>
    <div class="options" v-if="optionsVisible">
      <div class="option" @click="openUserManual">
        {{ $t('userManual') }}
      </div>
      <div class="option" @click="showLicenseDialog = true">
        {{ $t('licenses.licenses') }}
      </div>
    </div>
    <convo-license-dialog :visible="showLicenseDialog" @close="showLicenseDialog = false" />
  </div>
</template>

<script>
import ConvoLicenseDialog from '@/common/components/ConvoLicenseDialog.vue';

export default {
  name: 'ConvoOptionsSelect',
  components: { ConvoLicenseDialog },
  data() {
    return {
      optionsVisible: false,
      showLicenseDialog: false,
    };
  },
  methods: {
    handleOptionSelect(option) {
      this.closeOptions();
      this.$emit(option);
    },
    handleActivatorToggle() {
      if (this.optionsVisible) {
        this.closeOptions();
      } else {
        this.openOptions();
      }
    },
    openOptions() {
      this.optionsVisible = true;
      this.$emit('close');
      document.addEventListener('click', this.closeOptions);
    },
    closeOptions() {
      this.optionsVisible = false;
      this.$emit('close');
      document.removeEventListener('click', this.closeOptions);
    },
    openUserManual() {
      window.open('https://dinjec62guzne.cloudfront.net/manual/Convo_Benutzerhandbuch.pdf');
    },
  },
};
</script>

<style lang="scss" scoped>
.convo-options-select {
  position: relative;

  .activator {
    display: flex;
    align-items: flex-end;
    color: #fff;
    cursor: pointer;

    #questionmark {
      background-color: transparent;
      border: none;

      .convo-icon-questionmark-circle-alt {
        color: $primary-text-color;
        font-size: 28px;
      }
    }

    .convo-icon-down {
      font-size: 6px;
      margin: 16px 12px 15px -8px;
      color: $primary-text-color;
    }
  }
  .options {
    z-index: 4;
    position: absolute;
    left: -8px;
    top: 45px;
    border-radius: 8px;
    background-color: white;
    padding: 8px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    white-space: nowrap;

    .option {
      display: flex;
      align-items: center;
      font-size: 14px;
      cursor: pointer;
      padding: 8px;
      border-radius: 8px;

      &:hover {
        background-color: rgba(44, 62, 89, 0.2);
      }
    }
  }
}

@media (max-width: 1280px) {
  .convo-options-select {
    .options {
      top: 35px;
      padding: 4px;

      .option {
        font-size: 13px;
      }
    }
    .activator {
      #questionmark {
        .convo-icon-questionmark-circle-alt {
          font-size: 20px;
        }
      }

      .convo-icon-down {
        font-size: 6px;
      }
    }
  }
}
</style>
