<template>
  <div class="convo-editorial-view">
    <div v-if="!isCpdEditable" class="view-mode-warning"><i class="convo-icon-read-mode" />{{ $t('readMode') }}</div>
    <div class="editorial-area">
      <div class="header">
        <div class="row">
          <div class="title">
            <i class="convo-icon-translation" />
            {{ $t('editorialView.label') }}
          </div>
          <div class="add-language">
            <i class="convo-icon-plus-circle" />
            {{ $t('addLanguage') }}
          </div>
        </div>
        <div class="row">
          <div class="language">
            <country-flag country="de" rounded />
            {{ $t('editorialView.de') }}
          </div>

          <div class="language">
            <country-flag country="gb" rounded />
            {{ $t('editorialView.en') }}
          </div>
        </div>
      </div>

      <convo-translation-container
        v-for="element in elements"
        :key="element.id"
        :element-id="element.id"
        :translatableFields="element.translatableFields"
        :children="element.children"
        :i18n-prefix="element.i18nPrefix"
        :elementType="element.elementType"
        :editable="isCpdEditable"
      />
    </div>
  </div>
</template>

<script>
import CountryFlag from 'vue-country-flag';
import CpdModificationService from '@/common/services/CpdModificationService';
import ConvoProcessComponentService from '@/editorial_view/services/ConvoProcessComponentService';
import ConvoTranslationContainer from '@/editorial_view/components/ConvoTranslationContainer.vue';

export default {
  name: 'ConvoEditorialView',
  components: { CountryFlag, ConvoTranslationContainer },
  props: {
    uniqueCpdId: {
      type: String,
      required: true,
    },
  },
  computed: {
    elements() {
      const getElementsRecursive = (id) => {
        const elementType = CpdModificationService.getElementField(id, 'type');
        const translatableFields = ConvoProcessComponentService.getElementFields(elementType)
          .filter((field) => field.type === 'String' || field.type === 'RichText')
          .filter((field) => !field.translation_disabled);
        let children = CpdModificationService.getElementField(id, 'children');
        children = children ? children.map((child) => getElementsRecursive(child)) : this.getListItems(id); // elments without children may have children from a list input
        return {
          id,
          translatableFields,
          children,
          i18nPrefix: elementType,
          elementType,
        };
      };

      return CpdModificationService.getElementField(this.uniqueCpdId, 'children').map((child) => getElementsRecursive(child));
    },
    isCpdEditable() {
      return this.$store.getters.cpdEditable;
    },
  },
  methods: {
    getListItems(elementId) {
      const elementType = CpdModificationService.getElementField(elementId, 'type');
      return ConvoProcessComponentService.getElementFields(elementType)
        .filter((field) => field.type === 'List')
        .map((listField) => {
          // add all translatable fields of the item template to the list field
          const translatableFields = Object.keys(listField.item_template)
            .map((key) => {
              return { key, ...listField.item_template[key] };
            })
            .filter((itemField) => itemField.type === 'String' || itemField.type === 'RichText')
            .filter((field) => !field.translation_disabled);
          return { key: listField.key, translatableFields };
        })
        .flatMap((listField) => {
          // create for every list item an entry
          return CpdModificationService.getElementField(elementId, listField.key).map((id) => {
            return {
              id,
              translatableFields: listField.translatableFields,
              i18nPrefix: `${elementType}.${listField.key}`,
              elementType,
            };
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.convo-editorial-view {
  width: 100%;
  padding: 25px 50px;
  box-sizing: border-box;
  height: calc(100vh - #{$header-height});
  overflow: auto;

  .header {
    font-weight: bold;
    font-size: 24px;
    width: 100%;
    color: white;
    padding: 8px 8px 0 12px;
    display: inline-block;

    .row {
      width: 100%;
      display: inline-block;
      &:first-of-type {
        margin-bottom: 12px;
      }
    }

    .title {
      display: flex;
      align-items: center;
      float: left;
    }

    .convo-icon-translation {
      font-size: 36px;
      margin-right: 14px;
    }

    .language {
      width: 25vw;
      min-width: 250px;
      max-width: 400px;
      float: left;

      &:first-of-type {
        margin-left: 58px;
        margin-right: 100px;
      }
    }

    .add-language {
      display: none; //remove style above after adding functionality to add languages
      font-size: 16px;
      line-height: 36px;
      float: right;
    }
  }

  .editorial-area {
    background-color: $convo-blue-6;
    border-radius: 16px;
    padding: 8px;
  }

  .view-mode-warning {
    background-color: #f68705;
    color: white;
    margin-bottom: 20px;
    padding: 16px 0;
    border-radius: 20px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;

    .convo-icon-read-mode {
      margin-right: 16px;
    }
  }
}

@media (max-width: 1280px) {
  .convo-editorial-view {
    height: calc(100vh - #{$header-height-1280});

    .header {
      font-size: 16px;
      padding-top: 4px;

      .convo-icon-translation {
        font-size: 24px;
        margin: 0 12px 0 4px;
      }

      .language:first-of-type {
        margin-left: 40px;
        margin-right: 100px;
      }
    }

    .view-mode-warning {
      padding: 10px 0;
      font-size: 16px;
    }
  }
}
</style>
