<template>
  <div class="convo-translation-container" :class="[elementType, { 'has-no-children': !children || !children.length }]">
    <div class="header" @click="collapsed = !collapsed">
      <div class="name-wrapper">
        <i :class="getConvoIconForElementType(elementType)"></i>
        <span>{{ elementName + ' ' + nameSuffix }}</span>
        <div class="warning" v-if="isInconsistent">
          <i class="convo-icon-warning" />
        </div>
      </div>
      <i v-if="collapsed" class="convo-icon-plus" />
      <i v-else class="convo-icon-minus" />
    </div>
    <div v-if="!collapsed" class="body">
      <div class="translations">
        <convo-translation-input
          v-for="translation in translatableFields"
          :key="translation.key"
          :field-key="translation.key"
          :data-type="translation.type"
          :element-id="elementId"
          :i18n-prefix="i18nPrefix"
          :editable="editable"
        />
      </div>
      <div class="children" v-if="children">
        <convo-translation-container
          v-for="(child, index) in children"
          :key="child.id"
          :element-id="child.id"
          :translatable-fields="child.translatableFields"
          :children="child.children"
          :i18n-prefix="child.i18nPrefix"
          :element-type="child.elementType"
          :editable="editable"
          :name-suffix="elementType === ElementType.CHOICE ? index + 1 : ''"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CpdModificationService from '@/common/services/CpdModificationService';
import TranslationModificationService from '@/common/services/TranslationModificationService';
import ElementType from '@/common/enums/ElementType';
import ConvoTranslationInput from './ConvoTranslationInput.vue';

export default {
  name: 'ConvoTranslationContainer',
  components: { ConvoTranslationInput },
  props: {
    elementId: {
      type: String,
      required: true,
    },
    translatableFields: {
      type: Array,
      required: true,
    },
    children: {
      type: Array,
      required: false,
    },
    i18nPrefix: {
      type: String,
      required: true,
    },
    elementType: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    nameSuffix: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      collapsed: false,
      ElementType,
    };
  },
  computed: {
    elementName() {
      return CpdModificationService.getElementField(this.elementId, 'name');
    },
    isInconsistent() {
      const getInconsistenciesRecursive = (elementId, translatableFields, children) => {
        const hasInconsistencies = translatableFields.some((translatableField) => {
          const valueDe = TranslationModificationService.getTranslation('de', elementId, translatableField.key);
          const valueEn = TranslationModificationService.getTranslation('en', elementId, translatableField.key);
          return (valueDe && !valueEn) || (!valueDe && valueEn);
        });
        const childrenHaveInconsistencies = children
          ? children.some((child) => getInconsistenciesRecursive(child.id, child.translatableFields, child.children))
          : false;
        return hasInconsistencies || childrenHaveInconsistencies;
      };

      return getInconsistenciesRecursive(this.elementId, this.translatableFields, this.children);
    },
  },
  methods: {
    getConvoIconForElementType(elementType) {
      switch (elementType) {
        case 'section':
          return 'convo-icon-process';
        case 'subsection':
          return 'convo-icon-structure';
        case 'group':
          return 'convo-icon-group';
        case 'dynamic_group':
          return 'convo-icon-dynamic-group';
        default:
          return 'convo-icon-element';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.convo-translation-container {
  border-radius: 16px;
  width: 100%;
  padding: 8px 0 8px 8px;
  margin-bottom: 12px;
  box-sizing: border-box;
  color: $primary-color;

  .header {
    cursor: pointer;
    padding: 10px;
    display: flex;
    justify-content: space-between;

    .name-wrapper {
      font-size: 18px;
      text-align: center;
      display: flex;
      align-items: center;

      .warning {
        margin-left: 5px;
        color: $secondary-color;
        float: right;
      }
    }
  }

  .children {
    margin-top: 16px;
  }

  &.section {
    background-color: $convo-blue-4;

    .header {
      padding-left: 25px;

      .convo-icon-process {
        font-size: 6px;
        margin-right: 13px;
      }
    }

    .translations {
      padding-left: 61px;
    }
  }

  &.subsection {
    background-color: $convo-blue-3;

    .header {
      padding-left: 19px;

      .convo-icon-structure {
        font-size: 20px;
        margin-right: 13px;
      }
    }
  }

  &.group,
  &.dynamic_group {
    background-color: $convo-blue-2;

    .header {
      padding-left: 11px;

      .convo-icon-group,
      .convo-icon-dynamic-group {
        font-size: 20px;
        margin-right: 13px;
      }
    }

    .translations {
      padding-left: 44px;
    }
  }

  &.has-no-children,
  &.choice {
    background-color: $convo-blue-1;

    .header {
      padding-left: 4px;

      .convo-icon-element {
        font-size: 20px;
        margin-right: 12px;
      }
    }

    .translations {
      padding-left: 36px;
    }
  }

  &.choice.has-no-children {
    background-color: $convo-blue-2-lighten;
  }

  &.popup,
  &.rest {
    margin: 10px;
    width: auto;
  }
}

@media (max-width: 1280px) {
  .convo-translation-container {
    .header .name-wrapper {
      font-size: 14px;
    }

    .children {
      margin-top: 8px;
    }

    &.section {
      .header {
        padding-left: 12px;

        .convo-icon-process {
          font-size: 5px;
          margin-right: 13px;
        }
      }

      .translations {
        padding-left: 46px;
      }
    }

    &.subsection {
      .header {
        padding-left: 10px;

        .convo-icon-structure {
          font-size: 15px;
          margin-right: 12px;
        }
      }
    }

    &.group,
    &.dynamic_group {
      .header {
        padding-left: 6px;

        .convo-icon-group,
        .convo-icon-dynamic-group {
          font-size: 16px;
          margin-right: 8px;
        }
      }

      .translations {
        padding-left: 30px;
      }
    }

    &.has-no-children,
    &.choice {
      .header {
        padding-left: 0;

        .convo-icon-element {
          font-size: 14px;
          margin-right: 6px;
        }
      }

      .translations {
        padding-left: 22px;
      }
    }

    &.popup,
    &.rest {
      .header {
        margin-left: 4px;

        .convo-icon-element {
          margin-right: 16px;
        }
      }

      .translations {
        padding-left: 35px;
      }
    }
  }
}
</style>
