<template>
  <div>
    <draggable
      :animation="250"
      :value="components"
      :group="group"
      class="convo-draggable"
      drag-class="moving"
      ghost-class="ghost"
      @change="$emit('change', $event)"
      @choose="$emit('choose', $event)"
      draggable=".is-draggable"
      :force-fallback="true"
      :scroll-sensitivity="100"
      :scroll-speed="15"
    >
      <convo-brick
        v-for="componentId in components"
        :key="componentId"
        :component-id="componentId"
        :is-draggable="isDraggable"
        :class="isDraggable ? 'is-draggable' : 'not-draggable'"
      ></convo-brick>
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import ConvoBrick from './ConvoBrick.vue';

export default {
  name: 'ConvoDraggable',
  components: {
    draggable,
    ConvoBrick,
  },
  props: {
    components: {
      default: null,
      type: Array,
    },
    isDraggable: {
      default: true,
      type: Boolean,
    },
    group: {
      required: true,
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.convo-draggable {
  min-height: 60px;
  padding: 0 32px;

  ::v-deep .convo-brick:last-of-type {
    padding-bottom: 32px;
  }

  .container.group .convo-draggable {
    min-height: 92px;
  }

  ::v-deep .ghost {
    padding-bottom: 16px;
    margin-bottom: 0 !important;
  }
}
@media (max-width: 1400px) {
  .convo-draggable {
    padding: 0 24px;
  }
}

@media (max-width: 1280px) {
  .convo-draggable {
    min-height: 48px;
    padding: 0 24px;

    .container.group .convo-draggable {
      min-height: 76px;
    }
  }
}
</style>
