<template>
  <div class="convo-zoom-selector">
    <i class="convo-icon-zoom-out" @click="decreaseZoom" id="zoom-out-button" />
    {{ percentageString }}
    <i class="convo-icon-zoom-in" @click="increaseZoom" id="zoom-in-button" />
  </div>
</template>

<script>
export default {
  name: 'ConvoZoomSelector',
  props: {
    value: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      minZoom: 0.5,
      maxZoom: 1.5,
      zoomIntervall: 0.1,
    };
  },
  computed: {
    percentageString() {
      return `${Math.floor(this.value * 100)}%`;
    },
  },
  methods: {
    decreaseZoom() {
      if (this.round(this.value) > this.minZoom) {
        this.$emit('input', this.value - this.zoomIntervall);
      }
    },
    increaseZoom() {
      if (this.round(this.value) < this.maxZoom) {
        this.$emit('input', this.value + this.zoomIntervall);
      }
    },
    round(value) {
      return Math.round(value * 10) / 10;
    },
  },
};
</script>

<style lang="scss" scoped>
.convo-zoom-selector {
  align-items: center;
  color: $primary-color;
  display: flex;
  font-size: 22px;
  font-weight: 600;

  .convo-icon-zoom-out {
    cursor: pointer;
    font-size: 16px;
    margin-right: 12px;
  }

  .convo-icon-zoom-in {
    cursor: pointer;
    margin-left: 12px;
  }
}
</style>
