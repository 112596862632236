<template>
  <div class="convo-translation-input" v-if="translatable">
    <div class="input-holder">
      <div class="language">
        <div class="label">{{ localizedLabel }}</div>
        <el-input
          v-if="dataType === 'String'"
          :id="elementId + '-' + fieldKey + '-de'"
          v-model="valueDe"
          class="translation-input"
          :class="{ 'is-disabled': !editable }"
          :disabled="!editable"
        ></el-input>
        <div v-if="dataType === 'RichText'" class="translation-input">
          <convo-rich-text-editor :content="valueDe" :menubar-first="false" :editable="editable" @update="handleUpdateDe" />
        </div>
      </div>
      <div class="language">
        <div class="label">{{ localizedLabel }}</div>
        <el-input
          v-if="dataType === 'String'"
          class="translation-input"
          v-model="valueEn"
          :id="elementId + '-' + fieldKey + '-en'"
          :disabled="!editable"
        ></el-input>
        <div v-if="dataType === 'RichText'" class="translation-input">
          <convo-rich-text-editor :content="valueEn" :menubar-first="false" :editable="editable" @update="handleUpdateEn" />
        </div>
      </div>
    </div>
    <div class="warning" v-if="inconsistent">
      <i class="convo-icon-warning" />
    </div>
  </div>
</template>

<script>
import CpdModificationService from '@/common/services/CpdModificationService';
import TranslationModificationService from '@/common/services/TranslationModificationService';
import ConvoRichTextEditor from '@/common/components/ConvoRichTextEditor.vue';

export default {
  name: 'ConvoTranslationInput',
  components: { ConvoRichTextEditor },
  props: {
    fieldKey: {
      type: String,
      required: true,
    },
    dataType: {
      type: String,
      required: true,
    },
    elementId: {
      type: String,
      required: true,
    },
    i18nPrefix: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dialogVisibleDe: false,
      dialogVisibleEn: false,
    };
  },
  computed: {
    valueDe: {
      get() {
        return TranslationModificationService.getTranslation('de', this.elementId, this.fieldKey);
      },
      set(newValue) {
        TranslationModificationService.updateTranslation('de', this.elementId, this.fieldKey, newValue);
      },
    },
    valueEn: {
      get() {
        return TranslationModificationService.getTranslation('en', this.elementId, this.fieldKey);
      },
      set(newValue) {
        TranslationModificationService.updateTranslation('en', this.elementId, this.fieldKey, newValue);
      },
    },
    inconsistent() {
      return (this.valueDe && !this.valueEn) || (!this.valueDe && this.valueEn);
    },
    elementName() {
      return CpdModificationService.getElementField(this.elementId, 'name');
    },
    localizedLabel() {
      return this.$te(`${this.i18nPrefix}.${this.fieldKey}.editorialLabel`)
        ? this.$t(`${this.i18nPrefix}.${this.fieldKey}.editorialLabel`)
        : this.$t(`${this.i18nPrefix}.${this.fieldKey}.label`);
    },
    translatable() {
      const cpdValue = CpdModificationService.getElementField(this.elementId, this.fieldKey);
      return cpdValue && cpdValue.startsWith('$TRANSLATE(');
    },
  },
  methods: {
    handleUpdateDe(newValue) {
      this.valueDe = newValue;
    },
    handleUpdateEn(newValue) {
      this.valueEn = newValue;
    },
  },
};
</script>

<style lang="scss" scoped>
.convo-translation-input {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 10px 10px 0;
  box-sizing: border-box;
  .input-holder {
    width: 100%;

    .language {
      width: 25vw;
      min-width: 250px;
      max-width: 400px;
      float: left;
      margin-right: 100px;
    }

    .translation-input {
      width: 20vw;
      min-width: 250px;
      max-width: 400px;
      margin: 0;

      &.is-disabled {
        background-color: #fff;
        border-color: #e4e7ed;
        color: #c0c4cc;
        cursor: not-allowed;
      }

      .button {
        width: 100%;
      }
    }
  }
  .warning {
    display: flex;
    align-items: center;
    color: $secondary-color;
  }
}

@media (max-width: 1280px) {
  .convo-translation-input {
    .label {
      font-size: 14px;
    }
    ::v-deep .el-input__inner {
      padding: 0 6px;
    }
  }
}
</style>
