<template>
  <div class="convo-constants-toolbox">
    <div class="heading"><i class="convo-icon-constant-circle" />{{ $t('magicVariablesDialog.constants') }}</div>
    <div class="body">
      <convo-expression-language-draggable
        v-if="(constants.length > 0 && !isMainExpressionSelected) || requiredInputType === CelReturnType.DATE"
        :elements="constants"
        :group="requiredInputType"
        toolbox
        convo-constant
      />
      <div v-if="requiredInputType === CelReturnType.NONE" class="complete-expression-placeholder">
        {{ $t('magicVariablesDialog.completeExpressionConstants') }}
      </div>
      <div v-else-if="constants.length === 0" class="no-constants-placeholder">
        {{ $t('magicVariablesDialog.noConstants') }}
      </div>
      <div v-else-if="isMainExpressionSelected && requiredInputType !== CelReturnType.DATE" class="no-constants-placeholder">
        {{ $t('magicVariablesDialog.noConstantsForMainExpression') }}
      </div>
    </div>
  </div>
</template>

<script>
import ConvoExpressionLanguageService from '@/common/services/ConvoExpressionLanguageService';
import CelReturnType from '@/common/enums/CelReturnType';
import ConvoExpressionLanguageDraggable from '@/studio/components/right_sidepanel/inspector_panel/convo_expression_language_dialog/ConvoExpressionLanguageDraggable.vue';
import EventBus from '@/common/event-bus';

export default {
  name: 'ConvoConstantsToolbox',
  components: {
    ConvoExpressionLanguageDraggable,
  },
  props: {
    requiredInputType: {
      type: String,
      required: true,
    },
  },
  data() {
    return { CelReturnType, isMainExpressionSelected: false };
  },
  computed: {
    constants() {
      return ConvoExpressionLanguageService.getConvoExpressionLanguageConstants(this.requiredInputType);
    },
  },
  methods: {
    updateSelectedExpression(group, draggableId) {
      this.isMainExpressionSelected = draggableId === 'main-expression';
    },
  },
  mounted() {
    EventBus.onDraggableActivation(this.updateSelectedExpression);
  },
  beforeDestroy() {
    EventBus.onDraggableActivation(this.updateSelectedExpression, true);
  },
};
</script>

<style lang="scss" scoped>
.convo-constants-toolbox {
  padding: 24px;

  .heading {
    align-items: center;
    color: $primary-color;
    display: flex;
    font-size: 14px;
    font-weight: 600;

    .convo-icon-constant-circle {
      font-size: 18px;
      margin-right: 14px;
    }
  }

  .body {
    padding: 16px;

    .convo-expression-language-draggable {
      ::v-deep.convo-toolbox-constant:not(:last-child) {
        margin-bottom: 8px;
      }
    }

    .complete-expression-placeholder,
    .no-constants-placeholder {
      font-style: italic;
      word-break: break-word;
    }
  }
}
</style>
