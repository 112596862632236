<template>
  <div class="convo-pdf-configuration-dialog">
    <el-dialog :closeOnClickModal="false" top="6vh" :visible.sync="dialogVisible" @close="$emit('close')">
      <div slot="title" class="title">{{ $t('pdfConfiguration') }}</div>
      <div class="content">
        <div class="field-table">
          <div class="header">
            <div class="field-identifier">{{ $t('pdfFieldname') }}</div>
            <div class="field-mv">{{ $t('filledWithWhen') }}</div>
            <div class="action">{{ $t('action') }}</div>
          </div>
          <template v-for="(field, index) in pdfFields">
            <div class="row" :key="field.field_identifier">
              <div class="field-identifier">
                {{ field.field_identifier }}
              </div>
              <div class="field-mv" v-html="getElementNameForMvExpression(field.field_value)"></div>
              <div class="action">
                <i
                  :class="{
                    'convo-icon-function-circle-active': field.field_value,
                    'convo-icon-function-circle-default': !field.field_value,
                  }"
                  @click="openCelDialogForField(index)"
                />
              </div>
            </div>
          </template>
        </div>
        <span class="navigation">
          <el-button @click="dialogVisible = false">{{ $t('cancel') }}</el-button>
          <el-button id="expressionLanguageDialogConfirmationButton" class="primary" @click="handleConfirm">{{ $t('apply') }}</el-button>
        </span>
      </div>
      <convo-expression-language-dialog
        :visible="showCelDialog"
        :value="pdfFieldExpression"
        :type="pdfFieldType"
        :parentId="parentId"
        :field-key="pdfFieldId"
        :show-next-field-button="pdfHasNextField"
        :show-previous-field-button="pdfHasPreviousField"
        :expression-heading="celExpressionHeader"
        @close="showCelDialog = false"
        @open-next-field="saveAndOpenCelDialogForNextField"
        @open-previous-field="saveAndOpenCelDialogForPreviousField"
        @update="updateCelForField"
      />
    </el-dialog>
  </div>
</template>

<script>
import ConvoExpressionLanguageDialog from '@/studio/components/right_sidepanel/inspector_panel/convo_expression_language_dialog/ConvoExpressionLanguageDialog.vue';
import CelReturnType from '@/common/enums/CelReturnType';
import CpdModificationService from '@/common/services/CpdModificationService';

export default {
  name: 'ConvoPdfConfigurationDialog',
  components: { ConvoExpressionLanguageDialog },
  props: {
    fields: {
      type: Array,
      required: true,
    },
    parentId: {
      type: String,
      required: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      celExpressionHeader: '',
      dialogVisible: this.visible,
      pdfFields: [],
      pdfFieldExpression: null,
      pdfFieldId: '',
      pdfFieldIndex: null,
      pdfFieldType: CelReturnType.STRING,
      requiredInputType: this.type,
      showCelDialog: false,
      pdfHasNextField: false,
      pdfHasPreviousField: false,
    };
  },
  methods: {
    getElementNameForMvExpression(mvExpression) {
      if (!mvExpression) return '';
      if (mvExpression.startsWith('$VAR'))
        return CpdModificationService.getElementField(mvExpression.substring(5, mvExpression.indexOf('.') || 41), 'name');
      return '<i class="convo-icon-placeholder"></i>'.repeat(5);
    },
    handleConfirm() {
      this.pdfFields.forEach((pdfField) => {
        CpdModificationService.updateElementField(pdfField.id, 'field_identifier', pdfField.field_identifier);
        CpdModificationService.updateElementField(pdfField.id, 'field_type', pdfField.field_type);
        CpdModificationService.updateElementField(pdfField.id, 'field_options', pdfField.field_options);
        CpdModificationService.updateElementField(pdfField.id, 'field_value', pdfField.field_value);
      });
      this.$emit('update-fields');
      this.dialogVisible = false;
    },
    saveAndOpenCelDialogForNextField(newExpression) {
      this.updateCelForField(newExpression);
      this.$nextTick(() => {
        this.openCelDialogForField(this.pdfFieldIndex + 1);
      });
    },
    saveAndOpenCelDialogForPreviousField(newExpression) {
      this.updateCelForField(newExpression);
      this.$nextTick(() => {
        this.openCelDialogForField(this.pdfFieldIndex - 1);
      });
    },
    openCelDialogForField(fieldIndex) {
      const field = this.pdfFields[fieldIndex];
      this.pdfFieldExpression = field.field_value;
      this.pdfFieldType = field.field_type === 'TEXT_INPUT' ? CelReturnType.STRING : CelReturnType.NONE; // TODO #630 2022-01-22: handling for other field-types like checkboxes/radiobuttons
      this.pdfFieldId = field.id;
      this.pdfFieldIndex = fieldIndex;
      this.celExpressionHeader = this.$t('pdf_generation.celHeaderMessage', [field.field_identifier]);
      this.pdfHasNextField = this.fields.length > fieldIndex + 1;
      this.pdfHasPreviousField = fieldIndex > 0;
      this.showCelDialog = true;
    },
    updateCelForField(newExpression) {
      const fieldsCopy = [];
      this.pdfFields.forEach((field) => {
        // eslint-disable-next-line camelcase
        const { id, field_identifier, field_type, field_value, field_options } = field;
        fieldsCopy.push({ id, field_identifier, field_type, field_options, field_value });
      });
      fieldsCopy[this.pdfFieldIndex].field_value = newExpression;
      this.pdfFields = fieldsCopy;
    },
    mapFieldIdsToPdfFields(fieldIds) {
      return fieldIds.map((fieldId) => {
        return { id: fieldId, ...CpdModificationService.getElement(fieldId) };
      });
    },
  },
  watch: {
    visible(newValue) {
      this.pdfFields = this.mapFieldIdsToPdfFields(this.fields);
      this.dialogVisible = newValue;
    },
  },
  mounted() {
    this.pdfFields = this.mapFieldIdsToPdfFields(this.fields);
  },
};
</script>

<style lang="scss" scoped>
.convo-pdf-configuration-dialog {
  .title {
    margin-left: 16px;
    color: $primary-text-color;
    font-size: 20px;
  }

  .content {
    max-height: 80vh;
    width: 100%;
    max-width: 1300px;
    padding: 25px;
    margin-left: auto;
    margin-right: auto;
    color: $primary-color;
    font-size: 16px;

    .field-table {
      min-height: 40vh;
      max-height: 62vh;
      overflow: auto;
    }

    .header {
      padding-bottom: 6px;
      border-bottom: 2px solid $convo-blue-2;
      font-size: 18px;
      font-weight: bold;

      .action {
        text-align: center;
      }
    }

    .row,
    .header {
      display: flex;
      justify-content: space-between;

      .field-identifier,
      .field-mv {
        width: calc((100% - 100px) / 2);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .action {
        width: 100px;
        text-align: center;
      }
    }

    .row {
      padding: 12px 0;
      border-bottom: 1px solid $convo-blue-2;

      ::v-deep .convo-icon-placeholder + .convo-icon-placeholder {
        margin-left: -2px;
      }

      .action {
        position: relative;

        .convo-icon-function-circle-active,
        .convo-icon-function-circle-default {
          cursor: pointer;
          font-size: 24px;
          left: 35px;
          position: absolute;
          top: -2px;
        }
      }
    }

    .navigation {
      display: flex;
      justify-content: flex-end;
      margin-top: 24px;
      padding: 0 24px;

      button {
        background-color: $primary-text-color;
        border: 1px solid $primary-color;
        border-radius: 5px;
        color: $primary-color;
        font-family: $primary-font;
        font-size: 14px;
        font-weight: 600;
        height: 32px;
        margin-left: 32px;
        width: 140px;

        &.primary {
          background-color: $primary-color;
          color: $primary-text-color;

          &:disabled {
            background-color: $disabled-color;
            border: none;
            &:hover {
              box-shadow: none;
              cursor: default;
            }
          }
        }

        &:hover {
          box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15);
        }
      }
    }
  }

  ::v-deep {
    .el-dialog {
      width: 70vw;
      padding: 2px;
      border-radius: 8px;

      .el-dialog__header {
        background-color: $primary-color;
        border-radius: 8px 8px 0 0;
        padding: 6px;
      }
      .el-dialog__title {
        color: $primary-text-color;
      }
      .el-dialog__headerbtn {
        right: 12px;
        top: 10px;
      }
      .el-dialog__close {
        color: $primary-text-color;
        font-size: 20px;
      }
      .el-dialog__body {
        padding: 0;
      }
    }
  }
}
</style>
