<template>
  <div class="convo-rich-editor-dialog">
    <el-dialog :title="title" :visible.sync="dialogVisible" @close="handleClose">
      <convo-rich-text-editor :content="content" @update="handleUpdate($event)" />
      <span slot="footer">
        <el-button @click="handleConfirm">{{ $t('ok') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ConvoRichTextEditor from '@/common/components/ConvoRichTextEditor.vue';

export default {
  name: 'ConvoRichTextEditorDialog',
  components: {
    ConvoRichTextEditor,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: 'Editor',
    },
  },
  data() {
    return {
      dialogVisible: this.visible,
    };
  },
  watch: {
    visible(newValue) {
      this.dialogVisible = newValue;
    },
  },
  methods: {
    handleClose() {
      this.$emit('close');
    },
    handleConfirm() {
      this.dialogVisible = false;
    },
    handleUpdate(newValue) {
      this.$emit('update', newValue);
    },
  },
};
</script>
