import { resolveTenant } from '@/common/oidc/tenant-resolver';

export default {
  authority: `${window.oidcAuthority}${window.oidcAuthority.endsWith('/') ? '' : '/'}${resolveTenant()}`,
  clientId: `${window.oidcClientId}`,
  redirectUri: `${window.location.origin}/oidc-callback`,
  silentRedirectUri: `${window.location.origin}/silent-renew.html`,
  automaticSilentRenew: true, // If true oidc-client will try to renew your token when it is about to expire
  automaticSilentSignin: false, // If true vuex-oidc will try to silently signin unauthenticated users on public routes. Defaults to true
  responseType: 'code',
  scope: 'openid profile offline_access',
};
