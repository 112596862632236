<template>
  <div class="convo-functions-toolbox">
    <div class="heading"><i class="convo-icon-function-circle" />{{ $t('magicVariablesDialog.functions') }}</div>
    <div class="body">
      <div class="functions">
        <convo-expression-language-draggable
          v-if="functionsPartOne.length > 0"
          :elements="functionsPartOne"
          :group="requiredInputType"
          toolbox
          convo-function
        />
        <convo-expression-language-draggable
          v-if="functionsPartTwo.length > 0"
          :elements="functionsPartTwo"
          :group="requiredInputType"
          toolbox
          convo-function
        />
      </div>
      <convo-expression-language-draggable
        v-if="requiredInputType !== CelReturnType.NONE"
        :elements="genericFunctions"
        :group="requiredInputType"
        toolbox
        convo-function
        convo-generic-function
      />
      <div v-if="requiredInputType === CelReturnType.NONE" class="complete-expression-placeholder">
        {{ $t('magicVariablesDialog.completeExpressionFunctions') }}
      </div>
    </div>
  </div>
</template>

<script>
import ConvoExpressionLanguageService from '@/common/services/ConvoExpressionLanguageService';
import CelReturnType from '@/common/enums/CelReturnType';
import ConvoExpressionLanguageDraggable from '@/studio/components/right_sidepanel/inspector_panel/convo_expression_language_dialog/ConvoExpressionLanguageDraggable.vue';

export default {
  name: 'ConvoFunctionsToolbox',
  components: {
    ConvoExpressionLanguageDraggable,
  },
  props: {
    requiredInputType: {
      type: String,
      required: true,
    },
  },
  data() {
    return { CelReturnType };
  },
  computed: {
    functionsPartOne() {
      const functions = ConvoExpressionLanguageService.getConvoExpressionLanguageFunctions(this.requiredInputType);
      return functions.splice(0, functions.length / 2);
    },
    functionsPartTwo() {
      const functions = ConvoExpressionLanguageService.getConvoExpressionLanguageFunctions(this.requiredInputType);
      return functions.splice(functions.length / 2, functions.length);
    },
    genericFunctions() {
      return ConvoExpressionLanguageService.getConvoExpressionLanguageGenericFunctions();
    },
  },
};
</script>

<style lang="scss" scoped>
.convo-functions-toolbox {
  padding: 24px;

  .heading {
    align-items: center;
    color: $primary-color;
    display: flex;
    font-size: 14px;
    font-weight: 600;

    .convo-icon-function-circle {
      font-size: 18px;
      margin-right: 14px;
    }
  }

  .body {
    padding: 16px;
    .functions {
      display: flex;
      justify-content: space-between;

      .convo-expression-language-draggable {
        margin-bottom: 16px;
        width: calc(50% - 8px);
        ::v-deep.convo-toolbox-function:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }

    .complete-expression-placeholder {
      font-style: italic;
      word-break: break-word;
    }
  }
}
</style>
