import Vue from 'vue';
import VueI18n from 'vue-i18n';
import countries from 'i18n-iso-countries';
import countriesDe from 'i18n-iso-countries/langs/de.json';
import countriesEn from 'i18n-iso-countries/langs/en.json';
import rawDe from './translations/de.json';
import rawEn from './translations/en.json';

Vue.use(VueI18n);

/**
 * Preprocessor that allows the reuse of objects in i18n files using '@object:'-notation.
 * @param {Object} raw raw object to process
 * @param {Object} root root object in which the referenced objects can be found
 */
function process(raw, root) {
  const obj = {};
  Object.keys(raw).forEach((key) => {
    const value = raw[key];
    if (typeof value === 'object') {
      obj[key] = process(value, root);
    } else if (value.startsWith('@object:')) {
      const referencedObjectKey = value.substring(value.indexOf(':') + 1);
      obj[key] = process(root[referencedObjectKey], root);
    } else {
      obj[key] = value;
    }
  });
  return obj;
}

/**
 * Add country translations and call preprocessor
 *
 * @param {Object} rawTranslations raw translation
 * @param {Object} countryTranslations country translation (alpha2Code -> translation)
 */
function getTranslations(rawTranslations, countryTranslations) {
  const translations = { ...rawTranslations };
  translations.countries = {};

  // add country translations
  Object.keys(countryTranslations).forEach((alpha2Code) => {
    translations.countries[countries.alpha2ToAlpha3(alpha2Code)] = Array.isArray(countryTranslations[alpha2Code])
      ? countryTranslations[alpha2Code][0]
      : countryTranslations[alpha2Code];
  });
  return process(translations, translations);
}

const de = getTranslations(rawDe, countriesDe.countries);
const en = getTranslations(rawEn, countriesEn.countries);

const messages = { en, de };

const dateTimeFormats = {
  de: {
    short: {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    },
    medium: {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
    },
    long: {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    time: {
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  en: {
    medium: {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
    },
  },
};

export const locale = 'de';

export default new VueI18n({
  locale,
  fallbackLocale: locale,
  messages,
  dateTimeFormats,
});
