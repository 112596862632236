<template>
  <div class="convo-pdf-upload convo-ip-input">
    <label class="el-form-item__label">{{ $t('pdfFile') }}</label>
    <convo-spinner :name="uploadSpinner">
      <div
        v-if="!file || isLoading"
        class="upload-drop-zone"
        @drop.prevent="addFile($event.dataTransfer.files)"
        @dragover.prevent
        @dragenter="dragged = true"
        @dragend="dragged = false"
        @dragleave.self="dragged = false"
        tabindex="0"
        :class="{ dragged: dragged }"
      >
        <i class="convo-icon-save"></i><br />
        <span>{{ $t('dragPdfHere') }}</span>
        <div class="seperator">–––––––––– {{ $t('or') }} ––––––––––</div>
        <input id="search-pdf" class="hidden" type="file" accept="application/pdf" @change="addFile($event.target.files)" />
        <div class="button-pdf-search" @click="searchPdf">{{ $t('searchForPdf') }}</div>
      </div>
      <div v-else class="pdf-uploaded">
        <div class="pdf-information">
          <div class="pdf-name" :title="file[0].name">{{ file[0].name }}</div>
          <div class="button-pdf-delete" @click="deleteFile(true)">x</div>
        </div>
        <div class="button-pdf-configuration" @click="isPdfConfigurationDialogVisible = true">{{ $t('pdfConfiguration') }}</div>
      </div>
    </convo-spinner>
    <convo-dialog
      :is-dialog-visible="isErrorDialogVisible"
      :title="$t('checkFailed')"
      :content="errorDialogContent"
      :cancel-label="$t('close')"
      @close="isErrorDialogVisible = false"
      @cancel="isErrorDialogVisible = false"
    />
    <convo-pdf-configuration-dialog
      :fields="fields"
      :parentId="parentId"
      :visible="isPdfConfigurationDialogVisible"
      @update-fields="updatePdfFields"
      @close="isPdfConfigurationDialogVisible = false"
    />
  </div>
</template>

<script>
import ConvoInputMixin from '@/studio/mixins/ConvoInputMixin';
import ConvoInputI18nMixin from '@/studio/mixins/ConvoInputI18nMixin';
import ValidationMixin from '@/studio/mixins/ValidationMixin';
import CelReturnType from '@/common/enums/CelReturnType';
import PdfService from '@/studio/services/PdfService';
import ConvoSpinner from '@/common/components/ConvoSpinner.vue';
import ConvoDialog from '@/common/components/ConvoDialog.vue';
import CpdModificationService from '@/common/services/CpdModificationService';
import ConvoPdfConfigurationDialog from '@/studio/components/right_sidepanel/inspector_panel/ConvoPdfConfigurationDialog.vue';
import PdfGenerationErrors from '@/studio/enums/PdfGenerationErrors';

export default {
  name: 'ConvoPdfUpload',
  components: { ConvoSpinner, ConvoDialog, ConvoPdfConfigurationDialog },
  mixins: [ConvoInputMixin, ConvoInputI18nMixin, ValidationMixin],
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
    fieldKey: {
      type: String,
    },
  },
  data() {
    return {
      CelReturnType,
      dragged: false,
      errorDialogContent: '',
      isErrorDialogVisible: false,
      isLoading: false,
      isPdfConfigurationDialogVisible: false,
      fields: [],
      file: this.loadFileDetails(),
      uploadSpinner: 'uploadSpinner',
    };
  },
  methods: {
    addFile(fileToAdd) {
      if (!fileToAdd) return;
      if (fileToAdd.length > 1) {
        this.showErrorDialog('pdf_generation.error.onlyOnePDF');
      } else if (fileToAdd[0].type !== 'application/pdf') {
        this.showErrorDialog('pdf_generation.error.onlyPDF');
      } else if (fileToAdd[0].size > 10485760) {
        this.showErrorDialog('pdf_generation.error.maxFileSizeExceeded');
      } else {
        this.isLoading = true;
        this.file = fileToAdd;
        this.dragged = false;
        PdfService.uploadAndGetFields(this.$route.params.cpd, this.$route.params.version, this.file[0], this.uploadSpinner).then(
          (res) => {
            this.$store.commit('addReferencedPdf', res.id);
            this.fields = res.fields.map((field) =>
              CpdModificationService.createElement(() => ({
                field_identifier: field.identifier,
                field_type: field.type,
                field_options: field.options,
                field_value: null,
              }))
            );
            CpdModificationService.updateElementField(this.parentId, 'template_id', res.id);
            CpdModificationService.updateElementField(this.parentId, 'fields', this.fields);
            CpdModificationService.updateElementField(this.parentId, 'uploaded_pdf_name', this.file[0].name);
            this.isLoading = false;
          },
          (err) => {
            this.deleteFile();
            this.showErrorDialog(
              err.data?.errorCode !== undefined ? PdfGenerationErrors[err.data?.errorCode] : PdfGenerationErrors.UNKNOWN
            );
          }
        );
      }
    },
    clearCpdFields() {
      this.$store.commit('deleteReferencedPdf', CpdModificationService.getElementField(this.parentId, this.fieldKey));
      CpdModificationService.updateElementField(this.parentId, 'template_id', null);
      CpdModificationService.updateElementField(this.parentId, 'fields', []);
      CpdModificationService.updateElementField(this.parentId, 'uploaded_pdf_name', null);
    },
    deleteFile(clearCpdFields) {
      if (clearCpdFields) this.clearCpdFields();
      this.file = null;
      this.isLoading = false;
    },
    loadFileDetails() {
      return CpdModificationService.getElementField(this.parentId, this.fieldKey)
        ? [{ name: CpdModificationService.getElementField(this.parentId, 'uploaded_pdf_name') }]
        : null;
    },
    searchPdf() {
      document.getElementById('search-pdf').click();
    },
    showErrorDialog(errorTranslationKey) {
      this.errorDialogContent = this.$t(errorTranslationKey);
      this.isErrorDialogVisible = true;
    },
    updatePdfFields() {
      this.fields = CpdModificationService.getElementField(this.parentId, 'fields');
    },
  },
  beforeMount() {
    if (this.file) this.updatePdfFields();
  },
};
</script>

<style lang="scss" scoped>
@import '@/studio/styles/inputs.scss';

.convo-pdf-upload {
  .upload-drop-zone {
    max-width: 320px;
    height: 200px;
    border: 1px dotted $box-shadow-color;
    text-align: center;

    &.dragged {
      background-color: $convo-blue-4;
    }

    .convo-icon-save {
      display: inline-block;
      margin: 12px 0;
      font-size: 32px;
    }
  }

  .pdf-information {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    margin-bottom: 24px;
    border: 1px solid #dcdfe6;
    text-overflow: ellipsis;
  }

  .pdf-name {
    width: 280px;
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .button-pdf-delete {
    width: 25px;
    cursor: pointer;
  }

  .hidden {
    display: none;
  }

  .flex-break {
    flex-basis: 100%;
    height: 0;
  }

  .seperator {
    margin: 20px 0;
    color: $convo-blue-5-lighten;
  }

  .button-pdf-configuration,
  .button-pdf-search {
    cursor: pointer;
    border: 1px solid $primary-color;
    border-radius: 20px;
    padding: 6px;
    margin: 8px;
    text-align: center;
  }

  .button-pdf-configuration {
    margin: 0;
    background-color: $primary-color;
    color: white;
  }
}
</style>
