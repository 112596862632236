import countries from 'i18n-iso-countries';
import currencies from '../assets/currencies.json';
import fileEndings from '../assets/fileEndings.json';

const SelectOptionService = (() => {
  return {
    getCodes(option) {
      switch (option) {
        case 'countries':
          return Object.keys(countries.getAlpha3Codes());
        case 'currencies':
          return currencies;
        case 'fileEndings':
          return fileEndings;
        default:
          console.error("Option must be 'countries', 'currencies' or 'fileEndings.");
          return [];
      }
    },
  };
})();

export default SelectOptionService;
