<template>
  <div class="convo-expression">
    <div class="heading">{{ heading }}</div>
    <div class="expression" :class="{ filled: expressionId }">
      <convo-function
        v-if="isFunction"
        :id="expression[0]"
        :parentType="requiredInputType"
        :disabled="disabled"
        @delete="expression = []"
      />
      <convo-expression-language-draggable
        v-else
        :elements="expression"
        :group="requiredInputType"
        :disabled="disabled"
        :draggable-id="draggableId"
        @delete="expression = []"
      />
    </div>
  </div>
</template>

<script>
import CelReturnType from '@/common/enums/CelReturnType';
import ConvoExpressionLanguageDraggable from '@/studio/components/right_sidepanel/inspector_panel/convo_expression_language_dialog/ConvoExpressionLanguageDraggable.vue';
import ConvoFunction from '@/studio/components/right_sidepanel/inspector_panel/convo_expression_language_dialog/expression/ConvoFunction.vue';
import ConvoExpressionLanguageService from '@/common/services/ConvoExpressionLanguageService';
import CelExpressionType from '@/common/enums/CelExpressionType';
import EventBus from '@/common/event-bus';

export default {
  name: 'ConvoExpression',
  components: { ConvoFunction, ConvoExpressionLanguageDraggable },
  props: {
    expressionId: {
      type: String,
      required: false,
    },
    heading: {
      type: String,
      required: true,
    },
    requiredInputType: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      CelReturnType,
      draggableId: 'main-expression',
      expression: this.expressionId ? [this.expressionId] : [],
    };
  },
  computed: {
    expressionReturnType() {
      return ConvoExpressionLanguageService.getExpressionField(this.expressionId, 'returnType');
    },
    isFunction() {
      return this.expression.length > 0
        ? ConvoExpressionLanguageService.getExpressionField(this.expression[0], 'type') === CelExpressionType.FUNCTION
        : false;
    },
  },
  watch: {
    expression() {
      this.$emit('update:expression-id', this.expression[0]);
      if (this.expression.length === 0) {
        this.$nextTick(() => EventBus.emitDraggableActivation(this.requiredInputType, this.draggableId));
      } else {
        EventBus.emitDraggableActivation(CelReturnType.NONE);
      }
    },
  },
  mounted() {
    this.$nextTick(() => EventBus.emitDraggableActivation(this.requiredInputType, this.draggableId));
  },
};
</script>

<style lang="scss" scoped>
.convo-expression {
  background-color: $convo-blue-6;
  border-radius: 10px;
  padding: 20px;

  .heading {
    color: $primary-text-color;
    font-size: 20px;
    margin-bottom: 8px;
  }

  .expression {
    padding: 20px;

    &.filled > .convo-expression-language-draggable {
      max-width: 560px;
    }
    &:not(.filled) > .convo-expression-language-draggable {
      height: 100px;
    }

    ::v-deep {
      .convo-toolbox-function,
      .convo-toolbox-constant,
      .convo-toolbox-element-value {
        font-size: 24px;
        height: 100%;
      }
    }
  }
}

@media (max-width: 1280px) {
  .convo-expression {
    padding: 12px;

    .heading {
      font-size: 16px;
    }

    .expression {
      padding: 10px;
    }
  }
}
</style>
