<template>
  <div ref :class="isDraggable && draggableClass" @click="renameMode = false">
    <div
      class="convo-cpd-tile"
      :id="cpdTileId"
      :class="{ highlight: cpd.highlight, hide: cpd.hide, 'context-menu-open': contextMenuOpen }"
      @dblclick="openEditableCpdIfPossible(cpd.id, cpd.version)"
    >
      <div v-if="cpd.hasOrIsFollowUpVersion" class="next-version" @click.stop="showRelatedTiles">
        <div class="icon"><i class="convo-icon-copy" /></div>
      </div>
      <div class="tile-content">
        <div class="tile-header">
          <div class="name" v-if="!renameMode">{{ cpd.name }}</div>
          <div class="rename" v-else>
            <input
              class="name-input"
              ref="input"
              v-model="renameValue"
              :id="nameInputId"
              :placeholder="$t('dashboard.renamePlaceholder')"
              :maxlength="36"
              @keyup.enter="handleRenameConfirm"
              @keyup.esc="handleRenameCancel"
              @click.stop
            />
            <button :id="nameConfirmId" class="name-confirm" @click="handleRenameConfirm">
              <i class="convo-icon-check" />
            </button>
          </div>
          <convo-cpd-context-menu
            v-if="!creationMode"
            :is-locked="isLockedByOtherUser()"
            :has-or-is-follow-up-version="cpd.hasOrIsFollowUpVersion"
            :state="cpd.state"
            :unique-cpd-id="getUniqueCpdId"
            @edit="openEditableCpdIfPossible(cpd.id, cpd.version)"
            @force-edit="forceCpdEdit = true"
            @open-viewmode="openViewmode(cpd.id, cpd.version)"
            @context-menu-open="setContextMenuState"
            @follow-up="createFollowUpVersion"
            @rename="handleRenameStart"
            @delete="handleDeleteStart"
            @mouseenter.native="setIsDraggable(false)"
            @mouseleave.native="setIsDraggable(true)"
          />
        </div>
        <div class="tile-footer">
          <div class="tile-left-footer">
            <div class="modified-holder">
              <convo-user-avatar class="modified-by" :first-name="cpd.modifiedBy.firstName" :last-name="cpd.modifiedBy.lastName" />
              <div class="modified" :title="$t('modified')">{{ $d(cpd.modified, 'long') + ' ' + $t('oClock') }}</div>
            </div>
            <div class="id-holder" v-if="cpd.id">
              <i class="convo-icon-fingerprint" @click="copyCpdId"></i>
              <div class="id" @pointerdown.stop>{{ cpd.id }}</div>
            </div>
          </div>
          <el-popover v-if="isLockedByOtherUser()" trigger="hover" placement="bottom">
            <div>{{ $t('dashboard.inEditingBy') + ' ' + cpd.locked.user.firstName + ' ' + cpd.locked.user.lastName }}</div>
            <i class="convo-icon-lock" slot="reference"></i>
          </el-popover>
          <i v-else class="state" :class="statusIcon"></i>
        </div>
      </div>
    </div>
    <convo-confirmation-dialog
      v-if="deleteMode"
      :visible="deleteMode"
      :title="$t('dashboard.securityPrompt')"
      :content="content"
      :checkbox="cpd.state === stateEnum.ACTIVE"
      :checkbox-content="$t('dashboard.deleteCpdDialog.checkboxContent')"
      :confirm-label="$t('dashboard.deleteCpdDialog.confirm')"
      :cancel-label="$t('dashboard.cancel')"
      @close="deleteMode = false"
      @confirm="handleDeleteConfirm"
      @cancel="deleteMode = true"
      @mouseenter.native="setIsDraggable(false)"
      @mouseleave.native="setIsDraggable(true)"
    />
    <convo-confirmation-dialog
      v-if="forceCpdEdit"
      :checkbox="true"
      :checkbox-content="$t('dashboard.removeLockDialog.checkboxContent')"
      :content="$t('dashboard.removeLockDialog.content', [cpd.locked.user.firstName, cpd.locked.user.lastName])"
      :visible="forceCpdEdit"
      :title="$t('dashboard.securityPrompt')"
      :confirm-label="$t('dashboard.removeLockDialog.removeLock')"
      :cancel-label="$t('dashboard.removeLockDialog.openViewmode')"
      @close="forceCpdEdit = false"
      @confirm="overrideLockAndOpenStudio(cpd.id, cpd.version)"
      @cancel="openViewmode"
      @mouseenter.native="setIsDraggable(false)"
      @mouseleave.native="setIsDraggable(true)"
    />
  </div>
</template>

<script>
import RouterMixin from '@/common/mixins/RouterMixin';
import ConvoCpdContextMenu from '@/dashboard/components/ConvoCpdContextMenu.vue';
import CpdApiService from '@/common/services/CpdApiService';
import ConvoConfirmationDialog from '@/common/components/ConvoConfirmationDialog.vue';
import StateEnum from '@/common/enums/StateEnum';
import ConvoUserAvatar from '@/common/components/ConvoUserAvatar.vue';
import SpinnerEnum from '@/common/enums/SpinnerEnum';
import EventBus from '@/common/event-bus';
import { mapGetters } from 'vuex';

export default {
  name: 'ConvoCpdTile',
  components: { ConvoUserAvatar, ConvoConfirmationDialog, ConvoCpdContextMenu },
  mixins: [RouterMixin],
  props: {
    cpd: {
      type: Object,
      required: true,
    },
    draggableClass: {
      type: String,
      required: false,
    },
    creationMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDraggable: !this.creationMode,
      contextMenuOpen: false,
      deleteMode: false,
      forceCpdEdit: false,
      renameMode: this.creationMode,
      renameValue: this.cpd.name,
      cpdTileId: this.creationMode ? 'cpd-create-tile' : `cpd-tile-${this.cpd.id + 'v' + this.cpd.version}`,
      nameInputId: this.creationMode ? 'cpd-create-tile-name-input' : `cpd-tile-name-input-${this.cpd.id + 'v' + this.cpd.version}`,
      nameConfirmId: this.creationMode ? 'cpd-create-tile-name-confirm' : `cpd-tile-name-confirm-${this.cpd.id + 'v' + this.cpd.version}`,
      stateEnum: StateEnum,
    };
  },
  computed: {
    ...mapGetters('oidc', ['oidcUser']),
    content() {
      if (this.cpd.state === StateEnum.ACTIVE) {
        return this.$t('dashboard.deleteCpdDialog.contentActive', [
          `<span class="highlight">${this.cpd.name}</span>`,
          `<span class="highlight"> ${this.$d(this.cpd.lastDeployment, 'short')}</span>`,
        ]);
      }
      if (this.cpd.hasOrIsFollowUpVersion) {
        return (
          this.$t('dashboard.deleteCpdDialog.content', [`<span class="highlight">${this.cpd.name}</span>`]) +
          `<br/><br/>` +
          this.$t('dashboard.deleteCpdDialog.followUpHint')
        );
      }
      return this.$t('dashboard.deleteCpdDialog.content', [`<span class="highlight">${this.cpd.name}</span>`]);
    },
    statusIcon() {
      switch (this.cpd.state) {
        case StateEnum.DRAFT:
          return 'convo-icon-bulb';
        case StateEnum.REVIEW:
          return 'convo-icon-view';
        case StateEnum.READY:
          return 'convo-icon-check-square';
        case StateEnum.ACTIVE:
          return 'convo-icon-cloud';
        default:
          return '';
      }
    },
    getUniqueCpdId() {
      return this.cpd.id + 'v' + this.cpd.version;
    },
  },
  watch: {
    renameMode(isRenameMode) {
      this.setIsDraggable(!isRenameMode);
    },
    contextMenuOpen(isOpen) {
      this.setIsDraggable(!isOpen);
    },
    deleteMode(isDeleteMode) {
      this.setIsDraggable(!isDeleteMode);
    },
  },
  methods: {
    async copyCpdId() {
      if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
        await navigator.clipboard.writeText(this.cpd.id);
        EventBus.emitToast('toast.cpdIdCopied');
      }
    },
    setIsDraggable(val) {
      if (this.renameMode || this.contextMenuOpen || this.isDeleteMode) this.isDraggable = false;
      else this.isDraggable = val;
    },
    handleRenameStart() {
      this.renameValue = this.cpd.name;
      this.renameMode = true;
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
    handleRenameConfirm() {
      if (this.creationMode) {
        this.$emit('confirm', this.renameValue);
        this.renameValue = this.cpd.name;
      } else {
        this.renameValue = this.renameValue.trim();
        this.renameValue = this.renameValue === '' ? this.$t('untitledForm') : this.renameValue;
        CpdApiService.updateCpdName(this.cpd.id, this.cpd.version, this.renameValue).then(
          () => {
            this.$emit('update-cpd', this.cpd.id, this.cpd.version);
            this.renameMode = false;
            EventBus.emitToast('toast.cpdRenamed');
          },
          () => EventBus.emitToast('toast.cpdRenameFailed', true)
        );
      }
    },
    handleRenameCancel() {
      if (this.creationMode) {
        this.$emit('cancel', this.renameValue);
      }
      this.renameMode = false;
      this.renameValue = this.cpd.name;
    },
    handleDeleteStart() {
      this.deleteMode = true;
    },
    handleDeleteConfirm() {
      CpdApiService.deleteCpd(this.cpd.id, this.cpd.version).then(
        () => {
          this.$emit('delete-cpd', this.cpd.id);
          this.deleteMode = false;
          EventBus.emitToast('toast.cpdDeleted');
        },
        () => EventBus.emitToast('toast.cpdDeletionFailed', true)
      );
    },
    hasNonExpiredLock(expirationTime) {
      return expirationTime && new Date().getTime() < new Date(expirationTime).getTime();
    },
    isLockedByOtherUser() {
      return !!(
        this.cpd.state === StateEnum.DRAFT &&
        this.hasNonExpiredLock(this.cpd.locked?.expiredAt) &&
        this.oidcUser.sub !== this.cpd.locked.user.id
      );
    },

    createFollowUpVersion() {
      CpdApiService.createFollowUpVersion(this.cpd.id, this.cpd.version, SpinnerEnum.DASHBOARD).then(
        () => {
          this.$emit('reload-cpds');
          EventBus.emitToast('toast.cpdFollowUpCreated');
        },
        () => EventBus.emitToast('toast.cpdFollowUpCreationFailed', true)
      );
    },
    openEditableCpdIfPossible(cpdId, version) {
      if (this.oidcUser.realm_access.roles.includes('demo-access')) {
        this.$store.commit('updateCpdEditable', true);
        this.navigateToStudio(cpdId, version);
      } else if (this.cpd.state !== StateEnum.DRAFT || this.isLockedByOtherUser()) {
        this.navigateToStudio(cpdId, version);
      } else if (!this.cpd.locked) {
        CpdApiService.lockCpd(cpdId, version, SpinnerEnum.DASHBOARD).then(
          () => this.navigateToStudio(cpdId, version),
          () => {
            CpdApiService.getCpdsWithId(cpdId, SpinnerEnum.DASHBOARD).then(
              (cpds) => {
                if (cpds.length && CpdApiService.isLockedByCurrentUser(cpds[0])) {
                  this.navigateToStudio(cpdId, version);
                } else {
                  EventBus.emitToast('toast.cpdAlreadyLocked', true);
                  this.$emit('reload-cpds');
                }
              },
              () => EventBus.emitToast('toast.loadCpdFailed', true)
            );
          }
        );
      } else {
        this.overrideLockAndOpenStudio(cpdId, version);
      }
    },
    openViewmode() {
      this.forceCpdEdit = false;
      this.$store.commit('updateCpdEditable', false);
      this.navigateToStudio(this.cpd.id, this.cpd.version);
    },
    overrideLockAndOpenStudio(cpdId, version) {
      CpdApiService.updateCpdLock(cpdId, version, SpinnerEnum.DASHBOARD).then(
        () => this.navigateToStudio(cpdId, version),
        () => {
          EventBus.emitToast('toast.updateCpdLockFailed', true);
          this.$emit('reload-cpds');
        }
      );
      if (this.oidcUser.realm_access.roles.includes('demo-access')) {
        CpdApiService.deleteCpdLock(cpdId, version);
      }
    },
    setContextMenuState(isOpen) {
      this.contextMenuOpen = isOpen;
    },
    showRelatedTiles() {
      this.$emit('show-related-tiles', this.cpd.id, this.cpd.highlight);
    },
  },
  mounted() {
    if (this.creationMode) {
      document.addEventListener('click', this.handleRenameCancel);
      document.getElementById(this.nameInputId).focus();
    }
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleRenameCancel);
  },
};
</script>

<style lang="scss" scoped>
.convo-cpd-tile {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 8px;
  margin-bottom: 32px;
  color: $primary-color;
  box-shadow: 0 4px 10px $box-shadow-color;

  &.context-menu-open {
    z-index: 2;
  }

  &.hide {
    display: none;
  }

  &.highlight {
    border: 3px solid #f68705;
    .next-version {
      background-color: #f68705;
      top: -3px;
    }
  }

  .next-version {
    color: white;
    width: 35px;
    height: 70px;
    background-color: $primary-color;
    position: absolute;
    top: 0;
    right: -20px;
    border-radius: 8px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;

    .icon {
      padding: 10px 3px 0 0;
      color: white;
      text-align: right;
      font-size: 13px;
      margin-top: 17px;
      .convo-icon-copy {
        padding-right: 1px;
      }
    }
  }

  .tile-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    border-radius: 8px;
    height: $tile-height;
    padding: 16px;
    z-index: 1;
  }

  .tile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 24px;

    .name {
      font-size: 16px;
      font-weight: 600;
      word-break: break-word;
    }

    .rename {
      display: flex;
      align-items: center;
      width: 248px;
      height: 24px;
      border-radius: 16px;
      padding: 0 8px;
      margin-right: 8px;
      background-color: rgba(212, 220, 232, 1);

      .name-input {
        background-color: transparent;
        border: none;
        outline: none;
        width: calc(100% - 20px);
        font-size: 16px;

        &::placeholder {
          font-family: $primary-font;
          font-style: italic;
          font-size: 14px;
          color: rgba(44, 62, 89, 0.7);
        }
      }

      .name-confirm {
        width: 20px;
        font-size: 11px;
        margin-right: 2px;
        cursor: pointer;
        background-color: transparent;
        border: none;
        outline: none;
      }
    }

    .context-menu {
      font-size: 16px;
    }
  }

  .tile-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .tile-left-footer {
      .modified-holder {
        display: flex;
        align-items: flex-end;

        .modified-by {
          font-size: 32px;
          margin-right: 8px;
        }

        .modified {
          cursor: default;
          font-size: 14px;
          padding-bottom: 5px;
        }
      }

      .id-holder {
        display: flex;
        margin-top: 8px;
        align-items: center;
        color: rgba(44, 62, 89, 0.59);

        .convo-icon-fingerprint {
          font-size: 18px;
          color: $primary-color;
          cursor: pointer;
        }
        .id {
          cursor: text;
          font-size: 10px;
          margin: 3px 4px 0 4px;
          word-break: break-word;
        }
      }
    }

    .convo-icon-bulb {
      font-size: 20px;
    }

    .convo-icon-view {
      font-size: 14px;
    }

    .convo-icon-check-square {
      font-size: 17px;
    }

    .convo-icon-cloud {
      font-size: 15px;
    }

    .convo-icon-lock {
      font-size: 17px;
    }
  }
}

.moving-tile {
  opacity: 1 !important; //override default of 0.8

  .convo-cpd-tile .tile-content {
    border: 2px solid $convo-moving-blue;
    background-color: $convo-moving-blue;
    color: white;

    .tile-footer .id-holder {
      color: white;
    }
  }
}

.tile-to-move .tile-content {
  border: 2px solid $convo-ghost-blue;
  background-color: $convo-ghost-blue;
}

@media (max-width: 1280px) {
  .convo-cpd-tile {
    margin-bottom: 8px;
    .tile-content {
      padding: 8px 10px;
      height: $tile-height-1280;

      .tile-header {
        .name,
        .convo-cpd-context-menu {
          font-size: 12px;
        }

        .rename {
          height: 20px;
          padding: 0 6px 0 5px;
          margin-top: 16px;

          .name-input {
            font-size: 12px;

            &::placeholder {
              font-size: 12px;
            }
          }
        }
      }

      .tile-footer {
        .tile-left-footer {
          .modified-holder .modified {
            font-size: 12px;
          }

          .id-holder {
            margin-top: 8px;

            .id {
              font-size: 8px;
            }

            .convo-icon-fingerprint {
              font-size: 16px;
            }
          }
        }

        .convo-icon-bulb {
          font-size: 17px;
        }

        .convo-icon-view {
          font-size: 12px;
          padding-bottom: 2px;
        }

        .convo-icon-check-square {
          font-size: 15px;
        }

        .convo-icon-cloud {
          font-size: 13px;
        }

        .convo-icon-lock {
          font-size: 15px;
        }
      }
    }

    .next-version {
      right: -15px;
      height: 52px;

      .icon {
        font-size: 10px;
        margin-top: 10px;
        padding-right: 2px;
      }
    }
  }
}
</style>
