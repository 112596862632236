<template>
  <div id="convo-preview" class="convo-preview">
    <convo-preview-sidepanel
      :device-key="deviceKey"
      :device-visible.sync="deviceVisible"
      @refresh-engine-frame="refreshEngineFrame"
      @submit-disabled="updateSubmitDisabled"
    />
    <div class="content" ref="content">
      <div class="content-wrapper" :style="cssStyleContentWrapper">
        <div>
          <div class="iframeWrapper" :style="cssStyleIframeWrapper" :class="{ 'custom-frame': !deviceFrame && deviceVisible }">
            <convo-spinner :name="SpinnerEnum.PREVIEW" style="height: 100%">
              <iframe id="engine-frame" :src="url" class="preview-frame" @load="onIframeLoad"></iframe>
            </convo-spinner>
            <convo-device-frame :key="deviceFrame" v-if="deviceFrame && deviceVisible" :device-frame="deviceFrame" />
          </div>
        </div>
        <convo-zoom-selector v-model="zoom" />
      </div>
    </div>
  </div>
</template>

<script>
import RouterMixin from '@/common/mixins/RouterMixin';
import ConvoZoomSelector from '@/preview/components/ConvoZoomSelector.vue';
import EventBus from '@/common/event-bus';
import ConvoDeviceFrame from '@/preview/components/ConvoDeviceFrame.vue';
import ConvoSpinner from '@/common/components/ConvoSpinner.vue';
import SpinnerEnum from '@/common/enums/SpinnerEnum';
import ConvoPreviewSidepanel from './ConvoPreviewSidepanel.vue';

export default {
  name: 'ConvoPreview',
  components: {
    ConvoSpinner,
    ConvoDeviceFrame,
    ConvoZoomSelector,
    ConvoPreviewSidepanel,
  },
  mixins: [RouterMixin],
  props: {
    cpdId: String,
    version: Number,
  },
  data() {
    return {
      deviceKey: 'iPadPro',
      deviceFrame: 'ipad-frame',
      width: 1366,
      height: 882,
      scale: null,
      zoom: 1,
      deviceVisible: true,
      submitDisabled: true,
      SpinnerEnum,
    };
  },
  computed: {
    url() {
      return `${window.location.protocol}//${window.location.host}/preview-full/${this.cpdId}/${this.version}`;
    },
    cssStyleContentWrapper() {
      return `min-height: ${this.height * this.scale}px;`;
    },
    cssStyleIframeWrapper() {
      return `width: ${this.width}px; height: ${this.height}px;transform:scale(${this.scale});`;
    },
  },
  methods: {
    onIframeLoad() {
      setTimeout(() => {
        EventBus.emitSpinnerStop(SpinnerEnum.PREVIEW);
        this.handleSubmitDisabledChange();
        const engineFrame = document.getElementById('engine-frame').contentWindow.document;
        const observer = new MutationObserver(this.handleSubmitDisabledChange);
        observer.observe(engineFrame, { childList: true, subtree: true });
      }, 600);
    },
    updateZoom(value) {
      document.getElementById('engine-frame').contentWindow.document.documentElement.style.setProperty('--preview-zoom', value);
    },
    updateEnvironment(width, height, key, device) {
      this.height = height;
      this.width = width;
      this.deviceKey = key;
      this.deviceFrame = device;
      this.calculateScale();
    },
    calculateScale() {
      const containerWidth = this.$refs.content.clientWidth - 200;
      const containerHeight = this.$refs.content.clientHeight - 200;

      const scaleX = containerWidth > this.width ? 1 : containerWidth / this.width;
      const scaleY = containerHeight > this.height ? 1 : containerHeight / this.height;

      this.scale = Math.min(scaleX, scaleY);
    },
    handleSubmitDisabledChange() {
      const engineFrameDocument = document.getElementById('engine-frame').contentWindow.document;
      const submitButton = engineFrameDocument.getElementById('convo-submit-button');
      const forwardButton = engineFrameDocument.getElementById('convo-forward-button');

      if (submitButton && this.submitDisabled) {
        submitButton.setAttribute('disabled', 'true');
      } else if (submitButton) {
        submitButton.removeAttribute('disabled');
      }

      if (forwardButton) {
        forwardButton.removeAttribute('disabled');
      }
    },
    updateSubmitDisabled(disabled) {
      this.submitDisabled = disabled;
      this.handleSubmitDisabledChange();
    },
    refreshEngineFrame() {
      document.getElementById('engine-frame').contentWindow.location.reload();
      EventBus.emitSpinnerStart(SpinnerEnum.PREVIEW);
    },
  },
  watch: {
    // Watcher to change web tab title language. Immediate to force eager callback execution
    '$i18n.locale': {
      handler() {
        document.title = this.$t(`convoPreview`);
      },
      immediate: true,
    },
    zoom(newValue) {
      this.updateZoom(newValue);
    },
  },
  mounted() {
    this.updateZoom(this.zoom);
    EventBus.onPreviewEnvironmentChange(this.updateEnvironment);
    window.addEventListener('resize', this.calculateScale);
    this.calculateScale();
    EventBus.emitSpinnerStart(SpinnerEnum.PREVIEW);
  },
  beforeDestroy() {
    EventBus.onPreviewEnvironmentChange(this.updateEnvironment, true);
    window.removeEventListener('resize', this.calculateScale);
  },
};
</script>

<style lang="scss" scoped>
.convo-preview {
  display: flex;
  width: 100%;
  height: calc(100vh - #{$header-height});

  .content {
    overflow-y: auto;
    width: 100%;

    .content-wrapper {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      overflow-x: hidden;

      .iframeWrapper {
        box-shadow: 8px 8px 24px rgba(44, 62, 89, 0.5);
        position: relative;
        box-sizing: content-box;

        &.custom-frame {
          border: 24px solid black;
          border-radius: 20px;
          box-shadow: 8px 8px 16px $primary-color;
        }
        .preview-frame {
          height: 100%;
          width: 100%;
          box-sizing: border-box;
          border: none;
          display: inline-table;
        }
      }

      .convo-zoom-selector {
        bottom: 16px;
        position: absolute;
        right: 20px;
      }
    }
  }
}
</style>
