import end from './boundary/control_end.json';
import start from './boundary/control_start.json';
import section from './structure/section.json';
import subsection from './structure/subsection.json';
import group from './structure/group.json';
import dynamicgroup from './structure/dynamic_group.json';
import textfield from './input/textfield.json';
import numberfield from './input/numberfield.json';
import currencyfield from './input/currencyfield.json';
import emailfield from './input/emailfield.json';
import phonenumberfield from './input/phonenumberfield.json';
import datepicker from './input/datepicker.json';
import periodpicker from './input/periodpicker.json';
import multipledatespicker from './input/multipledatespicker.json';
import choice from './input/choice.json';
import fileupload from './input/fileupload.json';
import textoutput from './output/textoutput.json';
import popup from './special/popup.json';
import person from './input/person.json';
import textarea from './input/textarea.json';
import outcome from './output/outcome.json';
import pdfGeneration from './output/pdf_generation.json';
import mailGeneration from './output/mail_generation.json';

export default {
  control_start: start,
  control_end: end,
  group,
  section,
  subsection,
  dynamic_group: dynamicgroup,
  textfield,
  numberfield,
  currencyfield,
  emailfield,
  phonenumberfield,
  datepicker,
  periodpicker,
  multipledatespicker,
  choice,
  fileupload,
  textoutput,
  popup,
  person,
  textarea,
  outcome,
  pdf_generation: pdfGeneration,
  mail_generation: mailGeneration,
};
