<template>
  <div class="convo-function" :class="[{ disabled, inactive: activeChild }, `convo-function-${operator}`]">
    <div class="function-header" :class="{ inactive: activeChild }" @click="activeChild = null">
      <span class="params">
        <div v-for="(param, index) in dropzones" :key="index" class="param">
          <div v-if="param.prefixKey">
            {{ $t(param.prefixKey) }}
          </div>
          <div
            class="header-child-placeholder"
            :class="{
              filled: param.value[0],
              function: isFunction(param.value[0]),
              active: `${id}-${index}` === activeDropzone || param.value[0] === activeChild,
            }"
          />
          <div v-if="param.suffixKey && index !== dropzones.length - 1">
            {{ $t(param.suffixKey) }}
          </div>
          <div v-if="param.interParamKey">
            {{ $t(param.interParamKey) }}
          </div>
        </div>
      </span>
      <convo-function-trashbin v-if="!disabled" class="delete" @delete="$emit('delete')" />
    </div>
    <transition name="open-function">
      <convo-function
        v-if="activeChild"
        :id="activeChild"
        :disabled="disabled"
        :parentType="returnType === CelReturnType.GENERIC ? parentType : returnType"
        @delete="handleActiveChildDelete"
      />
    </transition>
    <div v-if="!activeChild" class="function-params">
      <div v-for="(dropzone, index) in dropzones" :key="index">
        <div class="param">
          <div v-if="dropzone.prefixKey" class="param-prefix">
            {{ $t(dropzone.prefixKey) }}
          </div>
          <convo-expression-language-draggable
            :elements="dropzone.value"
            :limit="1"
            :group="dropzone.type === CelReturnType.PARENT ? parentType : dropzone.type"
            :disabled="disabled"
            :draggable-id="`${id}-${index}`"
            @open-function="activeChild = dropzone.value[0]"
            @delete="dropzone.value = []"
          />
          <i
            v-if="dropzonesExtendable && index === dropzones.length - 1 && !disabled"
            :key="'icon' + index"
            class="convo-icon-circle-plus param-suffix"
            @click="addDropzone"
          />
          <div v-else-if="index === dropzones.length - 1 && dropzone.suffixKey && disabled" class="param-suffix" />
          <div v-else-if="dropzone.suffixKey" class="param-suffix">
            {{ $t(dropzone.suffixKey) }}
          </div>
        </div>
        <div v-if="dropzone.interParamKey" class="inter-param-label">
          {{ $t(dropzone.interParamKey) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CelReturnType from '@/common/enums/CelReturnType';
import ConvoExpressionLanguageDraggable from '@/studio/components/right_sidepanel/inspector_panel/convo_expression_language_dialog/ConvoExpressionLanguageDraggable.vue';
import ConvoFunctionTrashbin from '@/studio/components/right_sidepanel/inspector_panel/convo_expression_language_dialog/expression/ConvoFunctionTrashbin.vue';
import ConvoExpressionLanguageService from '@/common/services/ConvoExpressionLanguageService';
import CelParameterType from '@/common/enums/CelParameterType';
import CelExpressionType from '@/common/enums/CelExpressionType';
import EventBus from '@/common/event-bus';

export default {
  name: 'ConvoFunction',
  components: { ConvoExpressionLanguageDraggable, ConvoFunctionTrashbin },
  props: {
    id: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    parentType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      activeChild: null,
      dropzones: [],
      dropzonesExtendable: false,
      CelReturnType,
      activeDropzone: null,
    };
  },
  computed: {
    operator() {
      return ConvoExpressionLanguageService.getExpressionField(this.id, 'operator');
    },
    returnType() {
      const operator = ConvoExpressionLanguageService.getExpressionField(this.id, 'operator');
      return ConvoExpressionLanguageService.getExpressionDefinitionField(operator, 'returnType');
    },
  },
  watch: {
    dropzones: {
      deep: true,
      handler(dropzones) {
        ConvoExpressionLanguageService.updateExpressionField(
          this.id,
          'parameter',
          dropzones.flatMap((dropzone) => dropzone.value)
        );
        this.activateNextEmptyDropzone();
      },
    },
    activeChild(newActiveChild) {
      if (!newActiveChild) {
        this.$nextTick(this.activateNextEmptyDropzone);
      }
    },
  },
  methods: {
    setDropzones() {
      const parameter = ConvoExpressionLanguageService.getExpressionField(this.id, 'parameter');

      const parameterType = ConvoExpressionLanguageService.getExpressionDefinitionField(this.operator, 'parameterType');
      this.dropzonesExtendable = parameterType === CelParameterType.VARIABLE;
      if (parameterType === CelParameterType.FIX) {
        const parameterDefinition = ConvoExpressionLanguageService.getExpressionDefinitionField(this.operator, 'parameter');
        this.dropzones = parameterDefinition.map((paramDef, index) => ({ ...paramDef, value: parameter[index] ? [parameter[index]] : [] }));
      } else if (parameterType === CelParameterType.VARIABLE) {
        const parameterTemplate = ConvoExpressionLanguageService.getExpressionDefinitionField(this.operator, 'parameterTemplate');
        this.dropzones = parameter.map((param) => ({ ...parameterTemplate, value: [param] }));
        while (this.dropzones.length < 2) this.dropzones.push({ ...parameterTemplate, value: [] });
      }
    },
    addDropzone() {
      const parameterTemplate = ConvoExpressionLanguageService.getExpressionDefinitionField(this.operator, 'parameterTemplate');
      this.dropzones.push({ ...parameterTemplate, value: [] });
      this.$nextTick(this.activateNextEmptyDropzone);
    },
    isFunction(id) {
      return ConvoExpressionLanguageService.getExpressionField(id, 'type') === CelExpressionType.FUNCTION;
    },
    activateNextEmptyDropzone() {
      let finished = false;
      this.dropzones.forEach((dropzone, index) => {
        if (!finished && dropzone.value.length === 0) {
          EventBus.emitDraggableActivation(dropzone.type === CelReturnType.PARENT ? this.parentType : dropzone.type, `${this.id}-${index}`);
          finished = true;
        }
      });
      if (!finished) {
        EventBus.emitDraggableActivation(CelReturnType.NONE);
      }
    },
    handleActiveChildDelete() {
      this.dropzones.forEach((dropzone) => {
        if (dropzone.value[0] === this.activeChild) {
          dropzone.value.splice(0, 1);
        }
      });
      this.activeChild = null;
    },
    setActiveDropzone(group, draggableId) {
      this.activeDropzone = draggableId;
    },
  },
  mounted() {
    EventBus.onDraggableActivation(this.setActiveDropzone);
    this.setDropzones();
    this.$nextTick(this.activateNextEmptyDropzone);
  },
  beforeDestroy() {
    EventBus.onDraggableActivation(this.setActiveDropzone, true);
  },
};
</script>

<style lang="scss" scoped>
.convo-function {
  background-color: $convo-blue-2;
  border-radius: 10px 10px 5px 5px;
  &.disabled > .function-params {
    padding: 32px;
  }
  &.inactive {
    background-color: $convo-blue-4;
  }

  .function-header {
    border-radius: 10px 10px 0 0;
    box-shadow: 0 -1px 4px $convo-blue-5;
    color: $primary-color;
    padding: 8px 32px;
    position: relative;

    .params {
      display: flex;
      overflow: hidden;

      .param {
        align-items: center;
        display: flex;
        word-break: keep-all;
        .header-child-placeholder {
          border: 1px solid $primary-color;
          border-radius: 999px;
          height: 14px;
          margin: 0 16px;
          width: 14px;
          &.filled {
            background-color: $primary-color;
          }
          &.active {
            border-width: 2px;
          }
          &.function {
            width: 28px;
          }
        }
      }
    }

    .delete {
      color: $primary-color;
      cursor: pointer;
      font-size: 16px;
      position: absolute;
      right: 12px;
      top: 10px;

      ::v-deep.convo-icon-delete {
        display: none;
      }

      &:hover {
        color: $secondary-color;
      }
    }

    &.inactive {
      color: $convo-blue-5;
      cursor: pointer;
      .param {
        .header-child-placeholder {
          border-color: $convo-blue-5;
          &.filled {
            background-color: $convo-blue-5-lighten;
            border-color: $convo-blue-5-lighten;
          }
          &.active {
            background-color: $convo-blue-5;
            border-color: $primary-color-lighten;
          }
        }
      }
    }
  }

  .function-params {
    background-color: white;
    border: 2px solid $convo-blue-2;
    border-radius: 0 0 5px 5px;
    padding: 32px 80px;

    :not(:last-child) {
      .param {
        margin-bottom: 24px;
      }
    }

    .param {
      align-items: center;
      display: flex;
      justify-content: center;

      .param-prefix,
      .param-suffix {
        color: $primary-color;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        width: 100px;
        word-break: keep-all;
      }
      .param-prefix {
        margin-right: 12px;
      }
      .param-suffix {
        margin-left: 12px;
      }
      .convo-icon-circle-plus {
        cursor: pointer;
        font-size: 24px;
        font-weight: 500;
      }

      .convo-expression-language-draggable {
        height: 60px;
        max-width: 560px;
        width: calc(100% - 100px);
      }
      ::v-deep {
        .convo-toolbox-function,
        .convo-toolbox-constant,
        .convo-toolbox-element-value {
          font-size: 16px;
          height: 100%;
        }
      }
    }

    .inter-param-label {
      color: $primary-color;
      font-size: 14px;
      font-weight: 600;
      margin-top: -8px;
      margin-bottom: 16px;
      min-height: 14px;
      text-align: center;
    }
  }

  &:hover:not(.inactive) {
    .delete ::v-deep.convo-icon-delete {
      display: block;
    }
  }
}

@media (max-width: 1800px) {
  .convo-function > .function-params {
    padding: 32px 64px;
  }
}
@media (max-width: 1600px) {
  .convo-function > .function-params {
    padding: 32px;

    .param > .param-suffix {
      width: 80px;
    }
  }
}
@media (max-width: 1400px) {
  .convo-function > .function-header {
    font-size: 12px;
  }
}

@media (max-width: 1280px) {
  .convo-function {
    .function-params {
      padding: 16px;

      .param > .param-suffix {
        font-size: 12px;
      }
    }
  }
}

@keyframes bounce-in {
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
.open-function-enter-active {
  animation: bounce-in 0.5s;
}
</style>
