<template>
  <div class="convo-expression-language-dialog">
    <el-dialog :closeOnClickModal="false" top="6vh" :visible.sync="dialogVisible" @close="handleClose">
      <div slot="title" class="title"><i class="convo-icon-function-circle-default" />{{ $t('magicVariablesDialog.title') }}</div>
      <div class="content" v-if="dialogVisible">
        <div class="mv-toolbox">
          <convo-element-values-toolbox :parent-element="parentId" :required-input-type="requiredInputType" />
        </div>
        <div class="expression">
          <convo-expression :expression-id.sync="expression" :heading="expressionHeading" :required-input-type="type" />
          <span class="navigation">
            <el-button v-if="showPreviousFieldButton" class="primary" :disabled="!isExpressionValid" @click="openPreviousField">{{
              $t('previousField')
            }}</el-button>
            <el-button @click="handleCancel">{{ $t('cancel') }}</el-button>
            <el-button
              id="expressionLanguageDialogConfirmationButton"
              class="primary"
              @click="handleConfirm"
              :disabled="!isExpressionValid"
              >{{ $t('apply') }}</el-button
            >
            <el-button v-if="showNextFieldButton" class="primary" :disabled="!isExpressionValid" @click="openNextField">{{
              $t('nextField')
            }}</el-button>
          </span>
        </div>
        <div class="cel-toolbox">
          <convo-functions-toolbox :required-input-type="requiredInputType" />
          <convo-constants-toolbox :required-input-type="requiredInputType" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import EventBus from '@/common/event-bus';
import ConvoExpressionLanguageService from '@/common/services/ConvoExpressionLanguageService';
import ConvoElementValuesToolbox from '@/studio/components/right_sidepanel/inspector_panel/convo_expression_language_dialog/left_sidepanel/ConvoElementValuesToolbox.vue';
import ConvoFunctionsToolbox from '@/studio/components/right_sidepanel/inspector_panel/convo_expression_language_dialog/right_sidepanel/ConvoFunctionsToolbox.vue';
import ConvoConstantsToolbox from '@/studio/components/right_sidepanel/inspector_panel/convo_expression_language_dialog/right_sidepanel/ConvoConstantsToolbox.vue';
import ConvoExpression from '@/studio/components/right_sidepanel/inspector_panel/convo_expression_language_dialog/expression/ConvoExpression.vue';

export default {
  name: 'ConvoExpressionLanguageDialog',
  components: {
    ConvoExpression,
    ConvoConstantsToolbox,
    ConvoFunctionsToolbox,
    ConvoElementValuesToolbox,
  },
  props: {
    expressionHeading: {
      type: String,
      required: true,
    },
    fieldKey: {
      type: String,
      required: true,
    },
    parentId: {
      type: String,
      required: true,
    },
    showNextFieldButton: {
      type: Boolean,
      default: false,
    },
    showPreviousFieldButton: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
    value: {
      required: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogVisible: this.visible,
      expression: ConvoExpressionLanguageService.parseString(this.value),
      trash: [],
      requiredInputType: this.type,
    };
  },
  watch: {
    visible(newValue) {
      this.dialogVisible = newValue;
      if (newValue) {
        this.expression = ConvoExpressionLanguageService.parseString(this.value);
      }
    },
    expression() {
      ConvoExpressionLanguageService.setExpressionIdForField(this.parentId, this.fieldKey, this.expression);
    },
  },
  computed: {
    isExpressionValid() {
      return !this.expression || ConvoExpressionLanguageService.isExpressionValid(this.expression);
    },
  },
  methods: {
    handleClose() {
      this.$emit('close');
    },
    handleConfirm() {
      const expressionString = this.expression ? ConvoExpressionLanguageService.renderString(this.expression) : null;
      this.$emit('update', expressionString);
      this.dialogVisible = false;
    },
    handleCancel() {
      this.dialogVisible = false;
    },
    openNextField() {
      const expressionString = this.expression ? ConvoExpressionLanguageService.renderString(this.expression) : null;
      this.$emit('open-next-field', expressionString);
      this.dialogVisible = false;
    },
    openPreviousField() {
      const expressionString = this.expression ? ConvoExpressionLanguageService.renderString(this.expression) : null;
      this.$emit('open-previous-field', expressionString);
      this.dialogVisible = false;
    },
    updateRequiredInputType(type) {
      this.requiredInputType = type;
    },
  },
  mounted() {
    ConvoExpressionLanguageService.setExpressionIdForField(this.parentId, this.fieldKey, this.expression);
    EventBus.onDraggableActivation(this.updateRequiredInputType);
  },
  beforeDestroy() {
    EventBus.onDraggableActivation(this.updateRequiredInputType, true);
  },
};
</script>

<style lang="scss">
.v-modal {
  z-index: 2000 !important;
}
</style>

<style lang="scss" scoped>
.convo-expression-language-dialog {
  .title {
    color: $primary-text-color;
    font-size: 20px;
    .convo-icon-function-circle-default {
      font-size: 24px;
      margin: 0 8px;
      vertical-align: bottom;
    }
  }

  .content {
    display: flex;
    height: 80vh;

    .cel-toolbox {
      border-left: 1px solid $global-layout-line-color;
      height: 100%;
      overflow-y: auto;
      width: 360px;

      .convo-functions-toolbox,
      .convo-constants-toolbox {
        border-bottom: 1px solid $global-layout-line-color;
      }
    }
    .expression {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      overflow-y: auto;
      padding: 24px 16px;
      width: calc(100% - 360px - 360px);

      .navigation {
        display: flex;
        justify-content: flex-end;
        margin-top: 24px;
        padding: 0 24px;

        button {
          background-color: $primary-text-color;
          border: 1px solid $primary-color;
          border-radius: 5px;
          color: $primary-color;
          font-family: $primary-font;
          font-size: 14px;
          font-weight: 600;
          height: 32px;
          margin-left: 32px;
          width: 140px;

          &.primary {
            background-color: $primary-color;
            color: $primary-text-color;

            &:disabled {
              background-color: $disabled-color;
              border: none;
              &:hover {
                box-shadow: none;
                cursor: default;
              }
            }
          }

          &:hover {
            box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15);
          }
        }
      }
    }
    .mv-toolbox {
      border-right: 1px solid $global-layout-line-color;
      height: 100%;
      width: 360px;
    }
  }

  ::v-deep {
    .el-dialog {
      width: 90vw !important;
      padding: 2px;
      border-radius: 8px;

      .el-dialog__header {
        background-color: $primary-color;
        border-radius: 8px 8px 0 0;
        padding: 6px;
      }
      .el-dialog__title {
        color: $primary-text-color;
      }
      .el-dialog__headerbtn {
        right: 12px;
        top: 12px;
      }
      .el-dialog__close {
        color: $primary-text-color;
        font-size: 20px;
      }
      .el-dialog__body {
        padding: 0;
      }
    }
  }
}

@media (max-width: 1600px) {
  .convo-expression-language-dialog {
    .content {
      .cel-toolbox,
      .mv-toolbox {
        width: 320px;
      }
      .expression {
        width: calc(100% - 320px - 320px);
      }
    }
  }
}

@media (max-width: 1400px) {
  .convo-expression-language-dialog {
    .content {
      .cel-toolbox,
      .mv-toolbox {
        width: 280px;
      }
      .expression {
        width: calc(100% - 280px - 280px);
      }
    }
  }
}

@media (max-width: 1280px) {
  .convo-expression-language-dialog {
    ::v-deep .el-dialog {
      width: 95vw !important;
    }

    .title {
      font-size: 16px;
      convo-icon-function-circle-default {
        font-size: 20px;
        margin: 0 4px;
      }
    }

    .content {
      .cel-toolbox,
      .mv-toolbox {
        width: 280px;
      }
      .expression {
        width: calc(100% - 280px - 280px);
        padding: 16px;

        .navigation {
          padding: 0 12px;

          button {
            font-size: 13px;
            height: 32px;
            margin-left: 32px;
            width: 124px;
          }
        }
      }
    }
  }
}
</style>
