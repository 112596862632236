<template>
  <div :class="{ 'not-editable': !editable }">
    <router-view :editable="editable" />
  </div>
</template>

<script>
export default {
  name: 'ConvoRightSidepanel',
  props: {
    editable: {
      default: true,
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.sidepanel {
  width: 100%;
  border-left: 1px solid $global-layout-line-color;
  box-sizing: border-box;
}
</style>
