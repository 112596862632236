<template>
  <div class="convo-header-slot-dashboard">
    <div class="search-bar">
      <!--suppress HtmlFormInputWithoutLabel -->
      <input v-model="searchString" class="search-input" :placeholder="$t('dashboard.searchBarPlaceholder')" />
      <div class="search-suffix"><i class="convo-icon-search" /></div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/common/event-bus';

export default {
  name: 'ConvoHeaderSlotDashboard',
  data() {
    return {
      searchString: '',
      filterDelay: 50,
      timeout: null,
    };
  },
  watch: {
    searchString(newVal) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => EventBus.emitDashboardSearchStringChange(newVal), this.filterDelay);
    },
  },
};
</script>

<style lang="scss" scoped>
.convo-header-slot-dashboard {
  .search-bar {
    display: flex;
    align-items: center;
    width: 440px;
    height: 32px;
    margin: 0 auto;
    padding: 0 8px 0 16px;

    background-color: #fff;
    border-radius: 999px;

    .search-input {
      flex-grow: 1;
      height: 100%;
      border: none;
      outline: none;
      font-size: 16px;

      &::placeholder {
        font-family: $primary-font;
        font-style: italic;
        font-size: 12px;
        font-weight: lighter;
        color: rgba(44, 62, 89, 0.7);
      }
    }

    .convo-icon-search {
      font-size: 20px;
      color: $primary-color;
    }
  }
}

@media (max-width: 1280px) {
  .convo-header-slot-dashboard .search-bar {
    height: 20px;
    width: 360px;
    padding: 0 6px 0 8px;

    .search-input {
      font-size: 12px;
    }
    .convo-icon-search {
      font-size: 14px;
    }
  }
}
</style>
