<template>
  <div class="convo-chat-minimize" @click="minimizeChat"></div>
</template>

<script>
export default {
  name: 'ConvoChatMinimize',
  methods: {
    minimizeChat() {
      this.$emit('minimize-chat', true);
    },
  },
};
</script>

<style lang="scss" scoped>
.convo-chat-minimize {
  cursor: pointer;
  //LAYOUT
  border: 2px solid rgba(44, 62, 89, 1);
  width: 16px;
}
</style>
