<template>
  <div class="convo-messages" v-if="messages.length > 0">
    <convo-message
      :key="currentIndex"
      :message="messages[currentIndex]"
      :index="currentIndex"
      :previous-message="currentIndex > 0"
      :next-message="currentIndex < messages.length - 1"
      @previous="currentIndex -= 1"
      @next="currentIndex += 1"
    />
  </div>
</template>

<script>
import MessageService from '@/common/services/MessageService';
import ConvoMessage from './ConvoMessage.vue';

export default {
  name: 'ConvoMessages',
  components: {
    ConvoMessage,
  },
  data() {
    return {
      currentIndex: 0,
    };
  },
  computed: {
    messages() {
      return MessageService.getMessages();
    },
  },
  watch: {
    messages() {
      this.currentIndex = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.convo-messages {
  padding: 24px;
}
</style>
