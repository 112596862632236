import axios from 'axios';
import store, { ANALYTICS_NAMESPACE } from '@/common/store';
import AnalyticsEventType from '@/common/enums/AnalyticsEventType';

const AnalyticsService = (() => {
  const endpoint = `${window.backend}/v1/analytics`;
  const { userAgent } = window.navigator;
  // minimum event number to be collected before next backend call is sent
  const { requestSize } = window.analytics;
  // minimum time in milliseconds to wait between first and latest event before next backend call is sent
  const requestInterval = window.analytics.requestInterval * 1000;

  function maskId(url) {
    const uuidRegex = new RegExp('[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}', 'g');
    return url.replace(uuidRegex, '<id>');
  }

  return {
    commitUserAction(name, parameter) {
      store.commit(`${ANALYTICS_NAMESPACE}/addAnalyticsEvent`, {
        type: AnalyticsEventType.USER_ACTION,
        name,
        parameter,
        userAgent,
      });
      this.createRequestIfRequired();
    },

    commitUrlChange(url) {
      store.commit(`${ANALYTICS_NAMESPACE}/addAnalyticsEvent`, {
        type: AnalyticsEventType.URL_CHANGE,
        name: maskId(url),
        userAgent,
      });
      this.createRequestIfRequired();
    },

    commitError(name, parameter) {
      store.commit(`${ANALYTICS_NAMESPACE}/addAnalyticsEvent`, {
        type: AnalyticsEventType.ERROR,
        name,
        parameter,
        userAgent,
      });
      this.createRequestIfRequired();
    },

    createRequestIfRequired() {
      const analyticsEvents = store.getters[`${ANALYTICS_NAMESPACE}/analyticsEvents`];
      if (analyticsEvents.length > 0) {
        const timeInterval = Date.now() - new Date(analyticsEvents[0].time);
        if (analyticsEvents.length >= requestSize || timeInterval >= requestInterval) {
          axios.post(endpoint, analyticsEvents, { errorHandle: false }).then(() => {
            store.commit(`${ANALYTICS_NAMESPACE}/clearEvents`);
          });
        }
      }
    },
  };
})();

export default AnalyticsService;
