<template>
  <div class="convo-process-config">
    <div class="process-start">
      <div class="process-start-header" @click="toggle('start')" :key="activeCategories.length">
        {{ $t('control_start.elementName') }}
        <i :class="{ 'convo-icon-minus': isCategoryActive('start'), 'convo-icon-plus': !isCategoryActive('start') }" />
      </div>
      <convo-inspector-panel v-show="isCategoryActive('start')" :component-id="startElement" :editable="isDraftState" :show-name="false" />
    </div>
    <div class="process-end">
      <div class="process-end-header" @click="toggle('end')" :key="activeCategories.length">
        {{ $t('control_end.elementName') }}
        <i :class="{ 'convo-icon-minus': isCategoryActive('end'), 'convo-icon-plus': !isCategoryActive('end') }" />
      </div>
      <convo-inspector-panel v-show="isCategoryActive('end')" :component-id="endElement" :editable="isDraftState" :show-name="false" />
    </div>
  </div>
</template>

<script>
import ConvoInspectorPanel from '@/studio/components/right_sidepanel/inspector_panel/ConvoInspectorPanel.vue';
import CpdModificationService from '@/common/services/CpdModificationService';
import StateEnum from '@/common/enums/StateEnum';

export default {
  name: 'ConvoProcessConfig',
  components: { ConvoInspectorPanel },
  props: {
    uniqueCpdId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      activeCategories: ['start', 'end'],
    };
  },
  computed: {
    isDraftState() {
      return this.$store.getters.cpdState === StateEnum.DRAFT;
    },
    startElement() {
      return CpdModificationService.getElementField(this.uniqueCpdId, 'control_start');
    },
    endElement() {
      return CpdModificationService.getElementField(this.uniqueCpdId, 'control_end');
    },
  },
  methods: {
    isCategoryActive(category) {
      return this.activeCategories.includes(category);
    },
    toggle(category) {
      if (this.activeCategories.includes(category)) {
        this.activeCategories.splice(this.activeCategories.indexOf(category), 1);
      } else {
        this.activeCategories.push(category);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.convo-process-config {
  height: 100%;
  overflow-y: auto;

  .process-start-header,
  .process-end-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    padding: 16px;
    cursor: pointer;
    background-color: $convo-blue-4;
    color: $primary-color;
    font-size: 20px;
    font-weight: 600;

    :first-child {
      margin-right: 8px;
    }

    .convo-icon-minus,
    .convo-icon-plus {
      font-size: 14px;
      font-weight: bold;
    }
  }
}

@media (max-width: 1280px) {
  .convo-process-config {
    .process-start-header,
    .process-end-header {
      font-size: 14px;
      height: 40px;
      padding: 10px;

      .convo-icon-minus,
      .convo-icon-plus {
        font-size: 12px;
      }
    }
  }
}
</style>
