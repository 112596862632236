<template>
  <div class="convo-date-input convo-ip-input">
    <el-form-item :prop="fieldKey" :rules="[{ validator, trigger }]">
      <!-- prop is required to ensure validation, but is not used -->
      <el-popover
        ref="popover"
        :title="localizedTooltipTitle"
        trigger="hover"
        :content="localizedTooltipText"
        placement="left-start"
        width="320"
        :open-delay="800"
      />
      <span class="no-tabindex" slot="label" v-popover:popover>{{ localizedLabel }}</span>
      <el-date-picker
        v-if="celDisabled || !isConvoExpressionLanguage"
        v-model="value"
        :disabled="!editable"
        :type="multiple ? 'dates' : 'date'"
        :id="`${fieldKey}-input-${parentId}`"
        :placeholder="localizedPlaceholder"
        :format="$i18n.locale === 'de' ? 'dd.MM.yyyy' : 'yyyy-MM-dd'"
      >
      </el-date-picker>
      <template v-else>
        <el-popover ref="popover-cel" trigger="hover" width="480" placement="bottom-start" :open-delay="800">
          <convo-expression
            :key="dialogVisible"
            disabled
            :expression-id="expression"
            :heading="expressionHeading"
            :required-input-type="CelReturnType.DATE"
          />
        </el-popover>
        <span v-popover:popover-cel :id="`expression-language-placeholder-${fieldKey}`" class="active-mv">
          <i v-for="i in 4" :key="i" class="convo-icon-placeholder" />
        </span>
      </template>
      <el-button
        v-if="!celDisabled"
        :class="`expression-language-dialog-button-${fieldKey}`"
        @click="handleOpen"
        size="mini"
        class="mv-button"
        tabindex="-1"
      >
        <i v-if="isConvoExpressionLanguage" class="convo-icon-function-circle-active" />
        <i v-else class="convo-icon-function-circle-default" />
      </el-button>
    </el-form-item>
    <convo-expression-language-dialog
      v-if="!celDisabled"
      :visible="dialogVisible"
      :value="value"
      :type="CelReturnType.DATE"
      @update="value = $event"
      @close="handleClose"
      :parentId="parentId"
      :field-key="fieldKey"
      :expression-heading="expressionHeading"
    />
  </div>
</template>

<script>
import ConvoInputMixin from '@/studio/mixins/ConvoInputMixin';
import ConvoInputI18nMixin from '@/studio/mixins/ConvoInputI18nMixin';
import ValidationMixin from '@/studio/mixins/ValidationMixin';
import CelReturnType from '@/common/enums/CelReturnType';

export default {
  name: 'ConvoDateInput',
  mixins: [ConvoInputMixin, ConvoInputI18nMixin, ValidationMixin],
  props: {
    celDisabled: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      CelReturnType,
    };
  },
  methods: {
    validator(rule, value, callback) {
      this.validate(
        this.mandatory,
        (evaluated) => evaluated && !this.value,
        () => this.$t('validationError.mandatory'),
        callback
      );
      this.validate(
        this.min,
        (evaluated) => {
          if (this.multiple) {
            return evaluated && this.value && this.value.reduce((prev, curr) => prev || new Date(curr) < new Date(evaluated), false);
          }
          return evaluated && new Date(this.value) < new Date(evaluated);
        },
        (evaluated) => this.$t('validationError.minDate', [this.$d(new Date(evaluated), 'short')]),
        callback
      );
      this.validate(
        this.max,
        (evaluated) => {
          if (this.multiple) {
            return evaluated && this.value && this.value.reduce((prev, curr) => prev || new Date(curr) > new Date(evaluated), false);
          }
          return evaluated && new Date(this.value) > new Date(evaluated);
        },
        (evaluated) => this.$t('validationError.maxDate', [this.$d(new Date(evaluated), 'short')]),
        callback
      );
      callback();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/studio/styles/inputs.scss';
.convo-date-input {
  .el-date-editor {
    width: calc(100% - 52px);

    ::v-deep.el-input__inner {
      font-family: $primary-font;
      color: $primary-color;
    }
  }
}

@media (max-width: 1280px) {
  .convo-date-input ::v-deep.el-input__inner {
    padding-left: 26px;
    font-size: 10px;
  }
}
</style>
