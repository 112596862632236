import Vue from 'vue';
import VueRouter from 'vue-router';
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc';
import store, { ASYNC_PERSIST_KEY } from '@/common/store';
import OidcCallback from '@/common/components/OidcCallback.vue';
import ConvoLandingPage from '@/landing_page/components/ConvoLandingPage.vue';
import ConvoInspectorPanel from '@/studio/components/right_sidepanel/inspector_panel/ConvoInspectorPanel.vue';
import ConvoProcessConfig from '@/studio/components/right_sidepanel/ConvoProcessConfig.vue';
import ConvoPreview from '@/preview/components/ConvoPreview.vue';
import ConvoPreviewFullScreen from '@/preview/components/ConvoPreviewFullScreen.vue';
import ConvoStudio from '@/studio/components/ConvoStudio.vue';
import ConvoEditorialView from '@/editorial_view/components/ConvoEditorialView.vue';
import ConvoDashboard from '@/dashboard/components/ConvoDashboard.vue';
import ConvoNotFoundPage from '@/app/components/ConvoNotFoundPage.vue';
import AnalyticsService from '@/common/services/AnalyticsService';
import { setOptions as setVuexPersistOptions } from 'vuex-async-persist';

Vue.use(VueRouter);

const routes = [
  {
    path: '/oidc-callback',
    name: 'oidcCallback',
    component: OidcCallback,
  },
  {
    path: '/start',
    name: 'start',
    component: ConvoLandingPage,
    meta: {
      isPublic: true,
    },
  },
  {
    path: '',
    redirect: '/start',
  },
  {
    path: '/preview-full/:cpd/:version',
    props: (route) => ({
      cpdId: route.params.cpd,
      uniqueCpdId: route.params.cpd + 'v' + route.params.version,
    }),
    name: 'PreviewFullScreen',
    component: ConvoPreviewFullScreen,
  },
  {
    path: '/preview/:cpd/:version',
    name: 'Preview',
    props: (route) => ({
      cpdId: route.params.cpd,
      version: parseInt(route.params.version, 10),
    }),
    component: ConvoPreview,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: ConvoDashboard,
  },
  {
    path: '/studio/:cpd/:version',
    name: 'Studio',
    component: ConvoStudio,
    props: (route) => ({
      cpdId: route.params.cpd,
      version: parseInt(route.params.version, 10),
    }),
    children: [
      {
        path: 'component/:component',
        name: 'InspectorPanel',
        component: ConvoInspectorPanel,
        props: (route) => ({
          componentId: route.params.component,
          targetKey: route.query.target,
        }),
      },
      {
        path: 'config',
        name: 'ConvoProcessConfig',
        component: ConvoProcessConfig,
        props: (route) => ({
          uniqueCpdId: `${route.params.cpd}v${route.params.version}`,
        }),
      },
    ],
  },
  {
    path: '/studio/:cpd/:version/i18n',
    name: 'ConvoEditorialView',
    component: ConvoEditorialView,
    props: (route) => ({
      uniqueCpdId: route.params.cpd + 'v' + route.params.version,
    }),
  },
  {
    path: '/404',
    name: '404',
    component: ConvoNotFoundPage,
  },
  {
    path: '*',
    redirect: '/404',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(vuexOidcCreateRouterMiddleware(store, 'oidc'));

const prevKey = ASYNC_PERSIST_KEY;

router.afterEach((to) => {
  AnalyticsService.commitUrlChange(to.path);
  if (to.params && to.params.cpd && prevKey !== to.params.cpd)
    setVuexPersistOptions({ key: to.params.cpd + 'v' + to.params.version }, true);
  else if (!to.params.cpd && prevKey !== ASYNC_PERSIST_KEY) setVuexPersistOptions({ key: ASYNC_PERSIST_KEY }, false);
});

export default router;
