const ElementType = Object.freeze({
  CONTROL_START: 'control_start',
  CONTROL_END: 'control_end',

  SECTION: 'section',
  SUBSECTION: 'subsection',
  GROUP: 'group',
  DYNAMIC_GROUP: 'dynamic_group',

  REST: 'rest',
  POPUP: 'popup',
  PDF: 'pdf_generation',
  MAIL: 'mail_generation',

  TEXT_INPUT: 'textfield',
  NUMBER_INPUT: 'numberfield',
  CURRENCY_INPUT: 'currencyfield',
  MAIL_INPUT: 'emailfield',
  PHONE_INPUT: 'phonenumberfield',
  DATE_PICKER: 'datepicker',
  PERIOD_PICKER: 'periodpicker',
  MULTIPLE_DATES_PICKER: 'multipledatespicker',
  CHOICE: 'choice',
  FILEUPLOAD: 'fileupload',

  TEXT_OUTPUT: 'textoutput',

  PERSON: 'person',
});

export default ElementType;
