<template>
  <div class="convo-add-entry-dropdown">
    <div
      ref="activator"
      :class="['activator', { 'not-editable': !editable }]"
      :id="mask(`add-entry-${i18nPrefix}-${identifier}`)"
      @click="toggle"
    >
      <i class="convo-icon-plus-circle" />
    </div>
    <div v-if="visible" class="options">
      <div class="option" id="add-value" @click="select('key-value')">
        <i class="convo-icon-plus-circle" /> {{ $t(`${i18nPrefix}.add_key_value`) }}
      </div>
      <div v-if="!recursionDisabled" class="option" id="add-list" @click="select('key-list')">
        <i class="convo-icon-plus-circle" /> {{ $t(`${i18nPrefix}.add_key_list`) }}
      </div>
      <div v-if="!recursionDisabled" class="option" id="add-object" @click="select('key-object')">
        <i class="convo-icon-plus-circle" /> {{ $t(`${i18nPrefix}.add_key_object`) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConvoAddEntryDropdown',
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
    recursionDisabled: {
      type: Boolean,
      default: false,
    },
    i18nPrefix: {
      type: String,
      required: true,
    },
    identifier: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    toggle() {
      if (this.visible) {
        this.closeOptions();
      } else {
        this.openOptions();
      }
    },
    select(option) {
      this.$emit(option);
      this.closeOptions();
    },
    openOptions() {
      if (!this.editable) return;
      this.visible = true;
      document.addEventListener('click', this.closeOptions);
    },
    closeOptions(e) {
      if (!e || (e.target !== this.$refs.activator && e.target.parentElement !== this.$refs.activator)) {
        this.visible = false;
        document.removeEventListener('click', this.closeOptions);
      }
    },
    mask(string) {
      return string.replace(/\./g, '-').replace(/_/g, '-');
    },
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeOptions);
  },
};
</script>

<style lang="scss" scoped>
.convo-add-entry-dropdown {
  display: flex;
  justify-content: center;
  margin-top: -8px;
  position: relative;

  .activator {
    color: $primary-color;
    cursor: pointer;
    font-size: 24px;

    &.not-editable {
      color: $disabled-color;
    }
  }
  .options {
    background-color: white;
    border-radius: 8px;
    box-shadow: 3px 3px 9px $primary-color;
    bottom: 40px;
    padding: 16px;
    position: absolute;

    .option {
      align-items: center;
      color: $primary-color;
      cursor: pointer;
      display: flex;
      font-size: 14px;
      font-weight: 600;
      text-align: center;

      &:not(:last-child) {
        margin-bottom: 12px;
      }

      .convo-icon-plus-circle {
        font-size: 24px;
        margin-right: 8px;
      }
    }
  }
}

@media (max-width: 1280px) {
  .convo-add-entry-dropdown {
    margin-top: 0;

    .activator {
      font-size: 20px;
    }

    .options {
      padding: 10px;

      .option .convo-icon-plus-circle {
        font-size: 20px;
      }
    }
  }
}
</style>
