<template>
  <div class="convo-create-cpd-tile">
    <div
      id="cpd-create-tile-activator"
      :class="isDemo ? 'disable-create' : 'create-activator'"
      v-if="!creationMode"
      @click.stop="isDemo ? (creationMode = false) : (creationMode = true)"
    >
      <i class="convo-icon-plus-circle" />
      <div class="create-text">{{ $t('dashboard.createCpd') }}</div>
    </div>
    <convo-cpd-tile
      v-else
      creation-mode
      :cpd="{ name: initialName, state: initialState, modifiedBy: currentUser, modified: initialModified }"
      @confirm="handleCpdCreation"
      @cancel="creationMode = false"
    />
  </div>
</template>

<script>
import ConvoCpdTile from '@/dashboard/components/ConvoCpdTile.vue';
import CpdApiService from '@/common/services/CpdApiService';
import { mapGetters } from 'vuex';
import EventBus from '@/common/event-bus';

export default {
  name: 'ConvoCreateCpdTile',
  components: { ConvoCpdTile },
  data() {
    return {
      creationMode: false,
      initialModified: new Date(),
      initialState: 'DRAFT',
      initialName: '',
    };
  },
  computed: {
    ...mapGetters('oidc', ['oidcUser']),
    currentUser() {
      return {
        id: this.oidcUser.sub,
        firstName: this.oidcUser.given_name,
        lastName: this.oidcUser.family_name,
      };
    },
    isDemo() {
      return this.oidcUser.realm_access.roles.includes('demo-access');
    },
  },
  methods: {
    handleCpdCreation(name) {
      let cpdName = name.trim();
      cpdName = cpdName === '' ? this.$t('untitledForm') : cpdName;
      CpdApiService.createCpd(cpdName).then(
        () => {
          this.$emit('cpd-created');
          this.creationMode = false;
          EventBus.emitToast('toast.cpdCreated');
        },
        () => EventBus.emitToast('toast.cpdCreationFailed', true)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.convo-create-cpd-tile .create-activator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: $tile-height;
  border-radius: 8px;
  margin-bottom: 32px;
  background-color: rgba(139, 149, 165, 1);
  color: #fff;
  box-shadow: 0 4px 10px $box-shadow-color;
  cursor: pointer;

  .convo-icon-plus-circle {
    font-size: 24px;
  }

  .create-text {
    font-size: 14px;
    margin-top: 12px;
    text-align: center;
  }
}

.disable-create {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: $tile-height;
  border-radius: 8px;
  margin-bottom: 32px;
  background-color: rgba(139, 149, 165, 1);
  color: #fff;
  box-shadow: 0 4px 10px $box-shadow-color;
  cursor: not-allowed;
  .convo-icon-plus-circle {
    font-size: 24px;
  }
  .create-text {
    font-size: 14px;
    margin-top: 12px;
    text-align: center;
  }
}

@media (max-width: 1280px) {
  .convo-create-cpd-tile .create-activator {
    height: $tile-height-1280;
    margin-bottom: 8px;

    .convo-icon-plus-circle {
      font-size: 20px;
    }

    .create-text {
      font-size: 12px;
    }
  }

  .disable-create {
    height: $tile-height-1280;
    margin-bottom: 8px;

    .convo-icon-plus-circle {
      font-size: 20px;
    }

    .create-text {
      font-size: 12px;
    }
  }
}
</style>
