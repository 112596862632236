<template>
  <div :class="['convo-toolbox-function', { generic }]">{{ $t(translationKey) }}</div>
</template>

<script>
export default {
  name: 'ConvoToolboxFunction',
  props: {
    generic: {
      type: Boolean,
      required: false,
      default: false,
    },
    operator: {
      type: String,
      required: true,
    },
  },
  computed: {
    translationKey() {
      return `operators.${this.operator.toLowerCase()}.name`;
    },
  },
};
</script>

<style lang="scss" scoped>
.convo-toolbox-function {
  align-items: center;
  background-color: white;
  border: 1px solid $primary-color;
  border-radius: 10px;
  color: $primary-color;
  cursor: move;
  display: flex;
  font-size: 13px;
  height: 36px;
  justify-content: center;
  text-align: center;
  word-break: keep-all;

  &.generic {
    background-color: $convo-blue-2;
  }
}
@media (max-width: 1200px) {
  .convo-toolbox-function {
    font-size: 10px;
  }
}
</style>
