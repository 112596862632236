<template>
  <div class="convo-cpd-context-menu">
    <div :id="`cpd-tile-context-menu-activator-${uniqueCpdId}`" class="activator" @click="handleActivatorToggle">
      <i class="convo-icon-menu"></i>
    </div>
    <div class="context-menu" v-if="contextMenuVisible">
      <div
        v-if="!isLocked && state === states.DRAFT"
        :id="`cpd-tile-context-menu-rename-${uniqueCpdId}`"
        class="option rename"
        :class="isDemo && 'disable-option'"
        @click.stop="handleOptionSelect('rename')"
      >
        <div class="icon"><i class="convo-icon-rename" /></div>
        {{ $t('dashboard.contextMenu.rename') }}
      </div>
      <div
        v-if="!isLocked && state === states.DRAFT"
        :id="`cpd-tile-context-menu-edit-${uniqueCpdId}`"
        class="option edit"
        @click.stop="handleOptionSelect('edit')"
      >
        <div class="icon"><i class="convo-icon-edit" /></div>
        {{ $t('dashboard.contextMenu.edit') }}
      </div>
      <div
        :id="`cpd-tile-context-menu-open-viewmode-${uniqueCpdId}`"
        class="option open-viewmode"
        @click.stop="handleOptionSelect('open-viewmode')"
      >
        <div class="icon"><i class="convo-icon-view" /></div>
        {{ $t('dashboard.contextMenu.openOnlyRead') }}
      </div>
      <div
        v-if="isLocked && state === states.DRAFT"
        :id="`cpd-tile-context-menu-force-edit-${uniqueCpdId}`"
        class="option force-edit"
        :class="isDemo && 'disable-option'"
        @click.stop="handleOptionSelect('force-edit')"
      >
        <div class="icon"><i class="convo-icon-edit" /></div>
        {{ $t('dashboard.contextMenu.forceEdit') }}
      </div>
      <div
        v-if="state === states.ACTIVE && !hasOrIsFollowUpVersion"
        :id="`cpd-tile-context-menu-create-new-version-${uniqueCpdId}`"
        class="option new-version"
        @click.stop="handleOptionSelect('follow-up')"
      >
        <div class="icon"><i class="convo-icon-copy" /></div>
        {{ $t('dashboard.contextMenu.createNewVersion') }}
      </div>
      <div v-if="!isLocked" class="separator" />
      <div
        v-if="!isLocked"
        :id="`cpd-tile-context-menu-delete-${uniqueCpdId}`"
        class="option delete"
        :class="isDemo && 'disable-option'"
        @click.stop="handleOptionSelect('delete')"
      >
        <div class="icon"><i class="convo-icon-delete" /></div>
        {{ $t('dashboard.contextMenu.delete') }}
      </div>
    </div>
  </div>
</template>

<script>
import StateEnum from '@/common/enums/StateEnum';
import { mapGetters } from 'vuex';

export default {
  name: 'ConvoCpdContextMenu',
  props: {
    uniqueCpdId: {
      type: String,
      required: true,
    },
    state: {
      type: String,
      required: true,
    },
    hasOrIsFollowUpVersion: {
      type: Boolean,
      default: false,
    },
    isLocked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      contextMenuVisible: false,
      states: StateEnum,
    };
  },
  computed: {
    ...mapGetters('oidc', ['oidcUser']),
    isDemo() {
      return this.oidcUser.realm_access.roles.includes('demo-access');
    },
  },
  methods: {
    handleOptionSelect(option) {
      this.closeContextMenu();
      if (!this.isDemo || option === 'open-viewmode' || option === 'edit') {
        this.$emit(option);
      }
    },
    handleActivatorToggle() {
      if (this.contextMenuVisible) {
        this.closeContextMenu();
      } else {
        this.openContextMenu();
      }
    },
    openContextMenu() {
      this.contextMenuVisible = true;
      this.$emit('context-menu-open', true);
      setTimeout(() => document.addEventListener('click', this.closeContextMenu), 1);
    },
    closeContextMenu() {
      this.contextMenuVisible = false;
      this.$emit('context-menu-open', false);
      document.removeEventListener('click', this.closeContextMenu);
    },
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeContextMenu);
  },
};
</script>

<style lang="scss" scoped>
.convo-cpd-context-menu {
  position: relative;

  .activator {
    cursor: pointer;
    //font-size: 12px;
    padding: 2px 5px;
  }

  .context-menu {
    background-color: white;
    border-radius: 8px;
    padding: 8px;
    box-shadow: 0 4px 10px $box-shadow-color;
    white-space: nowrap;
    position: absolute;
    left: 16px;
    top: 0;

    .option {
      display: flex;
      cursor: pointer;
      padding: 8px;
      font-weight: 300;
      font-size: 12px;
      border-radius: 8px;

      &:hover {
        background-color: rgba(44, 62, 89, 0.2);
      }

      &:active {
        color: #fff;
        background-color: rgba(44, 62, 89, 1);
      }

      .icon {
        width: 22px;
        margin-right: 5px;

        .convo-icon-rename {
          font-size: 10px;
        }
        .convo-icon-edit {
          font-size: 16px;
          margin-left: 2px;
        }
        .convo-icon-copy {
          font-size: 13px;
        }
        .convo-icon-view {
          font-size: 12px;
          margin-left: -5px;
        }
        .convo-icon-delete {
          font-size: 16px;
          margin-left: 1px;
        }
      }
    }

    .separator {
      border-top: 1px solid rgba(231, 236, 243, 1);
      margin: 6px 0;
    }
    .disable-option {
      color: gray;
      cursor: not-allowed;
      text-decoration-line: line-through;
      &:hover {
        background-color: white;
      }
      &:active {
        color: rgba(44, 62, 89, 1);
        background-color: white;
      }
    }
  }
}

#active .context-menu {
  left: -235px;
  top: 25px;
}

@media (max-width: 1280px) {
  .convo-cpd-context-menu .context-menu {
    padding: 4px;

    .option {
      font-size: 10px;
      padding: 6px;

      .icon {
        width: 14px;

        .convo-icon-rename {
          font-size: 8px;
        }
        .convo-icon-edit {
          font-size: 13px;
          margin-left: 0;
        }
        .convo-icon-copy {
          font-size: 11px;
        }
        .convo-icon-view {
          font-size: 9px;
          margin-left: -5px;
        }
        .convo-icon-delete {
          font-size: 13px;
          margin-left: 1px;
        }
      }
    }
  }
}
</style>
