var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"convo-rich-text-editor"},[(!_vm.editable)?_c('div',{staticClass:"rich-text-preview",domProps:{"innerHTML":_vm._s(_vm.content)}}):(!_vm.menubarFirst)?_c('editor-content',{staticClass:"editor-content",attrs:{"editor":_vm.editor}}):_vm._e(),(_vm.editable)?_c('editor-menu-bar',{attrs:{"editor":_vm.editor},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var commands = ref.commands;
var isActive = ref.isActive;
return [_c('div',{staticClass:"menubar"},[_c('el-button',{staticClass:"menubar-button",class:{ 'is-active': isActive.bold() },on:{"click":commands.bold}},[_c('i',{staticClass:"convo-icon-bold"})]),_c('el-button',{staticClass:"menubar-button",class:{ 'is-active': isActive.italic() },on:{"click":commands.italic}},[_c('i',{staticClass:"convo-icon-italic"})]),_c('el-button',{staticClass:"menubar-button",class:{ 'is-active': isActive.strike() },on:{"click":commands.strike}},[_c('i',{staticClass:"convo-icon-strikethrough"})]),_c('el-button',{staticClass:"menubar-button",class:{ 'is-active': isActive.underline() },on:{"click":commands.underline}},[_c('i',{staticClass:"convo-icon-underlined"})]),_c('el-button',{staticClass:"menubar-button",class:{ 'is-active': isActive.bullet_list() },on:{"click":commands.bullet_list}},[_c('i',{staticClass:"convo-icon-bullet-point"})]),_c('el-button',{staticClass:"menubar-button",class:{ 'is-active': isActive.ordered_list() },on:{"click":commands.ordered_list}},[_c('i',{staticClass:"convo-icon-numbering"})]),_c('el-button',{staticClass:"menubar-button",class:{ 'is-active': isActive.blockquote() },on:{"click":commands.blockquote}},[_c('i',{staticClass:"convo-icon-quote"})]),_c('el-button',{staticClass:"menubar-button",on:{"click":commands.horizontal_rule}},[_c('i',{staticClass:"convo-icon-stroke"})]),(!_vm.hideUndoRedo)?_c('el-button',{staticClass:"menubar-button",on:{"click":commands.undo}},[_c('i',{staticClass:"convo-icon-undo"})]):_vm._e(),(!_vm.hideUndoRedo)?_c('el-button',{staticClass:"menubar-button",on:{"click":commands.redo}},[_c('i',{staticClass:"convo-icon-redo"})]):_vm._e()],1)]}}],null,false,1660021172)}):_vm._e(),(_vm.menubarFirst && _vm.editable)?_c('editor-content',{staticClass:"editor-content",attrs:{"editor":_vm.editor}}):_vm._e(),_c('editor-menu-bubble',{staticClass:"menububble",attrs:{"editor":_vm.editor},on:{"hide":_vm.hideLinkMenu},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var commands = ref.commands;
var isActive = ref.isActive;
var getMarkAttrs = ref.getMarkAttrs;
var menu = ref.menu;
return [_c('div',{staticClass:"menububble",class:{ 'is-active': menu.isActive },style:(("left: " + (menu.left) + "px; bottom: " + (menu.bottom) + "px;"))},[(_vm.linkMenuIsActive)?_c('form',{staticClass:"menububble__form",on:{"submit":function($event){$event.preventDefault();return _vm.setLinkUrl(commands.link)}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.linkUrl),expression:"linkUrl"}],ref:"linkInput",staticClass:"menububble__input",attrs:{"type":"text","placeholder":"https://"},domProps:{"value":(_vm.linkUrl)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.hideLinkMenu($event)},"input":function($event){if($event.target.composing){ return; }_vm.linkUrl=$event.target.value}}}),_c('button',{staticClass:"menububble__button",attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteLinkUrl(commands.link)}}},[_c('i',{staticClass:"convo-icon-delete"})])]):[_c('button',{staticClass:"menububble__button",class:{ 'is-active': isActive.link() },on:{"click":function($event){_vm.showLinkMenu(getMarkAttrs('link'))}}},[_c('span',[_vm._v(_vm._s(isActive.link() ? _vm.$t('richTextEditor.updateLink') : _vm.$t('richTextEditor.addLink')))]),_c('i',{staticClass:"convo-icon-chain"})])]],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }