<template>
  <div class="convo-toolbox-element-value">
    <span class="name">{{ name }}</span>
  </div>
</template>

<script>
export default {
  name: 'ConvoToolboxElementValue',
  props: {
    mvId: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.convo-toolbox-element-value {
  align-items: center;
  background-color: white;
  border: 1px solid $convo-blue-2;
  border-radius: 8px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  color: $primary-color;
  cursor: move;
  display: flex;
  font-size: 12px;
  height: 32px;
  padding: 8px;
  .name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
