<template>
  <el-dialog :title="$t('licenses.licenses')" :visible.sync="dialogVisible" @close="$emit('close')" custom-class="convo-license-dialog">
    <div>
      <div class="license-dialog-header">{{ $t('licenses.licenseDialogContentHeader') }}</div>
      <div class="content">
        <div v-for="(license, licenseIndex) in licenses" :key="licenseIndex">
          <div class="license-dialog-license-name">{{ license.licenseName }} :</div>
          <div class="license-dialog-dependencies" v-for="(dependency, dependencyIndex) in license.dependencies" :key="dependencyIndex">
            <a :href="dependency.dependencyURL" target="_blank"> {{ dependency.dependencyName }} </a>
            <span v-if="dependency.licenseURL">
              - <a :href="dependency.licenseURL" target="_blank"> {{ $t('licenses.license') }} </a>
            </span>
            <span v-if="dependency.licenseText">
              -
              <el-popover placement="right" popper-class="license-popover" width="400" trigger="click">
                <span class="license-activator" slot="reference">{{ $t('licenses.license') }}</span>
                <div class="license-popover-content">{{ dependency.licenseText }}</div>
              </el-popover>
            </span>
          </div>
        </div>
      </div>
      <span class="footer">
        <el-button id="close-button" @click="dialogVisible = false">{{ $t('close') }}</el-button>
      </span>
    </div>
  </el-dialog>
</template>

<script>
import axios from 'axios';
import frontendLicenses from '@/common/assets/licenses.json';

export default {
  name: 'ConvoLicenseDialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogVisible: this.visible,
      licenses: [],
    };
  },
  watch: {
    visible(newValue) {
      this.dialogVisible = newValue;
    },
  },
  mounted() {
    const result = {};
    Object.entries(frontendLicenses)
      .filter((entry) => entry[1].licenses)
      .filter(([name]) => !name.match(/^@ppi\/convo-engine-web-classic@/) && !name.match(/^studio@/))
      .forEach(([name, dependency]) => {
        if (!result[dependency.licenses]) {
          result[dependency.licenses] = {
            licenseName: dependency.licenses,
            dependencies: [],
          };
        }
        result[dependency.licenses].dependencies.push({
          dependencyName: name,
          dependencyURL: dependency.url || dependency.repository || undefined,
          licenseText: dependency.licenseText,
        });
      });
    this.licenses = result;

    const sourceBackend = (process.env.VUE_APP_BACKEND || window.backend) + '/v1/licenses';
    axios.get(sourceBackend).then((data) => {
      const backendDependencies = data.data.dependencies;
      backendDependencies
        .filter((dependency) => dependency.moduleLicense)
        .forEach((dependency) => {
          if (!this.licenses[dependency.moduleLicense]) {
            this.licenses[dependency.moduleLicense] = {
              licenseName: dependency.moduleLicense,
              dependencies: [],
            };
          }
          this.licenses[dependency.moduleLicense].dependencies.push({
            dependencyName: dependency.moduleName + '@' + dependency.moduleVersion,
            dependencyURL: dependency.moduleUrl,
            licenseURL: dependency.moduleLicenseUrl,
          });
        });
    });
  },
};
</script>

<style lang="scss">
// overwriting of these css classes doesn't work in scoped css
.license-popover {
  font-size: 14px;
  padding: 26px;
  word-break: break-word;

  .license-popover-content {
    overflow: auto;
    padding-right: 14px;
    height: 400px;
    white-space: pre-line;
  }
}
</style>

<style scoped lang="scss">
::v-deep {
  .el-dialog {
    width: 850px;
    top: 2vh;
    border-radius: 6px;
    padding: 24px 24px 16px 24px;
    font-weight: 600;
    color: $primary-color;
  }

  .el-dialog__header {
    padding: 0 0 24px 0;
  }
  .el-dialog__title {
    font-size: 24px;
    color: $primary-color;
  }

  .el-dialog__close {
    color: $primary-color;
    font-size: 24px;
    font-weight: 600;
  }

  .el-dialog__body {
    padding: 0;
  }
}

.convo-license-dialog {
  font-weight: 600;
  padding: 6px;
}
.license-dialog-header {
  font-style: italic;
  font-size: 16px;
  word-break: break-word;
  margin: 0 0 16px 0;
  color: $primary-lightened-color;
}
.license-dialog-license-name {
  font-size: 16px;
  margin-bottom: 8px;
  margin-top: 24px;
  color: $primary-lightened-color;
}
.license-dialog-dependencies {
  font-weight: 600;
  margin-bottom: 10px;
  color: $primary-lightened-color;
  .license-activator {
    cursor: pointer;
  }
}

.content {
  font-weight: 600;
  font-size: 14px;
  padding-right: 0;
  word-break: break-word;
  overflow: auto;
  height: 400px;
}

.footer {
  display: flex;
  justify-content: flex-end;
  margin: 12px 0 0 0;

  #close-button {
    height: 40px;
    border: 2px solid $primary-color;
    border-radius: 8px;
    padding: 8px 16px;
    font-size: 16px;
    font-weight: 600;
    background-color: $primary-color;
    color: $primary-text-color;
  }
}

@media (max-width: 1280px) {
  ::v-deep {
    .el-dialog {
      width: 600px;
      margin-top: 1vh;
      margin-bottom: 10vh;
    }

    .el-dialog__header {
      padding: 0 0 20px 0;
    }

    .el-dialog__title {
      font-size: 20px;
    }
  }

  .license-dialog-header {
    font-size: 14px;
  }
  .license-dialog-license-Name {
    font-size: 14px;
    margin-top: 16px;
  }

  .content {
    font-size: 12px;
    height: 250px;
  }

  .footer {
    margin: 8px 0 0 0;

    #close-button {
      height: 36px;
      padding: 6px 12px;
      font-size: 14px;
    }
  }
}
</style>
