<template>
  <div class="convo-element-value">
    <div class="element-value-header">
      <div class="parent-name">{{ $t(`${parentType}.elementName`) }} {{ parentName }}</div>
      <i v-if="!disabled" class="convo-icon-delete" @click="$emit('delete')" />
    </div>
    <div class="element-value-body">
      <div class="element-value-name">{{ name }}</div>
    </div>
  </div>
</template>

<script>
import CpdModificationService from '@/common/services/CpdModificationService';
import ConvoExpressionLanguageService from '@/common/services/ConvoExpressionLanguageService';
import MagicVariablesService from '@/common/services/MagicVariablesService';

export default {
  name: 'ConvoElementValue',
  props: {
    id: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    mvId() {
      return ConvoExpressionLanguageService.getExpressionField(this.id, 'parameter')[0];
    },
    parentId() {
      return this.mvId.split('.')[0];
    },
    parentName() {
      return CpdModificationService.getElementField(this.parentId, 'name');
    },
    parentType() {
      return CpdModificationService.getElementField(this.parentId, 'type');
    },
    name() {
      return MagicVariablesService.getNameOfMv(this.mvId);
    },
  },
  mounted() {
    if (!MagicVariablesService.isMvValid(this.mvId)) {
      this.$emit('delete');
    }
  },
};
</script>

<style lang="scss" scoped>
.convo-element-value {
  background-color: #fff;
  height: 60px;
  overflow: hidden;

  .element-value-header {
    align-items: center;
    background-color: $primary-color;
    border-color: $primary-color;
    border-radius: 5px 5px 0 0;
    border-style: solid;
    border-width: 1px 1px 0 1px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    color: #fff;
    display: flex;
    font-size: 12px;
    height: 50%;
    justify-content: space-between;
    padding: 0 6px 0 8px;

    .parent-name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .convo-icon-delete {
      cursor: pointer;
      display: none;
      font-size: 16px;
      margin-top: -2px;

      &:hover {
        color: $secondary-color;
      }
    }
  }

  .element-value-body {
    align-items: center;
    border-color: $primary-color;
    border-radius: 0 0 5px 5px;
    border-style: solid;
    border-width: 0 1px 1px 1px;
    color: $primary-color;
    display: flex;
    font-size: 14px;
    height: 50%;
    padding: 0 8px;

    .element-value-name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &:hover {
    .convo-icon-delete {
      display: block;
    }
  }
}
</style>
