<template>
  <div class="convo-color-picker" :style="selectedColorStyle">
    <div class="selector" @click="openColorPicker"></div>
    <el-color-picker
      class="color-input"
      ref="color-input"
      :predefine="predefinedColors"
      :value="value"
      @active-change="emitValueChange"
    ></el-color-picker>
  </div>
</template>

<script>
import colorDefinition from '@/common/assets/colors.json';

export default {
  name: 'ConvoColorPicker',
  props: {
    value: {
      type: String,
      required: true,
    },
    colorKey: {
      type: String,
      required: true,
    },
  },
  computed: {
    selectedColorStyle() {
      return `--selected-color: ${this.value}`;
    },
    predefinedColors() {
      return colorDefinition.default.filter((color) => color.cssClass === this.colorKey).map((color) => color.value);
    },
  },
  methods: {
    openColorPicker() {
      this.$refs['color-input'].handleTrigger();
    },
    emitValueChange(event) {
      this.$emit('input', this.rgbToHex(event));
    },
    rgbToHex(rgb) {
      return rgb
        .substring(rgb.indexOf('(') + 1, rgb.indexOf(')'))
        .split(',')
        .reduce((prev, curr) => prev + parseInt(curr, 10).toString(16).padStart(2, '0'), '#');
    },
  },
};
</script>

<style scoped lang="scss">
.convo-color-picker {
  position: relative;
  .selector {
    background-color: var(--selected-color);
    border-radius: 999px;
    cursor: pointer;
    height: 28px;
    width: 28px;
    box-shadow: 1px 1px 2px $primary-color;
  }
  .color-input {
    top: -2px;
    left: 170px;
    position: absolute;
    visibility: hidden;
  }
}

@media (max-width: 1280px) {
  .convo-color-picker .selector {
    height: 22px;
    width: 22px;
  }
}
</style>
