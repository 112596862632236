<template>
  <div class="convo-recipient-mv-placeholder">
    <el-popover ref="popover-cel" trigger="hover" width="480" placement="bottom-start" :open-delay="800">
      <convo-expression disabled :expression-id="expression" :heading="expressionHeading" :required-input-type="CelReturnType.STRING" />
    </el-popover>
    <span v-popover:popover-cel>
      <i v-for="i in 4" :key="i" class="convo-icon-placeholder" />
    </span>
  </div>
</template>

<script>
import ConvoExpressionLanguageService from '@/common/services/ConvoExpressionLanguageService';
import CelReturnType from '@/common/enums/CelReturnType';
import ConvoExpression from '../convo_expression_language_dialog/expression/ConvoExpression.vue';

export default {
  name: 'ConvoRecipientMvPlaceholder',
  components: {
    ConvoExpression,
  },
  props: {
    parentId: {
      type: String,
      required: true,
    },
    fieldKey: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    expressionHeading: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      CelReturnType,
      expression:
        ConvoExpressionLanguageService.getExpressionIdForField(this.parentId, this.fieldKey) ||
        ConvoExpressionLanguageService.parseString(this.value),
    };
  },
  methods: {
    getRecipientKey(index) {
      return this.recipientLabel + index;
    },
  },
};
</script>

<style scoped lang="scss">
.convo-recipient-mv-placeholder {
  cursor: help;
  .convo-icon-placeholder {
    margin-left: -2px;
  }
}
</style>
