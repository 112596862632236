<template>
  <div class="convo-toolbox" :class="{ 'show-services': showServices }">
    <div class="element-group" v-for="(group, groupIndex) in structure" :key="groupIndex">
      <div class="element-group-header" @click="toggleActiveElementGroup(group)">
        {{ $t('toolbox.' + group.key) }}
        <i :class="[{ 'convo-icon-minus': isGroupActive(group.key), 'convo-icon-plus': !isGroupActive(group.key) }]" />
      </div>
      <div class="element-group-body" v-if="isGroupActive(group.key)">
        <draggable
          v-for="(dragzone, dragzoneIndex) in group.dragzones"
          :key="`dragzone${groupIndex}.${dragzoneIndex}`"
          :list="dragzone.elements"
          :group="{
            name: dragzone.canvasSlot,
            pull: 'clone',
            put: false,
          }"
          :clone="cloneComponent"
          draggable=".is-draggable"
          :sort="false"
          :force-fallback="true"
          :scroll-sensitivity="100"
          :scroll-speed="15"
          drag-class="moving"
        >
          <convo-toolbox-brick v-for="type in dragzone.elements" :key="type" :type="type" :is-draggable="isDraggable" />
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import ConvoProcessComponentService from '@/common/services/ConvoProcessComponentService';
import CpdModificationService from '@/common/services/CpdModificationService';
import ElementType from '@/common/enums/ElementType';
import ConvoToolboxBrick from './ConvoToolboxBrick.vue';
import toolbox from '../../definition/studio_layout/toolbox.json';

export default {
  name: 'ConvoToolbox',
  components: {
    draggable,
    ConvoToolboxBrick,
  },
  props: {
    isDraggable: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      activeElementGroup: 'input',
      structure: null,
      roles: this.$store.getters['oidc/oidcUser'].realm_access.roles,
    };
  },
  computed: {
    showPdfGeneration() {
      return this.roles.includes('pdf-access');
    },
    showMailGeneration() {
      return this.roles.includes('mail-access');
    },
    showServices() {
      return this.showPdfGeneration || this.showMailGeneration;
    },
  },
  methods: {
    cloneComponent(type) {
      if (type === ElementType.SUBSECTION || type === ElementType.SECTION) {
        return this.insertStructuralElements(type);
      }
      const componentCreationHandler = ConvoProcessComponentService.getElementCreationHandler(type);
      return CpdModificationService.createElement(componentCreationHandler);
    },
    insertStructuralElements(type) {
      const groupCreationHandler = ConvoProcessComponentService.getElementCreationHandler(ElementType.GROUP);
      const groupId = CpdModificationService.createElement(groupCreationHandler);

      const subsectionCreationHandler = (id) => {
        const definition = ConvoProcessComponentService.getElementCreationHandler(ElementType.SUBSECTION)(id);
        definition.children = [groupId];
        return definition;
      };
      const subsectionId = CpdModificationService.createElement(subsectionCreationHandler);

      if (type === ElementType.SUBSECTION) {
        return subsectionId;
      }

      const sectionCreationHandler = (id) => {
        const definition = ConvoProcessComponentService.getElementCreationHandler(ElementType.SECTION)(id);
        definition.children = [subsectionId];
        return definition;
      };
      return CpdModificationService.createElement(sectionCreationHandler);
    },
    isGroupActive(group) {
      return this.activeElementGroup === group;
    },
    toggleActiveElementGroup(group) {
      if (this.activeElementGroup === group.key) this.activeElementGroup = '';
      else this.activeElementGroup = group.key;
    },
  },
  created() {
    const structure = [];
    toolbox
      .filter((toolboxGroup) => this.showServices || toolboxGroup.groupLabelKey !== 'services')
      .forEach((toolboxGroup) => {
        const dragzones = [];
        let currentCanvasSlot = ConvoProcessComponentService.getMetadata(toolboxGroup.elements[0]).canvasSlot;
        let currentElements = [];
        toolboxGroup.elements
          .filter(
            (element) =>
              toolboxGroup.groupLabelKey !== 'services' ||
              (element === 'pdf_generation' && this.showPdfGeneration) ||
              (element === 'mail_generation' && this.showMailGeneration)
          )
          .forEach((element) => {
            const elementCanvasSlot = ConvoProcessComponentService.getMetadata(element).canvasSlot;
            if (currentCanvasSlot === elementCanvasSlot) {
              currentElements.push(element);
            } else {
              dragzones.push({ canvasSlot: currentCanvasSlot, elements: currentElements });
              currentCanvasSlot = elementCanvasSlot;
              currentElements = [element];
            }
          });
        dragzones.push({ canvasSlot: currentCanvasSlot, elements: [...currentElements] });
        structure.push({ dragzones, key: toolboxGroup.groupLabelKey });
      });
    this.structure = structure;
  },
};
</script>

<style lang="scss" scoped>
$element-group-header-height: 56px;
$element-group-header-height-1280: 40px;
$element-group-margin-bottom: 8px;
$element-group-margin-bottom-1280: 6px;

.convo-toolbox {
  height: 100%;

  .element-group {
    &:not(:last-child) {
      margin-bottom: $element-group-margin-bottom;
    }

    .element-group-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      height: $element-group-header-height;
      padding: 16px;
      color: $primary-color;
      font-size: 20px;
      font-weight: 600;
      background-color: $convo-blue-4;

      .convo-icon-plus,
      .convo-icon-minus {
        font-size: 14px;
        font-weight: bold;
      }
    }

    .element-group-body {
      padding: 16px;
      max-height: calc(100vh - #{$header-height} - 3 * #{$element-group-header-height} - 2 * #{$element-group-margin-bottom});
      overflow-y: auto;

      :not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }

  &.show-services .element-group .element-group-body {
    max-height: calc(100vh - #{$header-height} - 4 * #{$element-group-header-height} - 3 * #{$element-group-margin-bottom});
  }

  scrollbar-color: $convo-blue-4 #fff !important;
  ::-webkit-scrollbar-track {
    background: #fff;
  }
  ::-webkit-scrollbar-thumb {
    background: $convo-blue-4;
  }
}

@media (max-width: 1600px) {
  .convo-toolbox .element-group .element-group-header {
    font-size: 18px;
  }
}
@media (max-width: 1400px) {
  .convo-toolbox .element-group .element-group-header {
    font-size: 16px;
  }
}

@media (max-width: 1280px) {
  .convo-toolbox {
    .element-group {
      &:not(:last-child) {
        margin-bottom: $element-group-margin-bottom-1280;
      }

      .element-group-header {
        height: $element-group-header-height-1280;
        font-size: 14px;
        padding: 10px;

        .convo-icon-plus,
        .convo-icon-minus {
          font-size: 12px;
        }
      }
      .element-group-body {
        padding: 6px;
        max-height: calc(
          100vh - #{$header-height-1280} - 3 * #{$element-group-header-height-1280} - 2 * #{$element-group-margin-bottom-1280}
        );

        :not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }

    &.show-services .element-group .element-group-body {
      max-height: calc(100vh - #{$header-height} - 4 * #{$element-group-header-height} - 3 * #{$element-group-margin-bottom});
    }
  }
}
</style>
