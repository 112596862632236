import store from '@/common/store';
import { uuid } from 'vue-uuid';

/**
 * This service encapsulates all functionality regarding the handling of messages.
 */
const MessageService = (() => {
  return {
    /**
     * Adds a message. If a message with the same id already exists, it will be overwritten.
     *
     * @param {String} type INFO, WARNING or ERROR
     * @param {Function} contentHandler handler to get the message text
     * @param {Function} actionHandler an optional action for the message (e.g. a router call)
     * @param {String} givenId optional message id, if undefined a new one is generated
     */
    addMessage(type, contentHandler, actionHandler, givenId) {
      const id = givenId || uuid.v4();
      store.commit('deleteMessage', id);
      store.commit('addMessage', { id, type, contentHandler, actionHandler });
    },
    /**
     * Deletes the message with the given id.
     *
     * @param {String} id the id representing the message to be deleted
     */
    deleteMessage(id) {
      store.commit('deleteMessage', id);
    },
    /**
     * @returns the list of messages optionally filtered by the given type
     * @param {String} type optional INFO, WARNING or ERROR
     */
    getMessages(type) {
      return store.getters.messages(type);
    },
  };
})();

export default MessageService;
