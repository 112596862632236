import ValidationStrategy from '@/studio/enums/ValidationStrategy';
import CpdModificationService from '@/common/services/CpdModificationService';

/**
 * Mixin to ensure studio validation.
 */
export default {
  props: {
    mandatory: Object,
    min: Object,
    max: Object,
    regex: Object,
  },
  data() {
    return {
      trigger: 'change',
    };
  },
  methods: {
    validate(validationObject, validationHandler, errorMessageHandler, callback) {
      if (validationObject) {
        const evaluated = this.evaluate(validationObject);
        const invalid = Array.isArray(evaluated)
          ? evaluated.reduce((prev, curr) => prev || validationHandler(curr), false)
          : validationHandler(evaluated);
        const causingValue = Array.isArray(evaluated) ? evaluated.filter(validationHandler)[0] : evaluated;

        if (invalid) {
          callback(new Error(errorMessageHandler(causingValue)));
        }
      }
    },
    evaluate(validationObject) {
      switch (validationObject.validation_strategy) {
        case ValidationStrategy.DYNAMIC:
          return CpdModificationService.getElementField(this.parentId, validationObject.value);
        case ValidationStrategy.DYNAMIC_LIST:
          return validationObject.value.map((field) => CpdModificationService.getElementField(this.parentId, field));
        default:
          return validationObject.value;
      }
    },
  },
};
