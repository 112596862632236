<template>
  <div class="convo-device-frame">
    <img
      :class="['device-frame', deviceFrame]"
      :src="require(`../../common/assets/${deviceFrame}.svg`)"
      class="test"
      :id="`device-frame-${deviceFrame}`"
      alt="device frame"
    />
    <div :class="[`${deviceFrame}-time`, 'time']">{{ $d(now, 'time') }}</div>
    <div :class="[`${deviceFrame}-date`, 'date']">{{ $d(now, 'medium') }}</div>
  </div>
</template>

<script>
export default {
  name: 'ConvoDeviceFrame',
  props: {
    deviceFrame: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      now: new Date(),
      interval: null,
    };
  },
  created() {
    this.interval = setInterval(() => {
      this.now = new Date();
    }, 1000 * 60);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.device-frame {
  position: absolute;
  pointer-events: none;
}
.time,
.date {
  position: absolute;
  z-index: 2;
  font-size: 14px;
}

.iphone12-frame {
  width: 478px;
  top: -90px;
  left: -28px;
}
.iphone12-frame-time {
  top: -46px;
  left: 28px;
}
.iphone12-frame-date {
  display: none;
}

.galaxy-s21-frame {
  width: 470px;
  top: -116px;
  left: -30px;
}
.galaxy-s21-frame-time {
  top: -78px;
  left: 28px;
}
.galaxy-s21-frame-date {
  display: none;
}

.ipad-frame {
  width: 1518px;
  top: -134px;
  left: -70px;
}
.ipad-frame-time {
  top: -70px;
  left: 28px;
}
.ipad-frame-date {
  top: -70px;
  left: 80px;
}

.galaxy-tab-frame {
  width: 1588px;
  top: -222px;
  left: -154px;
}
.galaxy-tab-frame-time {
  top: -102px;
  left: 32px;
  color: white;
}
.galaxy-tab-frame-date {
  display: none;
}

.imac-frame {
  width: 2074px;
  top: -120px;
  left: -74px;
}
.imac-frame-time {
  top: -44px;
  right: 4px;
  color: white;
  font-size: 12px;
}
.imac-frame-date {
  top: -44px;
  right: 38px;
  color: white;
  font-size: 12px;
}

.macbook-frame {
  width: 1944px;
  top: -80px;
  left: -238px;
}
.macbook-frame-time {
  top: -10px;
  right: 6px;
  color: white;
  font-size: 12px;
}
.macbook-frame-date {
  top: -10px;
  right: 42px;
  color: white;
  font-size: 12px;
}
</style>
