<template>
  <div class="icon-list">
    <button id="back-to-studio-button" @click="navigateToStudio(cpdId, version)">
      <i class="convo-icon-backward" />
    </button>
  </div>
</template>

<script>
import RouterMixin from '@/common/mixins/RouterMixin';

export default {
  name: 'ConvoHeaderSlotEditorialView',
  mixins: [RouterMixin],
  data() {
    return {
      cpdId: this.$route.params.cpd,
      version: this.$route.params.version,
    };
  },
};
</script>

<style lang="scss" scoped>
.icon-list {
  position: relative;

  button {
    background-color: transparent;
    color: #fff;
    font-size: 18px;
    border: none;
    cursor: pointer;
    outline: none;
  }
}

@media (max-width: 1280px) {
  .icon-list button {
    font-size: 14px;
  }
}
</style>
