<template>
  <div class="convo-element" :class="{ 'no-border-radius': noBorderRadius, 'no-box-shadow': noBoxShadow, folded }">
    <div class="element-header" @click="toggleFolded">
      <span class="element-name">{{ name }}</span>
      <i :class="{ 'convo-icon-minus': !folded, 'convo-icon-plus': folded }" />
    </div>
    <div class="element-body" v-show="!folded">
      <convo-expression-language-draggable :elements="elements" :group="requiredInputType" element-value toolbox />
    </div>
  </div>
</template>

<script>
import CpdModificationService from '@/common/services/CpdModificationService';
import ConvoExpressionLanguageDraggable from '@/studio/components/right_sidepanel/inspector_panel/convo_expression_language_dialog/ConvoExpressionLanguageDraggable.vue';
import MagicVariablesService from '@/common/services/MagicVariablesService';
import CelReturnType from '@/common/enums/CelReturnType';
import CelExpressionType from '@/common/enums/CelExpressionType';

export default {
  name: 'ConvoElement',
  components: { ConvoExpressionLanguageDraggable },
  props: {
    elementId: {
      type: String,
      required: true,
    },
    alternativeName: {
      type: String,
      required: false,
    },
    noBorderRadius: {
      type: Boolean,
      required: false,
      default: false,
    },
    noBoxShadow: {
      type: Boolean,
      required: false,
      default: false,
    },
    requiredInputType: {
      type: String,
      required: true,
    },
    isWithinDynamicGroup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      folded: true,
    };
  },
  computed: {
    name() {
      return this.alternativeName ? this.alternativeName : CpdModificationService.getElementField(this.elementId, 'name');
    },
    elements() {
      const mapExportedVarsToTreeStructureRecursive = (element) => {
        // Case 1: element is a list (top level or from a group children field )
        if (Array.isArray(element)) {
          return element.map(mapExportedVarsToTreeStructureRecursive);
        }
        // Case 2: element is an object (representing a group or a mv)
        switch (element.structuralRole) {
          case 'group':
            return mapExportedVarsToTreeStructureRecursive(element.children);
          case 'mv':
            return {
              isLeaf: true,
              operator: 'VAR',
              name: element.label,
              returnType: this.isWithinDynamicGroup ? CelReturnType.LIST : element.type,
              type: CelExpressionType.MV,
              id: element.id,
            };
          default:
            throw new Error(`StructuralRole "${element.structuralRole}" unknown.`);
        }
      };
      const elements = mapExportedVarsToTreeStructureRecursive(MagicVariablesService.getExportedVars(this.elementId))
        .flat()
        .filter((element) => element.returnType === this.requiredInputType || this.requiredInputType === CelReturnType.GENERIC);

      if (elements.length === 0) {
        this.$emit('empty');
      }

      return elements;
    },
  },
  methods: {
    toggleFolded() {
      this.folded = !this.folded;
    },
  },
};
</script>

<style lang="scss" scoped>
.convo-element {
  border-radius: 10px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;

  .element-header {
    background-color: $primary-color;
    border-color: $primary-color;
    border-radius: 10px 10px 0 0;
    border-style: solid;
    border-width: 2px 2px 0 2px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    color: $primary-text-color;
    cursor: pointer;
    display: flex;
    font-size: 12px;
    justify-content: space-between;
    padding: 8px 12px;

    .element-name {
      margin-right: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .element-body {
    background-color: white;
    border-color: $primary-color;
    border-radius: 0 0 10px 10px;
    border-style: solid;
    border-width: 0 2px 2px 2px;
    padding: 24px 24px 10px 24px;

    ::v-deep.convo-toolbox-element-value {
      margin-bottom: 14px;
    }
  }

  &.no-box-shadow {
    box-shadow: none;
  }
  &.folded {
    .element-header {
      border-bottom-width: 2px;
      border-radius: 10px;
    }
  }
  &.no-border-radius {
    border-radius: 0;
    .element-header,
    .element-body {
      border-radius: 0;
    }
  }
}

@media (max-width: 1280px) {
  .convo-element {
    .element-header {
      font-size: 10px;
      padding: 6px;
    }

    .element-body {
      padding: 8px;
    }
  }
}
</style>
