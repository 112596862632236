<template>
  <convo-spinner :name="spinnerEnum.STUDIO">
    <div class="convo-studio" :class="{ loaded: !loading }" v-loading="loading">
      <template v-if="!loading">
        <convo-left-sidepanel class="sidepanel" :is-draggable="isCpdEditable" />
        <div class="canvas">
          <convo-canvas :unique-cpd-id="uniqueCpdId" :editable="isCpdEditable" />
        </div>
        <convo-right-sidepanel class="sidepanel" :editable="isCpdEditable" />
      </template>
    </div>
  </convo-spinner>
</template>

<script>
import CpdApiService from '@/common/services/CpdApiService';
import CpdModificationService from '@/common/services/CpdModificationService';
import ConvoCanvas from '@/studio/components/canvas/ConvoCanvas.vue';
import ConvoRightSidepanel from '@/studio/components/right_sidepanel/ConvoRightSidepanel.vue';
import ConvoLeftSidepanel from '@/studio/components/left_sidepanel/ConvoLeftSidepanel.vue';
import InvalidReferencesWorkerMixin from '@/app/mixins/InvalidReferencesWorkerMixin';
import ConvoEditorialView from '@/editorial_view/components/ConvoEditorialView.vue';
import EventBus from '@/common/event-bus';
import ConvoSpinner from '@/common/components/ConvoSpinner.vue';
import SpinnerEnum from '@/common/enums/SpinnerEnum';
import { mapGetters } from 'vuex';

export default {
  name: 'ConvoStudio',
  mixins: [InvalidReferencesWorkerMixin],
  components: {
    ConvoSpinner,
    ConvoCanvas,
    ConvoLeftSidepanel,
    ConvoRightSidepanel,
  },
  props: {
    cpdId: String,
    version: Number,
  },
  data() {
    return {
      loading: true,
      prevComponent: null,
      uniqueCpdId: this.cpdId + 'v' + this.version,
      spinnerEnum: SpinnerEnum,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // eslint-disable-next-line no-param-reassign
      vm.prevComponent = from.name;
    });
  },
  computed: {
    ...mapGetters('oidc', ['oidcUser']),
    isCpdEditable() {
      return this.$store.getters.cpdEditable;
    },
  },
  methods: {
    getCpd() {
      if (!CpdModificationService.getElement(this.uniqueCpdId)) {
        this.navigateToDashboard();
        EventBus.emitToast('toast.loadCpdFailed', true);
      }
      this.loading = false;
    },
    async loadCpd() {
      CpdApiService.loadCpd(
        this.cpdId,
        this.version,
        this.spinnerEnum.STUDIO,
        this.isCpdEditable && this.oidcUser.realm_access.roles.includes('demo-access')
      ).then(
        (cpd) => {
          this.$store.commit('updateCpdWithoutChanges', cpd);
          EventBus.emitCpdLoaded(cpd);
          this.loading = false;
        },
        () => {
          this.navigateToDashboard();
          EventBus.emitToast('toast.loadCpdFailed', true);
          this.loading = false;
        }
      );
    },
  },
  mounted() {
    EventBus.emitClearToasts();
    if (this.prevComponent === ConvoEditorialView.name) {
      this.getCpd();
    } else {
      this.loadCpd();
    }
    EventBus.onUnlockCpd(() => {
      CpdApiService.deleteCpdLock(this.cpdId, this.version);
    });
  },
};
</script>
<style lang="scss" scoped>
.convo-studio {
  display: flex;
  width: 100vw;
  height: calc(100vh - #{$header-height});

  .canvas {
    width: calc(100% - 2 * #{$sidepanel-width-large});
    padding: 0 12px;
    overflow: auto;
  }

  .sidepanel {
    width: $sidepanel-width-large;
  }
}

@media (max-width: 1600px) {
  .convo-studio {
    .sidepanel {
      width: $sidepanel-width-medium;
    }

    .canvas {
      width: calc(100% - 2 * #{$sidepanel-width-medium});
    }
  }
}

@media (max-width: 1400px) {
  .convo-studio {
    .sidepanel {
      width: $sidepanel-width-small;
    }

    .canvas {
      width: calc(100% - 2 * #{$sidepanel-width-small});
    }
  }
}

@media (max-width: 1280px) {
  .convo-studio {
    height: calc(100vh - #{$header-height-1280});
  }
}
</style>
