import Vue from 'vue';

const getDefaultState = () => ({
  mailConfigs: {},
});

export default function mailConfigs() {
  const initialState = getDefaultState();

  const getters = {
    mailConfig: (state) => {
      return (id) => state.mailConfigs[id];
    },
    mailConfigs: (state) => {
      return Object.values(state.mailConfigs);
    },
  };

  const mutations = {
    updateMailConfig(state, mailConfig) {
      Vue.set(state.mailConfigs, mailConfig.id, mailConfig);
    },
    updateMailConfigField(state, { id, field, value }) {
      Vue.set(state.mailConfigs[id], field, value);
    },
    setMailConfigs(state, mailConfigList) {
      mailConfigList.forEach((mailConfig) => Vue.set(state.mailConfigs, mailConfig.id, mailConfig));
    },
    deleteMailConfig(state, id) {
      Vue.delete(state.mailConfigs, id);
    },
    clear(state) {
      // Make it reactive
      Object.keys(state).forEach((id) => Vue.delete(state, id));
      Object.entries(getDefaultState()).forEach(([key, value]) => {
        Vue.set(state, key, value);
      });
    },
  };

  return {
    state: initialState,
    getters,
    mutations,
  };
}
