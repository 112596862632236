import DisabledStrategy from '@/studio/enums/DisabledStrategy';
import CpdModificationService from '@/common/services/CpdModificationService';

/**
 * Mixin to disable fields.
 */
export default {
  props: {
    disabled: Object,
  },
  computed: {
    isDisabled() {
      if (this.disabled) {
        if (this.disabled.disabled_strategy === DisabledStrategy.EQUAL) {
          const value = CpdModificationService.getElementField(this.parentId, this.disabled.field_name);
          return value === this.disabled.value;
        }
      }
      return false;
    },
    disabledTooltipText() {
      return this.disabled ? this.translateWithPrefix('disabled') : undefined;
    },
    disabledTooltipTrigger() {
      return this.isDisabled ? 'hover' : 'manual';
    },
  },
  watch: {
    isDisabled(newValue) {
      if (newValue) {
        this.value = this.disabled.default;
      }
    },
  },
  mounted() {
    if (this.isDisabled) {
      this.value = this.disabled.default;
    }
  },
};
