<template>
  <div :class="{ loading: isLoading }">
    <div v-if="isLoading" class="spinner-box">
      <div class="spinner-background"></div>
      <div class="spinner-container">
        <div class="rectangle-position">
          <div class="rectangle-view">
            <span class="one h6"></span>
            <span class="two h3"></span>
          </div>
        </div>

        <div class="rectangle-position">
          <div class="rectangle-view">
            <span class="one h1"></span>
            <span class="two h4"></span>
          </div>
        </div>

        <div class="rectangle-position">
          <div class="rectangle-view">
            <span class="one h5"></span>
            <span class="two h2"></span>
          </div>
        </div>
        <table class="spinner-background-image"></table>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import EventBus from '@/common/event-bus';

export default {
  name: 'ConvoSpinner',
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialogVisible: this.visible,
      needCheckboxCheck: this.checkbox,
      isLoading: false,
    };
  },
  mounted() {
    EventBus.onSpinnerStop((spinnerName) => {
      if (this.name === spinnerName) this.isLoading = false;
    });

    EventBus.onSpinnerStart((spinnerName) => {
      if (this.name === spinnerName) this.isLoading = true;
    });
  },
};
</script>

<style lang="scss" scoped>
.loading {
  position: relative;
  max-height: 100vh;
  overflow: hidden;
  .spinner-box {
    position: absolute;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .spinner-background {
    background: #2c3e59;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.3;
    z-index: 9950;
  }

  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9951;
    background: #2c3e59;
    height: 200px;
  }

  .spinner-background-image {
    background-image: url('/spinner_background.png');
    height: 102px;
    width: 90px;
    z-index: -1;
    position: absolute;
  }

  .rectangle-position {
    position: absolute;
    width: 90px;
    height: 51px;
  }

  .rectangle-position:nth-of-type(2) {
    transform: rotate(60deg);
  }
  .rectangle-position:nth-of-type(3) {
    transform: rotate(-60deg);
  }

  .rectangle-view {
    width: 100%;
    height: 100%;
    position: relative;

    span {
      position: absolute;
      width: 5px;
      height: 0;
      background: #ffffff;
      z-index: 999999;
    }

    .h1 {
      left: 0;
      animation: bottom-to-top 3.6s ease infinite;
    }

    .h2 {
      right: 0;
      animation: top-to-bottom 3.6s ease 0.3s infinite;
    }

    .h3 {
      right: 0;
      animation: top-to-bottom 3.6s ease 0.6s infinite;
    }

    .h4 {
      right: 0;
      animation: top-to-bottom 3.6s ease 0.9s infinite;
    }

    .h5 {
      left: 0;
      animation: bottom-to-top 3.6s ease 1.2s infinite;
    }

    .h6 {
      left: 0;
      animation: bottom-to-top 3.6s ease 1.5s infinite;
    }
  }

  @keyframes bottom-to-top {
    0% {
      bottom: 0;
      height: 0;
    }
    6.944444444% {
      bottom: 0;
      height: 100%;
    }
    50% {
      top: 0;
      height: 100%;
    }
    59.944444433% {
      top: 0;
      height: 0;
    }
  }

  @keyframes top-to-bottom {
    0% {
      top: 0;
      height: 0;
    }
    6.944444444% {
      top: 0;
      height: 100%;
    }
    50% {
      bottom: 0;
      height: 100%;
    }
    59.944444433% {
      bottom: 0;
      height: 0;
    }
  }
}
</style>
