import store from '@/common/store';

/**
 * This service encapsulates all functionality required to update mail configs in the store.
 */
const MailConfigModificationService = (() => {
  return {
    getMailConfig(id) {
      return store.getters.mailConfig(id);
    },
    updateMailConfig(mailConfig) {
      store.commit('updateMailConfig', mailConfig);
    },
    getMailConfigField(id, fieldKey) {
      const mailConfig = store.getters.mailConfig(id);
      return mailConfig ? mailConfig[fieldKey] : undefined;
    },
    updateMailConfigField(id, field, value) {
      store.commit('updateMailConfigField', { id, field, value });
    },
    createMailConfig(id) {
      store.commit('updateMailConfig', { id, contentTemplate: '' });
    },
    deleteMailConfig(id) {
      store.commit('deleteMailConfig', id);
    },
  };
})();

export default MailConfigModificationService;
