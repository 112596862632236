import Vue from 'vue';
import i18n from '@/common/i18n';

const getDefaultState = () => ({
  magicVariableReferences: [],
  magicVariables: [],
  mvTypeCache: {},
  mvNameCache: {},
});

export function generateMvNameForListItem(listItemId, keyOfFieldToUseAsLabel, storeGetters) {
  const storedName = storeGetters.componentById(listItemId)[keyOfFieldToUseAsLabel];
  return storedName && storedName.startsWith('$TRANSLATE(')
    ? storeGetters.translation(storeGetters.preferredLang, `${listItemId}.${keyOfFieldToUseAsLabel}`)
    : storedName;
}

export default function magicVariables() {
  const initialState = getDefaultState();

  const getters = {
    magicVariables: (state) => {
      return state.magicVariables;
    },
    magicVariableReferences: (state) => {
      return state.magicVariableReferences;
    },
    mvTypeCache: (state) => {
      return (mvId) => (state.mvTypeCache[mvId] ? state.mvTypeCache[mvId] : null);
    },
    mvNameCache: (state, storeGetters) => {
      return (mvId) => {
        if (state.mvNameCache[mvId]) {
          if (state.mvNameCache[mvId].listItemId) {
            return generateMvNameForListItem(
              state.mvNameCache[mvId].listItemId,
              state.mvNameCache[mvId].keyOfFieldToUseAsLabel,
              storeGetters
            );
          }
          if (state.mvNameCache[mvId].translationKey) return () => i18n.t(state.mvNameCache[mvId].translationKey);
          return null;
        }
        return null;
      };
    },
  };

  const mutations = {
    updateMvNameCache(state, { mvId, value }) {
      Vue.set(state.mvNameCache, mvId, value);
    },
    updateMvTypeCache(state, { mvId, value }) {
      Vue.set(state.mvTypeCache, mvId, value);
    },
    addMagicVariableReference(state, { elementId, fieldKey, magicVariableId }) {
      state.magicVariableReferences.push({ elementId, fieldKey, magicVariableId });
    },
    deleteMagicVariableReference(state, { elementId, fieldKey, magicVariableId }) {
      // Attention: this function deletes all occurences of the reference
      const clearedArray = state.magicVariableReferences.filter(
        (reference) =>
          !(reference.elementId === elementId && reference.fieldKey === fieldKey && reference.magicVariableId === magicVariableId)
      );
      Vue.set(state, 'magicVariableReferences', clearedArray);
    },
    addMagicVariable(state, magicVariableId) {
      state.magicVariables.push(magicVariableId);
    },
    deleteMagicVariable(state, magicVariableIdToDelete) {
      const clearedArray = state.magicVariables.filter((magicVariableId) => magicVariableId !== magicVariableIdToDelete);
      Vue.set(state, 'magicVariables', clearedArray);
    },
    clear(state) {
      // Make it reactive
      Object.entries(getDefaultState()).forEach(([key, value]) => {
        Vue.set(state, key, value);
      });
    },
  };

  return {
    state: initialState,
    getters,
    mutations,
  };
}
