import store from '@/common/store';

// eslint-disable-next-line import/prefer-default-export
export function getRequestConfig() {
  return {
    headers: {
      Authorization: `Bearer ${store.getters['oidc/oidcAccessToken']}`,
    },
  };
}
