<template>
  <div class="convo-chat-messages-area">
    <div v-for="(q, index) in questions" :key="index">
      <p class="author author-chat">{{ $t('CONVO') }}</p>
      <p class="message-format message-question-box" v-html="$t(q)" />
      <p class="author author-user">{{ $t('chatUser') }}</p>
      <p v-if="isAnswerTranslatable(index)" class="message-format message-answer-box">{{ $t(answers[index].answer) }}</p>
      <p v-else class="message-format message-answer-box">{{ answers[index].answer }}</p>
    </div>
    <p v-if="!isFinished" class="author author-chat">{{ $t('CONVO') }}</p>
    <p v-if="!isFinished" class="message-format message-question-box" v-html="$t(question)" />
  </div>
</template>

<script>
export default {
  name: 'ConvoChatMessagesArea',
  props: {
    answers: Array,
    isFinished: Boolean,
    question: String,
    questions: Array,
  },
  methods: {
    isAnswerTranslatable(i) {
      return this.answers[i].answerType === 'options';
    },
  },
};
</script>

<style lang="scss" scoped>
.convo-chat-messages-area {
  .author {
    //FONT
    font-family: $font-logo;
    font-size: 11px;
    font-style: italic;
    font-weight: 300;
    line-height: 15px;
    //COLOR
    color: rgba(139, 149, 165, 1);
    //LAYOUT
    margin: 0;
  }

  .author-chat {
    //LAYOUT
    margin-left: 16px;
  }

  .author-user {
    //LAYOUT
    float: right;
    margin-right: 16px;
  }

  .message-format {
    //COLOR
    color: $primary-color;
    //FONT
    font-family: $primary-font;
    font-size: 14px;
    white-space: pre-line; // needed to display the <br /> in the json file (de & en)
    word-break: break-word;
    //LAYOUT
    margin-top: 0;
    margin-bottom: 8px;
    padding: 8px;
    width: fit-content;
  }

  .message-answer-box {
    //COLOR
    background: $chat-message-answer-box-background;
    //LAYOUT
    border-radius: $chat-message-answer-box-border-radius;
    float: right;
    margin-left: 16px;
    margin-right: 8px;
  }

  .message-question-box {
    //COLOR
    background: $primary-text-color;
    //LAYOUT
    border-radius: $chat-message-question-box-border-radius;
    margin-left: 8px;
    margin-right: 16px;
  }

  p {
    clear: both;
  }
}

@media (max-width: 1280px) {
  .convo-chat-messages-area {
    .author {
      font-size: 8px;
    }
    .message-format {
      font-size: 11px;
    }
  }
}
</style>
