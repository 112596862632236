<template>
  <div class="convo-canvas">
    <div v-if="!editable" class="view-mode-warning"><i class="convo-icon-read-mode" />{{ $t('readMode') }}</div>
    <div
      :class="['cpd-element', { selected: selected }]"
      @click.stop="navigateToProcessConfig"
      tabindex="-1"
      @keyup.ctrl.86.stop="paste"
      @keydown.meta.86.stop="pasteOnMac"
    >
      <div id="start-element" :class="getStateIcon">
        <i :class="'convo-icon-' + getStateIcon" />
        <span class="process-start-name">{{ cpdName }}</span>
      </div>
      <div class="convo-draggable-holder">
        <convo-draggable
          :components="children"
          :group="CanvasSlot.WITHIN_CANVAS"
          :is-draggable="editable"
          @change="handleChangeEvent"
          @choose="handleChooseEvent"
        />
      </div>
    </div>
    <convo-messages id="convo-messages" />
  </div>
</template>

<script>
import CpdModificationService from '@/common/services/CpdModificationService';
import StateEnum from '@/common/enums/StateEnum';
import KeyboardControlMixin from '@/studio/mixins/KeyboardControlMixin';
import RouterMixin from '@/common/mixins/RouterMixin';
import ConvoDraggableEventHandlerMixin from '@/studio/mixins/ConvoDraggableEventHandlerMixin';
import CanvasSlot from '@/studio/enums/CanvasSlot';
import ConvoMessages from '@/studio/components/canvas/ConvoMessages.vue';
import ConvoDraggable from './ConvoDraggable.vue';

export default {
  name: 'ConvoCanvas',
  mixins: [RouterMixin, ConvoDraggableEventHandlerMixin, KeyboardControlMixin(true)],
  components: {
    ConvoMessages,
    ConvoDraggable,
  },
  data() {
    return { CanvasSlot };
  },
  props: {
    uniqueCpdId: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    cpdName() {
      return this.$store.getters.cpdName;
    },
    getStateIcon() {
      switch (this.$store.getters.cpdState) {
        case StateEnum.DRAFT:
          return 'bulb';
        case StateEnum.REVIEW:
          return 'view';
        case StateEnum.READY:
          return 'check-square';
        case StateEnum.ACTIVE:
          return 'cloud';
        default:
          return '';
      }
    },
    selected() {
      return this.$route.path.includes('config');
    },
    children: {
      get() {
        return CpdModificationService.getElementField(this.uniqueCpdId, 'children');
      },
      set(value) {
        CpdModificationService.updateElementField(this.uniqueCpdId, 'children', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.convo-canvas {
  max-width: 760px;
  margin: 24px auto;

  .cpd-element,
  .view-mode-warning {
    outline: none;
    border-radius: 20px;
    margin: 0 auto;
  }

  .view-mode-warning {
    background-color: #f68705;
    color: white;
    margin-bottom: 20px;
    padding: 16px 0;
    text-align: center;
    font-size: 18px;
    font-weight: bold;

    .convo-icon-read-mode {
      margin-right: 16px;
    }
  }

  .cpd-element {
    background-color: $convo-blue-6;

    #start-element {
      text-align: center;
      color: #fff;
      padding: 10px 0;
      font-size: 20px;

      .process-start-name {
        vertical-align: middle;
      }

      .convo-icon-bulb,
      .convo-icon-view,
      .convo-icon-check-square,
      .convo-icon-cloud {
        margin-right: 10px;
        font-size: 18px;
        vertical-align: middle;
      }

      .convo-icon-bulb {
        font-size: 24px;
      }
    }

    &.selected {
      box-shadow: 3px 3px 9px $primary-color;
      font-weight: bold;
    }

    .convo-draggable-holder {
      ::v-deep .convo-brick:not(:last-child),
      ::v-deep .convo-toolbox-brick:not(:last-child) {
        padding-bottom: 16px;
      }
    }
  }

  #convo-messages {
    position: sticky;
    bottom: 0;
  }
}

@media (max-width: 1280px) {
  .convo-canvas {
    max-width: 600px;
    margin: 12px auto;

    .view-mode-warning {
      margin-bottom: 12px;
      padding: 10px 0;
      font-size: 16px;

      .convo-icon-read-mode {
        margin-right: 10px;
      }
    }

    .cpd-element {
      #start-element {
        font-size: 14px;
        padding: 8px 0;

        &.bulb .convo-icon-bulb {
          font-size: 16px;
        }
      }

      &.selected {
        box-shadow: 2px 2px 6px $primary-color;
      }

      .convo-draggable-holder {
        ::v-deep .convo-brick:not(:last-child),
        ::v-deep .convo-toolbox-brick:not(:last-child) {
          padding-bottom: 10px;
        }
      }
    }
  }
}
</style>
