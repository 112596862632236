<template>
  <div class="dashboard" :class="isDemo && 'demo-dashboard-padding'" id="dashboard">
    <div v-if="isDemo" class="demo-popup">
      <p v-html="$t('demoInfoMessage')" />
    </div>
    <convo-spinner :name="spinnerEnum.DASHBOARD">
      <div class="scroll-helper" :class="isDemo && 'demo-scroll-helper-height'" />
      <div class="columns">
        <div class="column" :class="{ highlight: highlight === states.DRAFT }">
          <div class="column-head">
            <div class="icon-text-holder" :title="$t('state.draftTooltip')">
              <i class="icon convo-icon-bulb" />
              {{ $t('state.draft') }}
            </div>
          </div>
          <div class="column-body">
            <convo-create-cpd-tile class="create-cpd-tile" @cpd-created="loadCpdList" />
            <convo-draggable-tile
              :id="states.DRAFT.toLowerCase()"
              :tiles="draft"
              :group="{ name: 'draft', pull: ['draft', 'review'], put: ['draft', 'review', 'ready'] }"
              @highlight="highlightColumn"
              @reload-cpds="loadCpdList"
              @show-related-tiles="showRelatedTiles"
            />
          </div>
        </div>
        <div class="column" :class="{ highlight: highlight === states.REVIEW }">
          <div class="column-head">
            <div class="icon-text-holder" :title="$t('state.reviewTooltip')">
              <i class="icon convo-icon-view" />
              {{ $t('state.review') }}
            </div>
          </div>
          <div class="column-body">
            <convo-draggable-tile
              :id="states.REVIEW.toLowerCase()"
              :tiles="review"
              :group="{ name: 'review', pull: ['draft', 'review', 'ready'], put: ['draft', 'review', 'ready'] }"
              @highlight="highlightColumn"
              @reload-cpds="loadCpdList"
              @show-related-tiles="showRelatedTiles"
            />
          </div>
        </div>
        <div class="column" :class="{ highlight: highlight === states.READY }">
          <div class="column-head">
            <div class="icon-text-holder" :title="$t('state.readyTooltip')">
              <i class="icon convo-icon-check-square" />
              <span class="status-text">{{ $t('state.ready') }}</span>
            </div>
          </div>
          <div class="column-body">
            <convo-draggable-tile
              :id="states.READY.toLowerCase()"
              :tiles="ready"
              :group="{ name: 'ready', pull: ['draft', 'review', 'ready', 'active'], put: ['review', 'ready'] }"
              @highlight="highlightColumn"
              @reload-cpds="loadCpdList"
              @show-related-tiles="showRelatedTiles"
            />
          </div>
        </div>
        <div class="column" :class="{ highlight: highlight === states.ACTIVE }">
          <div class="column-head">
            <div class="icon-text-holder" :title="$t('state.activeTooltip')">
              <i class="icon convo-icon-cloud" />
              {{ $t('state.active') }}
            </div>
          </div>
          <div class="column-body">
            <convo-draggable-tile
              :id="states.ACTIVE.toLowerCase()"
              :tiles="active"
              :group="{ name: 'active', pull: [], put: ['ready', 'active'] }"
              @highlight="highlightColumn"
              @reload-cpds="loadCpdList"
              @show-related-tiles="showRelatedTiles"
            />
          </div>
        </div>
      </div>
    </convo-spinner>
    <convo-chat v-if="isDemo" />
  </div>
</template>

<script>
import ConvoCreateCpdTile from '@/dashboard/components/ConvoCreateCpdTile.vue';
import ConvoDraggableTile from '@/dashboard/components/ConvoDraggableTile.vue';
import ConvoSpinner from '@/common/components/ConvoSpinner.vue';
import CpdApiService from '@/common/services/CpdApiService';
import StateEnum from '@/common/enums/StateEnum';
import { mapGetters } from 'vuex';
import { matchSorter } from 'match-sorter';
import { deleteStorageEntries as deleteUnusedStorageEntries } from 'vuex-async-persist';
import EventBus from '@/common/event-bus';
import SpinnerEnum from '@/common/enums/SpinnerEnum';
import ConvoChat from './chat/ConvoChat.vue';

export default {
  name: 'ConvoDashboard',
  components: { ConvoSpinner, ConvoDraggableTile, ConvoCreateCpdTile, ConvoChat },
  data() {
    return {
      draft: [],
      review: [],
      ready: [],
      active: [],
      states: StateEnum,
      cpdList: [],
      highlight: null,
      searchString: '',
      spinnerEnum: SpinnerEnum,
    };
  },
  computed: {
    ...mapGetters('oidc', ['oidcUser']),
    isDemo() {
      return this.oidcUser.realm_access.roles.includes('demo-access');
    },
  },
  methods: {
    highlightColumn(event) {
      this.highlight = event ? event.toUpperCase() : null;
    },
    loadCpdList() {
      CpdApiService.getCpds(this.spinnerEnum.DASHBOARD).then(
        (cpdList) => {
          this.cpdList = cpdList;
        },
        () => EventBus.emitToast('toast.loadCpdsFailed', true)
      );
    },
    updateSearchString(newSearchString) {
      this.searchString = newSearchString;
    },
    filterCpdList() {
      const filteredCpdList = this.searchString
        ? matchSorter(this.cpdList, this.searchString.trim(), {
            keys: ['name', 'id', (cpd) => cpd.modifiedBy.firstName.trim() + ' ' + cpd.modifiedBy.lastName.trim()],
            threshold: matchSorter.rankings.CONTAINS,
          })
        : this.cpdList;

      this.cpdListToState(filteredCpdList);
    },
    cpdListToState(cpdList) {
      this.draft = [];
      this.review = [];
      this.ready = [];
      this.active = [];
      cpdList.forEach((cpd) => {
        if (cpd.state === this.states.DRAFT) this.draft.push(cpd);
        else if (cpd.state === this.states.REVIEW) this.review.push(cpd);
        else if (cpd.state === this.states.READY) this.ready.push(cpd);
        else if (cpd.state === this.states.ACTIVE) this.active.push(cpd);
      });
    },
    hideOrHighlightCpd(cpd, cpdId, isCurrentlyHighlighted) {
      return { ...cpd, hide: !isCurrentlyHighlighted && cpd.id !== cpdId, highlight: !isCurrentlyHighlighted && cpd.id === cpdId };
    },
    changePositionOnHighlighting(array, cpdId) {
      const index = array.findIndex((cpd) => cpd.id === cpdId);
      if (index !== -1) {
        array.unshift(array[index]);
        array.splice(index + 1, 1);
      }
      return array;
    },
    showRelatedTiles(cpdId, isCurrentlyHighlighted) {
      this.draft = this.changePositionOnHighlighting(
        this.draft.map((cpd) => this.hideOrHighlightCpd(cpd, cpdId, isCurrentlyHighlighted)),
        cpdId
      );
      this.review = this.changePositionOnHighlighting(
        this.review.map((cpd) => this.hideOrHighlightCpd(cpd, cpdId, isCurrentlyHighlighted)),
        cpdId
      );
      this.ready = this.changePositionOnHighlighting(
        this.ready.map((cpd) => this.hideOrHighlightCpd(cpd, cpdId, isCurrentlyHighlighted)),
        cpdId
      );
      this.active = this.changePositionOnHighlighting(
        this.active.map((cpd) => this.hideOrHighlightCpd(cpd, cpdId, isCurrentlyHighlighted)),
        cpdId
      );
    },
  },
  watch: {
    cpdList() {
      this.filterCpdList();
    },
    searchString() {
      this.filterCpdList();
    },
  },
  mounted() {
    this.loadCpdList();
    EventBus.onDashboardSearchStringChange(this.updateSearchString);
  },
  beforeDestroy() {
    EventBus.onDashboardSearchStringChange(this.updateSearchString, true);
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === 'oidcCallback') {
      CpdApiService.getCpds().then((cpdList) => {
        deleteUnusedStorageEntries([...cpdList.map((cpd) => cpd.id + 'v' + cpd.version), 'convo']);
      });
    }
    next();
  },
};
</script>

<!--suppress SassScssResolvedByNameOnly -->
<style lang="scss" scoped>
$dashboard-padding-tb: 50px;
$dashboard-padding-tb-1280: 20px;
$dashboard-padding-lr: 25px;
$dashboard-padding-lr-1280: 10px;
$column-head-height: 100px;
$column-head-height-1280: 42px;

.dashboard {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: $dashboard-padding-tb $dashboard-padding-lr;
  height: calc(100vh - #{$header-height});
  overflow-y: auto;

  .demo-popup {
    //FONT
    font-family: $primary-font;
    font-size: 18px;
    font-weight: bold;
    //COLOR
    background-color: #f68705;
    color: white;
    outline: none;
    //LAYOUT
    border-radius: 20px;
    margin: 8px auto 8px;
    position: relative;
    text-align: center;
    width: 90%;
    z-index: 3;
  }

  .columns {
    margin: auto;
    display: flex;
    width: 90%;

    .column {
      &:not(:last-child) {
        margin-right: auto;
      }
      border-radius: 25px;
      width: 23%;
      min-height: calc(100vh - #{$header-height} - 2 * #{$dashboard-padding-tb});

      &:nth-of-type(1) {
        background-color: $convo-blue-1;
        .convo-draggable-tile {
          height: calc(100% - #{$tile-height});
        }
        .icon {
          font-size: 22px;
        }
      }
      &:nth-of-type(2) {
        background-color: $convo-blue-2;
        .icon {
          font-size: 15px;
        }
      }
      &:nth-of-type(3) {
        background-color: $convo-blue-3;
        .status-text {
          vertical-align: top;
        }
        .icon {
          font-size: 18px;
        }
      }
      &:nth-of-type(4) {
        background-color: $convo-blue-4;
      }

      &.highlight {
        background-color: $convo-blue-4;
        .column-head,
        .column-body {
          border-left: 2px dashed #1f3c86;
          border-right: 2px dashed #1f3c86;
        }
        .column-head {
          border-top: 2px dashed #1f3c86;
        }
        .column-body {
          border-bottom: 2px dashed #1f3c86;
        }
      }

      .column-head,
      .column-body {
        width: 100%;
        padding-right: 30px;
        padding-left: 30px;
      }

      .column-head {
        height: $column-head-height;
        border-radius: 25px 25px 0 0;
        padding-top: 20px;
        z-index: 3;
        background-color: inherit;
        position: -webkit-sticky;
        position: sticky;
        top: -40px;
        color: $primary-color;

        .icon-text-holder {
          padding: 25px 0;
          text-align: center;
          font-weight: bold;

          .icon {
            margin-right: 3px;
          }
        }
      }

      .column-body {
        height: calc(100% - #{$column-head-height});
      }

      .create-cpd-card {
        margin-top: 20px;
        margin-bottom: 10px;
      }
    }
  }
  .scroll-helper {
    // need this element to hide scrolling content behind column head
    position: fixed;
    top: $header-height;
    z-index: 2;
    width: calc(100% - 2 * #{$dashboard-padding-lr});
    height: $column-head-height;
    background-color: white;
  }

  .demo-scroll-helper-height {
    height: 56px;
  }
}

.demo-dashboard-padding {
  padding: 0 $dashboard-padding-lr;
}

@media (max-width: 1280px) {
  .dashboard {
    padding: $dashboard-padding-tb-1280 $dashboard-padding-lr-1280;
    height: calc(100vh - #{$header-height-1280});

    .demo-popup {
      font-size: 14px;
    }

    .columns .column {
      .column-head,
      .column-body {
        padding-right: 16px;
        padding-left: 16px;
      }

      .column-head {
        top: -16px;
        height: $column-head-height-1280;
        padding-top: 12px;
        font-size: 14px;

        .icon-text-holder {
          padding: 0;
        }
      }

      &:nth-of-type(1) .icon {
        font-size: 17px;
      }
      &:nth-of-type(2) .icon {
        font-size: 12px;
      }
      &:nth-of-type(3) .icon {
        font-size: 14px;
      }
      &:nth-of-type(4) .icon {
        font-size: 13px;
      }
    }

    .scroll-helper {
      top: $header-height-1280;
      height: $column-head-height-1280;
    }
  }
}
</style>
