<template>
  <div :id="`inspector-panel-${componentId}`" :key="componentId" class="convo-inspector-panel">
    <div class="top-container" v-if="showName">
      <el-input
        class="name-input"
        :id="`name-input-${componentId}`"
        :disabled="!editable"
        :placeholder="$t('elementNamePlaceholder')"
        v-model="componentName"
      />
    </div>

    <div class="body-container">
      <div v-for="category in config" :id="`category-${category.key}`" class="category" :key="category.key">
        <div
          class="category-header"
          :id="`category-header-${category.key}`"
          :key="activeCategories.length"
          @click="toggleCategory(category.key)"
        >
          {{ $t(`${type}.${category.key}`) }}
          <i :class="{ 'convo-icon-minus': isCategoryActive(category.key), 'convo-icon-plus': !isCategoryActive(category.key) }" />
        </div>
        <div v-show="isCategoryActive(category.key)" class="category-body" :id="`category-body-${category.key}`">
          <el-form ref="form" label-position="top" :model="{}">
            <!-- model is required to ensure validation, but is not used -->
            <template v-for="field in category.fields">
              <div v-if="field.type === 'Heading'" class="category-subheading" :key="field.key">
                {{ $t(`${type}.${field.key}`) }}
              </div>
              <convo-input-delegator
                v-else
                class="delegated-input"
                :class="{ 'is-target': targetKey === field.key }"
                :editable="editable"
                :field="field"
                :i18n-prefix="type + '.' + field.key"
                :key="field.key"
                :parent-id="componentId"
              />
            </template>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CpdModificationService from '@/common/services/CpdModificationService';
import ConvoProcessComponentService from '@/common/services/ConvoProcessComponentService';
import RouterMixin from '@/common/mixins/RouterMixin';
import ConvoInputDelegator from '@/studio/components/right_sidepanel/inspector_panel/ConvoInputDelegator.vue';
import CelReturnType from '@/common/enums/CelReturnType';

export default {
  name: 'ConvoInspectorPanel',
  mixins: [RouterMixin],
  components: {
    ConvoInputDelegator,
  },
  props: {
    componentId: {
      type: String,
      required: true,
    },
    targetKey: {
      type: String,
      default: null,
    },
    showName: {
      type: Boolean,
      default: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      activeCategories: ['general', 'inputs'],
      CelReturnType,
    };
  },
  computed: {
    type() {
      return CpdModificationService.getElementField(this.componentId, 'type');
    },
    componentName: {
      get() {
        return CpdModificationService.getElementField(this.componentId, 'name');
      },
      set(newValue) {
        CpdModificationService.updateElementField(this.componentId, 'name', newValue);
      },
    },
    config() {
      if (!this.type) return [];
      return ConvoProcessComponentService.getInspectorPanelDefinition(this.type).map((category) => ({
        key: category.key,
        fields: category.fields.map(this.getField),
      }));
    },
    element() {
      return CpdModificationService.getElement(this.componentId);
    },
  },
  watch: {
    targetKey() {
      this.openTargetKeyCategory();
    },
    element: {
      deep: true,
      handler() {
        this.forceValidation();
      },
    },
    componentId() {
      setTimeout(this.forceValidation, 10); // wait until form is rendered
    },
  },
  methods: {
    openTargetKeyCategory() {
      if (this.targetKey) {
        const targetKeyCategory = this.config.find((category) => category.fields.some((field) => field.key === this.targetKey));
        if (targetKeyCategory) this.activeCategories = [targetKeyCategory.key];
      }
    },
    forceValidation() {
      this.$refs.form.forEach((form) => form.validate(() => {}));
    },
    toggleCategory(category) {
      if (this.activeCategories.includes(category)) {
        this.activeCategories.splice(this.activeCategories.indexOf(category), 1);
      } else {
        this.activeCategories.push(category);
      }
    },
    isCategoryActive(category) {
      return this.activeCategories.includes(category);
    },
    getField(fieldKey) {
      const field = ConvoProcessComponentService.getFieldDefinition(this.type, fieldKey);

      if (!field) {
        return { key: fieldKey, type: 'Heading' };
      }
      return { key: fieldKey, ...field };
    },
  },
  mounted() {
    this.openTargetKeyCategory();
    this.forceValidation();
  },
};
</script>

<style lang="scss">
$top-container-height: 96px;
$top-container-height-1280: 52px;

.convo-inspector-panel {
  height: 100%;

  .top-container {
    display: flex;
    align-items: center;
    height: $top-container-height;
    padding: 24px;
    background-color: $convo-blue-4;

    input,
    .is-disabled input {
      background-color: transparent;
      color: $primary-color;
      border: none;
      font-family: $primary-font;
      font-size: 20px;
      font-weight: 600;

      &::placeholder {
        color: $primary-color;
        font-style: italic;
      }
    }
  }

  .body-container {
    height: calc(100% - #{$top-container-height});
    overflow-y: auto;

    .category {
      margin-bottom: 4px;

      .category-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 48px;
        padding: 16px;
        cursor: pointer;
        background-color: $convo-blue-2;
        color: $primary-color;
        font-size: 18px;
        font-weight: 600;

        :first-child {
          margin-right: 8px;
        }

        .convo-icon-minus,
        .convo-icon-plus {
          font-size: 14px;
          font-weight: bold;
        }
      }

      .category-body {
        padding: 24px 24px 12px 24px;

        .category-subheading {
          font-size: 18px;
          font-weight: 600;
          color: $primary-color;
          margin-bottom: 24px;

          &:not(:first-child) {
            margin-top: 48px;
          }
        }
      }
    }
  }
}

@media (max-width: 1400px) {
  .convo-inspector-panel {
    .top-container {
      input {
        font-size: 16px;
      }
    }
  }
  .convo-inspector-panel > .body-container > .category {
    .category-header {
      font-size: 16px;
    }
    .category-body {
      padding: 16px 12px 0 12px;
    }
  }
}

@media (max-width: 1280px) {
  .convo-inspector-panel {
    .top-container {
      height: $top-container-height-1280;
      padding: 0;

      input {
        font-size: 14px;
        padding: 0 10px;
      }
    }

    .body-container {
      height: calc(100% - #{$top-container-height-1280});

      .category {
        .category-header {
          height: 36px;
          padding: 12px;
          font-size: 14px;

          .convo-icon-minus,
          .convo-icon-plus {
            font-size: 12px;
          }
        }

        .category-body {
          padding: 6px 12px 0 10px;

          .category-subheading {
            font-size: 14px;
            margin-bottom: 8px;

            &:not(:first-child) {
              margin-top: 24px;
            }
          }
        }
      }
    }
  }
}
</style>
