<template>
  <div :class="['convo-constant', typeName]">
    <div class="icon-holder"><i class="convo-icon-constant" /></div>
    <div class="body">
      <template v-if="type === CelReturnType.BOOLEAN">
        <el-switch v-model="value" :disabled="disabled" />
      </template>
      <template v-else-if="type === CelReturnType.DATE">
        <span class="body-child">{{ $t('magicVariablesDialog.currentDate') }}</span>
        <i class="convo-icon-plus body-child" />
        <el-input-number class="body-child" v-model="value" :disabled="disabled" />
        <span class="body-child">{{ $t('magicVariablesDialog.days') }}</span>
      </template>
      <template v-else-if="type === CelReturnType.NUMBER">
        <el-input-number v-model="value" :disabled="disabled" />
      </template>
      <template v-else-if="type === CelReturnType.STRING">
        <el-input class="text-input" v-model="value" :disabled="disabled" />
      </template>
    </div>
    <i v-if="!disabled" class="convo-icon-delete" @click="$emit('delete')" />
  </div>
</template>

<script>
import CelReturnType, { getName } from '@/common/enums/CelReturnType';
import ConvoExpressionLanguageService from '@/common/services/ConvoExpressionLanguageService';

export default {
  name: 'ConvoConstant',
  props: {
    id: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      CelReturnType,
    };
  },
  computed: {
    type() {
      const operator = ConvoExpressionLanguageService.getExpressionField(this.id, 'operator');
      return ConvoExpressionLanguageService.getExpressionDefinitionField(operator, 'returnType');
    },
    typeName() {
      return getName(this.type);
    },
    value: {
      get() {
        return ConvoExpressionLanguageService.getExpressionField(this.id, 'parameter')[0];
      },
      set(newValue) {
        ConvoExpressionLanguageService.updateExpressionField(this.id, 'parameter', [newValue]);
      },
    },
  },
  methods: {
    getDefaultValue() {
      switch (this.type) {
        case CelReturnType.STRING:
          return '';
        case CelReturnType.NUMBER:
        case CelReturnType.DATE:
          return 0;
        case CelReturnType.BOOLEAN:
          return true;
        default:
          return null;
      }
    },
  },
  mounted() {
    const currentValue = ConvoExpressionLanguageService.getExpressionField(this.id, 'parameter')[0];
    if (currentValue === undefined || currentValue === null) {
      this.value = this.getDefaultValue();
    }
  },
};
</script>

<style lang="scss" scoped>
.convo-constant {
  background-color: white;
  border: 1px solid $primary-color;
  border-radius: 5px;
  color: $primary-text-color;
  display: flex;
  height: 60px;
  overflow: hidden;

  .icon-holder {
    align-items: center;
    background-color: $primary-color;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 40px;
  }

  .body {
    align-items: center;
    color: $primary-color;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    height: 100%;
    justify-content: center;
    padding: 0 8px 0 28px;
    text-align: center;
    width: calc(100% - 60px);
    word-break: keep-all;

    .text-input {
      width: 100%;
    }

    .body-child {
      margin: 0 8px;
    }
  }

  .convo-icon-delete {
    color: $primary-color;
    cursor: pointer;
    display: none;
    font-size: 16px;
    margin-top: 8px;

    &:hover {
      color: $secondary-color;
    }
  }

  &:hover {
    .convo-icon-delete {
      display: block;
    }
  }
}
</style>
