<template>
  <div class="convo-rich-text-input convo-ip-input">
    <el-form-item>
      <el-popover
        ref="popover"
        :title="localizedTooltipTitle"
        trigger="hover"
        :content="localizedTooltipText"
        placement="left-start"
        width="320"
        :open-delay="800"
      />
      <span class="no-tabindex" slot="label" v-popover:popover v-if="value">{{ localizedLabel }}</span>
      <div class="rich-text-preview" v-html="value" />
      <div :key="value" :class="['rich-text-button-holder', { edit: value, add: !value, 'not-editable': !editable }]">
        <div v-if="value" @click="handleOpen" class="rich-text-edit-button">
          <i class="convo-icon-edit-circle" /> {{ $t(`${i18nPrefix}.editText`) }}
        </div>
        <div v-else @click="handleOpen" class="rich-text-add-button">
          <i class="convo-icon-plus-circle" /> {{ $t(`${i18nPrefix}.addText`) }}
        </div>
      </div>
      <convo-rich-editor-dialog
        :key="preferredLang"
        :visible="dialogVisible"
        :content="value"
        :title="`${componentName} ${localizedLabel}`"
        @update="handleUpdate($event)"
        @close="handleClose"
      />
    </el-form-item>
  </div>
</template>

<script>
import TranslationModificationService from '@/common/services/TranslationModificationService';
import CpdModificationService from '@/common/services/CpdModificationService';
import ConvoInputI18nMixin from '@/studio/mixins/ConvoInputI18nMixin';
import ConvoRichEditorDialog from '@/common/components/ConvoRichEditorDialog.vue';

export default {
  name: 'ConvoRichTextInput',
  mixins: [ConvoInputI18nMixin],
  components: { ConvoRichEditorDialog },
  props: {
    parentId: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    fieldKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  computed: {
    value: {
      get() {
        return TranslationModificationService.getTranslation(this.preferredLang, this.parentId, this.fieldKey);
      },
      set(newValue) {
        TranslationModificationService.updateTranslation(this.preferredLang, this.parentId, this.fieldKey, newValue);
      },
    },
    componentName() {
      return CpdModificationService.getElementField(this.parentId, 'name');
    },
    preferredLang() {
      return TranslationModificationService.getPreferredLang();
    },
  },
  methods: {
    handleUpdate(newValue) {
      this.value = newValue;
    },
    handleOpen() {
      if (this.editable) this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/studio/styles/inputs.scss';

.convo-rich-text-input {
  .rich-text-preview {
    line-height: 24px;
    word-wrap: break-word;
    text-align: justify;
    padding: 0 8px;
  }

  ::v-deep .el-form-item__content {
    display: inherit;
  }

  .rich-text-button-holder {
    display: flex;

    &.add {
      margin-left: 4px;
      justify-content: left;
    }
    &.edit {
      justify-content: center;
    }

    .rich-text-add-button,
    .rich-text-edit-button {
      display: flex;
      align-items: center;
      text-align: center;

      font-size: 14px;
      color: $primary-color;
      font-weight: 600;
      cursor: pointer;

      .convo-icon-plus-circle,
      .convo-icon-edit-circle {
        font-size: 24px;
        margin-right: 8px;
      }
    }

    &.not-editable {
      .rich-text-add-button,
      .rich-text-edit-button {
        color: $disabled-color;
      }
    }
  }
}
</style>
