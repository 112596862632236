import store from '@/common/store';

/**
 * This service encapsulates all functionality required to update translations in the store.
 */
const TranslationModificationService = (() => {
  return {
    getTranslation(lang, elementId, fieldKey) {
      const key = `${elementId}.${fieldKey}`;
      return store.getters.translation(lang, key);
    },
    updateTranslation(lang, elementId, fieldKey, value) {
      const key = `${elementId}.${fieldKey}`;
      store.commit('updateTranslation', { lang, key, value });
    },
    getTranslationsForElement(lang, elementId) {
      return store.getters.translationsForElement(lang, elementId);
    },
    getPreferredLang() {
      return store.getters.preferredLang;
    },
    changePreferredLang(lang) {
      return store.commit('changePreferredLang', lang);
    },
  };
})();

export default TranslationModificationService;
