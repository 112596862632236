<template>
  <div class="convo-element-values-toolbox">
    <div class="element-values-header first-level-child">{{ $t('magicVariablesDialog.elementValues') }}</div>
    <div class="element-values">
      <convo-structural-element
        v-for="section in sections"
        class="first-level-child"
        :key="section + requiredInputType"
        :element-id="section"
        :required-input-type="requiredInputType"
        :stop-signs="updatedStopSigns"
        @empty="handleEmptyChild(section)"
      />
      <convo-element
        v-if="!isStartElementEmpty"
        class="first-level-child"
        alternative-name="Prozessstart"
        :element-id="startElement"
        no-border-radius
        no-box-shadow
        :required-input-type="requiredInputType"
        @empty="handleEmptyChild(startElement)"
      />
      <div v-if="requiredInputType === CelReturnType.NONE" class="complete-expression-placeholder">
        {{ $t('magicVariablesDialog.completeExpressionElementValues') }}
      </div>
      <div v-else-if="sections.length === 0 && isStartElementEmpty" class="no-element-values-placeholder">
        {{ $t('magicVariablesDialog.noElementValues') }}
      </div>
    </div>
  </div>
</template>

<script>
import CpdModificationService from '@/common/services/CpdModificationService';
import ElementType from '@/common/enums/ElementType';
import ConvoStructuralElement from '@/studio/components/right_sidepanel/inspector_panel/convo_expression_language_dialog/left_sidepanel/ConvoStructuralElement.vue';
import ConvoElement from '@/studio/components/right_sidepanel/inspector_panel/convo_expression_language_dialog/left_sidepanel/ConvoElement.vue';
import CelReturnType from '@/common/enums/CelReturnType';

export default {
  name: 'ConvoElementValuesToolbox',
  components: { ConvoElement, ConvoStructuralElement },
  props: {
    parentElement: {
      type: String,
      required: true,
    },
    requiredInputType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      emptyChildren: [],
      CelReturnType,
    };
  },
  computed: {
    uniqueCpdId() {
      return this.$route.params.cpd + 'v' + this.$route.params.version;
    },
    sections() {
      const children = CpdModificationService.getElementField(this.$route.params.cpd + 'v' + this.$route.params.version, 'children').filter(
        (element) => CpdModificationService.getElementField(element, 'type') === ElementType.SECTION
      );
      return children
        .filter(
          (child, index) =>
            children.indexOf(this.stopSigns[0]) === -1 || // children don't include stop sign
            children.indexOf(this.stopSigns[0]) > index || // current element is before stop sign
            (child === this.stopSigns[0] && this.stopSigns.length > 1) // child equals stop sign but stop sign is on another level
        )
        .filter((child) => !this.emptyChildren.includes(child));
    },
    startElement() {
      return CpdModificationService.getElementField(this.$route.params.cpd + 'v' + this.$route.params.version, 'control_start');
    },
    stopSigns() {
      const nonSectionFirstLevelElements = [ElementType.POPUP, ElementType.PDF];
      if (nonSectionFirstLevelElements.includes(CpdModificationService.getElementField(this.parentElement, 'type'))) {
        // the stop sign for non sections (popup, pdf generation) has to be the first section after the popup
        const structuralElements = CpdModificationService.getElementField(this.uniqueCpdId, 'children');
        return structuralElements
          .slice(structuralElements.indexOf(this.parentElement) + 1)
          .filter((element) => CpdModificationService.getElementField(element, 'type') === ElementType.SECTION)
          .filter((section, index) => index === 0);
      }

      const buildStopSigns = (id) => {
        if (id === this.parentElement) {
          return [id];
        }
        const childrenSigns = (CpdModificationService.getElementField(id, 'children') || []).flatMap(buildStopSigns);
        return childrenSigns.length > 0 ? [id, ...childrenSigns] : [];
      };

      return buildStopSigns(this.uniqueCpdId).slice(1);
    },
    updatedStopSigns() {
      return this.stopSigns.slice(1);
    },
    isStartElementEmpty() {
      return this.emptyChildren.includes(this.startElement);
    },
  },
  watch: {
    requiredInputType() {
      this.emptyChildren = [];
    },
  },
  methods: {
    handleEmptyChild(child) {
      this.emptyChildren.push(child);
    },
  },
};
</script>

<style lang="scss" scoped>
.convo-element-values-toolbox {
  height: 100%;

  .element-values-header {
    align-items: center;
    background-color: $convo-blue-4;
    color: $primary-color;
    display: flex;
    font-size: 18px;
    font-weight: 600;
    height: 56px;
    padding: 16px;
  }

  .element-values {
    height: calc(100% - 56px);
    overflow-y: auto;

    .first-level-child:not(:last-child) {
      margin-bottom: 2px;
    }

    .complete-expression-placeholder,
    .no-element-values-placeholder {
      font-style: italic;
      padding: 16px;
      word-break: break-word;
    }
  }
}

@media (max-width: 1280px) {
  .convo-element-values-toolbox {
    .element-values-header {
      font-size: 14px;
      height: 40px;
      padding: 12px;
    }
  }
}
</style>
