<template>
  <div :id="id" class="convo-draggable-tile">
    <draggable
      :list="tiles"
      :group="group"
      :animation="250"
      :force-fallback="true"
      class="draggable"
      draggable=".is-draggable"
      ghost-class="tile-to-move"
      dragClass="moving-tile"
      @change="moved"
      @end="removeHighlighting"
      :move="onMove"
    >
      <convo-cpd-tile
        v-for="(cpd, index) in cpds"
        :draggable-class="isDemo ? '' : 'is-draggable'"
        :class="index"
        :cpd="cpd"
        :key="cpd.id"
        @delete-cpd="removeCpdFromColumn"
        @show-related-tiles="showRelatedTiles"
        @reload-cpds="reloadCpds"
        @update-cpd="reloadCpd"
      />
    </draggable>
    <convo-confirmation-dialog
      :visible="changedStateToActiveDialog"
      :title="$t('dashboard.securityPrompt')"
      :content="dialogContent"
      :button-click-not-required="false"
      :confirm-label="$t('dashboard.changeStateToActiveDialog.confirm')"
      :cancel-label="$t('dashboard.cancel')"
      @confirm="changeStateToActive"
      @close="cancelStateChange"
      @cancel="cancelStateChange"
    />
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import CpdApiService from '@/common/services/CpdApiService';
import ConvoCpdTile from '@/dashboard/components/ConvoCpdTile.vue';
import ConvoConfirmationDialog from '@/common/components/ConvoConfirmationDialog.vue';
import StateEnum from '@/common/enums/StateEnum';
import SpinnerEnum from '@/common/enums/SpinnerEnum';
import EventBus from '@/common/event-bus';

export default {
  name: 'ConvoDraggableTile',
  components: {
    draggable,
    ConvoCpdTile,
    ConvoConfirmationDialog,
  },
  props: {
    tiles: {
      default: null,
      type: Array,
    },
    group: {
      type: Object,
      default() {
        return {
          name: '',
          pull: [],
          put: [],
        };
      },
    },
    id: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      cpds: this.tiles,
      changedStateToActiveDialog: false,
      cpdIdToChange: '',
      cpdVersionToChange: 0,
      cpdNameToChange: '',
      dialogContent: this.$t('dashboard.changeStateToActiveDialog.content', [`<span class="highlight">${this.cpdNameToChange}</span>`]),
      isDemo: this.$store.getters['oidc/oidcUser'].realm_access.roles.includes('demo-access'),
      state: StateEnum,
    };
  },
  methods: {
    onMove({ relatedContext }) {
      this.$emit('highlight', relatedContext.component.$parent.$el.id);
    },
    moved(event) {
      if (event.added) {
        const cpdId = event.added.element.id;
        const versionNumber = event.added.element.version;
        const newState = this.id.toUpperCase();
        const cpd = this.cpds.find((c) => c.id === cpdId);
        cpd.state = newState;
        if (newState === this.state.ACTIVE) {
          this.cpdIdToChange = cpdId;
          this.cpdVersionToChange = versionNumber;
          this.cpdNameToChange = cpd.name;
          this.dialogContent = this.$t('dashboard.changeStateToActiveDialog.content', [
            `<span class="highlight">${this.cpdNameToChange}</span>`,
          ]);
          if (cpd.hasOrIsFollowUpVersion) this.dialogContent += '<br><br>' + this.$t('dashboard.changeStateToActiveDialog.override');
          this.changedStateToActiveDialog = true;
        } else {
          CpdApiService.overrideState(cpdId, versionNumber, newState).then(
            () => this.reloadCpd(cpdId),
            () => {
              EventBus.emitToast('toast.stateUpdateFailed', true);
              this.reloadCpds();
            }
          );
        }
      }
    },
    reloadCpd(id) {
      CpdApiService.getCpdsWithId(id, SpinnerEnum.DASHBOARD).then(
        (cpds) => {
          if (cpds.length) {
            const cpd = cpds[0]; // resulting array will have exactly one cpd (if it's the initial version) or moved cpd on array position 0 (if there is another cpd in active state)
            const index = this.cpds.findIndex((oldCpd) => oldCpd.id === cpd.id);
            if (index !== -1) {
              this.cpds[index].state = cpd.state;
              this.cpds[index].name = cpd.name;
              this.cpds[index].modified = cpd.modified;
              this.cpds[index].modifiedBy = cpd.modifiedBy;
              this.cpds[index].lastDeployment = cpd.lastDeployment;
            }
          } else {
            const index = this.cpds.findIndex((oldCpd) => oldCpd.id === id);
            this.cpds.splice(index, 1);
          }
        },
        () => EventBus.emitToast('toast.loadCpdFailed', true)
      );
    },
    removeCpdFromColumn(id) {
      const indexToRemove = this.cpds.findIndex((oldCpd) => oldCpd.id === id);
      if (this.cpds[indexToRemove].hasOrIsFollowUpVersion) {
        this.reloadCpds();
      } else {
        this.cpds.splice(indexToRemove, 1);
      }
    },
    reloadCpds() {
      this.$emit('reload-cpds');
    },
    removeHighlighting() {
      this.$emit('highlight');
    },
    changeStateToActive() {
      this.changedStateToActiveDialog = false;
      CpdApiService.overrideState(this.cpdIdToChange, this.cpdVersionToChange, this.state.ACTIVE).then(
        () => {
          this.reloadCpds();
          EventBus.emitToast('toast.cpdActivated');
        },
        () => EventBus.emitToast('toast.cpdActivationFailed', true)
      );
    },
    cancelStateChange() {
      // check needed
      if (this.changedStateToActiveDialog) {
        this.changedStateToActiveDialog = false;
        this.$emit('reload-cpds');
      }
    },
    showRelatedTiles(cpdId, highlight) {
      this.$emit('show-related-tiles', cpdId, highlight);
    },
  },
  watch: {
    tiles() {
      this.cpds = this.tiles;
    },
  },
};
</script>

<style lang="scss" scoped>
.convo-draggable-tile {
  height: 100%;

  .draggable {
    height: 100%;

    &.highlight {
      border: 4px solid red;
    }
  }

  .is-draggable {
    cursor: move;
  }
}
</style>
