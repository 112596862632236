<template>
  <div class="convo-header-slot-preview">
    <convo-device-selector
      category="desktop"
      device-icon="convo-icon-desktop"
      :is-selected="isSelected('desktop')"
      @select="select('desktop')"
    />
    <convo-device-selector
      category="phone"
      device-icon="convo-icon-smartphone"
      :is-selected="isSelected('phone')"
      @select="select('phone')"
    />
    <convo-device-selector
      category="tablet"
      device-icon="convo-icon-tablet"
      :is-selected="isSelected('tablet')"
      @select="select('tablet')"
    />
    <convo-custom-device-selector :is-selected="isSelected('custom')" @select="select('custom')" />
  </div>
</template>

<script>
import ConvoDeviceSelector from '@/preview/components/header/ConvoDeviceSelector.vue';
import ConvoCustomDeviceSelector from '@/preview/components/header/ConvoCustomDeviceSelector.vue';

export default {
  name: 'ConvoHeaderSlotPreview',
  components: { ConvoCustomDeviceSelector, ConvoDeviceSelector },
  data() {
    return {
      selectedDeviceCategory: 'tablet',
    };
  },
  methods: {
    select(category) {
      this.selectedDeviceCategory = category;
    },
    isSelected(category) {
      return this.selectedDeviceCategory === category;
    },
  },
};
</script>

<style lang="scss" scoped>
.convo-header-slot-preview {
  display: flex;
  justify-content: center;

  .convo-device-selector {
    margin-right: 20px;
  }

  ::v-deep.convo-icon-desktop {
    font-size: 26px;
  }
  ::v-deep.convo-icon-smartphone {
    margin-left: 4px;
  }
  ::v-deep.convo-icon-slider-settings {
    margin-top: 1px;
    font-size: 22px;
  }
}

@media (max-width: 1280px) {
  .convo-header-slot-preview ::v-deep {
    &.convo-icon-desktop,
    &.convo-icon-smartphone,
    &.convo-icon-tablet,
    &.convo-icon-slider-settings {
      font-size: 18px;
      margin-top: 4px;
    }
  }
}
</style>
