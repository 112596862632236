import axios from 'axios';
import { getRequestConfig } from '@/common/oidc/utils';

const ConvoChatContentApiService = (() => {
  const endpoint = process.env.VUE_APP_BACKEND || window.backend + '/v1/feedback';
  return {
    sendUserInput(inputDto) {
      if (inputDto.policyAgreement) {
        axios.post(endpoint, inputDto, getRequestConfig());
      } else if (inputDto.feedbackText) {
        axios.post(endpoint, { feedbackText: inputDto.feedbackText }, getRequestConfig());
      }
    },
  };
})();

export default ConvoChatContentApiService;
