<template>
  <div :class="['convo-toast', { warning }]" ref="toast" @mouseenter="mouseenter" @mouseleave="mouseleave">
    <span class="message">{{ $t(messageKey) }}</span>
    <button class="convo-toast-close convo-icon-cancel" @click="close"></button>
  </div>
</template>

<script>
export default {
  name: 'ConvoToast',
  props: {
    messageKey: {
      type: String,
      required: true,
    },
    timeToLive: {
      type: Number,
      required: true,
    },
    warning: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      interval: null,
      time: 0,
      timeout: 25,
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    updateOpacity() {
      if (this.$refs.toast) {
        const opacity = Math.sqrt(-1 * this.time + this.timeToLive) / Math.sqrt(this.timeToLive);
        this.$refs.toast.style.setProperty('--toast-opacity', `${opacity}`);
      }
    },
    setInterval() {
      this.interval = setInterval(() => {
        this.time += this.timeout;
        this.updateOpacity();
      }, this.timeout);
    },
    mouseenter() {
      this.$emit('mouseenter');
      clearInterval(this.interval);
    },
    mouseleave() {
      this.$emit('mouseleave');
      this.setInterval();
    },
  },
  mounted() {
    this.updateOpacity();
    this.setInterval();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.convo-toast {
  display: flex;
  height: 70px;
  width: 595px;
  padding: 20px;
  border-radius: 10px;
  color: $primary-text-color;
  background-color: $primary-color;
  align-items: center;
  justify-content: space-between;
  opacity: var(--toast-opacity);

  &:hover {
    opacity: 1;
  }

  &.warning {
    background-color: $secondary-color;
  }

  .message {
    margin-right: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  button {
    background-color: transparent;
    border: none;
    color: $primary-text-color;
    cursor: pointer;
    outline: none;
  }
}

@media (max-width: 1280px) {
  .convo-toast {
    height: 52px;
    width: 450px;
    padding: 14px;
    font-size: 14px;
  }
}
</style>
