<template>
  <div class="convo-subject-input">
    <el-input
      v-if="!isConvoExpressionLanguage"
      v-model="subject"
      :disabled="disabled"
      :placeholder="$t('mail_generation.subject.placeholder')"
    />
    <template v-else>
      <div class="subject-label">{{ $t('mail_generation.subject.label') }}</div>
      <el-popover ref="popover-cel" trigger="hover" width="480" placement="bottom-start" :open-delay="800">
        <convo-expression
          :key="dialogVisible"
          disabled
          :expression-id="expression"
          :heading="expressionHeading"
          :required-input-type="CelReturnType.STRING"
        />
      </el-popover>
      <span v-popover:popover-cel class="active-mv">
        <i v-for="i in 4" :key="i" class="convo-icon-placeholder" />
      </span>
    </template>
    <el-button size="mini" tabindex="-1" :class="['mv-button', { disabled }]" :disabled="disabled" @click="handleOpen">
      <i v-if="isConvoExpressionLanguage" class="convo-icon-function-circle-active" />
      <i v-else class="convo-icon-function-circle-default" />
    </el-button>
    <convo-expression-language-dialog
      :expression-heading="expressionHeading"
      :field-key="fieldKey"
      :parent-id="parentId"
      :type="CelReturnType.STRING"
      :value="subject"
      :visible="dialogVisible"
      @close="handleClose"
      @update="handleUpdate"
    />
  </div>
</template>

<script>
import CelReturnType from '@/common/enums/CelReturnType';
import ConvoExpressionLanguageService from '@/common/services/ConvoExpressionLanguageService';
import ConvoExpression from '../convo_expression_language_dialog/expression/ConvoExpression.vue';
import ConvoExpressionLanguageDialog from '../convo_expression_language_dialog/ConvoExpressionLanguageDialog.vue';

export default {
  name: 'ConvoSubjectInput',
  components: {
    ConvoExpression,
    ConvoExpressionLanguageDialog,
  },
  props: {
    parentId: {
      type: String,
      required: true,
    },
    fieldKey: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogVisible: false,
      CelReturnType,
    };
  },
  computed: {
    subject: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
    isConvoExpressionLanguage() {
      return ConvoExpressionLanguageService.isConvoExpressionLanguage(this.subject);
    },
    expression() {
      if (this.isConvoExpressionLanguage && !ConvoExpressionLanguageService.getExpressionIdForField(this.parentId, this.fieldKey)) {
        return ConvoExpressionLanguageService.parseString(this.subject);
      }
      return ConvoExpressionLanguageService.getExpressionIdForField(this.parentId, this.fieldKey);
    },
    expressionHeading() {
      return this.$t('mail_generation.subject.expressionHeading');
    },
  },
  methods: {
    handleOpen() {
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handleUpdate(newValue) {
      this.subject = newValue;
      this.dialogVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
.convo-subject-input {
  display: flex;
  align-items: center;

  .el-input {
    ::v-deep .el-input__inner {
      border: 1px solid $border-color;
      &::placeholder {
        font-style: italic;
      }
      &:focus {
        border-color: $primary-color;
      }
    }
  }

  .subject-label {
    margin: 8px 16px 8px 8px;
    color: $primary-color;
    font-weight: bold;
  }

  .convo-icon-placeholder {
    margin-left: -2px;
  }

  .mv-button {
    border: none;
    padding: 2px;
    color: $primary-color;
    font-size: 24px;
    margin-left: 4px;
    cursor: pointer;

    &.disabled {
      color: $disabled-color;
      cursor: not-allowed;
    }
  }
}
</style>
