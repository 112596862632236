<template>
  <div class="convo-user-avatar" :title="`${this.firstName} ${this.lastName}`">
    <div class="user-initials">{{ firstNameInitial }}{{ lastNameInitial }}</div>
  </div>
</template>

<script>
export default {
  name: 'ConvoUserAvatar',
  props: {
    firstName: {
      type: String,
      required: true,
    },
    lastName: {
      type: String,
      required: true,
    },
  },
  computed: {
    firstNameInitial() {
      return this.firstName.charAt(0).toUpperCase();
    },
    lastNameInitial() {
      return this.lastName.charAt(0).toUpperCase();
    },
  },
};
</script>

<style lang="scss" scoped>
.convo-user-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  height: 32px;
  width: 32px;
  background-color: #425d85;
  cursor: default;

  .user-initials {
    font-size: 14px;
    color: #fff;
  }
}

@media (max-width: 1280px) {
  .convo-user-avatar {
    height: 24px;
    width: 24px;

    .user-initials {
      font-size: 11px;
    }
  }
}
</style>
