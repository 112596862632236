<template>
  <div class="convo-left-sidepanel">
    <convo-toolbox class="toolbox" :is-draggable="isDraggable" />
  </div>
</template>

<script>
import ConvoToolbox from './ConvoToolbox.vue';

export default {
  name: 'ConvoLeftSidepanel',
  components: {
    ConvoToolbox,
  },
  props: {
    isDraggable: {
      default: true,
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.convo-left-sidepanel {
  border-right: 1px solid $global-layout-line-color;
  box-sizing: border-box;
  max-height: calc(100vh - #{$header-height});
  height: 100%;
  width: 100%;
}

@media (max-width: 1280px) {
  .convo-left-sidepanel {
    max-height: calc(100vh - #{$header-height-1280});
  }
}
</style>
