import and from './boolean/and.json';
import gt from './boolean/gt.json';
import negate from './boolean/negate.json';
import or from './boolean/or.json';
import now from './date/now.json';
import ifthenelse from './generic/if.json';
import concatlists from './list/concatlists.json';
import sum from './number/sum.json';
import concat from './string/concat.json';
import conststr from './string/conststr.json';
import constnum from './number/constnum.json';
import constbool from './boolean/constbool.json';
import mv from './generic/var.json';
import tostring from './string/to_string.json';

export default {
  sum,
  concat,
  negate,
  and,
  or,
  gt,
  concatlists,
  var: mv,
  if: ifthenelse,
  tostring,
  constnum,
  conststr,
  constbool,
  now,
};
