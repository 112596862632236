// eslint-disable-next-line import/prefer-default-export
export function resolveTenant() {
  if (window.location.hostname.split('.')[0].split('-').length === 1) {
    if (window.location.hostname.split('.')[0] === 'localhost' || window.location.hostname.split('.')[0] === 'convo-studio.local') {
      return 'convo';
    }
    return window.location.hostname.split('.')[0];
  }
  return window.location.hostname.split('-')[0];
}
