import RouterMixin from '@/common/mixins/RouterMixin';
import EventBus from '@/common/event-bus';
import AnalyticsService from '@/common/services/AnalyticsService';
import CpdModificationService from '@/common/services/CpdModificationService';
import ElementType from '@/common/enums/ElementType';

export default {
  mixins: [RouterMixin],
  methods: {
    handleChooseEvent(event) {
      let elementId = event.item.id;
      const index = elementId.indexOf('-') + 1;
      elementId = elementId.substring(index);
      this.navigateToInspectorPanel(elementId);
    },
    handleChangeEvent(event) {
      const clone = JSON.parse(JSON.stringify(this.children));

      if (event.added) {
        const newIndex = this.getNewIndex(event.added.element, event.added.newIndex, clone);
        clone.splice(newIndex, 0, event.added.element);
        this.navigateToInspectorPanel(event.added.element);
        const element = CpdModificationService.getElement(event.added.element);
        if (element) {
          AnalyticsService.commitUserAction('dragAndDrop', element.type);
        }
      }
      if (event.removed) {
        clone.splice(event.removed.oldIndex, 1);
      }
      if (event.moved) {
        clone.splice(event.moved.oldIndex, 1);
        const newIndex = this.getNewIndex(event.moved.element, event.moved.newIndex, clone);
        clone.splice(newIndex, 0, event.moved.element);
        this.navigateToInspectorPanel(event.moved.element);
      }

      this.children = clone;
    },
    handleDeletionEvent(componentId) {
      // TODO call this method only for elements which are in the store, workaround: this.$store.state[this.componentId || this.cpd]
      if (
        this &&
        this.$store.getters.componentById(this.componentId || this.uniqueCpdId) &&
        this.children &&
        this.children.includes(componentId)
      ) {
        this.children = CpdModificationService.getElementField(this.componentId || this.uniqueCpdId, 'children').filter(
          (child) => child !== componentId
        );
        this.clearInspectorPanel(componentId);
      }
    },
    getNewIndex(elementId, newIndex, listWithoutElement) {
      if (CpdModificationService.getElementField(elementId, 'type') === ElementType.MAIL) {
        // put mail generation to the end
        return listWithoutElement.length;
      }
      if (CpdModificationService.getElementField(listWithoutElement[newIndex - 1], 'type') === ElementType.MAIL) {
        // put element in front of mail generation
        return this.getNewIndex(elementId, newIndex - 1, listWithoutElement);
      }
      return newIndex;
    },
  },
  mounted() {
    EventBus.onElementDeletion(this.handleDeletionEvent);
  },
  beforeDestroy() {
    EventBus.onElementDeletion(this.handleDeletionEvent, true);
  },
};
