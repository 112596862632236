const MessageType = Object.freeze({
  INFO: 'enum.message_type.info',
  WARNING: 'enum.message_type.warning',
  ERROR: 'enum.message_type.error',
});

export default MessageType;

export function getShortKey(type) {
  switch (type) {
    case MessageType.INFO:
      return 'info';
    case MessageType.WARNING:
      return 'warning';
    case MessageType.ERROR:
      return 'error';
    default:
      return type;
  }
}
