<template>
  <el-dialog
    custom-class="convo-dialog"
    :closeOnClickModal="buttonClickNotRequired"
    :title="title"
    :visible.sync="dialogVisible"
    @close="handleButtonClick('close')"
  >
    <div class="content">
      <div v-html="content" />
      <div v-if="hasCheckbox" id="checkbox">
        <el-checkbox id="confirm-dialog-checkbox" @change="checkboxChanged">{{ checkboxContent }}</el-checkbox>
      </div>
    </div>
    <span class="footer">
      <el-button id="cancel-button" @click="handleButtonClick('cancel')">{{ cancelLabel }}</el-button>
      <el-button v-if="hasConfirmation" id="confirm-button" @click="handleButtonClick('confirm')" :disabled="needCheckboxCheck">
        {{ confirmLabel }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'ConvoDialog',
  props: {
    buttonClickNotRequired: {
      type: Boolean,
      default: true,
    },
    cancelLabel: {
      type: String,
      required: true,
    },
    checkboxContent: {
      type: String,
      required: false,
    },
    confirmLabel: {
      type: String,
      required: false,
    },
    content: {
      type: String,
      required: true,
    },
    hasCheckbox: {
      type: Boolean,
      default: false,
    },
    hasConfirmation: {
      type: Boolean,
      default: false,
    },
    isDialogVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialogVisible: this.isDialogVisible,
      needCheckboxCheck: this.hasCheckbox,
    };
  },
  watch: {
    isDialogVisible(newValue) {
      this.dialogVisible = newValue;
    },
  },
  methods: {
    handleButtonClick(type) {
      this.$emit(type);
      this.dialogVisible = false;
    },
    checkboxChanged(event) {
      this.needCheckboxCheck = !event;
    },
  },
};
</script>

<style lang="scss">
// overwriting of these css classes doesn't work in scoped css
.convo-dialog {
  .content {
    color: $primary-color;
    font-size: 16px;
    word-break: break-word;
    padding: 0 0 32px 0;

    .highlight {
      font-weight: 600;
      font-style: italic;
      color: $secondary-color;
    }

    #checkbox {
      display: flex;
      align-items: center;
      margin-top: 15px;

      .el-checkbox {
        align-items: center;
        display: flex;

        .el-checkbox__label {
          font-size: 16px;
          white-space: pre-wrap;
        }
      }
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 0;
    font-size: 16px;

    #confirm-button,
    #cancel-button {
      height: 40px;
      border: 2px solid $primary-color;
      border-radius: 8px;
      padding: 8px 16px;
      font-size: 16px;
      font-weight: 600;
    }

    #cancel-button {
      background-color: $primary-text-color;
      color: $primary-color;
      &:hover {
        background-color: $primary-text-color;
      }
    }
    #confirm-button {
      background-color: $primary-color;
      color: $primary-text-color;

      &:disabled {
        background-color: $disabled-color;
        border-color: $disabled-color;
        cursor: default;
      }
    }
  }
}

@media (max-width: 1280px) {
  .convo-dialog {
    .content {
      font-size: 13px;
      padding-bottom: 20px;

      #checkbox {
        margin-top: 10px;
        .el-checkbox .el-checkbox__label {
          font-size: 13px;
        }
      }
    }

    .footer {
      font-size: 13px;

      #confirm-button,
      #cancel-button {
        height: 32px;
        padding: 6px 12px;
        font-size: 13px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
::v-deep {
  .el-dialog {
    width: 520px;
    margin-top: 40vh !important;
    border-radius: 8px;
    padding: 24px;
  }

  .el-dialog__header {
    padding: 0 0 32px 0;
  }

  .el-dialog__title {
    font-size: 20px;
    color: $primary-color;
  }

  .el-dialog__close {
    color: $primary-color;
    font-size: 20px;
    font-weight: 600;
  }

  .el-dialog__body {
    padding: 0;
  }
}

@media (max-width: 1280px) {
  ::v-deep {
    .el-dialog {
      width: 420px;
      margin-top: 20vh !important;
      padding: 16px;
    }

    .el-dialog__header {
      padding-bottom: 20px;
    }

    .el-dialog__title,
    .el-dialog__close {
      font-size: 18px;
    }
  }
}
</style>
