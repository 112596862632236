import Vue from 'vue';

const EventBus = (() => {
  const bus = new Vue();
  const debug = false;
  const details = false;

  function emit(eventId, ...props) {
    if (debug) {
      console.group(`Emitting event ${eventId}`);
      if (details) {
        console.log(`Event has ${props.length} prop(s):`);
        props.forEach((prop) => console.log(`\t${JSON.stringify(prop, null, 1)}`));
      }
      console.groupEnd();
    }

    bus.$emit(eventId, ...props);
  }

  function on(eventId, appliedHandler, revoke, handler) {
    if (revoke) {
      if (debug) {
        console.group(`Revoking listener for event ${eventId}`);
        if (details) {
          console.log('Handler function stack is:');
          console.log(appliedHandler);
          console.log(handler);
        }
        console.groupEnd();
      }

      bus.$off(eventId, appliedHandler);
    } else {
      if (debug) {
        console.group(`Registering new listener for event ${eventId}`);
        if (details) {
          console.log('Handler function stack is:');
          console.log('\tApplied handler function:');
          console.log('\t' + appliedHandler);
          console.log('\tHandler function:');
          console.log('\t' + handler);
        }
        console.groupEnd();
      }

      bus.$on(eventId, appliedHandler);
    }
  }
  // TODO Refactor unnecessary lambda wrappers and resulting duplicate handler params
  return {
    emitCpdLoaded(cpd) {
      emit('CPD_LOADED', cpd);
    },

    onCpdLoaded(handler, revoke) {
      on('CPD_LOADED', handler, revoke, handler);
    },
    emitClearToasts() {
      emit('CLEAR_TOASTS');
    },
    onClearToasts(handler, revoke) {
      on('CLEAR_TOASTS', handler, revoke, handler);
    },
    emitDashboardSearchStringChange(searchString) {
      emit('DASHBOARD_SEARCH_STRING_CHANGE', searchString);
    },

    onDashboardSearchStringChange(handler, revoke) {
      on('DASHBOARD_SEARCH_STRING_CHANGE', handler, revoke, handler);
    },

    emitDraggableActivation(group, draggableId) {
      emit('DRAGGABLE_ACTIVATION', group, draggableId);
    },

    onDraggableActivation(handler, revoke) {
      on('DRAGGABLE_ACTIVATION', handler, revoke, handler);
    },

    emitElementDeletion(compontentId) {
      emit('DELETE_ELEMENT', compontentId);
    },

    onElementDeletion(handler, revoke) {
      on('DELETE_ELEMENT', (compontentId) => handler(compontentId), revoke, handler);
    },

    emitPreviewEnvironmentChange(width, height, key, device) {
      emit('PREVIEW_ENVIRONMENT_CHANGE', width, height, key, device);
    },

    onPreviewEnvironmentChange(handler, revoke) {
      on('PREVIEW_ENVIRONMENT_CHANGE', handler, revoke, handler);
    },

    emitSpinnerStart(spinnerName) {
      emit('SPINNER_START', spinnerName);
    },

    onSpinnerStart(handler, revoke) {
      on('SPINNER_START', handler, revoke, handler);
    },

    emitSpinnerStop(spinnerName) {
      emit('SPINNER_STOP', spinnerName);
    },

    onSpinnerStop(handler, revoke) {
      on('SPINNER_STOP', handler, revoke, handler);
    },

    emitToast(messageKey, warning) {
      emit('TOAST', messageKey, warning);
    },

    onToast(handler, revoke) {
      on('TOAST', handler, revoke, handler);
    },

    emitUnlockCpd() {
      emit('UNLOCK_CPD');
    },

    onUnlockCpd(handler, revoke) {
      on('UNLOCK_CPD', handler, revoke, handler);
    },
  };
})();

export default EventBus;
