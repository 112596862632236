<template>
  <div :class="['convo-attachment-select', { disabled }]">
    <div class="activator" ref="activator" :title="$t(tooltipKey)" @click="toggleActivator"><i :class="activatorIcon" /></div>
    <div class="options" v-show="optionsVisible">
      <div v-if="options.length === 0" class="placeholder">{{ $t('mail_generation.mailConfigurationDialog.noAttachmentOptions') }}</div>
      <div v-for="(option, index) in options" :key="index" class="option" @click="selectOption(option)">{{ option.name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConvoAttachmentSelect',
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    activatorIcon: {
      type: String,
      default: 'convo-icon-attachment',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tooltipKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      optionsVisible: false,
    };
  },
  methods: {
    toggleActivator() {
      if (!this.disabled) {
        if (this.optionsVisible) {
          this.closeOptions();
        } else {
          this.openOptions();
        }
      }
    },
    selectOption(option) {
      if (!this.disabled) {
        this.$emit('select', option);
        this.optionsVisible = false;
      }
    },
    openOptions() {
      this.optionsVisible = true;
      document.addEventListener('click', this.closeOptions);
    },
    closeOptions(event) {
      if (!event || (event.target !== this.$refs.activator && event.target.parentElement !== this.$refs.activator)) {
        this.optionsVisible = false;
        document.removeEventListener('click', this.closeOptions);
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeOptions);
  },
};
</script>

<style scoped lang="scss">
.convo-attachment-select {
  .activator {
    position: relative;
    .convo-icon-pdf {
      margin: 0 6px;
      font-size: 20px;
      cursor: pointer;
    }
    .convo-icon-attachment {
      margin: 0 6px;
      font-size: 18px;
      cursor: pointer;
    }
  }
  .options {
    position: absolute;
    background-color: #fff;
    padding: 4px;
    box-shadow: 3px 3px 9px $box-shadow-color;
    border-radius: 8px;
    z-index: 999;

    .placeholder {
      margin: 4px;
      padding: 4px;
      color: $disabled-color;
      font-style: italic;
    }

    .option {
      max-width: 248px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: 4px;
      padding: 4px;
      cursor: pointer;
      border-radius: 4px;

      &:hover {
        background-color: $convo-blue-2;
      }
    }
  }

  &.disabled {
    .convo-icon-pdf,
    .convo-icon-attachment {
      color: $disabled-color;
      cursor: not-allowed;
    }
  }
}
</style>
