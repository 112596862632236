import axios from 'axios';
import store from '@/common/store';
import EventBus from '@/common/event-bus';

const PdfService = (() => {
  const endpoint = (process.env.VUE_APP_BACKEND || window.backend) + '/v1/pdf';

  function startSpinner(spinnerName) {
    EventBus.emitSpinnerStart(spinnerName);
  }

  function stopSpinner(spinnerName) {
    EventBus.emitSpinnerStop(spinnerName);
  }

  return {
    async uploadAndGetFields(cpdId, version, pdf, spinnerName) {
      startSpinner(spinnerName);
      const formData = new FormData();
      formData.append('pdfTemplate', pdf);
      formData.append('cpdId', cpdId);
      formData.append('version', version);

      return axios
        .post(endpoint, formData, {
          headers: {
            Authorization: `Bearer ${store.getters['oidc/oidcAccessToken']}`,
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => response.data)
        .finally(() => stopSpinner(spinnerName));
    },
  };
})();

export default PdfService;
