<template>
  <div class="convo-number-input convo-ip-input">
    <el-form-item :prop="fieldKey" :rules="[{ validator, trigger }]">
      <!-- prop is required to ensure validation, but is not used -->
      <el-popover
        ref="popover-label"
        :title="localizedTooltipTitle"
        trigger="hover"
        :content="localizedTooltipText"
        placement="left-start"
        width="320"
        :open-delay="800"
      />
      <span class="no-tabindex" slot="label" v-popover:popover-label>{{ localizedLabel }}</span>
      <el-popover
        ref="popover-disabled"
        :key="disabledTooltipTrigger"
        :trigger="disabledTooltipTrigger"
        :content="disabledTooltipText"
        placement="bottom-start"
        width="320"
        :open-delay="800"
      />
      <el-input-number
        v-if="celDisabled || !isConvoExpressionLanguage"
        v-model="value"
        :id="`${fieldKey}-input-${parentId}`"
        :disabled="isDisabled || !editable"
        v-popover:popover-disabled
        :key="`input-${isDisabled ? 'disabled' : 'active'}`"
      />
      <template v-else>
        <el-popover ref="popover-cel" trigger="hover" width="480" placement="bottom-start" :open-delay="800">
          <convo-expression
            :key="dialogVisible"
            disabled
            :expression-id="expression"
            :heading="expressionHeading"
            :required-input-type="CelReturnType.NUMBER"
          />
        </el-popover>
        <span v-popover:popover-cel :id="`expression-language-placeholder-${fieldKey}`" class="active-mv">
          <i v-for="i in 4" :key="i" class="convo-icon-placeholder" />
        </span>
      </template>
      <el-button
        v-if="!celDisabled"
        :class="`expression-language-dialog-button-${fieldKey}`"
        @click="handleOpen"
        size="mini"
        class="mv-button"
        :disabled="isDisabled"
        tabindex="-1"
      >
        <i v-if="isConvoExpressionLanguage" class="convo-icon-function-circle-active" />
        <i v-else class="convo-icon-function-circle-default" />
      </el-button>
    </el-form-item>
    <convo-expression-language-dialog
      v-if="!celDisabled"
      :visible="dialogVisible"
      :value="value"
      :type="CelReturnType.NUMBER"
      @update="value = $event"
      @close="handleClose"
      :parent-id="parentId"
      :field-key="fieldKey"
      :expression-heading="expressionHeading"
    />
  </div>
</template>

<script>
import ConvoInputMixin from '@/studio/mixins/ConvoInputMixin';
import ConvoInputI18nMixin from '@/studio/mixins/ConvoInputI18nMixin';
import ValidationMixin from '@/studio/mixins/ValidationMixin';
import DisabledMixin from '@/studio/mixins/DisabledMixin';
import CelReturnType from '@/common/enums/CelReturnType';

export default {
  name: 'ConvoNumberInput',
  mixins: [ConvoInputMixin, DisabledMixin, ConvoInputI18nMixin, ValidationMixin],
  props: {
    celDisabled: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      CelReturnType,
    };
  },
  methods: {
    validator(rule, value, callback) {
      this.validate(
        this.mandatory,
        (evaluated) => evaluated && !this.value,
        () => this.$t('validationError.mandatory'),
        callback
      );
      this.validate(
        this.min,
        (evaluated) => this.value < evaluated,
        (evaluated) => this.$t('validationError.minValue', [evaluated]),
        callback
      );
      this.validate(
        this.max,
        (evaluated) => this.value > evaluated,
        (evaluated) => this.$t('validationError.maxValue', [evaluated]),
        callback
      );
      callback();
    },
  },
};
</script>

<style lang="scss">
@import '@/studio/styles/inputs.scss';
.convo-number-input {
  .el-input-number {
    input {
      font-family: $primary-font;
      color: $primary-color;
    }
  }
}
</style>
