import { uuid } from 'vue-uuid';
import Vue from 'vue';

const getDefaultState = () => ({
  sessionId: uuid.v4(),
  analyticsEvents: [],
});

export default function analytics() {
  const initialState = getDefaultState();

  const getters = {
    sessionId: (state) => state.sessionId,
    analyticsEvents: (state) => state.analyticsEvents,
  };

  const mutations = {
    addAnalyticsEvent(state, { type, name, parameter, userAgent }) {
      state.analyticsEvents.push({
        type,
        time: new Date(),
        sessionId: state.sessionId,
        userAgent,
        name,
        parameter,
      });
    },
    clearEvents(state) {
      Vue.set(state, 'analyticsEvents', []);
    },
  };

  return {
    state: initialState,
    getters,
    mutations,
    namespaced: true,
  };
}
