<template>
  <el-form class="convo-boolean-input convo-ip-input">
    <el-form-item>
      <template v-if="showToggle">
        <el-popover
          ref="popover-disabled"
          :key="disabledTooltipTrigger"
          :trigger="disabledTooltipTrigger"
          :content="disabledTooltipText"
          placement="bottom-start"
          width="320"
          :open-delay="800"
        />
        <el-switch
          v-if="celDisabled || !isConvoExpressionLanguage"
          :id="`${fieldKey}-input-${parentId}`"
          v-model="value"
          :disabled="isDisabled || !editable"
          v-popover:popover-disabled
          :key="`input-${isDisabled ? 'disabled' : 'active'}`"
        />
        <template v-else>
          <el-popover ref="popover-cel" trigger="hover" placement="bottom-start" width="480" :open-delay="800">
            <convo-expression
              :key="dialogVisible"
              disabled
              :expression-id="expression"
              :heading="expressionHeading"
              :required-input-type="CelReturnType.BOOLEAN"
            />
          </el-popover>
          <span v-popover:popover-cel :id="`expression-language-placeholder-${fieldKey}`" class="active-mv">
            <i v-for="i in 2" :key="i" class="convo-icon-placeholder" />
          </span>
        </template>
      </template>
      <el-popover
        ref="popover-label"
        :title="localizedTooltipTitle"
        trigger="hover"
        :content="localizedTooltipText"
        placement="bottom-start"
        width="320"
        :open-delay="800"
      />
      <span v-popover:popover-label class="convo-boolean-input-label no-tabindex">{{ localizedLabel }}</span>
      <el-button
        v-if="!celDisabled"
        :class="`expression-language-dialog-button-${fieldKey}`"
        @click="handleOpen"
        size="mini"
        :disabled="isDisabled"
        class="mv-button"
        tabindex="-1"
      >
        <i v-if="isConvoExpressionLanguage" class="convo-icon-function-circle-active" />
        <i v-else class="convo-icon-function-circle-default" />
      </el-button>
    </el-form-item>
    <convo-expression-language-dialog
      v-if="!celDisabled"
      :visible="dialogVisible"
      :value="value"
      :type="CelReturnType.BOOLEAN"
      @update="value = $event || defaultValue"
      @close="handleClose"
      :parentId="parentId"
      :field-key="fieldKey"
      :expression-heading="expressionHeading"
    />
  </el-form>
</template>

<script>
import ConvoInputMixin from '@/studio/mixins/ConvoInputMixin';
import ConvoInputI18nMixin from '@/studio/mixins/ConvoInputI18nMixin';
import DisabledMixin from '@/studio/mixins/DisabledMixin';
import CelReturnType from '@/common/enums/CelReturnType';
import ConvoProcessComponentService from '@/common/services/ConvoProcessComponentService';
import CpdModificationService from '@/common/services/CpdModificationService';

export default {
  name: 'ConvoBooleanInput',
  mixins: [ConvoInputMixin, DisabledMixin, ConvoInputI18nMixin],
  props: {
    celDisabled: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    showToggle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      CelReturnType,
    };
  },
  computed: {
    defaultValue() {
      const elementType = CpdModificationService.getElementField(this.parentId, 'type');
      return ConvoProcessComponentService.getDefaultValue(elementType, this.fieldKey);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/studio/styles/inputs.scss';
.convo-boolean-input {
  ::v-deep.el-switch {
    height: inherit;
    margin-right: 8px;
  }
  .active-mv {
    margin-right: 8px;
  }

  .convo-boolean-input-label {
    font-size: 16px;
    color: $primary-color;
    font-weight: 300;
    cursor: help;
  }
}
@media (max-width: 1400px) {
  .convo-boolean-input {
    .convo-boolean-input-label {
      font-size: 14px;
    }
  }
}

@media (max-width: 1280px) {
  .convo-boolean-input {
    .convo-boolean-input-label {
      font-size: 12px;
    }

    ::v-deep.el-switch {
      margin-right: 4px;
      transform: scale(0.75);
    }
  }
}
</style>
