import { uuid } from 'vue-uuid';

const inDev = false;
let inDevId = 10;

const CpdIdService = (() => {
  return {
    /**
     * Generates an unique ID according to the CPD specifications.
     * This method is centralized in a service to make it easier to centrally
     * switch the ID generation strategy later on for easier debugging.
     *
     * @returns The generated ID
     */
    getId() {
      if (inDev) {
        inDevId += 1;
        return String(inDevId);
      }
      return uuid.v4();
    },
  };
})();

export default CpdIdService;
