<template>
  <div class="convo-not-found-page">
    <div class="upper-container">
      <div class="logo" @click="navigateHome">CONVO</div>
      <button class="go-back-button" @click="backwards"><i class="convo-icon-backward" />{{ $t('notFoundPage.back') }}</button>
    </div>
    <div class="main-container">
      <div class="not-found-message">
        <div class="message-header">{{ $t('notFoundPage.sorry') }}</div>
        <div class="message-text">{{ $t('notFoundPage.message') }}</div>
        <button class="message-button" @click="navigateHome">{{ $t('notFoundPage.backToHome') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import RouterMixin from '@/common/mixins/RouterMixin';

export default {
  name: 'ConvoNotFoundPage',
  mixins: [RouterMixin],
  data() {
    return {
      prevRoute: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === 'oidcCallback') {
      next({ name: 'dashboard' });
    } else {
      next((vm) => {
        // eslint-disable-next-line no-param-reassign
        vm.prevRoute = from;
      });
    }
  },
  methods: {
    backwards() {
      if (!this.prevRoute || !this.prevRoute.name || this.prevRoute.name === 'oidcCallback') {
        this.navigateHome();
      } else {
        this.navigateBackwards();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$upper-container-height: 200px;

.convo-not-found-page {
  height: 100vh;
  min-width: $app-min-width;
  width: 100vw;

  .upper-container {
    height: $upper-container-height;
    padding: 40px;

    .logo {
      color: $primary-text-color;
      cursor: pointer;
      font-family: $font-logo;
      font-size: 64px;
      margin-bottom: 24px;
    }
    .go-back-button {
      align-items: center;
      background-color: $primary-color;
      border: 1px solid $primary-text-color;
      border-radius: 999px;
      color: $primary-text-color;
      cursor: pointer;
      display: flex;
      font-size: 24px;
      outline: none;
      padding: 16px 64px 16px 24px;

      .convo-icon-backward {
        margin-right: 40px;
      }
    }
  }

  .main-container {
    align-items: center;
    display: flex;
    height: calc(100% - #{$upper-container-height});
    margin-left: 50%;
    max-width: 560px;

    .message-header {
      color: $secondary-color;
      font-size: 40px;
      font-weight: 600;
      margin-bottom: 32px;
      text-transform: uppercase;
    }
    .message-text {
      color: $primary-text-color;
      font-size: 32px;
      margin-bottom: 64px;
    }
    .message-button {
      background-color: $secondary-color;
      border: none;
      border-radius: 999px;
      color: $primary-text-color;
      cursor: pointer;
      font-size: 24px;
      outline: none;
      padding: 20px 64px;
    }
  }

  &::after {
    align-items: center;
    background-color: $primary-color;
    color: $primary-lightened-color;
    content: '404';
    display: flex;
    font-size: 40vw;
    height: 100vh;
    justify-content: center;
    min-width: $app-min-width;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: -1;
  }
}
</style>
