/**
 * JavaScript setTimeout extended with pause and resume functionality.
 */
export default function Timer(callback, delay) {
  let timer;
  let start;
  let remaining = delay;

  this.pause = () => {
    clearTimeout(timer);
    remaining -= new Date() - start;
  };

  this.resume = () => {
    start = new Date();
    clearTimeout(timer);
    timer = setTimeout(callback, remaining);
  };

  this.resume();
}
