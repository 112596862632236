<template>
  <div class="convo-landing-page">
    <div>
      <div class="logo">{{ $t('CONVO') }}</div>
      <div class="login" id="convo-login-button" @click="signIn">{{ $t('landingPage.signIn') }}</div>
      <div class="loading" v-loading="loading"></div>
      <div class="error" :id="`error-${errorKey}`" v-if="showError">{{ $t(`landingPage.error.${errorKey}`) }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ConvoLandingPage',
  data() {
    return {
      loading: false,
      showError: false,
      errorKey: '',
    };
  },
  computed: {
    ...mapGetters('oidc', ['oidcIsAuthenticated']),
  },
  mounted() {
    if (this.oidcIsAuthenticated) {
      this.$router.push('/dashboard');
    }
    if (this.$route.query.error === '401') {
      this.errorKey = 'unauthorized';
      this.showError = true;
    }
    if (this.$route.query.error === '403') {
      this.showError = true;
      this.errorKey = 'forbidden';
    }
  },
  methods: {
    signIn() {
      this.$router.push('/dashboard');
      this.loading = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.convo-landing-page {
  background-color: $primary-color;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .logo {
    text-align: center;
    font-size: 100px;
    font-family: $font-logo;
    color: $primary-text-color;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .login {
    text-align: center;
    color: white;
    font-size: 20px;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .loading {
    width: 400px;
    height: 75px;
  }

  ::v-deep .loading {
    .el-loading-mask {
      background-color: transparent;

      .el-loading-spinner .circular .path {
        stroke: white;
      }
    }
  }

  .error {
    text-align: center;
    color: lightcoral;
    font-size: 20px;
  }
}
</style>
