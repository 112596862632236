<template>
  <draggable
    class="convo-expression-language-draggable"
    :class="{ filled: !dragging && elements.length === 1, inactive: !active, toolbox }"
    :clone="clone"
    :disabled="disabled || !active || (!toolbox && elements.length === 1)"
    drag-class="moving-element"
    :force-fallback="true"
    ghost-class="ghost-element"
    :group="{
      name: group,
      pull: toolbox ? 'clone' : false,
      put: !toolbox && elements.length < 1 && (group === CelReturnType.GENERIC || [group, CelReturnType.GENERIC]),
    }"
    :list="elements"
    :sort="false"
    @add="handleAdd"
  >
    <template v-for="(element, index) in elements">
      <convo-toolbox-element-value v-if="toolbox && elementValue" :key="index" :mv-id="element.id" :name="element.name" />
      <convo-toolbox-function
        v-else-if="toolbox && convoFunction"
        :key="index"
        :operator="element.operator"
        :generic="convoGenericFunction"
      />
      <convo-toolbox-constant v-else-if="toolbox && convoConstant" :key="index" :type="element.returnType" />
      <convo-element-value
        v-else-if="is(element, CelExpressionType.MV)"
        :key="element"
        :id="element"
        :disabled="disabled"
        @delete="$emit('delete', element)"
      />
      <convo-constant
        v-else-if="is(element, CelExpressionType.CONSTANT)"
        :key="element"
        :id="element"
        :disabled="disabled"
        @delete="$emit('delete', element)"
      />
      <convo-function-placeholder
        v-else
        :key="element"
        :id="element"
        :disabled="disabled"
        @open-function="$emit('open-function')"
        @delete="$emit('delete', element)"
      />
    </template>
    <div v-if="!toolbox && elements.length === 0" class="dropzone-placeholder" :class="{ inactive: !active }" @click="activate">
      <i class="convo-icon-drag-and-drop" />
      <div>{{ $t('magicVariablesDialog.dropzonePlaceholder') }}</div>
    </div>
  </draggable>
</template>

<script>
import draggable from 'vuedraggable';
import EventBus from '@/common/event-bus';
import CelReturnType from '@/common/enums/CelReturnType';
import CelExpressionType from '@/common/enums/CelExpressionType';
import ConvoToolboxElementValue from '@/studio/components/right_sidepanel/inspector_panel/convo_expression_language_dialog/left_sidepanel/ConvoToolboxElementValue.vue';
import ConvoToolboxFunction from '@/studio/components/right_sidepanel/inspector_panel/convo_expression_language_dialog/right_sidepanel/ConvoToolboxFunction.vue';
import ConvoToolboxConstant from '@/studio/components/right_sidepanel/inspector_panel/convo_expression_language_dialog/right_sidepanel/ConvoToolboxConstant.vue';
import ConvoElementValue from '@/studio/components/right_sidepanel/inspector_panel/convo_expression_language_dialog/expression/ConvoElementValue.vue';
import ConvoFunctionPlaceholder from '@/studio/components/right_sidepanel/inspector_panel/convo_expression_language_dialog/expression/ConvoFunctionPlaceholder.vue';
import ConvoConstant from '@/studio/components/right_sidepanel/inspector_panel/convo_expression_language_dialog/expression/ConvoConstant.vue';
import { uuid } from 'vue-uuid';
import ConvoExpressionLanguageService from '@/common/services/ConvoExpressionLanguageService';

export default {
  name: 'ConvoExpressionLanguageDraggable',
  components: {
    ConvoConstant,
    ConvoFunctionPlaceholder,
    ConvoElementValue,
    ConvoToolboxConstant,
    ConvoToolboxFunction,
    ConvoToolboxElementValue,
    draggable,
  },
  props: {
    elements: {
      type: Array,
      required: true,
    },
    toolbox: {
      type: Boolean,
      default: false,
    },
    elementValue: {
      type: Boolean,
      default: false,
    },
    convoFunction: {
      type: Boolean,
      default: false,
    },
    convoGenericFunction: {
      type: Boolean,
      default: false,
    },
    convoConstant: {
      type: Boolean,
      default: false,
    },
    group: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    draggableId: {
      type: String,
      default: uuid.v4(),
    },
  },
  data() {
    return {
      dragging: false,
      active: this.toolbox,
      CelReturnType,
      CelExpressionType,
    };
  },
  methods: {
    clone(element) {
      return ConvoExpressionLanguageService.createNewExpression(element);
    },
    activate() {
      this.active = true;
      EventBus.emitDraggableActivation(this.group, this.draggableId);
    },
    deactivate(group, draggableId) {
      if (this.draggableId !== draggableId) {
        this.active = this.toolbox;
      } else {
        this.active = true;
      }
    },
    is(id, expressionType) {
      return ConvoExpressionLanguageService.getExpressionField(id, 'type') === expressionType;
    },
    handleAdd() {
      if (this.elements.length > 0 && this.is(this.elements[0], CelExpressionType.FUNCTION)) {
        this.$emit('open-function');
      }
    },
  },
  mounted() {
    EventBus.onDraggableActivation(this.deactivate);
  },
  beforeDestroy() {
    EventBus.onDraggableActivation(this.deactivate, true);
  },
};
</script>

<style lang="scss" scoped>
.convo-expression-language-draggable {
  background-color: white;
  border: 3px dotted $primary-color;
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 66px;
  position: relative;

  &.filled {
    border: 1.5px dotted $convo-blue-5;
    padding: 1px;
  }

  &.inactive {
    border-color: $convo-blue-4;
  }

  &.toolbox {
    border: 0;
    min-height: 35px;
  }

  .dropzone-placeholder {
    align-items: center;
    box-sizing: border-box;
    color: $primary-color;
    display: flex;
    font-style: italic;
    height: 100%;
    justify-content: center;
    padding: 12px 24px;
    word-break: break-word;
    position: absolute;
    top: 0;

    .convo-icon-drag-and-drop {
      font-size: 32px;
      margin-right: 24px;
    }

    &.inactive {
      color: $convo-blue-4;
      cursor: pointer;
    }
  }

  .moving-element {
    background-color: $convo-moving-blue;
    border: none;
    box-shadow: none;
    color: $primary-text-color;
    opacity: 1 !important;
    ::v-deep.end {
      border: none;
    }
  }

  .ghost-element {
    background-color: $convo-ghost-blue;
    border: none;
    box-shadow: none;
    color: $primary-text-color;
    opacity: 1 !important;
    ::v-deep.end {
      border: none;
    }
  }

  .dropzone-placeholder:not(:only-child) {
    display: none;
  }
}

@media (max-width: 1440px) {
  .convo-expression-language-draggable > .dropzone-placeholder {
    font-size: 12px;
  }
}
@media (max-width: 1280px) {
  .convo-expression-language-draggable {
    min-height: 56px;

    .dropzone-placeholder {
      font-size: 10px;
    }
  }
}
</style>
