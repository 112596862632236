<template>
  <el-popover :class="{ 'popover-visible': popoverVisible }" placement="top" trigger="manual" v-model="popoverVisible" width="220">
    <p>{{ $t('trashbin.dialogMessage') }}</p>
    <div style="text-align: right; margin: 0">
      <el-button size="mini" type="text" @click.stop="popoverVisible = false">
        {{ $t('trashbin.cancel') }}
      </el-button>
      <el-button type="primary" size="mini" @click.stop="trashworker">
        {{ $t('trashbin.confirm') }}
      </el-button>
    </div>
    <el-button
      slot="reference"
      :id="`trashbin-activator-${componentId}`"
      :class="['convo-trashbin-activator', { dark }]"
      icon="convo-icon-delete"
      @click.stop="handleClick"
      @keypress.enter.native="handleClick"
      @blur.native="popoverVisible = false"
    >
    </el-button>
  </el-popover>
</template>

<script>
import CpdModificationService from '@/common/services/CpdModificationService';
import RouterMixin from '@/common/mixins/RouterMixin';
import AnalyticsService from '@/common/services/AnalyticsService';
import EventBus from '@/common/event-bus';
import MailConfigModificationService from '@/common/services/MailConfigModificationService';
import ElementType from '@/common/enums/ElementType';

export default {
  name: 'ConvoTrashbin',
  mixins: [RouterMixin],
  data() {
    return {
      popoverVisible: this.deletionRequested,
    };
  },
  props: {
    componentId: {
      required: true,
      type: String,
    },
    deletionRequested: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    deletionRequested(requested) {
      if (requested) {
        this.popoverVisible = true;
        this.$emit('deletion-request-processed');
      }
    },
  },
  methods: {
    trashworker() {
      const type = CpdModificationService.getElementField(this.componentId, 'type');
      CpdModificationService.deleteElement(this.componentId);
      if (type === ElementType.MAIL) {
        MailConfigModificationService.deleteMailConfig(this.componentId);
      }
      EventBus.emitToast('toast.elementDeleted');
      this.popoverVisible = false;
      AnalyticsService.commitUserAction('deletion', type);
    },
    handleClick() {
      this.popoverVisible = !this.popoverVisible;
    },
  },
  mounted() {
    window.addEventListener('keyup', (event) => {
      if (event.keyCode === 13 && this.popoverVisible) {
        this.trashworker();
      }
    });
    window.addEventListener('keyup', (event) => {
      if (event.keyCode === 27 && this.popoverVisible) {
        this.popoverVisible = false;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.convo-trashbin-activator {
  background: inherit;
  border-color: transparent;
  padding: 0 !important;
  font-size: 16px;
  color: #fff;
  &.dark {
    color: $primary-color;
  }

  &:hover {
    background: inherit;
    color: $secondary-color;
    border-color: transparent;
  }
  &:focus {
    background: inherit;
    color: $secondary-color;
    border-color: transparent;
  }
}
.popover-visible {
  .convo-trashbin-activator {
    visibility: visible !important;
  }
}
</style>
