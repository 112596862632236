<template>
  <div class="convo-chat-input-area">
    <div v-if="!isAnswerTextInput" class="chat-answers-div">
      <div v-for="(chatDataOption, index) in chatData" :key="index">
        <p class="chat-answers-format" @click="selectedInput(chatDataOption.option, chatDataOption.next, chatDataOption.optionValue)">
          {{ $t(chatDataOption.option) }}
        </p>
      </div>
    </div>
    <div v-if="isAnswerTextInput && !isFinished" class="answer-input">
      <div class="input-button-container">
        <input
          v-if="!isTextareaInput"
          ref="newMessageArea"
          class="new-message-box"
          :placeholder="[[$t(chatData[0])]]"
          v-model.trim="newMessage"
          @keyup.enter.prevent="addMessage"
        />
        <textarea
          v-else
          ref="newMessageArea"
          class="new-message-box"
          maxlength="1024"
          :placeholder="[[$t(chatData[0])]]"
          v-model.trim="newMessage"
          @keyup.enter.prevent="addMessage"
        />
        <button :disabled="!validateData()" class="button-new-message" @click="addMessage">
          <i class="convo-icon-submit-arrow" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
const nameAccepted = /^[\w'\-,.][^0-9_.,!¡?÷¿/\\+=@#$%ˆ&§"°*(){}|~<>;:[\]]{2,128}$/;
const emailAccepted = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneAccepted = /^[+]?[0-9]{7,17}$/;

export default {
  name: 'ConvoChatInputArea',
  data() {
    return {
      newMessage: null,
    };
  },
  props: {
    chatData: Array,
    isAnswerTextInput: Boolean,
    isFinished: Boolean,
    isTextareaInput: Boolean,
  },

  methods: {
    addMessage() {
      if (this.validateData()) {
        this.$emit('answer', this.newMessage);
        this.newMessage = '';
      }
    },
    selectedInput(answer, option, optionValue) {
      this.$emit('selected-answer-and-next', { selectedAnswer: answer, optionNext: option, optionValue });
    },
    validateData() {
      if (this.newMessage === null || this.newMessage === '') {
        return false;
      }
      if (this.chatData[1] === 'text') {
        if (!nameAccepted.test(this.newMessage)) {
          return false;
        }
      }
      if (this.chatData[1] === 'email') {
        if (!emailAccepted.test(this.newMessage) || this.newMessage.length > 128) {
          return false;
        }
      }
      if (this.chatData[1] === 'number') {
        if (!phoneAccepted.test(this.newMessage)) {
          return false;
        }
      }
      return true;
    },
  },
  updated() {
    if (this.$refs.newMessageArea) {
      this.$nextTick(() => this.$refs.newMessageArea.focus());
    }
  },
};
</script>

<style lang="scss" scoped>
.convo-chat-input-area {
  .answer-input {
    //LAYOUT
    padding: 8px;

    .input-button-container {
      //LAYOUT
      align-items: center;
      background-color: $primary-text-color;
      border-radius: 10px;
      display: flex;

      .button-new-message {
        cursor: pointer;
        background-color: white;
        //LAYOUT
        border: none;
      }

      .button-new-message:disabled {
        cursor: not-allowed;
        //LAYOUT
        opacity: 0.3;
      }

      .convo-icon-submit-arrow {
        font-size: 24px;
      }

      .new-message-box {
        //FONT
        font-family: $primary-font;
        font-size: $chat-answers-box-font-size;
        //LAYOUT
        border: none;
        border-radius: 10px;
        height: 40px;
        width: 300px;
      }

      .new-message-box:focus,
      textarea:focus {
        outline: none;
      }

      textarea {
        //LAYOUT
        max-height: $chat-body-height / 2;
        min-height: 104px;
        //maxlength: 1024 (given in tag)
        overflow: auto;
        resize: none;
      }
    }
  }

  .chat-answers-div {
    //LAYOUT
    margin: 8px 8px;
    padding-left: 8px;

    .chat-answers-format {
      cursor: pointer;
      //FONT
      font-family: $primary-font;
      font-size: $chat-answers-box-font-size;
      //COLOR
      background: rgba(255, 249, 249, 1);
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
      color: $primary-color;
      //LAYOUT
      border-radius: 10px;
      margin-bottom: 8px;
      margin-top: 8px;
      padding: 8px;
    }
  }
}

@media (max-width: 1280px) {
  .convo-chat-input-area {
    .answer-input {
      .input-button-container {
        .new-message-box {
          font-size: 10px;
        }
      }
    }
    .chat-answers-div {
      .chat-answers-format {
        font-size: 10px;
      }
    }
  }
}
</style>
