<template>
  <div class="convo-header">
    <div class="left">
      <div class="logo" @click="handleNavigateToDashboard">CONVO</div>
      <span v-if="isDemo" class="demo-logo">Demo</span>
    </div>

    <div class="center">
      <component :is="headerSlot" />
    </div>

    <div class="right">
      <convo-options-select />
      <convo-language-select />
      <div id="logged-in-user" class="signed-in-user" v-if="oidcUser">
        <convo-user-avatar :first-name="oidcUser.given_name" :last-name="oidcUser.family_name" />
        <span class="user-mail" :title="oidcUser.email">{{ oidcUser.email }}</span>
      </div>
      <button id="sign-out" @click="signOut"><i class="convo-icon-logout" /></button>
    </div>
    <convo-confirmation-dialog
      v-if="!isDemo"
      :visible="showLeavingConfirmDialog"
      :title="$t('leavingConfirmationDialog.title')"
      :content="$t('leavingConfirmationDialog.content')"
      :button-click-not-required="true"
      :confirm-label="$t('leavingConfirmationDialog.leaveWithSave')"
      :cancel-label="$t('leavingConfirmationDialog.leaveWithoutSave')"
      @confirm="leaveWithSave"
      @cancel="leaveWithoutSave"
      @close="showLeavingConfirmDialog = false"
    />
  </div>
</template>

<script>
import CountryFlag from 'vue-country-flag';
import { mapActions, mapGetters } from 'vuex';
import RouterMixin from '@/common/mixins/RouterMixin';
import LeaveConfirmationMixin from '@/common/mixins/LeaveConfirmationMixin';
import ConvoUserAvatar from '@/common/components/ConvoUserAvatar.vue';
import ConvoLanguageSelect from '@/app/components/ConvoLanguageSelect.vue';
import ConvoOptionsSelect from '@/app/components/ConvoOptionsSelect.vue';
import ConvoHeaderSlotEmpty from '@/app/components/ConvoHeaderSlotEmpty.vue';
import ConvoHeaderSlotStudio from '@/studio/components/header/ConvoHeaderSlotStudio.vue';
import ConvoHeaderSlotEditorialView from '@/editorial_view/components/ConvoHeaderSlotEditorialView.vue';
import ConvoHeaderSlotDashboard from '@/dashboard/components/header/ConvoHeaderSlotDashboard.vue';
import ConvoHeaderSlotPreview from '@/preview/components/header/ConvoHeaderSlotPreview.vue';
import EventBus from '@/common/event-bus';

export default {
  name: 'ConvoHeader',
  components: {
    ConvoLanguageSelect,
    ConvoOptionsSelect,
    ConvoHeaderSlotDashboard,
    ConvoUserAvatar,
    ConvoHeaderSlotStudio,
    ConvoHeaderSlotEditorialView,
    ConvoHeaderSlotPreview,
    CountryFlag,
  },
  mixins: [RouterMixin, LeaveConfirmationMixin],

  computed: {
    ...mapGetters('oidc', ['oidcUser']),
    isDemo() {
      return this.oidcUser ? this.oidcUser.realm_access.roles.includes('demo-access') : false;
    },
    headerSlot() {
      if (this.isEditorialView) {
        return ConvoHeaderSlotEditorialView;
      }
      if (this.isStudioEditor) {
        return ConvoHeaderSlotStudio;
      }
      if (this.isDashboard) {
        return ConvoHeaderSlotDashboard;
      }
      if (this.isPreview) {
        return ConvoHeaderSlotPreview;
      }
      return ConvoHeaderSlotEmpty;
    },
  },
  created() {
    window.addEventListener(
      'beforeunload',
      function emitUnlock() {
        EventBus.emitUnlockCpd();
      },
      false
    );
  },
  methods: {
    ...mapActions('oidc', ['signOutOidc']),
    signOut() {
      EventBus.emitUnlockCpd();
      this.signOutOidc({ post_logout_redirect_uri: `${window.location.origin}/start` });
    },
  },
};
</script>

<style lang="scss" scoped>
$center-width: 500px;
.convo-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: $header-height;
  background-color: $primary-color;

  .left {
    display: flex;
    align-items: center;

    .logo {
      font-family: $font-logo;
      font-size: 36px;
      color: $primary-text-color;
      cursor: pointer;
      margin-left: 16px;
    }
    .demo-logo {
      font-family: $font-logo;
      font-size: 20px;
      color: $chat-header-demo-text;
      padding-left: 8px;
      padding-top: 12px;
    }
  }

  .right {
    display: flex;
    align-items: center;

    .convo-options-select {
      margin-right: 6px;
    }

    .options-select {
      display: flex;
      align-items: center;
      color: $primary-text-color;
      margin-right: 16px;
    }

    .language-select {
      display: flex;
      align-items: center;
      color: $primary-text-color;
    }

    .signed-in-user {
      margin-left: 16px;
      display: flex;
      align-items: center;
      .user-mail {
        color: $primary-text-color;
        margin-left: 8px;
        font-size: 14px;
        max-width: calc(50vw - 230px - #{$center-width}/ 2);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    #sign-out {
      margin-right: 4px;
      margin-left: 8px;
      background-color: transparent;
      border: none;
      outline: none;
      color: $primary-text-color;
      font-size: 26px;
      cursor: pointer;
    }
  }

  .center {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: $center-width;
  }
}

@media (max-width: 1280px) {
  .convo-header {
    height: $header-height-1280;

    .left .logo {
      font-size: 18px;
    }

    .left .demo-logo {
      font-size: 12px;
      padding-bottom: 8px;
    }

    .right {
      .signed-in-user > .user-mail {
        font-size: 12px;
        max-width: calc(50vw - 130px - #{$center-width}/ 2);
      }
      #sign-out {
        font-size: 14px;
      }
    }
  }
}
</style>
