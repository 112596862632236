<template>
  <div class="convo-open-chat">
    <p v-if="hasChatStarted" class="chat-bubble chat-bubble-triangle" @click="openChat">{{ $t('chatBubble') }}</p>
    <i class="convo-icon-dialog-circle" @click="openChat" />
  </div>
</template>

<script>
export default {
  name: 'ConvoOpenChat',
  data() {
    return {
      hasChatStarted: false,
    };
  },
  methods: {
    chatStarted() {
      this.hasChatStarted = true;
    },
    openChat() {
      this.hasChatStarted = false;
      this.$emit('open-chat', false);
    },
  },
  mounted() {
    setTimeout(this.chatStarted, 30000);
  },
};
</script>

<style lang="scss" scoped>
.convo-open-chat {
  //LAYOUT
  bottom: 56px;
  position: absolute;
  right: 32px;
  z-index: 7;

  .chat-bubble {
    cursor: pointer;
    //FONT
    font-family: $primary-font;
    font-size: 20px;
    font-weight: 300;
    //COLOR
    background: $primary-text-color;
    filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.25));
    color: $primary-color;
    //LAYOUT
    padding: 16px;
    text-align: center;
    width: fit-content;
  }

  .chat-bubble-triangle:before {
    //LAYOUT
    border-bottom: 8px solid transparent;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 16px solid $primary-text-color;
    bottom: -24px;
    content: '';
    position: absolute;
    right: 10%;
  }

  .convo-icon-dialog-circle {
    font-size: 80px;
    color: $chat-header-demo-text;
    cursor: pointer;
    float: right;
  }
}

@media (max-width: 1280px) {
  .convo-open-chat {
    bottom: 24px;
    .chat-bubble {
      font-size: 14px;
    }

    .convo-icon-dialog-circle {
      font-size: 56px;
    }
  }
}
</style>
