import CpdModificationService from '@/common/services/CpdModificationService';

export default {
  props: {
    i18nPrefix: {
      type: String,
      required: true,
    },
  },
  computed: {
    localizedLabel() {
      return this.translateWithPrefix('label');
    },
    localizedPlaceholder() {
      return this.translateWithPrefix('placeholder');
    },
    localizedTooltipTitle() {
      return this.translateWithPrefix('tooltip_title');
    },
    localizedTooltipText() {
      return this.translateWithPrefix('tooltip_text');
    },
    expressionHeading() {
      return this.translateWithPrefix('expressionHeading', [CpdModificationService.getElementField(this.parentId, 'name')]);
    },
  },
  methods: {
    translateWithPrefix(key, options) {
      return this.$t(`${this.i18nPrefix}.${key}`, options);
    },
  },
  mounted() {
    // remove tabindex for translated label
    document.getElementsByClassName('no-tabindex').forEach((e) => e.removeAttribute('tabindex'));
  },
};
