<template>
  <div class="icon-list">
    <div v-if="isDeveloper" class="dev-options">
      <button @click.stop="handleActivatorToggle"><i class="convo-icon-dev" /></button>
      <div class="options" v-if="optionsVisible">
        <div class="option" @click.stop="handleOptionSelect('cpd')">
          <span>CPD</span>
        </div>
        <div class="option" @click.stop="handleOptionSelect('translations')">
          <span>Übersetzung</span>
        </div>
      </div>
      <el-dialog class="dev-option-dialog" :visible.sync="dialogVisible" width="80%">
        <span style="white-space: pre">
          <code>{{ dialogContent }}</code>
        </span>
      </el-dialog>
    </div>
    <button id="home-button" @click="handleNavigateToDashboard" :title="$t('backToDashboard')">
      <i class="convo-icon-home" />
    </button>
    <button id="editorial-view-button" @click="navigateToEditorialView(cpdId, version)" :title="$t('openEditorialView')">
      <i class="convo-icon-translation" />
    </button>
    <button id="preview-button" @click="openPreviewInNewTab(cpdId, version)" :title="$t('openPreview')">
      <i class="convo-icon-preview" />
    </button>
    <button :disabled="isDemo" v-if="isCpdEditable()" id="save-button" @click="save" :title="$t('saveCpd')">
      <i class="convo-icon-save-alt" />
    </button>
    <convo-confirmation-dialog
      v-if="!isDemo"
      :visible="showLeavingConfirmDialog"
      :title="$t('leavingConfirmationDialog.title')"
      :content="$t('leavingConfirmationDialog.content')"
      :button-click-not-required="true"
      :confirm-label="$t('leavingConfirmationDialog.leaveWithSave')"
      :cancel-label="$t('leavingConfirmationDialog.leaveWithoutSave')"
      @confirm="leaveWithSave"
      @cancel="leaveWithoutSave"
      @close="showLeavingConfirmDialog = false"
    />
  </div>
</template>

<script>
import CpdStoreToYamlService from '@/common/services/CpdStoreToYamlService';
import CpdApiService from '@/common/services/CpdApiService';
import RouterMixin from '@/common/mixins/RouterMixin';
import LeaveConfirmationMixin from '@/common/mixins/LeaveConfirmationMixin';
import { mapGetters } from 'vuex';
import EventBus from '@/common/event-bus';
import SpinnerEnum from '@/common/enums/SpinnerEnum';

export default {
  name: 'ConvoHeaderSlotStudio',
  mixins: [RouterMixin, LeaveConfirmationMixin],
  data() {
    return {
      dialogVisible: false,
      dialogContent: null,
      optionsVisible: false,
      cpdId: this.$route.params.cpd,
      version: this.$route.params.version,
    };
  },
  computed: {
    ...mapGetters('oidc', ['oidcUser']),
    isDeveloper() {
      return this.oidcUser.realm_access.roles.includes('developer-access');
    },
    isDemo() {
      return this.oidcUser.realm_access.roles.includes('demo-access');
    },
  },
  methods: {
    handleActivatorToggle() {
      if (this.optionsVisible) {
        this.closeOptions();
      } else {
        this.openOptions();
      }
    },
    openOptions() {
      this.optionsVisible = true;
      document.addEventListener('click', this.closeOptions);
    },
    closeOptions() {
      this.optionsVisible = false;
      document.removeEventListener('click', this.closeOptions);
    },
    handleOptionSelect(option) {
      const uniqueCpdId = this.cpdId + 'v' + this.version;
      switch (option) {
        case 'cpd':
          this.dialogContent = CpdStoreToYamlService.buildCpdYamlFromStoreValues(uniqueCpdId);
          this.dialogVisible = true;
          break;
        case 'translations':
          this.dialogContent = this.$store.state.i18n;
          this.dialogVisible = true;
          break;
        default:
          break;
      }
    },
    isCpdEditable() {
      return this.$store.getters.cpdEditable;
    },
    save() {
      CpdApiService.overwriteCpd(this.cpdId, this.version, SpinnerEnum.STUDIO).then(
        (res) => {
          EventBus.emitCpdLoaded(JSON.parse(res.config.data));
          EventBus.emitToast('toast.cpdSaved');
        },
        (res) => {
          if (res.status === 403) {
            EventBus.emitToast('toast.cpdLockOverwritten', true);
          } else {
            EventBus.emitToast('toast.cpdSaveFailed', true);
          }
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-list {
  position: relative;

  button {
    background-color: transparent;
    color: #fff;
    font-size: 26px;
    border: none;
    cursor: pointer;
    margin: 5px;
    outline: none;
  }

  button:disabled {
    background-color: transparent;
    color: $disabled-color;
    font-size: 26px;
    border: none;
    cursor: not-allowed;
    margin: 5px;
    outline: none;
  }

  .dev-options {
    float: left;

    .options {
      z-index: 3;
      position: absolute;
      left: 0;
      top: 40px;
      background-color: #fff;

      .option {
        display: flex;
        align-items: center;
        padding: 5px;
        font-size: 14px;
        cursor: pointer;
        &:hover {
          background-color: $convo-blue-2;
        }
      }
    }

    .dev-option-dialog {
      text-align: left;
    }
  }
}

@media (max-width: 1280px) {
  .icon-list button,
  .icon-list button[disabled] {
    font-size: 18px;
  }
}
</style>
