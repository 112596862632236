<template>
  <div
    :id="`${type}-${componentId}`"
    class="convo-brick"
    @click.stop="openInspectorPanel"
    tabindex="-1"
    @keyup.delete.stop="deletionRequested = true"
    @keyup.ctrl.67.stop="copy"
    @keyup.ctrl.86="paste"
    @keydown.meta.67.stop="copyOnMac"
    @keydown.meta.86="pasteOnMac"
  >
    <div :class="['container', { selected }, type]" v-if="isContainer">
      <div class="container-header" :id="`element-header-${componentId}`">
        <div class="container-icon"><i :class="typeIcon" /></div>
        <div class="container-name">
          <div
            v-show="!isRenameMode"
            ref="renameBrick"
            :id="`rename-brick-activator-${componentId}`"
            :class="['rename-brick-activator', { active: isRenameMode }]"
            @dblclick="activateInput"
          ></div>
          <input
            class="name-input"
            ref="nameInput"
            :id="`name-input-${componentId}`"
            :disabled="!editable"
            :placeholder="$t('elementNamePlaceholder')"
            v-model="componentName"
            autocomplete="off"
            @focusout="deactivateInput"
            @keyup.13="deactivateInput"
            @pointerdown.stop
          />
        </div>
        <convo-trashbin
          v-if="!isRenameMode && isDraggable"
          class="container-trashbin"
          :component-id="componentId"
          :deletion-requested="deletionRequested"
          @deletion-request-processed="deletionRequested = false"
          dark
        >
        </convo-trashbin>
        <div v-else class="container-icon"><i class="convo-icon-lock" /></div>
      </div>
      <div class="convo-draggable-holder">
        <convo-draggable
          :components="children"
          @change="handleChangeEvent"
          @choose="handleChooseEvent"
          :group="providedCanvasSlot"
          :is-draggable="isDraggable"
        />
      </div>
    </div>

    <div :class="['component', { selected }, type]" v-else>
      <div class="component-header" :id="`element-header-${componentId}`">
        <div class="component-name">
          <div
            v-show="!isRenameMode"
            ref="renameBrick"
            :id="`rename-brick-activator-${componentId}`"
            :class="['rename-brick-activator', { active: isRenameMode }]"
            @dblclick="activateInput"
          ></div>
          <input
            class="name-input"
            ref="nameInput"
            :id="`name-input-${componentId}`"
            :disabled="!editable"
            :placeholder="$t('elementNamePlaceholder')"
            v-model="componentName"
            autocomplete="off"
            @focusout="deactivateInput"
            @keyup.13="deactivateInput"
            @pointerdown.stop
          />
        </div>
        <convo-trashbin
          v-if="!isRenameMode && isDraggable && type !== ElementType.CONTROL_START && type !== ElementType.CONTROL_END"
          class="component-trashbin"
          :component-id="componentId"
          :deletion-requested="deletionRequested"
          @deletion-request-processed="deletionRequested = false"
        ></convo-trashbin>
        <div v-if="!isDraggable" class="container-icon"><i class="convo-icon-lock" /></div>
      </div>
      <div class="component-body">
        <div class="component-type">{{ typeLabel }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import CpdModificationService from '@/common/services/CpdModificationService';
import ElementType from '@/common/enums/ElementType';
import RouterMixin from '@/common/mixins/RouterMixin';
import ConvoDraggableEventHandlerMixin from '@/studio/mixins/ConvoDraggableEventHandlerMixin';
import KeyboardControlMixin from '@/studio/mixins/KeyboardControlMixin';
import ConvoProcessComponentService from '@/common/services/ConvoProcessComponentService';
import ConvoTrashbin from './ConvoTrashbin.vue';

export default {
  name: 'ConvoBrick',

  components: {
    ConvoTrashbin,
  },
  mixins: [RouterMixin, ConvoDraggableEventHandlerMixin, KeyboardControlMixin(false)],
  props: {
    componentId: {
      type: String,
      required: true,
    },
    isDraggable: {
      type: Boolean,
      default: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      ElementType,
      deletionRequested: false,
      isRenameMode: false,
    };
  },
  computed: {
    name() {
      return CpdModificationService.getElementField(this.componentId, 'name');
    },
    type() {
      return CpdModificationService.getElementField(this.componentId, 'type');
    },
    componentName: {
      get() {
        return CpdModificationService.getElementField(this.componentId, 'name');
      },
      set(newValue) {
        CpdModificationService.updateElementField(this.componentId, 'name', newValue);
      },
    },
    typeLabel() {
      return this.type ? this.$t(`${this.type}.elementName`) : '';
    },
    typeIcon() {
      switch (this.type) {
        case ElementType.SECTION:
          return 'convo-icon-process';
        case ElementType.SUBSECTION:
          return 'convo-icon-structure';
        case ElementType.GROUP:
          return 'convo-icon-group';
        case ElementType.DYNAMIC_GROUP:
          return 'convo-icon-dynamic-group';
        default:
          return '';
      }
    },
    isContainer() {
      return CpdModificationService.getElementField(this.componentId, 'children') !== undefined;
    },
    providedCanvasSlot() {
      const metadata = ConvoProcessComponentService.getMetadata(this.type);
      return metadata ? metadata.providedCanvasSlot : undefined;
    },
    selected() {
      return this.componentId && this.$route.params.component === this.componentId;
    },
    children: {
      get() {
        return CpdModificationService.getElementField(this.componentId, 'children');
      },
      set(value) {
        CpdModificationService.updateElementField(this.componentId, 'children', value);
      },
    },
  },
  methods: {
    openInspectorPanel() {
      if (this.componentId) {
        this.navigateToInspectorPanel(this.componentId);
      }
    },
    activateInput() {
      this.isRenameMode = true;
      this.$refs.renameBrick.classList.add('active');
      this.$refs.nameInput.select();
    },
    deactivateInput() {
      this.isRenameMode = false;
      this.deletionRequested = false;
      this.$refs.renameBrick.classList.remove('active');
      this.$refs.nameInput.blur();
    },
  },
};
</script>

<style lang="scss" scoped>
.convo-brick {
  outline: none;
  cursor: pointer;

  &.is-draggable {
    cursor: move;
  }

  .container {
    border-radius: 16px;
    font-weight: normal;

    &.selected {
      box-shadow: 3px 3px 9px $primary-color;

      > .container-header .name-input {
        font-weight: bold;
      }
    }

    &.section {
      background-color: $convo-blue-4;
    }
    &.subsection {
      background-color: $convo-blue-3;
    }
    &.group,
    &.dynamic_group {
      background-color: $convo-blue-2;
    }

    .container-header {
      display: flex;
      justify-content: space-between;
      padding: 8px 12px 0 10px;
      color: $primary-color;
      height: 36px;

      .container-name {
        text-align: center;
        font-size: 16px;
        overflow: hidden;
        text-overflow: clip;
        white-space: nowrap;
        margin: 0 16px;

        .rename-brick-activator {
          position: absolute;
          height: 20px;
          width: 450px;
          cursor: pointer;
          background-color: transparent;
          border: none;
          outline: none;
        }

        .name-input {
          text-align: center;
          align-items: center;
          width: 450px;
          cursor: text;
          font-family: 'Montserrat', sans-serif;
          font-size: 16px;
          color: $primary-color;
          background-color: transparent;
          border: none;
          outline: none;

          &::placeholder {
            color: $primary-color;
            font-style: italic;
            font-weight: normal;
          }
        }
      }
      .container-icon,
      .container-trashbin {
        width: 25px;
        font-size: 18px;
      }

      .container-trashbin {
        text-align: right;
      }

      .convo-icon-structure {
        font-size: 17px;
      }
      .convo-icon-process {
        font-size: 8px;
      }
    }
  }

  .component {
    border-radius: 10px;
    overflow: hidden;
    height: 60px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #fff;

    &.selected {
      box-shadow: 3px 3px 9px $primary-color;
      .component-header {
        background-color: $primary-color;
      }
    }

    .component-header {
      height: 50%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      padding: 0 8px;
      background-color: $convo-blue-5;
      color: #fff;
      font-size: 12px;
      font-weight: 500;

      .component-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-right: 4px;

        .rename-brick-activator {
          position: absolute;
          overflow: visible;
          text-overflow: clip;
          align-items: start;
          height: 16px;
          width: 450px;
          cursor: pointer;
          background-color: transparent;
          color: #fff;
          font-size: 12px;
          font-family: $primary-font;
          border: none;
          outline: none;
        }

        .name-input {
          overflow: hidden;
          text-overflow: ellipsis;
          align-items: start;
          width: 450px;
          max-height: 16px;
          cursor: text;
          background-color: transparent;
          color: #fff;
          font-size: 12px;
          font-family: $primary-font;
          border: none;
          outline: none;

          &::placeholder {
            font-style: italic;
            color: #fff;
          }
        }
      }
    }

    .component-body {
      height: 50%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 0 0 10px 10px;
      padding: 0 16px;
      color: $primary-color;
      font-size: 16px;
      font-weight: 300;
    }
  }

  &.moving {
    opacity: 1 !important; // Reversing ghost effects for bricks
    .component > .component-header {
      background-color: $convo-moving-blue;
    }
    .component > .component-body {
      color: $convo-moving-blue;
    }
    .container > .container-header {
      color: $convo-moving-blue;
    }
  }
  &.ghost {
    .component > .component-header {
      background-color: $convo-ghost-blue;
    }
    .component > .component-body {
      color: $convo-ghost-blue;
    }
    .container > .container-header {
      color: $convo-ghost-blue;
    }
  }

  ::v-deep.convo-trashbin-activator {
    transition: visibility 0s;
    visibility: hidden;
  }
  &:hover > .component:first-of-type > .component-header > .component-trashbin,
  .container-header:hover > .container-trashbin {
    ::v-deep.convo-trashbin-activator {
      transition-delay: 0.5s;
      visibility: visible;
    }
  }
}

@media (max-width: 1400px) {
  .convo-brick > .container > .container-header > .container-name,
  .convo-brick > .component > .component-body {
    font-size: 14px;
  }
}

@media (max-width: 1280px) {
  .convo-brick .component {
    height: 48px;
  }
}
</style>
