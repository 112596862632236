<template>
  <div class="convo-structural-element" :class="type">
    <div class="structural-element-header" @click="toggleFolded">
      <span>
        <i :class="icon" /><span class="structural-element-name">{{ name }}</span>
      </span>
      <i :class="{ 'convo-icon-minus': !folded, 'convo-icon-plus': folded }" />
    </div>
    <div class="structural-element-body" v-show="!folded">
      <template v-for="(child, index) in children">
        <convo-structural-element
          v-if="isChildStructural"
          :key="index"
          :element-id="child"
          :required-input-type="requiredInputType"
          :stop-signs="updatedStopSigns"
          @empty="handleEmptyChild(child)"
        />
        <convo-element
          v-else
          :key="index"
          :element-id="child"
          :required-input-type="requiredInputType"
          :is-within-dynamic-group="type === ElementType.DYNAMIC_GROUP"
          @empty="handleEmptyChild(child)"
        />
      </template>
    </div>
  </div>
</template>

<script>
import ElementType from '@/common/enums/ElementType';
import ConvoElement from '@/studio/components/right_sidepanel/inspector_panel/convo_expression_language_dialog/left_sidepanel/ConvoElement.vue';
import CpdModificationService from '@/common/services/CpdModificationService';

export default {
  name: 'ConvoStructuralElement',
  components: {
    ConvoElement,
  },
  props: {
    elementId: {
      type: String,
      required: true,
    },
    requiredInputType: {
      type: String,
      required: true,
    },
    stopSigns: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      folded: false,
      emptyChildren: [],
      ElementType,
    };
  },
  computed: {
    name() {
      return CpdModificationService.getElementField(this.elementId, 'name');
    },
    type() {
      return CpdModificationService.getElementField(this.elementId, 'type');
    },
    updatedStopSigns() {
      return this.stopSigns.slice(1);
    },
    icon() {
      switch (this.type) {
        case ElementType.SECTION:
          return 'convo-icon-process';
        case ElementType.SUBSECTION:
          return 'convo-icon-structure';
        case ElementType.GROUP:
          return 'convo-icon-group';
        case ElementType.DYNAMIC_GROUP:
          return 'convo-icon-dynamic-group';
        default:
          return '';
      }
    },
    isChildStructural() {
      return this.type === ElementType.SECTION || this.type === ElementType.SUBSECTION;
    },
    children() {
      const children = CpdModificationService.getElementField(this.elementId, 'children');
      const filteredChildren = children
        .filter(
          (child, index) =>
            children.indexOf(this.stopSigns[0]) === -1 || // children don't include stop sign
            children.indexOf(this.stopSigns[0]) > index || // current element is before stop sign
            (child === this.stopSigns[0] && this.stopSigns.length > 1) // child equals stop sign but stop sign is on another level
        )
        .filter((child) => !this.emptyChildren.includes(child));

      if (filteredChildren.length === 0) {
        this.$emit('empty');
      }

      return filteredChildren;
    },
  },
  watch: {
    children() {
      if (this.children.length === 0) {
        this.$emit('empty');
      }
    },
  },
  methods: {
    toggleFolded() {
      this.folded = !this.folded;
    },
    handleEmptyChild(child) {
      this.emptyChildren.push(child);
    },
  },
};
</script>

<style lang="scss" scoped>
.convo-structural-element {
  &.section {
    background-color: $convo-blue-4-lighten;
  }
  &.subsection {
    background-color: $convo-blue-3-lighten;
  }
  &.group,
  &.dynamic_group {
    background-color: $convo-blue-2-lighten;
  }

  .structural-element-header {
    align-items: center;
    color: $primary-color;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    padding: 8px 12px;

    :first-child {
      align-items: center;
      display: flex;
    }

    .structural-element-name {
      margin-left: 8px;
    }
    .convo-icon-process {
      font-size: 5px;
    }
  }
  .structural-element-body {
    padding-bottom: 8px;
    padding-left: 16px;

    .convo-structural-element,
    .convo-element {
      margin-bottom: 8px;
    }
  }

  &.group,
  &.dynamic_group {
    .structural-element-body {
      padding-right: 16px;
    }
  }
}

@media (max-width: 1280px) {
  .convo-structural-element {
    .structural-element-header {
      font-size: 12px;
    }
  }
}
</style>
