<template>
  <div class="convo-language-select">
    <div class="activator" @click.stop="handleActivatorToggle">
      <country-flag :country="getFlagCode($i18n.locale)" rounded />
      <i class="convo-icon-down" />
    </div>
    <div class="options" v-if="optionsVisible">
      <div class="option" v-for="lang in availableLanguages" :key="lang" @click.stop="handleOptionSelect(lang)">
        <country-flag :country="getFlagCode(lang)" rounded />
        <span>{{ $t(`language.${lang}`) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import CountryFlag from 'vue-country-flag';

export default {
  name: 'ConvoLanguageSelect',
  components: { CountryFlag },
  data() {
    return {
      optionsVisible: false,
    };
  },
  computed: {
    availableLanguages() {
      return this.$i18n.availableLocales.filter((lang) => lang !== this.$i18n.locale);
    },
  },
  methods: {
    getFlagCode(lang) {
      if (lang.startsWith('de')) {
        return 'de';
      }
      if (lang.startsWith('en')) {
        return 'gb';
      }
      return lang;
    },
    handleOptionSelect(option) {
      this.closeOptions();
      this.$i18n.locale = option;
    },
    handleActivatorToggle() {
      if (this.optionsVisible) {
        this.closeOptions();
      } else {
        this.openOptions();
      }
    },
    openOptions() {
      this.optionsVisible = true;
      document.addEventListener('click', this.closeOptions);
    },
    closeOptions() {
      this.optionsVisible = false;
      document.removeEventListener('click', this.closeOptions);
    },
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeOptions);
  },
};
</script>

<style lang="scss" scoped>
.convo-language-select {
  position: relative;

  .activator {
    display: flex;
    align-items: flex-end;
    color: #fff;
    cursor: pointer;

    .convo-icon-down {
      font-size: 6px;
      margin: 0 4px 2px 6px;
    }
  }
  .options {
    z-index: 4;
    position: absolute;
    left: -8px;
    top: 32px;
    border-radius: 8px;
    background-color: white;
    padding: 8px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

    .option {
      display: flex;
      align-items: center;
      font-size: 14px;
      cursor: pointer;
      ::v-deep .normal-flag {
        margin-right: -5px;
      }
    }
  }
}

@media (max-width: 1280px) {
  .convo-language-select {
    .activator {
      margin-right: 10px;
      .convo-icon-down {
        position: absolute;
        left: 24px;
        top: 10px;
      }
    }

    .options {
      z-index: 4;
      top: 26px;
      padding: 4px;

      .option {
        font-size: 12px;

        .normal-flag {
          margin-right: -12px;
        }
      }
    }

    ::v-deep .normal-flag {
      transform: scale(0.4);
    }
  }
}
</style>
