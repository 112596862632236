import CONVO_EXPRESSION_LANGUAGE_FUNCTIONS from '@/common/definition/convo_expression_language_functions';
import { uuid } from 'vue-uuid';
import store from '@/common/store';
import CelExpressionType from '@/common/enums/CelExpressionType';
import CelReturnType from '@/common/enums/CelReturnType';

const ConvoExpressionLanguageService = {
  /**
   * @returns a list of convo expression language constants filtered by the return type
   * @param {String} type the desired return type of the functions
   */
  getConvoExpressionLanguageConstants(type) {
    return Object.values(CONVO_EXPRESSION_LANGUAGE_FUNCTIONS)
      .filter((celFunction) => celFunction.type === CelExpressionType.CONSTANT)
      .filter((celFunction) => !type || celFunction.returnType === type || type === CelReturnType.GENERIC);
  },

  /**
   * @returns a list of (non-generic) convo expression language functions filtered by the return type
   * @param {String} type the desired return type of the functions
   */
  getConvoExpressionLanguageFunctions(type) {
    return Object.values(CONVO_EXPRESSION_LANGUAGE_FUNCTIONS)
      .filter((celFunction) => celFunction.type === CelExpressionType.FUNCTION && celFunction.returnType !== CelReturnType.GENERIC)
      .filter((celFunction) => !type || celFunction.returnType === type || type === CelReturnType.GENERIC);
  },

  /**
   * @returns a list of all generic convo expression language functions
   */
  getConvoExpressionLanguageGenericFunctions() {
    return Object.values(CONVO_EXPRESSION_LANGUAGE_FUNCTIONS)
      .filter((celFunction) => celFunction.type === CelExpressionType.FUNCTION)
      .filter((celFunction) => celFunction.returnType === CelReturnType.GENERIC);
  },

  /**
   * @returns true if the given string is a convo expression language expression but not $TRANSLATE
   * @param {String} string The string to be checked.
   */
  isConvoExpressionLanguage(string) {
    return typeof string === 'string' && new RegExp('^\\$([A-Z]{2,11})\\(.*\\)$').test(string) && !string.startsWith('$TRANSLATE(');
  },

  /**
   * Takes an expression string and creates its store representation.
   * @param expressionString The string to be parsed, e.g. '$SUM($VAR(c4c61975-53bb-4972-b388-2b7ddb8e30b5),$VAR(3e0d1051-dbec-42bf-9a3b-cf8181475f3c))'
   * @returns the store id of the outer expression
   */
  parseString(expressionString) {
    if (!this.isConvoExpressionLanguage(expressionString)) {
      return undefined;
    }

    let currentExpression = expressionString;
    const functionWithoutInnerFunctionsPattern = new RegExp(/\$([A-Z]{2,11})\(([^$()]*)\)/); // matches SUM(a, b) but not SUM(SUM(a, b), c) so only the innermost function are matched.

    let nextInnermostSubexpressionMatches = currentExpression.match(functionWithoutInnerFunctionsPattern);

    while (nextInnermostSubexpressionMatches) {
      // Extract the operator, e.g. "SUM"
      const functionName = nextInnermostSubexpressionMatches[1];

      // Extract the operands (e.g "text1, text2"),
      // split them at the comma separator (e.g. ["text1", "text2"])
      // replace the space escape sequence ("\s") with an actual space,
      // secure type boolean for special case constbool
      const parameters = nextInnermostSubexpressionMatches[2]
        .split(',')
        .map((parameter) => (typeof parameter === 'string' ? parameter.replace(/\\s/g, ' ') : parameter))
        .map((p) => (functionName.toLowerCase() === 'constbool' ? p === 'true' : p));

      // build result object
      const result = {
        operator: functionName.toLowerCase(),
        type: CONVO_EXPRESSION_LANGUAGE_FUNCTIONS[functionName.toLowerCase()].type,
        parameter: parameters,
      };

      // save result object in store and replace expression with corresponding id
      const id = uuid.v4();
      store.commit('addExpression', { id, expression: result });
      currentExpression = currentExpression.replace(nextInnermostSubexpressionMatches[0], id);

      // get the next expression
      nextInnermostSubexpressionMatches = currentExpression.match(functionWithoutInnerFunctionsPattern);
    }

    return currentExpression;
  },

  /**
   * Convert a store representation of an expression to its string representation.
   * @param expressionId The ID of the expression to convert.
   * @returns {string} The expression string.
   */
  renderString(expressionId) {
    const renderRecursive = (expId) => {
      const exp = store.getters.expression(expId);
      const val = typeof expId === 'string' ? expId.replace(/^\s+|\s+$/g, '\\s') : expId; // escape leading and trailing spaces
      return exp ? `$${exp.operator.toUpperCase()}(${exp.parameter.map(renderRecursive).join(',')})` : val;
    };
    return renderRecursive(expressionId);
  },

  /**
   * @param expression The expression string to be checked.
   * @returns {string[]} A list of all referenced expressions.
   */
  getReferencedMvs(expression) {
    if (!expression) {
      return [];
    }
    return Array.from(JSON.stringify(expression).matchAll(/\$VAR\(([^)]*)\)/g), (x) => x[1]);
  },

  /**
   * Creates a new store representation of an expression.
   * @param toolboxObject The expression template to create a new expression from.
   * @returns {string} The store id of the created expression.
   */
  createNewExpression(toolboxObject) {
    const expression = {
      operator: toolboxObject.operator.toLowerCase(),
      type: toolboxObject.type,
      parameter: toolboxObject.type === CelExpressionType.MV ? [toolboxObject.id] : [],
    };
    const id = uuid.v4();
    store.commit('addExpression', { id, expression });
    return id;
  },

  /**
   * @param id expression id
   * @param field field name
   * @returns {*} The value of an expression field.
   */
  getExpressionField(id, field) {
    const storedExpression = store.getters.expression(id);
    return storedExpression ? storedExpression[field] : undefined;
  },

  /**
   * Updates an expression field in store.
   * @param id expression id
   * @param field field name
   * @param value new value
   */
  updateExpressionField(id, field, value) {
    store.commit('updateExpressionField', { id, field, value });
  },

  /**
   * @param operator operator identifying the expression type
   * @param field field identifying the kind of meta information
   * @returns {*} A specific meta information of an expression.
   */
  getExpressionDefinitionField(operator, field) {
    const expressionDefinition = CONVO_EXPRESSION_LANGUAGE_FUNCTIONS[operator];
    return expressionDefinition ? expressionDefinition[field] : undefined;
  },

  /**
   * @param elementId element id
   * @param fieldKey field key
   * @returns {string} Returns the store id of the expression which is set for the given element field.
   */
  getExpressionIdForField(elementId, fieldKey) {
    return store.getters.expressionIdForField(elementId, fieldKey);
  },

  /**
   * Sets a element field to expression (represented by its store id) mapping.
   * @param elementId element id
   * @param fieldKey field key
   * @param expressionId expreesion id
   */
  setExpressionIdForField(elementId, fieldKey, expressionId) {
    store.commit('setFieldMapping', { elementId, fieldKey, expressionId });
  },

  /**
   * Checks whether an expression is valid.
   * @param expressionId expression to be checked
   * @returns {boolean} true if the expression is complete and filled in correctly, otherwise false
   */
  isExpressionValid(expressionId) {
    const checkRecursive = (expId) => {
      if (expId === undefined || expId === null) {
        return false;
      }

      const operator = this.getExpressionField(expId, 'operator');
      const parameter = this.getExpressionField(expId, 'parameter');
      const min = this.getExpressionDefinitionField(operator, 'parameterMin');
      const max = this.getExpressionDefinitionField(operator, 'parameterMax');

      return (!min || parameter.length >= min) && (!max || parameter.length <= max) && (!parameter || parameter.every(checkRecursive));
    };

    return checkRecursive(expressionId);
  },
};

export default ConvoExpressionLanguageService;
