<template>
  <div
    class="convo-key-value-input convo-ip-input"
    :class="{ 'even-level': isEvenLevel && !recursionDisabled, 'odd-level': !isEvenLevel && !recursionDisabled }"
  >
    <div class="header">
      <span>{{ $t(`${i18nPrefix}.key_value_header`) }} {{ identifier }}</span>
      <div :class="['remove-button', { 'not-editable': !editable }]" @click="removeIfEditable">
        <i class="convo-icon-delete" />
      </div>
    </div>
    <el-form label-position="top" :model="{}" ref="objectform">
      <el-form-item v-if="!hideKeyInput" :label="$t(`${i18nPrefix}.key`)" prop="key" :rules="[{ validator, trigger }]">
        <el-input
          :disabled="!editable"
          :id="mask(`key-input-${i18nPrefix}-${identifier}`)"
          :value="valueKey"
          @input="$emit('update:value-key', $event)"
        />
      </el-form-item>
      <el-form-item :label="$t(`${i18nPrefix}.value`)">
        <el-input
          v-if="!isConvoExpressionLanguage"
          :disabled="!editable"
          :id="mask(`value-input-${i18nPrefix}-${identifier}`)"
          :value="value"
          @input="$emit('update:value', $event)"
        />
        <template v-else>
          <el-popover ref="popover-cel" placement="bottom-start" :open-delay="800" trigger="hover" width="480">
            <convo-expression
              :key="dialogVisible"
              disabled
              :expression-id="expression"
              :heading="expressionHeading"
              :required-input-type="CelReturnType.STRING"
            />
          </el-popover>
          <span v-popover:popover-cel :id="`expression-language-placeholder-${valueKey}`" class="active-mv">
            <i v-for="i in 4" :key="i" class="convo-icon-placeholder" />
          </span>
        </template>
        <el-button @click="handleOpen" size="mini" :class="['mv-button', { 'not-editable': !editable }]">
          <i v-if="isConvoExpressionLanguage" class="convo-icon-function-circle-active" />
          <i v-else class="convo-icon-function-circle-default" />
        </el-button>
      </el-form-item>
    </el-form>
    <convo-expression-language-dialog
      :parent-id="parentId"
      :field-key="identifier"
      :type="CelReturnType.GENERIC"
      :value="value"
      :visible="dialogVisible"
      @update="$emit('update:value', $event)"
      @close="handleClose"
      :expression-heading="expressionHeading"
    />
  </div>
</template>

<script>
import ConvoExpressionLanguageService from '@/common/services/ConvoExpressionLanguageService';
import ConvoExpressionLanguageDialog from '@/studio/components/right_sidepanel/inspector_panel/convo_expression_language_dialog/ConvoExpressionLanguageDialog.vue';
import ConvoExpression from '@/studio/components/right_sidepanel/inspector_panel/convo_expression_language_dialog/expression/ConvoExpression.vue';
import CelReturnType from '@/common/enums/CelReturnType';
import ValidationStrategy from '@/studio/enums/ValidationStrategy';
import ValidationMixin from '@/studio/mixins/ValidationMixin';
import CpdModificationService from '@/common/services/CpdModificationService';

export default {
  name: 'ConvoKeyValueInput',
  mixins: [ValidationMixin],
  components: {
    ConvoExpressionLanguageDialog,
    ConvoExpression,
  },
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
    parentId: {
      type: String,
      required: true,
    },
    valueKey: {
      required: false,
      type: String,
    },
    value: {
      required: false,
      type: String,
    },
    level: {
      required: true,
      type: Number,
    },
    identifier: {
      required: true,
      type: String,
    },
    i18nPrefix: {
      type: String,
      required: true,
    },
    recursionDisabled: {
      type: Boolean,
      default: false,
    },
    hideKeyInput: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogVisible: false,
      CelReturnType,
    };
  },
  computed: {
    isConvoExpressionLanguage() {
      return ConvoExpressionLanguageService.isConvoExpressionLanguage(this.value);
    },
    expression() {
      if (this.isConvoExpressionLanguage && !ConvoExpressionLanguageService.getExpressionIdForField(this.parentId, this.identifier)) {
        return ConvoExpressionLanguageService.parseString(this.value);
      }
      return ConvoExpressionLanguageService.getExpressionIdForField(this.parentId, this.identifier);
    },
    expressionHeading() {
      return this.$t(`${this.i18nPrefix}.expressionHeading`, [CpdModificationService.getElementField(this.parentId, 'name')]);
    },
    isEvenLevel() {
      return this.level % 2 === 0;
    },
  },
  methods: {
    handleOpen() {
      if (this.editable) this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    removeIfEditable() {
      if (this.editable) this.$emit('remove');
    },
    validator(rule, value, callback) {
      this.validate(
        { validation_strategy: ValidationStrategy.STATIC, value: true },
        (evaluated) => evaluated && (!this.valueKey || this.valueKey === ''),
        () => this.$t('validationError.mandatory'),
        callback
      );
      callback();
    },
    mask(string) {
      return string.replace(/\./g, '-').replace(/_/g, '-');
    },
  },
  mounted() {
    if (this.$refs.objectform) {
      this.$refs.objectform.validate(() => {});
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/studio/styles/inputs.scss';

.convo-key-value-input {
  padding: 8px;
  border-radius: 8px;

  &.even-level {
    background-color: $convo-blue-3;
  }
  &.odd-level {
    background-color: $convo-blue-1;
  }

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $primary-color;
    padding-bottom: 4px;
    margin-bottom: 24px;
    color: $primary-color;
    font-size: 16px;
    font-weight: 600;

    .remove-button {
      cursor: pointer;
      &.not-editable {
        cursor: not-allowed;
      }
    }
  }

  .el-input {
    width: calc(100% - 52px);

    ::v-deep.el-input__inner {
      font-family: $primary-font;
      color: $primary-color;
    }
  }
}

@media (max-width: 1280px) {
  .convo-key-value-input {
    .header {
      font-size: 14px;
      margin-bottom: 14px;
    }

    &:not(:last-child) {
      margin-bottom: 6px;
    }
  }
}
</style>
