<template>
  <div class="convo-preview-sidepanel">
    <div class="level-1-heading">{{ $t('preview.label') }}</div>
    <div class="sidepanel-body">
      <div class="body-entry">
        <button id="refresh-engine-frame" @click="refreshEngineFrame">
          <i class="convo-icon-refresh" />
        </button>
        {{ $t(`preview.refresh`) }}
      </div>
      <div class="body-entry">
        <el-switch id="enable-submit" v-model="submitEnabled" @change="disableSubmit" />
        {{ $t(`preview.submit`) }}
      </div>

      <div class="level-2-heading" @click="togglePresentation">
        {{ $t('preview.presentation')
        }}<i
          :class="{
            'convo-icon-plus': !presentationCollapsed,
            'convo-icon-minus': presentationCollapsed,
          }"
        />
      </div>
      <div v-if="presentationCollapsed">
        <div class="body-entry">
          <button @click="toggleDeviceVisible" :class="{ hidden: !deviceVisible }">
            <i class="convo-icon-view" id="hide-device-frame-button" v-if="deviceVisible" />
            <i class="convo-icon-hidden" id="show-device-frame-button" v-else />
          </button>
          {{ $t(`preview.devices.${deviceKey}`) }}
        </div>

        <div v-for="colorGroup in colorGroups" :key="colorGroup.key">
          <div class="level-3-heading" @click="toggleColorGroup(colorGroup)">
            {{ $t(`colorGroup.${colorGroup.key}`) }}
            <i
              :class="{
                'convo-icon-plus': !visibleColorGroups.includes(colorGroup),
                'convo-icon-minus': visibleColorGroups.includes(colorGroup),
              }"
            />
          </div>
          <template v-if="visibleColorGroups.includes(colorGroup)">
            <div class="body-entry" v-for="color in colorGroup.colors" :key="color.cssClass">
              <convo-color-picker v-model="color.value" :color-key="color.cssClass" /> {{ $t(`color.${color.cssClass}`) }}
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { setOptions as setVuexPersistOptions } from 'vuex-async-persist';
import colorDefinition from '@/common/assets/colors.json';
import ConvoColorPicker from '@/preview/components/ConvoColorPicker.vue';
import cpdModificationService from '@/common/services/CpdModificationService';
import EventBus from '@/common/event-bus';

export default {
  name: 'ConvoPreviewSidepanel',
  components: {
    ConvoColorPicker,
  },
  props: {
    deviceKey: {
      type: String,
      required: true,
    },
    deviceVisible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      lastSizeChangeTimestamp: 0,
      colorGroups: [],
      shouldUpdateColorsInStore: true,
      visibleColorGroups: [],
      submitEnabled: false,
      presentationCollapsed: true,
    };
  },
  mounted() {
    let timeout = null;
    setVuexPersistOptions({
      onStateReplacement: () => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          this.shouldUpdateColorsInStore = false;
          this.resetColorGroups();
        }, 500);
      },
    });
  },
  methods: {
    resetColorGroups() {
      this.colorGroups = [];
      colorDefinition.presentation.forEach((colorGroup) => {
        this.colorGroups.push({
          key: colorGroup.key,
          colors: colorGroup.colors.map((colorKey) => ({
            cssClass: colorKey,
            value: cpdModificationService.getElementField(this.$route.params.cpd + 'v' + this.$route.params.version, 'colors')[colorKey],
          })),
        });
      });
    },
    togglePresentation() {
      this.presentationCollapsed = !this.presentationCollapsed;
    },
    toggleColorGroup(colorGroup) {
      const index = this.visibleColorGroups.indexOf(colorGroup);
      if (index === -1) {
        this.visibleColorGroups.push(colorGroup);
      } else {
        this.visibleColorGroups.splice(index, 1);
      }
    },
    toggleDeviceVisible() {
      this.$emit('update:device-visible', !this.deviceVisible);
    },
    refreshEngineFrame() {
      this.$emit('refresh-engine-frame');
    },
    disableSubmit(enabled) {
      const endElement = cpdModificationService.getElementField(this.$route.params.cpd + 'v' + this.$route.params.version, 'control_end');
      const method = cpdModificationService.getElementField(endElement, 'method');
      const url = cpdModificationService.getElementField(endElement, 'url');

      if (url && method) {
        this.$emit('submit-disabled', !enabled);
        if (enabled) EventBus.emitToast('toast.previewSubmitEnabled');
      } else {
        setTimeout(() => {
          this.submitEnabled = false;
        }, 400);
        EventBus.emitToast('toast.previewSubmitDisabled');
      }
    },
  },
  watch: {
    colorGroups: {
      handler(newColors) {
        if (this.shouldUpdateColorsInStore) {
          // add new colors to new colors object
          const colorsStoreObject = {};
          newColors
            .map((colorGroup) => colorGroup.colors)
            .flat()
            .forEach((color) => {
              colorsStoreObject[color.cssClass] = color.value;
            });
          // commit colors object to colors field in store
          cpdModificationService.updateElementField(this.$route.params.cpd + 'v' + this.$route.params.version, 'colors', colorsStoreObject);
        } else {
          this.shouldUpdateColorsInStore = true;
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.convo-preview-sidepanel {
  border-right: 1px solid $global-layout-line-color;
  height: 100%;
  overflow-x: hidden;
  width: 320px;

  .level-1-heading {
    align-items: center;
    background-color: $convo-blue-4;
    color: $primary-color;
    display: flex;
    font-size: 20px;
    font-weight: 600;
    height: 64px;
    margin-bottom: 2px;
    padding: 12px;
  }

  .sidepanel-body {
    height: calc(100% - 66px);
    overflow-x: hidden;
    overflow-y: auto;

    .level-2-heading {
      align-items: center;
      background-color: $convo-blue-3;
      color: $primary-color;
      cursor: pointer;
      display: flex;
      font-size: 18px;
      font-weight: 600;
      height: 56px;
      justify-content: space-between;
      margin-top: 2px;
      padding: 12px;
    }

    .level-3-heading {
      align-items: center;
      background-color: $convo-blue-2;
      color: $primary-color;
      cursor: pointer;
      display: flex;
      font-size: 16px;
      font-weight: 600;
      height: 48px;
      justify-content: space-between;
      margin-top: 2px;
      padding: 12px;
    }

    .body-entry {
      align-items: center;
      color: $primary-color;
      display: flex;
      font-size: 16px;
      font-weight: 300;
      height: 56px;
      padding: 16px;

      button {
        border: none;
        color: $primary-color;
        background-color: transparent;
        cursor: pointer;
        height: 100%;
        margin-right: 14px;
        outline: none;
        padding: 0;

        &.hidden {
          padding-top: 8px;
          margin-right: 13px;
        }

        .convo-icon-hidden {
          font-size: 14px;
        }
        .convo-icon-view {
          font-size: 16px;
        }
      }

      :first-child {
        margin-right: 8px;
      }

      .convo-icon-refresh {
        font-size: 22px;
        margin: 0 12px;
      }
    }
  }
}

@media (max-width: 1280px) {
  .convo-preview-sidepanel {
    width: 300px;

    .level-1-heading {
      font-size: 16px;
      height: 48px;
    }

    .sidepanel-body {
      height: calc(100% - 50px);

      .level-2-heading {
        font-size: 14px;
        height: 48px;
      }

      .level-3-heading {
        font-size: 14px;
        height: 48px;
      }

      .body-entry {
        font-size: 14px;
        height: 48px;
        padding: 12px;

        button {
          .convo-icon-hidden {
            font-size: 12px;
          }
          .convo-icon-view {
            font-size: 14px;
          }
        }

        .convo-icon-refresh {
          font-size: 18px;
          margin: 0 14px;
        }
      }
    }
  }
}
</style>
