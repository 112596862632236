<template>
  <div class="full-preview">
    <div class="process-wrapper">
      <div v-if="indexedDBNotAvailable">{{ $t('indexedDbNotAvailable') }}</div>
      <convo-process v-else-if="cpdId && cpd" :cpd="cpd" :cpd-id="cpdId" :messages="messages" />
    </div>
  </div>
</template>

<script>
import jsyml from 'js-yaml';
import CpdStoreToYamlService from '@/common/services/CpdStoreToYamlService';
import TranslationTransformationService from '@/common/services/TranslationTransformationService';
import { setOptions as setVuexPersistOptions } from 'vuex-async-persist';

export default {
  name: 'ConvoPreview',
  props: {
    cpdId: String,
    uniqueCpdId: String,
  },
  data() {
    return {
      cpd: null,
      messages: null,
      indexedDBNotAvailable: false,
    };
  },
  methods: {
    onStorageChange() {
      this.cpd = this.getCpd();
      this.messages = this.getMessages();
    },
    getCpd() {
      const yml = CpdStoreToYamlService.buildCpdYamlFromStoreValues(this.uniqueCpdId);
      return yml && Object.keys(yml).length > 0 && jsyml.load(yml);
    },
    getMessages() {
      return TranslationTransformationService.getUsedMessagesFromStore(this.uniqueCpdId);
    },
    checkIndexedDB() {
      // #361 changes can be removed
      const r = indexedDB.open('convo test');
      r.onerror = () => {
        this.indexedDBNotAvailable = true;
        return true;
      };
    },
  },
  mounted() {
    let timeout = null;
    setVuexPersistOptions({
      onStateReplacement: () => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          this.cpd = this.getCpd();
          this.messages = this.getMessages();
        }, 500);
      },
    });
  },
  created() {
    this.checkIndexedDB();
  },
  beforeDestroy() {
    if (this.unsubscribe) this.unsubscribe();
  },
};
</script>

<style lang="scss" scoped>
.process-wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  div {
    display: block;
  }
  .convo-process {
    min-height: 100%;
  }
}
.full-preview {
  position: relative;
  box-sizing: border-box;
  min-height: 100%;
  zoom: var(--preview-zoom);
}
</style>
