import CpdModificationService from '@/common/services/CpdModificationService';
import ConvoExpressionLanguageService from '@/common/services/ConvoExpressionLanguageService';
import ConvoExpressionLanguageDialog from '../components/right_sidepanel/inspector_panel/convo_expression_language_dialog/ConvoExpressionLanguageDialog.vue';
import ConvoExpression from '../components/right_sidepanel/inspector_panel/convo_expression_language_dialog/expression/ConvoExpression.vue';

export default {
  components: {
    ConvoExpressionLanguageDialog,
    ConvoExpression,
  },
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
    parentId: {
      type: String,
      required: true,
    },
    fieldKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  computed: {
    value: {
      get() {
        const value = CpdModificationService.getElementField(this.parentId, this.fieldKey);
        return value === null ? undefined : value;
      },
      set(newValue) {
        // Only update if value changed
        const fieldValue = CpdModificationService.getElementField(this.parentId, this.fieldKey);
        if (fieldValue !== newValue) {
          const value = newValue === undefined ? null : newValue;
          CpdModificationService.updateElementField(this.parentId, this.fieldKey, value);
        }
      },
    },
    componentName() {
      return CpdModificationService.getElementField(this.parentId, 'name') || this.$t('elementNamePlaceholder');
    },
    isConvoExpressionLanguage() {
      return ConvoExpressionLanguageService.isConvoExpressionLanguage(this.value);
    },
    expression() {
      if (this.isConvoExpressionLanguage && !ConvoExpressionLanguageService.getExpressionIdForField(this.parentId, this.fieldKey)) {
        return ConvoExpressionLanguageService.parseString(this.value);
      }
      return ConvoExpressionLanguageService.getExpressionIdForField(this.parentId, this.fieldKey);
    },
  },
  methods: {
    handleOpen() {
      if (this.editable) this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
