<template>
  <div class="convo-select-input convo-ip-input">
    <el-form-item :prop="fieldKey" :rules="[{ validator, trigger }]">
      <!-- prop is required to ensure validation, but is not used -->
      <el-popover
        ref="popover"
        :title="localizedTooltipTitle"
        trigger="hover"
        :content="localizedTooltipText"
        placement="left-start"
        width="326"
        :open-delay="800"
      />
      <span class="no-tabindex" slot="label" v-popover:popover>{{ localizedLabel }}</span>
      <el-select
        :id="`${fieldKey}-input-${parentId}`"
        v-model="value"
        :disabled="!editable"
        :multiple="multiple"
        clearable
        collapse-tags
        filterable
        :placeholder="localizedPlaceholder"
      >
        <div v-if="multiple" @click="selectAll" class="select-all-button">{{ selectAllLabel }}</div>
        <el-option v-for="(option, index) in processedOptions" :key="index" :label="option.label" :value="option.key" />
      </el-select>
    </el-form-item>
  </div>
</template>

<script>
import CpdModificationService from '@/common/services/CpdModificationService';
import ConvoInputI18nMixin from '@/studio/mixins/ConvoInputI18nMixin';
import ValidationMixin from '@/studio/mixins/ValidationMixin';
import ConvoSelectOptionService from '@/studio/services/ConvoSelectOptionService';

export default {
  name: 'ConvoSelectInput',
  mixins: [ConvoInputI18nMixin, ValidationMixin],
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
    parentId: {
      type: String,
      required: true,
    },
    fieldKey: {
      type: String,
      required: true,
    },
    optionStrategy: {
      type: String,
      required: true,
    },
    options: {
      type: [Array, String],
      required: true,
    },
    translateOptions: {
      type: Boolean,
      required: true,
    },
    multiple: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    value: {
      get() {
        return CpdModificationService.getElementField(this.parentId, this.fieldKey);
      },
      set(newValue) {
        CpdModificationService.updateElementField(this.parentId, this.fieldKey, newValue);
      },
    },
    processedOptions() {
      switch (this.optionStrategy) {
        case 'static':
          return this.map(this.options);
        case 'external':
          return this.map(ConvoSelectOptionService.getCodes(this.options));
        case 'dynamic':
          return this.map(CpdModificationService.getElementField(this.parentId, this.options));
        default:
          console.error("Option strategy must be 'static', 'external' or 'dynamic'.");
          return [];
      }
    },
    selectAllLabel() {
      return this.value.length === this.processedOptions.length ? this.$t('selectInput.deselectAll') : this.$t('selectInput.selectAll');
    },
  },
  watch: {
    processedOptions(newValue) {
      if (this.multiple) {
        this.value = this.value.filter((option) => newValue.includes(option));
      } else if (!newValue.includes(this.value)) {
        this.value = null;
      }
    },
  },
  methods: {
    map(options) {
      return options
        .map((code) => {
          return {
            label: this.translateOptions ? this.translateWithPrefix(`options.${code}`) : code,
            key: code,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label));
    },
    selectAll() {
      this.value = this.value.length === this.processedOptions.length ? [] : this.processedOptions.map((option) => option.key);
    },
    validator(rule, value, callback) {
      this.validate(
        this.mandatory,
        (evaluated) => evaluated && !this.value,
        () => this.$t('validationError.mandatory'),
        callback
      );
      callback();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/studio/styles/inputs.scss';
.convo-select-input {
  .el-select {
    width: calc(100% - 52px);

    ::v-deep.el-select__input {
      font-family: $primary-font;
      color: $primary-color;
    }

    ::v-deep.el-tag {
      font-family: $primary-font;
      color: $primary-color;
    }
  }
}

::v-deep.el-select-dropdown__item {
  font-family: $primary-font;
  color: $primary-color;

  &.selected {
    background-color: $primary-color !important;
    color: $primary-text-color !important;
  }
}

.select-all-button {
  width: 100%;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid $primary-color;
  padding: 8px 20px;
  margin-bottom: 4px;

  font-family: $primary-font;
  color: $primary-color;
  font-size: 14px;
  text-align: left;

  &:hover {
    cursor: pointer;
    background-color: inherit;
  }
}

@media (max-width: 1280px) {
  .convo-select-input ::v-deep.el-select .el-input {
    font-size: 10px;
  }

  ::v-deep.el-select-dropdown__item {
    font-size: 12px;
    padding: 0 8px;
    height: 24px;
    line-height: 24px;
  }
}
</style>
