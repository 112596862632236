import Vue from 'vue';
import Vuex from 'vuex';
import createPlugin from 'vuex-async-persist';
import messagesModule from './modules/messages';
import magicVariablesModule from './modules/magic-variables';
import referencedPdfsModule from './modules/pdf';
import processDefinitionsModule from './modules/process-definitions';
import translationsModule from './modules/translations';
import mailConfigsModule from './modules/mail-configs';
import keyboardControlModule from './modules/keyboard-control';
import analytics from './modules/analytics';
import oidc from './modules/oidc';
import expressionLanguage from './modules/expression-language';

Vue.use(Vuex);

export const ANALYTICS_NAMESPACE = 'analytics';

export const ASYNC_PERSIST_KEY = 'convo';

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    analytics: analytics('analytics'),
    clipboard: keyboardControlModule('clipboard'),
    expressionLanguage: expressionLanguage('expressionLanguage'),
    i18n: translationsModule('i18n'),
    magicVariables: magicVariablesModule('magicVariables'),
    mailConfigs: mailConfigsModule('mailConfigs'),
    messages: messagesModule('messages'),
    oidc,
    pdf: referencedPdfsModule('referencedPdfs'),
    processDefinitions: processDefinitionsModule('processDefinitions'),
  },
  plugins: [
    createPlugin({
      mutationsToIgnore: ['oidc', 'convoProcess', 'clipboard', 'magicVariables', 'messages', 'analytics', 'cache', 'expressionLanguage'],
      paths: ['i18n', 'processDefinitions', 'mailConfigs'],
      overwrite: true,
      key: ASYNC_PERSIST_KEY,
    }),
  ],
});

export default store;
