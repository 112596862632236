import Vue from 'vue';

const getDefaultState = () => ({
  referencedPdfs: [],
});

export default function referencedPdfs() {
  const initialState = getDefaultState();

  const getters = {
    referencedPdfs: (state) => {
      return state.referencedPdfs;
    },
  };

  const mutations = {
    addReferencedPdf(state, pdfId) {
      state.referencedPdfs.push(pdfId);
    },
    deleteReferencedPdf(state, pdfIdToDelete) {
      const clearedArray = state.referencedPdfs.filter((pdfId) => pdfId !== pdfIdToDelete);
      Vue.set(state, 'referencedPdfs', clearedArray);
    },
    updateReferencedPdfs(state, newReferencedPdfs) {
      Vue.set(state, 'referencedPdfs', newReferencedPdfs);
    },
    clear(state) {
      // Make it reactive
      Object.entries(getDefaultState()).forEach(([key, value]) => {
        Vue.set(state, key, value);
      });
    },
  };

  return {
    state: initialState,
    getters,
    mutations,
  };
}
