/**
 * Router Mixin to configure navigation globally.
 */
export default {
  computed: {
    showHeaders() {
      const routesWithoutHeaders = ['/preview-full', 'start', '/404'];
      return !routesWithoutHeaders.find((ele) => this.$route.path.toString().includes(ele));
    },
    isEditorialView() {
      return this.$route.path.includes('i18n');
    },
    isStudioEditor() {
      return this.$route.path.includes('studio');
    },
    isDashboard() {
      return this.$route.path.includes('dashboard');
    },
    isPreview() {
      return this.$route.path.includes('preview');
    },
  },
  methods: {
    navigateToDashboard() {
      const path = '/dashboard';
      if (this.$route.path !== path) {
        this.$router.push(path);
      } else {
        this.$router.go(0);
      }
    },
    navigateToStudio(cpdId, version) {
      const path = `/studio/${cpdId}/${version}/config`;
      if (this.$route.path !== path && cpdId && version) {
        this.$router.push(path);
      }
    },
    navigateToPreview(cpdId, version) {
      const path = `/preview/${cpdId}/${version}`;
      if (this.$route.path !== path && cpdId && version) {
        this.$router.push(path);
      }
    },
    openPreviewInNewTab(cpdId, versionNumber) {
      const routeData = this.$router.resolve({
        name: 'Preview',
        params: {
          cpd: cpdId,
          version: versionNumber,
        },
      });
      window.open(routeData.href, '_blank');
    },
    navigateToInspectorPanel(compId, targetKey) {
      const cpdId = this.$route.params.cpd;
      const versionNumber = this.$route.params.version;
      const path = `/studio/${cpdId}/${versionNumber}/component/${compId}`;
      if (this.$route.path !== path && cpdId && versionNumber && compId) {
        this.$router.replace({ path, query: { target: targetKey } });
      }
    },
    navigateToProcessConfig() {
      const cpdId = this.$route.params.cpd;
      const versionNumber = this.$route.params.version;
      const path = `/studio/${cpdId}/${versionNumber}/config`;
      if (this.$route.path !== path && cpdId && versionNumber) {
        this.$router.replace({ path });
      }
    },
    clearInspectorPanel(compId) {
      const cpdId = this.$route.params.cpd;
      const versionNumber = this.$route.params.version;
      if (!compId || this.$route.path.includes(compId)) {
        this.navigateToStudio(cpdId, versionNumber);
      }
    },
    navigateToEditorialView(cpdId, version) {
      const path = `/studio/${cpdId}/${version}/i18n`;
      if (this.$route.path !== path && cpdId && version) {
        this.$router.push(path);
      }
    },
    navigateBackwards() {
      this.$router.go(-1);
    },
    navigateHome() {
      this.$router.push('/');
    },
  },
};
