<template>
  <div ref="container" class="convo-list-input convo-ip-input">
    <div v-if="isListOfTypeArray">
      <div v-for="(itemId, index) in list" :key="itemId" class="list-item">
        <div class="list-item-header">
          {{ $t(`${i18nPrefix}.itemName`) + ' ' + (index + 1) }}
          <div class="remove-list-item" @click="removeItem(index)"><i class="convo-icon-delete" /></div>
        </div>
        <el-form label-width="120px" label-position="top" :model="{}" ref="listform">
          <!-- model is required to ensure validation, but is not used -->
          <template v-for="field in itemFields">
            <convo-input-delegator
              :editable="editable"
              :key="field.key"
              :parent-id="itemId"
              :field="field"
              :i18n-prefix="i18nPrefix + '.' + field.key"
            />
          </template>
        </el-form>
      </div>

      <div class="add-list-item-holder">
        <div :class="['add-list-item', { 'not-editable': !editable }]" :id="`add-${fieldKey}-item`" @click="addItem">
          <i class="convo-icon-plus-circle" /> {{ $t(`${i18nPrefix}.addItem`) }}
        </div>
      </div>
    </div>

    <div class="mv-input" v-if="canBeMvList">
      <template v-if="isConvoExpressionLanguage">
        <el-popover ref="popover-cel" trigger="hover" width="480" placement="bottom-start" :open-delay="800">
          <convo-expression
            :key="dialogVisible"
            disabled
            :expression-id="expression"
            :heading="expressionHeading"
            :required-input-type="CelReturnType.LIST"
          />
        </el-popover>
        <span v-popover:popover-cel :id="`expression-language-placeholder-${fieldKey}`" class="active-mv">
          <i v-for="i in 4" :key="i" class="convo-icon-placeholder" />
        </span>
      </template>
      <el-button :class="`expression-language-dialog-button-${fieldKey}`" @click="handleOpen" size="mini" class="mv-button">
        <i v-if="isConvoExpressionLanguage" class="convo-icon-function-circle-active" />
        <i v-else class="convo-icon-function-circle-default" />
      </el-button>
      <convo-expression-language-dialog
        :visible="dialogVisible"
        :value="list"
        :type="CelReturnType.LIST"
        @update="handleUpdate"
        @close="handleClose"
        :parentId="parentId"
        :field-key="fieldKey"
        :expression-heading="expressionHeading"
      />
    </div>
  </div>
</template>

<script>
import CpdModificationService from '@/common/services/CpdModificationService';
import ConvoInputMixin from '@/studio/mixins/ConvoInputMixin';
import MagicVariablesService from '@/common/services/MagicVariablesService';
import CelReturnType from '@/common/enums/CelReturnType';
import ConvoInputDelegator from '@/studio/components/right_sidepanel/inspector_panel/ConvoInputDelegator.vue';

export default {
  name: 'ConvoListInput',
  mixins: [ConvoInputMixin],
  components: { ConvoInputDelegator },
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
    itemTemplate: {
      type: Object,
      required: true,
    },
    isMvList: {
      type: Boolean,
      default: false,
    },
    i18nPrefix: {
      type: String,
      required: true,
    },
    canBeMvList: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      CelReturnType,
    };
  },
  computed: {
    list: {
      get() {
        return CpdModificationService.getElementField(this.parentId, this.fieldKey);
      },
      set(newValue) {
        CpdModificationService.updateElementField(this.parentId, this.fieldKey, newValue);
      },
    },
    isListOfTypeArray() {
      return Array.isArray(this.list);
    },
    itemFields() {
      return Object.keys(this.itemTemplate).map((fieldKey) => ({
        key: fieldKey,
        ...this.itemTemplate[fieldKey],
      }));
    },
    listObjects() {
      return this.isListOfTypeArray ? this.list.map(CpdModificationService.getElement) : this.list;
    },
    expressionHeading() {
      return this.$t(`${this.i18nPrefix}.expressionHeading`, [CpdModificationService.getElementField(this.parentId, 'name')]);
    },
  },
  watch: {
    list(newValue) {
      if (newValue === null) this.list = [];
    },
    listObjects: {
      deep: true,
      handler() {
        // timeout is necessary for this.$refs to be updated
        setTimeout(this.forceValidation, 0);
      },
    },
  },
  methods: {
    addItem() {
      if (!this.editable) return;
      const itemCreationHandler = (id) => {
        const newItem = { name: this.$t(`${this.i18nPrefix}.itemName`) };
        Object.keys(this.itemTemplate).forEach((field) => {
          // add every field of item template
          const fieldTemplate = this.itemTemplate[field];
          const isTranslatableField =
            (fieldTemplate.type === 'String' || fieldTemplate.type === 'RichText') && !fieldTemplate.translation_disabled;
          // initialize with default value or translation key depending whether field is translatable
          newItem[field] = isTranslatableField ? `$TRANSLATE(${id}.${field})` : fieldTemplate.default;
        });
        return newItem;
      };
      const id = CpdModificationService.createElement(itemCreationHandler);
      const listClone = this.isListOfTypeArray ? JSON.parse(JSON.stringify(this.list)) : [];
      listClone.push(id);
      this.list = listClone;
      if (this.isMvList) {
        MagicVariablesService.addMagicVariable(`${this.parentId}.${this.fieldKey}.${id}`);
      }
    },
    removeItem(index) {
      if (!this.editable) return;
      const elementId = this.list[index];
      if (this.isMvList) {
        MagicVariablesService.deleteMagicVariable(`${this.parentId}.${this.fieldKey}.${elementId}`);
      }
      const listClone = JSON.parse(JSON.stringify(this.list));
      listClone.splice(index, 1);
      this.list = listClone;
      CpdModificationService.deleteElement(elementId);
    },
    forceValidation() {
      if (this.$refs.listform && this.isListOfTypeArray) {
        this.$refs.listform.forEach((form) => form.validate(() => {}));
      }
    },
    handleUpdate(newValue) {
      this.list = newValue;
    },
  },
  mounted() {
    this.forceValidation();
  },
};
</script>

<style lang="scss" scoped>
@import '@/studio/styles/inputs.scss';
.convo-list-input {
  .list-item {
    .list-item-header {
      display: flex;
      justify-content: space-between;

      border-bottom: 1px solid $primary-color;
      padding-bottom: 4px;
      margin-bottom: 24px;

      color: $primary-color;
      font-size: 16px;
      font-weight: 600;

      .remove-list-item {
        cursor: pointer;
      }
    }

    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }

  .add-list-item-holder {
    display: flex;
    justify-content: center;
    margin-bottom: 32px;

    .add-list-item {
      display: flex;
      align-items: center;
      text-align: center;

      color: $primary-color;
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;

      .convo-icon-plus-circle {
        font-size: 24px;
        margin-right: 8px;
      }
      &.not-editable {
        color: $disabled-color;
      }
    }
  }

  .mv-input {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 1400px) {
  .convo-list-input {
    .list-item .list-item-header,
    .add-list-item-holder .add-list-item {
      font-size: 14px;
    }
  }
}

@media (max-width: 1280px) {
  .convo-list-input {
    .list-item .list-item-header,
    .add-list-item-holder .add-list-item {
      font-size: 12px;
    }

    .add-list-item-holder {
      margin-bottom: 16px;
    }
  }
}
</style>
