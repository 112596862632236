<template>
  <convo-text-input
    v-if="field.type === 'String' || field.type === 'Generic'"
    :cel-disabled="field.cel_disabled"
    :editable="editable"
    :field-key="field.key"
    :i18n-prefix="i18nPrefix"
    :is-generic="field.type === 'Generic'"
    :mandatory="field.mandatory"
    :parent-id="parentId"
    :regex="field.regex"
    :textarea="field.extensive_text"
    :translation-disabled="field.translation_disabled"
  />
  <convo-number-input
    v-else-if="field.type === 'Number'"
    :cel-disabled="field.cel_disabled"
    :disabled="field.disabled"
    :editable="editable"
    :field-key="field.key"
    :i18n-prefix="i18nPrefix"
    :mandatory="field.mandatory"
    :max="field.max"
    :min="field.min"
    :parent-id="parentId"
  />
  <convo-boolean-input
    v-else-if="field.type === 'Boolean'"
    :cel-disabled="field.cel_disabled"
    :disabled="field.disabled"
    :editable="editable"
    :field-key="field.key"
    :i18n-prefix="i18nPrefix"
    :parent-id="parentId"
    :show-toggle="!field.hide_toggle"
  />
  <convo-date-input
    v-else-if="field.type === 'Date'"
    :cel-disabled="field.cel_disabled"
    :editable="editable"
    :field-key="field.key"
    :i18n-prefix="i18nPrefix"
    :mandatory="field.mandatory"
    :max="field.max"
    :min="field.min"
    :multiple="field.multiple"
    :parent-id="parentId"
  />
  <convo-select-input
    v-else-if="field.type === 'Select'"
    :editable="editable"
    :field-key="field.key"
    :i18n-prefix="i18nPrefix"
    :mandatory="field.mandatory"
    :multiple="field.multiple"
    :option-strategy="field.option_strategy"
    :options="field.options"
    :parent-id="parentId"
    :translate-options="field.translate_options"
  />
  <convo-list-input
    v-else-if="field.type === 'List'"
    :can-be-mv-list="field.can_be_mv_list"
    :editable="editable"
    :field-key="field.key"
    :i18n-prefix="i18nPrefix"
    :is-mv-list="field.is_mv_list"
    :item-template="field.item_template"
    :parent-id="parentId"
  />
  <convo-rich-text-input
    v-else-if="field.type === 'RichText'"
    :parent-id="parentId"
    :editable="editable"
    :field-key="field.key"
    :i18n-prefix="i18nPrefix"
  />
  <convo-object-input
    v-else-if="field.type === 'Object'"
    :editable="editable"
    :field-key="field.key"
    :i18n-prefix="i18nPrefix"
    :parent-id="parentId"
    :recursion-disabled="field.recursion_disabled"
  />
  <convo-pdf-upload
    v-else-if="field.type === 'FileUpload'"
    :editable="editable"
    :field-key="field.key"
    :i18n-prefix="i18nPrefix"
    :parent-id="parentId"
  />
  <convo-mail-detail-input
    v-else-if="field.type === 'MailDetails'"
    :editable="editable"
    :field-key="field.key"
    :i18n-prefix="i18nPrefix"
    :parent-id="parentId"
  />
</template>

<script>
import ConvoTextInput from './ConvoTextInput.vue';
import ConvoNumberInput from './ConvoNumberInput.vue';
import ConvoBooleanInput from './ConvoBooleanInput.vue';
import ConvoDateInput from './ConvoDateInput.vue';
import ConvoSelectInput from './ConvoSelectInput.vue';
import ConvoRichTextInput from './ConvoRichTextInput.vue';
import ConvoObjectInput from './convo_object_input/ConvoObjectInput.vue';
import ConvoPdfUpload from './ConvoPdfUpload.vue';
import ConvoMailDetailInput from './convo_mail_detail_input/ConvoMailDetailInput.vue';

export default {
  name: 'ConvoInputDelegator',
  components: {
    ConvoMailDetailInput,
    ConvoTextInput,
    ConvoNumberInput,
    ConvoBooleanInput,
    ConvoDateInput,
    ConvoSelectInput,
    ConvoRichTextInput,
    ConvoObjectInput,
    ConvoPdfUpload,
  },
  props: {
    parentId: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    field: {
      type: Object,
      required: true,
    },
    i18nPrefix: {
      type: String,
      default: '',
    },
  },
  beforeCreate() {
    // ConvoListInput uses the ConvoInputDelegator and creates a circular reference by this. Solution:
    // https://vuejs.org/v2/guide/components-edge-cases.html#Circular-References-Between-Components
    /* eslint-disable-next-line  global-require */
    this.$options.components.ConvoListInput = require('./ConvoListInput.vue').default;
  },
};
</script>

<style lang="scss" scoped>
.el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 0 !important;
}

::v-deep .el-form-item--small .el-form-item__error {
  position: relative;
  padding-top: 5px;
}
</style>
