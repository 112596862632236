<template>
  <div class="convo-toolbox-constant">
    <span class="icon-name-holder"
      ><i :class="icon" class="icon" /><span class="constant-name">{{ $t(translationKey) }}</span></span
    >
    <div class="end"></div>
  </div>
</template>

<script>
import CelReturnType, { getName } from '@/common/enums/CelReturnType';

export default {
  name: 'ConvoToolboxConstant',
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    icon() {
      switch (this.type) {
        case CelReturnType.STRING:
          return 'convo-icon-text';
        case CelReturnType.NUMBER:
          return 'convo-icon-number';
        case CelReturnType.BOOLEAN:
          return 'convo-icon-toggle';
        case CelReturnType.DATE:
          return 'convo-icon-datepicker';
        default:
          return '';
      }
    },
    translationKey() {
      return `constant.${getName(this.type)}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.convo-toolbox-constant {
  align-items: center;
  background-color: white;
  border: 1px solid $primary-color;
  border-radius: 10px;
  color: $primary-color;
  cursor: move;
  display: flex;
  font-size: 13px;
  height: 36px;
  justify-content: space-between;
  overflow: hidden;

  .icon-name-holder {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(100% - 20px);
    .icon {
      color: $convo-blue-3;
      margin: 0 20px;
    }
  }

  .end {
    background-color: $convo-blue-3;
    border-left: 1px solid $primary-color;
    height: 100%;
    width: 18px;
  }
}
@media (max-width: 1280px) {
  .convo-toolbox-constant .icon-name-holder .icon {
    margin: 0 4px 0 8px;
  }
}
</style>
