import EntryType from '@/studio/enums/EntryType';
/**
 * This service converts an object into an alternative list representation which is used for the {@link ConvoObjectInput} and its child components.
 * The following rules are applied for this:
 *
 * An object consists of a combination of key-value-entries, key-list-entries and key-object-entries.
 * sample object: { a: 'x', b: { c: 'y'}, d: [ 'z' ]} -> [<keyValueEntry>, <keyObjectEntry>, <keyListEntry>]
 *
 * A key-value-entry consists of a key and a value-entry.
 * example: { type: 'keyValueEntry', key: 'a', value: <valueEntry> }
 *
 * A key-list-entry consists of a key and a list-entry.
 * example: { type: 'keyListEntry', key: 'd', value: <listEntry> }
 *
 * A key-object-entry consists of a key and a object-entry.
 * example: { type: 'keyObjectEntry', key: 'b', value: <objectEntry> }
 *
 * A value-entry consists of exactly one value.
 * example: { type: 'valueEntry', value: 'x' }
 *
 * A list-entry consists of a combination of value-entries, list-entries and object-entries.
 * list entry: { type: 'listEntry', value: [ { type: 'valueEntry', value: 'z' } ] }
 *
 * A object-entry consists of a combination of key-value-entries, key-list-entries and key-object-entries.
 * object entry: { type: 'objectEntry', value: [ { type: 'keyValueEntry', key: 'c', value: { type: 'valueEntry', value: 'y' } } ] }
 */
const ObjectConversionService = (() => {
  function getValueEntry(value) {
    return { type: EntryType.VALUE, value };
  }
  function getObjectEntry(value) {
    return { type: EntryType.OBJECT, value };
  }
  function getListEntry(value) {
    return { type: EntryType.LIST, value };
  }
  function getKeyValueEntry(key, value) {
    return { type: EntryType.KEY_VALUE, key, value };
  }
  function getKeyObjectEntry(key, value) {
    return { type: EntryType.KEY_OBJECT, key, value };
  }
  function getKeyListEntry(key, value) {
    return { type: EntryType.KEY_LIST, key, value };
  }

  function objectToObjectEntry(object) {
    const value = [];

    Object.keys(object).forEach((key) => {
      const val = object[key];
      if (Array.isArray(val)) {
        // eslint-disable-next-line no-use-before-define
        value.push(getKeyListEntry(key, listToListEntry(val)));
      } else if (typeof val === 'object' && val !== null) {
        value.push(getKeyObjectEntry(key, objectToObjectEntry(val)));
      } else {
        value.push(getKeyValueEntry(key, getValueEntry(val)));
      }
    });

    return getObjectEntry(value);
  }

  function listToListEntry(list) {
    const value = [];

    list.forEach((val) => {
      if (Array.isArray(val)) {
        value.push(listToListEntry(val));
      } else if (typeof val === 'object' && val !== null) {
        value.push(objectToObjectEntry(val));
      } else {
        value.push(getValueEntry(val));
      }
    });

    return getListEntry(value);
  }

  function objectEntryToObject(objectEntry) {
    const object = {};
    objectEntry.value.forEach((entry) => {
      if (entry.type === EntryType.KEY_OBJECT) {
        object[entry.key] = objectEntryToObject(entry.value);
      } else if (entry.type === EntryType.KEY_LIST) {
        // eslint-disable-next-line no-use-before-define
        object[entry.key] = listEntryToList(entry.value);
      } else {
        object[entry.key] = entry.value.value;
      }
    });

    return object;
  }

  function listEntryToList(listEntry) {
    return listEntry.value.map((entry) => {
      if (entry.type === EntryType.OBJECT) {
        return objectEntryToObject(entry);
      }
      if (entry.type === EntryType.LIST) {
        return listEntryToList(entry);
      }
      return entry.value;
    });
  }

  return {
    getEmptyKeyValueEntry() {
      return getKeyValueEntry('', getValueEntry(''));
    },
    getEmptyKeyObjectEntry() {
      return getKeyObjectEntry('', getObjectEntry([]));
    },
    getEmptyKeyListEntry() {
      return getKeyListEntry('', getListEntry([]));
    },
    getEmptyValueEntry() {
      return getValueEntry('');
    },
    getEmptyObjectEntry() {
      return getObjectEntry([]);
    },
    getEmptyListEntry() {
      return getListEntry([]);
    },
    objectToList(object) {
      return objectToObjectEntry(object).value;
    },
    listToObject(list) {
      return objectEntryToObject(getObjectEntry(list));
    },
  };
})();

export default ObjectConversionService;
