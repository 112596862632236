<template>
  <div class="convo-rich-text-editor">
    <div v-if="!editable" v-html="content" class="rich-text-preview" />
    <editor-content v-else-if="!menubarFirst" class="editor-content" :editor="editor" />
    <editor-menu-bar v-if="editable" :editor="editor" v-slot="{ commands, isActive }">
      <div class="menubar">
        <el-button :class="{ 'is-active': isActive.bold() }" @click="commands.bold" class="menubar-button">
          <i class="convo-icon-bold" />
        </el-button>

        <el-button :class="{ 'is-active': isActive.italic() }" @click="commands.italic" class="menubar-button">
          <i class="convo-icon-italic" />
        </el-button>

        <el-button :class="{ 'is-active': isActive.strike() }" @click="commands.strike" class="menubar-button">
          <i class="convo-icon-strikethrough" />
        </el-button>

        <el-button :class="{ 'is-active': isActive.underline() }" @click="commands.underline" class="menubar-button">
          <i class="convo-icon-underlined" />
        </el-button>

        <el-button :class="{ 'is-active': isActive.bullet_list() }" @click="commands.bullet_list" class="menubar-button">
          <i class="convo-icon-bullet-point" />
        </el-button>

        <el-button :class="{ 'is-active': isActive.ordered_list() }" @click="commands.ordered_list" class="menubar-button">
          <i class="convo-icon-numbering" />
        </el-button>

        <el-button :class="{ 'is-active': isActive.blockquote() }" @click="commands.blockquote" class="menubar-button">
          <i class="convo-icon-quote" />
        </el-button>

        <el-button @click="commands.horizontal_rule" class="menubar-button">
          <i class="convo-icon-stroke" />
        </el-button>

        <el-button v-if="!hideUndoRedo" @click="commands.undo" class="menubar-button">
          <i class="convo-icon-undo" />
        </el-button>

        <el-button v-if="!hideUndoRedo" @click="commands.redo" class="menubar-button">
          <i class="convo-icon-redo" />
        </el-button>
      </div>
    </editor-menu-bar>
    <editor-content v-if="menubarFirst && editable" class="editor-content" :editor="editor" />
    <editor-menu-bubble class="menububble" :editor="editor" @hide="hideLinkMenu" v-slot="{ commands, isActive, getMarkAttrs, menu }">
      <div class="menububble" :class="{ 'is-active': menu.isActive }" :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`">
        <form class="menububble__form" v-if="linkMenuIsActive" @submit.prevent="setLinkUrl(commands.link)">
          <input
            class="menububble__input"
            type="text"
            v-model="linkUrl"
            placeholder="https://"
            ref="linkInput"
            @keydown.esc="hideLinkMenu"
          />
          <button class="menububble__button" @click="deleteLinkUrl(commands.link)" type="button">
            <i class="convo-icon-delete" />
          </button>
        </form>
        <template v-else>
          <button class="menububble__button" @click="showLinkMenu(getMarkAttrs('link'))" :class="{ 'is-active': isActive.link() }">
            <span>{{ isActive.link() ? $t('richTextEditor.updateLink') : $t('richTextEditor.addLink') }}</span>
            <i class="convo-icon-chain" />
          </button>
        </template>
      </div>
    </editor-menu-bubble>
  </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar, EditorMenuBubble } from 'tiptap';
import {
  Blockquote,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  Bold,
  Italic,
  Link,
  Strike,
  Underline,
  History,
} from 'tiptap-extensions';

export default {
  name: 'ConvoRichTextEditor',
  components: {
    EditorContent,
    EditorMenuBar,
    EditorMenuBubble,
  },
  props: {
    content: {
      type: String,
      default: '',
    },
    menubarFirst: {
      type: Boolean,
      default: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    hideUndoRedo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editor: new Editor({
        onUpdate: ({ getHTML }) => {
          this.$emit('update', this.cleanHtml(getHTML()));
        },
        extensions: [
          new Blockquote(),
          new BulletList(),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new Link(),
          new Bold(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
        ],
        content: this.content,
      }),
      linkUrl: null,
      linkMenuIsActive: false,
    };
  },
  methods: {
    showLinkMenu(attrs) {
      this.linkUrl = attrs.href;
      this.linkMenuIsActive = true;
      this.$nextTick(() => {
        this.$refs.linkInput.focus();
      });
    },
    hideLinkMenu() {
      this.linkUrl = null;
      this.linkMenuIsActive = false;
    },
    setLinkUrl(command) {
      this.linkUrl = this.linkUrl.startsWith('https://') ? this.linkUrl : `https://${this.linkUrl}`;
      command({ href: this.linkUrl });
      this.hideLinkMenu();
    },
    deleteLinkUrl(command) {
      this.linkUrl = null;
      command({ href: null });
      this.hideLinkMenu();
    },
    cleanHtml(html) {
      return html === '<p></p>' ? '' : html;
    },
  },
  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>

<style lang="scss" scoped>
.convo-rich-text-editor {
  .editor-content {
    margin: 10px 0;
    background-color: white;
  }

  .menubar {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    background-color: white;

    .menubar-button {
      border: 0;
      margin: 0;

      &.is-active {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }

    .convo-icon-stroke {
      font-size: 2px;
    }

    .convo-icon-chain {
      margin-left: 4px;
    }

    .convo-icon-bullet-point {
      font-size: 11px;
    }

    .convo-icon-quote {
      font-size: 10px;
    }

    .convo-icon-undo,
    .convo-icon-redo {
      font-size: 13px;
    }

    .convo-icon-underlined {
      font-size: 14px;
    }
  }
}
</style>

<style lang="scss">
// overwriting of these css classes doesn't work in scoped css
.convo-rich-text-editor {
  .el-dialog .el-dialog__body {
    padding-top: 0;
    padding-bottom: 0;
  }
  .ProseMirror {
    padding: 5px 10px;
    border: 1px solid $border-color;
    border-radius: 5px;
    min-height: 250px;

    p {
      margin: 0;
    }

    blockquote {
      border-left: 3px solid rgba(black, 0.1);
      padding-left: 10px;
      font-style: italic;
    }

    &:focus-visible {
      outline: none;
      border-color: $primary-color;
    }
  }

  .menububble {
    position: absolute;
    display: flex;
    z-index: 20;
    background: rgb(0, 0, 0);
    border-radius: 5px;
    padding: 0.3rem;
    margin-bottom: 0.5rem;
    transform: translateX(-50%);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s, visibility 0.2s;

    &.is-active {
      opacity: 1;
      visibility: visible;
    }

    &__button {
      display: inline-flex;
      background: transparent;
      border: 0;
      color: rgb(255, 255, 255);
      padding: 0.2rem 0.5rem;
      margin-right: 0.2rem;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }

      &.is-active {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }

    &__form {
      display: flex;
      align-items: center;
    }

    &__input {
      font: inherit;
      border: none;
      background: transparent;
      color: rgb(255, 255, 255);
    }
  }
}

@media (max-width: 1280px) {
  .convo-rich-text-editor {
    .ProseMirror {
      padding: 6px;
      min-height: 150px;
      font-size: 14px;
    }
  }
}
</style>
