<template>
  <div class="convo-toast-holder">
    <convo-toast
      v-for="toast in displayedToasts"
      :key="toast.id"
      :warning="toast.warning"
      :message-key="toast.messageKey"
      :time-to-live="timeToLive"
      @close="removeToast(toast.id)"
      @mouseenter="toast.timer.pause"
      @mouseleave="toast.timer.resume"
    />
  </div>
</template>

<script>
import EventBus from '@/common/event-bus';
import ConvoToast from '@/common/components/ConvoToast.vue';
import { uuid } from 'vue-uuid';
import Timer from '@/common/services/Timer';

export default {
  name: 'ConvoToastHolder',
  components: { ConvoToast },
  data() {
    return {
      toasts: [],
      timeToLive: 5000,
    };
  },
  computed: {
    displayedToasts() {
      return this.toasts.slice(-5);
    },
  },
  methods: {
    addToast(messageKey, warning) {
      const id = uuid.v4();
      const timer = new Timer(() => this.removeToast(id), this.timeToLive);
      this.toasts.push({ id, messageKey, warning, timer });
    },
    clearToasts() {
      this.toasts = [];
    },
    removeToast(id) {
      this.toasts = this.toasts.filter((t) => t.id !== id);
    },
  },
  mounted() {
    EventBus.onClearToasts(this.clearToasts);
    EventBus.onToast(this.addToast);
  },
  beforeDestroy() {
    EventBus.onClearToasts(this.clearToasts, true);
    EventBus.onToast(this.addToast, true);
  },
};
</script>

<style lang="scss" scoped>
.convo-toast-holder {
  bottom: 80px;
  left: 80px;
  position: absolute;
  z-index: 999;

  .convo-toast:not(:last-child) {
    margin-bottom: 16px;
  }
}

@media (max-width: 1280px) {
  .convo-toast-holder {
    bottom: 25px;
    left: 25px;
  }
}
</style>
