<template>
  <div class="convo-text-input convo-ip-input">
    <el-form-item :prop="fieldKey" :rules="[{ validator, trigger }]">
      <!-- prop is required to ensure validation, but is not used -->
      <el-popover
        ref="popover"
        :title="localizedTooltipTitle"
        trigger="hover"
        :content="localizedTooltipText"
        placement="left-start"
        width="320"
        :open-delay="800"
      />
      <span class="no-tabindex" slot="label" v-popover:popover>{{ localizedLabel }}</span>
      <el-input
        v-if="celDisabled || !isConvoExpressionLanguage"
        :disabled="!editable"
        :id="`${fieldKey}-input-${parentId}`"
        :type="textarea ? 'textarea' : 'text'"
        :rows="1"
        autosize
        v-model="i18nValue"
        :placeholder="localizedPlaceholder"
      />
      <template v-else>
        <el-popover ref="popover-cel" trigger="hover" width="480" placement="bottom-start" :open-delay="800">
          <convo-expression
            :key="dialogVisible"
            disabled
            :expression-id="expression"
            :heading="expressionHeading"
            :required-input-type="CelReturnType.STRING"
          />
        </el-popover>
        <span v-popover:popover-cel :id="`expression-language-placeholder-${fieldKey}`" class="active-mv">
          <i v-for="i in 4" :key="i" class="convo-icon-placeholder" />
        </span>
      </template>
      <el-button
        v-if="!celDisabled"
        size="mini"
        tabindex="-1"
        :class="['mv-button', `expression-language-dialog-button-${fieldKey}`, { 'not-editable': !editable }]"
        @click="handleOpen"
      >
        <i v-if="isConvoExpressionLanguage" class="convo-icon-function-circle-active" />
        <i v-else class="convo-icon-function-circle-default" />
      </el-button>
    </el-form-item>
    <convo-expression-language-dialog
      v-if="!celDisabled"
      :visible="dialogVisible"
      :value="value"
      :type="isGeneric ? CelReturnType.GENERIC : CelReturnType.STRING"
      @update="handleUpdate"
      @close="handleClose"
      :parent-id="parentId"
      :field-key="fieldKey"
      :expression-heading="expressionHeading"
    />
  </div>
</template>

<script>
import TranslationModificationService from '@/common/services/TranslationModificationService';
import ConvoInputMixin from '@/studio/mixins/ConvoInputMixin';
import ConvoInputI18nMixin from '@/studio/mixins/ConvoInputI18nMixin';
import ValidationMixin from '@/studio/mixins/ValidationMixin';
import CelReturnType from '@/common/enums/CelReturnType';

export default {
  name: 'ConvoTextInput',
  mixins: [ConvoInputMixin, ConvoInputI18nMixin, ValidationMixin],
  props: {
    celDisabled: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    isGeneric: {
      type: Boolean,
      default: false,
    },
    textarea: {
      type: Boolean,
      default: false,
    },
    translationDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      CelReturnType,
    };
  },
  computed: {
    i18nValue: {
      get() {
        if (this.translationDisabled) {
          return this.value;
        }
        const preferredLang = TranslationModificationService.getPreferredLang();
        return TranslationModificationService.getTranslation(preferredLang, this.parentId, this.fieldKey);
      },
      set(newValue) {
        if (this.translationDisabled) {
          this.value = newValue;
        } else {
          const preferredLang = TranslationModificationService.getPreferredLang();
          const fieldValue = TranslationModificationService.getTranslation(preferredLang, this.parentId, this.fieldKey);
          if (fieldValue !== newValue) {
            TranslationModificationService.updateTranslation(preferredLang, this.parentId, this.fieldKey, newValue);
          }
        }
      },
    },
  },
  methods: {
    handleUpdate(newValue) {
      if (this.translationDisabled) {
        this.value = newValue;
      } else {
        this.value = newValue !== null ? newValue : `$TRANSLATE(${this.parentId}.${this.fieldKey})`;
      }
    },
    validator(rule, value, callback) {
      this.validate(
        this.mandatory,
        (evaluated) => evaluated && !this.i18nValue,
        () => this.$t('validationError.mandatory'),
        callback
      );
      this.validate(
        this.regex,
        (evaluated) => this.i18nValue && !new RegExp(`^${evaluated}$`).test(this.i18nValue),
        () => this.$t('validationError.regex'),
        callback
      );
      callback();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/studio/styles/inputs.scss';

.convo-text-input {
  .el-input,
  .el-textarea {
    width: calc(100% - 52px);

    ::v-deep.el-input__inner,
    ::v-deep.el-textarea__inner {
      font-family: $primary-font;
      color: $primary-color;
    }
  }
}
</style>
