import CpdModificationService from '@/common/services/CpdModificationService';
import TranslationModificationService from '@/common/services/TranslationModificationService';
import ConvoProcessComponentService from '@/common/services/ConvoProcessComponentService';
import MagicVariablesService from '@/common/services/MagicVariablesService';
import ConvoExpressionLanguageService from '@/common/services/ConvoExpressionLanguageService';
import CpdIdService from '@/common/services/CpdIdService';
import CanvasSlot from '@/studio/enums/CanvasSlot';
import EventBus from '@/common/event-bus';

export default (isCanvas) => {
  return {
    methods: {
      copy() {
        const elements = {};
        const translations = { de: {}, en: {} };

        const addElementsToClipboard = (id) => {
          const element = CpdModificationService.getElement(id);
          elements[id] = JSON.parse(JSON.stringify(element));

          // handle children
          (element.children || []).forEach(addElementsToClipboard);
          (element.choice_items || []).forEach(addElementsToClipboard);

          // handle translations
          Object.keys(translations).forEach((lang) => {
            translations[lang] = {
              ...translations[lang],
              ...TranslationModificationService.getTranslationsForElement(lang, id),
            };
          });
        };

        addElementsToClipboard(this.componentId);

        this.$store.commit('overwriteClipboard', {
          copiedId: this.componentId,
          elements,
          translations,
        });

        EventBus.emitToast('toast.elementCopied');
      },
      paste(event) {
        const { clipboard } = this.$store.getters;
        if (clipboard.copiedId) {
          const copiedCanvasSlot = ConvoProcessComponentService.getMetadata(clipboard.elements[clipboard.copiedId].type).canvasSlot;
          const providedCanvasSlot = isCanvas
            ? CanvasSlot.WITHIN_CANVAS
            : ConvoProcessComponentService.getMetadata(CpdModificationService.getElementField(this.componentId, 'type')).providedCanvasSlot;
          if (copiedCanvasSlot === providedCanvasSlot) {
            // Step 1: define recursive method to add copy of clipboard entry under new id (return value) to store
            const insertCopyIntoStore = (id) => {
              // Step 1.1: create a deep copy of the clipboard entry and generate new id
              const elementCopy = JSON.parse(JSON.stringify(clipboard.elements[id]));
              const newId = CpdIdService.getId();

              // Step 1.2: call method recursively for children
              if (elementCopy.children) {
                elementCopy.children = elementCopy.children.map((childId) => insertCopyIntoStore(childId));
              }
              if (elementCopy.choice_items) {
                elementCopy.choice_items = elementCopy.choice_items.map((itemId) => insertCopyIntoStore(itemId));
              }

              // Step 1.3: replace translation keys and copy translations from clipboard to store
              Object.keys(elementCopy).forEach((key) => {
                const value = elementCopy[key];
                if (typeof value === 'string' && value.startsWith(`$TRANSLATE(${id}.`)) {
                  Object.keys(clipboard.translations).forEach((lang) => {
                    const translationKey = value.substring(value.indexOf('(') + 1, value.indexOf(')'));
                    TranslationModificationService.updateTranslation(
                      lang,
                      newId,
                      translationKey.split('.')[1],
                      clipboard.translations[lang][translationKey]
                    );
                  });
                  elementCopy[key] = value.replace(id, newId);
                }
              });

              // Step 1.4 commit element to store
              this.$store.commit('createElement', { id: newId, element: elementCopy });

              // Step 1.5: updatemagic variable references
              Object.keys(elementCopy).forEach((key) => {
                const value = elementCopy[key];
                MagicVariablesService.updateMvReferencesForElementField(newId, key, ConvoExpressionLanguageService.getReferencedMvs(value));
              });
              MagicVariablesService.addElement(newId);
              return newId;
            };

            // Step 2: call defined method and add the id of the pasted element to children
            const clonedChildren = JSON.parse(JSON.stringify(this.children));
            clonedChildren.push(insertCopyIntoStore(clipboard.copiedId));
            this.children = clonedChildren;

            EventBus.emitToast('toast.elementPasted');
            event.stopPropagation();
          }
        }
      },
      copyOnMac() {
        if (window.navigator.platform.startsWith('Mac')) {
          this.copy();
        }
      },
      pasteOnMac(event) {
        if (window.navigator.platform.startsWith('Mac')) {
          this.paste(event);
        }
      },
    },
  };
};
