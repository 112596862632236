import Vue from 'vue';

const getDefaultState = () => ({
  messages: [],
});

export default function messages() {
  const initialState = getDefaultState();

  const getters = {
    messages: (state) => {
      return (type) => state.messages.filter((msg) => !type || msg.type === type);
    },
  };

  const mutations = {
    addMessage(state, { type, contentHandler, actionHandler, id }) {
      state.messages.push({ type, contentHandler, actionHandler, id });
    },
    deleteMessage(state, id) {
      Vue.set(
        state,
        'messages',
        state.messages.filter((message) => message.id !== id)
      );
    },
    clear(state) {
      // Make it reactive
      Object.entries(getDefaultState()).forEach(([key, value]) => {
        Vue.set(state, key, value);
      });
    },
  };

  return {
    state: initialState,
    getters,
    mutations,
  };
}
