<template>
  <div class="convo-device-selector">
    <div class="activator">
      <i :class="[deviceIcon, { selected: isSelected }]" @click="handleDeviceChange(selectedDevice)" :id="`${category}-selector`" />
      <div @click="handleActivatorToggle" ref="activator" :id="`${category}-options-activator`"><i class="convo-icon-down" /></div>
    </div>
    <div class="options" v-if="optionsVisible" @click.stop>
      <div
        :id="`option-${device.key}`"
        class="option"
        :class="{ selected: device.key === selectedDevice.key }"
        v-for="device in devices"
        :key="device.key"
        @click.stop="handleDeviceChange(device)"
      >
        {{ $t(`preview.devices.${device.key}`) }}
      </div>
    </div>
  </div>
</template>

<script>
import defaultDevices from '@/preview/environments/devices.json';
import EventBus from '@/common/event-bus';

export default {
  name: 'ConvoDeviceSelector',
  props: {
    category: {
      type: String,
      required: true,
    },
    deviceIcon: {
      type: String,
      required: true,
    },
    isSelected: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      optionsVisible: false,
      selectedDevice: defaultDevices.find((device) => device.category === this.category),
    };
  },
  computed: {
    devices() {
      return defaultDevices.filter((device) => device.category === this.category);
    },
  },
  methods: {
    handleDeviceChange(device) {
      this.selectedDevice = device;
      this.$emit('select');
      EventBus.emitPreviewEnvironmentChange(device.width, device.height, device.key, device.frame);
      this.closeOptions();
    },
    handleActivatorToggle() {
      if (this.optionsVisible) {
        this.closeOptions();
      } else {
        this.openOptions();
      }
    },
    openOptions() {
      this.optionsVisible = true;
      document.addEventListener('click', this.closeOptions);
    },
    closeOptions(e) {
      if (!e || (e.target !== this.$refs.activator && e.target.parentElement !== this.$refs.activator)) {
        this.optionsVisible = false;
        document.removeEventListener('click', this.closeOptions);
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeOptions);
  },
};
</script>

<style lang="scss" scoped>
.convo-device-selector {
  position: relative;

  .activator {
    color: $primary-text-color;
    cursor: pointer;
    display: flex;
    font-size: 24px;

    .selected {
      color: $secondary-color;
    }
    .convo-icon-down {
      font-size: 6px;
      margin-left: 8px;
    }
  }

  .options {
    background-color: $primary-text-color;
    border-radius: 8px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    color: $primary-color;
    font-size: 16px;
    left: 0;
    min-width: 240px;
    padding: 4px;
    position: absolute;
    top: 32px;
    z-index: 1;

    .option {
      cursor: pointer;
      margin: 4px;
      padding: 4px;

      &:hover {
        background-color: $convo-blue-1;
      }

      &.selected {
        background-color: $convo-blue-2;
      }
    }
  }
}

@media (max-width: 1280px) {
  .convo-device-selector {
    .activator {
      font-size: 18px;
    }
    .options {
      top: 24px;
      padding: 0;
      min-width: 160px;
      font-size: 13px;
    }
  }
}
</style>
