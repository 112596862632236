<template>
  <div class="convo-mail-configuration-dialog">
    <el-dialog :closeOnClickModal="false" top="16vh" :visible.sync="dialogVisible" @close="handleClose">
      <div slot="title" class="title">{{ translate('mailConfigurationDialog.title') }}</div>
      <div class="content">
        <convo-recipient-input
          v-model="recipients"
          :disabled="disabled"
          :limitReached="recipientLimitReached"
          :expression-heading="translate('recipients.expressionHeading')"
          :parent-id="parentId"
          :recipient-label="translate('recipients.label')"
        />
        <convo-recipient-input
          v-model="ccRecipients"
          :disabled="disabled"
          :limitReached="recipientLimitReached"
          :expression-heading="translate('cc_recipients.expressionHeading')"
          :parent-id="parentId"
          :recipient-label="translate('cc_recipients.label')"
        />
        <convo-recipient-input
          v-model="bccRecipients"
          :disabled="disabled"
          :limitReached="recipientLimitReached"
          :expression-heading="translate('bcc_recipients.expressionHeading')"
          :parent-id="parentId"
          :recipient-label="translate('bcc_recipients.label')"
        />

        <convo-subject-input v-model="subject" :disabled="disabled" :parent-id="parentId" field-key="subject" />

        <div class="attachments">
          <div class="attachment-label">{{ translate('mailConfigurationDialog.attachments') }}</div>
          <div v-if="attachments.length === 0" class="attachment-placeholder">{{ translate('mailConfigurationDialog.noAttachments') }}</div>
          <div v-for="(attachment, index) in attachments" :key="index" class="attachment">
            <span class="attachment-name">{{ attachment.name }}</span>
            <i class="convo-icon-cancel" @click="removeAttachment(index)" />
          </div>
          <div class="attachment-buttons">
            <convo-attachment-select
              activator-icon="convo-icon-pdf"
              :options="pdfElements"
              :disabled="disabled || attachments.length >= 5"
              tooltip-key="mail_generation.mailConfigurationDialog.pdfAttachmentTooltip"
              @select="addAttachment($event)"
            />
            <convo-attachment-select
              :options="fielUploadElements"
              :disabled="disabled || attachments.length >= 5"
              tooltip-key="mail_generation.mailConfigurationDialog.attachmentTooltip"
              @select="addAttachment($event)"
            />
          </div>
        </div>
        <convo-rich-text-editor
          :class="{ disabled }"
          :content="contentTemplate"
          :editable="!disabled"
          hide-undo-redo
          @update="handleContentUpdate"
        />
        <div class="navigation">
          <el-button @click="handleClose">{{ $t('cancel') }}</el-button>
          <el-button class="primary" @click="confirm" :disabled="disabled || !isValid">{{ $t('apply') }}</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ConvoRichTextEditor from '@/common/components/ConvoRichTextEditor.vue';
import ConvoRecipientInput from '@/studio/components/right_sidepanel/inspector_panel/convo_mail_detail_input/ConvoRecipientInput.vue';
import CpdModificationService from '@/common/services/CpdModificationService';
import ElementType from '@/common/enums/ElementType';
import ConvoAttachmentSelect from '@/studio/components/right_sidepanel/inspector_panel/convo_mail_detail_input/ConvoAttachmentSelect.vue';
import TranslationModificationService from '@/common/services/TranslationModificationService';
import ConvoSubjectInput from '@/studio/components/right_sidepanel/inspector_panel/convo_mail_detail_input/ConvoSubjectInput.vue';
import MailConfigModificationService from '@/common/services/MailConfigModificationService';

export default {
  name: 'ConvoMailConfigurationDialog',
  components: { ConvoSubjectInput, ConvoAttachmentSelect, ConvoRecipientInput, ConvoRichTextEditor },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    parentId: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogVisible: this.visible,
      recipients: CpdModificationService.getElementField(this.parentId, 'recipients').slice(),
      ccRecipients: CpdModificationService.getElementField(this.parentId, 'cc_recipients').slice(),
      bccRecipients: CpdModificationService.getElementField(this.parentId, 'bcc_recipients').slice(),
      subject: CpdModificationService.getElementField(this.parentId, 'subject'),
      contentTemplate: MailConfigModificationService.getMailConfigField(this.parentId, 'contentTemplate'),
      attachments: [],
    };
  },
  computed: {
    isValid() {
      return (
        this.subject && this.subject.trim() && (this.recipients.length > 0 || this.ccRecipients.length > 0 || this.bccRecipients.length > 0)
      );
    },
    uniqueCpdId() {
      return this.$route.params.cpd + 'v' + this.$route.params.version;
    },
    pdfElements() {
      return CpdModificationService.getOrderedElements(this.uniqueCpdId, ElementType.PDF)
        .filter((id) => this.attachments.filter((a) => a.id === id).length === 0)
        .map((id) => ({
          id,
          name: this.getPdfName(id),
          type: ElementType.PDF,
        }));
    },
    fielUploadElements() {
      return CpdModificationService.getOrderedElements(this.uniqueCpdId, ElementType.FILEUPLOAD)
        .filter((id) => this.attachments.filter((a) => a.id === id).length === 0)
        .map((id) => ({
          id,
          name: CpdModificationService.getElementField(id, 'name'),
          type: ElementType.FILEUPLOAD,
        }));
    },
    recipientLimitReached() {
      return this.recipients.length + this.ccRecipients.length + this.bccRecipients.length >= 10;
    },
  },
  watch: {
    visible(newValue) {
      this.dialogVisible = newValue;
    },
  },
  methods: {
    confirm() {
      CpdModificationService.updateElementField(this.parentId, 'recipients', this.recipients);
      CpdModificationService.updateElementField(this.parentId, 'cc_recipients', this.ccRecipients);
      CpdModificationService.updateElementField(this.parentId, 'bcc_recipients', this.bccRecipients);
      CpdModificationService.updateElementField(this.parentId, 'subject', this.subject);
      CpdModificationService.updateElementField(
        this.parentId,
        'attachments',
        this.attachments.filter((a) => a.type === ElementType.FILEUPLOAD).map((a) => `$VAR(${a.id}.value)`)
      );
      CpdModificationService.updateElementField(
        this.parentId,
        'pdf_attachments',
        this.attachments.filter((a) => a.type === ElementType.PDF).map((a) => `$VAR(${a.id}.config)`)
      );
      MailConfigModificationService.updateMailConfigField(this.parentId, 'contentTemplate', this.contentTemplate);
      this.$emit('close');
    },
    translate(key) {
      return this.$t(`mail_generation.${key}`);
    },
    removeAttachment(index) {
      if (!this.disabled) {
        this.attachments.splice(index, 1);
      }
    },
    getPdfName(elementId) {
      const pdfName = TranslationModificationService.getTranslation(this.$i18n.locale, elementId, 'pdf_name');
      return pdfName ? `${pdfName}.pdf` : CpdModificationService.getElementField(elementId, 'name');
    },
    getIdOutOfMvExpression(mvExpression) {
      return mvExpression.substring(mvExpression.indexOf('(') + 1, mvExpression.indexOf('.'));
    },
    handleClose() {
      this.$emit('close');
    },
    addAttachment(attachment) {
      this.attachments.push(attachment);
    },
    handleContentUpdate(newValue) {
      this.contentTemplate = newValue;
    },
  },
  mounted() {
    const pdfAttachments = CpdModificationService.getElementField(this.parentId, 'pdf_attachments')
      .map((mvExpression) => this.getIdOutOfMvExpression(mvExpression))
      .filter((id) => CpdModificationService.getOrderedElements(this.uniqueCpdId, ElementType.PDF).includes(id))
      .map((id) => ({
        id,
        type: ElementType.PDF,
        name: this.getPdfName(id),
      }));

    const fileAttachments = CpdModificationService.getElementField(this.parentId, 'attachments').map((mvExpression) => ({
      id: this.getIdOutOfMvExpression(mvExpression),
      type: ElementType.FILEUPLOAD,
      name: CpdModificationService.getElementField(this.getIdOutOfMvExpression(mvExpression), 'name'),
    }));

    this.attachments = pdfAttachments.concat(fileAttachments);
  },
};
</script>

<style scoped lang="scss">
.convo-mail-configuration-dialog {
  .title {
    margin-left: 16px;
    color: $primary-text-color;
    font-size: 18px;
  }

  .content {
    padding: 16px 16px 0 16px;

    .convo-recipient-input {
      margin: 6px 0;
    }

    .attachments {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: 8px 4px 16px 8px;

      .attachment-label {
        margin-right: 8px;
        color: $primary-color;
        font-weight: bold;
      }

      .attachment-placeholder {
        margin: 2px 8px 2px 0;
        padding: 2px 0;
        color: $disabled-color;
        font-style: italic;
      }

      .attachment {
        display: flex;
        max-width: 240px;
        margin: 2px;
        padding: 4px 8px;
        align-items: center;
        border-radius: 4px;
        word-break: keep-all;
        font-size: 12px;
        color: $primary-color;
        background-color: $convo-blue-2;

        .attachment-name {
          width: calc(100% - 10px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .convo-icon-cancel {
          margin-left: 8px;
          font-size: 8px;
          cursor: pointer;
        }
      }

      .attachment-buttons {
        display: flex;
      }
    }

    .convo-rich-text-editor {
      margin-top: 16px;

      &.disabled {
        min-height: 240px;
        border: 1px solid $border-color;
        border-radius: 4px;
        padding: 8px 12px;
      }
    }
  }

  .navigation {
    display: flex;
    justify-content: flex-end;
    margin: 24px 16px 16px 16px;
    button {
      background-color: $primary-text-color;
      border: 1px solid $primary-color;
      border-radius: 5px;
      color: $primary-color;
      font-family: $primary-font;
      font-size: 14px;
      font-weight: 600;
      height: 32px;
      margin-left: 32px;
      width: 140px;

      &.primary {
        background-color: $primary-color;
        color: $primary-text-color;

        &:disabled {
          background-color: $disabled-color;
          border: none;

          &:hover {
            box-shadow: none;
            cursor: default;
          }
        }
      }

      &:hover {
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15);
      }
    }
  }

  ::v-deep {
    .el-dialog {
      width: 60vw;
      padding: 2px;
      border-radius: 8px;

      .el-dialog__header {
        background-color: $primary-color;
        border-radius: 8px 8px 0 0;
        padding: 6px;
      }
      .el-dialog__title {
        color: $primary-text-color;
      }
      .el-dialog__headerbtn {
        right: 12px;
        top: 10px;
      }
      .el-dialog__close {
        color: $primary-text-color;
        font-size: 20px;
      }
      .el-dialog__body {
        padding: 0;
      }
    }
  }
}
</style>
