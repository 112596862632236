<template>
  <div class="convo-custom-device-selector">
    <div class="activator">
      <i class="convo-icon-slider-settings" :class="{ selected: isSelected }" @click="handleSelection" id="custom-selector" />
      <div @click="handleActivatorToggle" ref="activator" id="custom-options-activator"><i class="convo-icon-down" /></div>
    </div>
    <div class="options" v-if="optionsVisible" @click.stop>
      <div class="option">
        {{ $t('preview.width') }}: <input v-model="customWidth" @input="handleSelection" id="custom-width-input" />
        {{ $t('preview.pixel') }}
      </div>
      <div class="option">
        {{ $t('preview.height') }}: <input v-model="customHeight" @input="handleSelection" id="custom-height-input" />
        {{ $t('preview.pixel') }}
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/common/event-bus';

export default {
  name: 'ConvoCustomDeviceSelector',
  props: {
    isSelected: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      minHeight: 340,
      maxHeight: 2000,
      minWidth: 340,
      maxWidth: 4000,
      customHeight: 500,
      customWidth: 800,
      optionsVisible: false,
    };
  },
  methods: {
    handleSelection() {
      this.$emit('select');

      let height = this.customHeight;
      if (this.customHeight < this.minHeight) {
        height = this.minHeight;
      }
      if (this.customHeight > this.maxHeight) {
        height = this.maxHeight;
      }

      let width = this.customWidth;
      if (this.customWidth < this.minWidth) {
        width = this.minWidth;
      }
      if (this.customWidth > this.maxWidth) {
        width = this.maxWidth;
      }

      EventBus.emitPreviewEnvironmentChange(width, height, 'custom');
    },
    handleActivatorToggle() {
      if (this.optionsVisible) {
        this.closeOptions();
      } else {
        this.openOptions();
      }
    },
    openOptions() {
      this.optionsVisible = true;
      document.addEventListener('click', this.closeOptions);
    },
    closeOptions(e) {
      if (!e || (e.target !== this.$refs.activator && e.target.parentElement !== this.$refs.activator)) {
        this.optionsVisible = false;
        document.removeEventListener('click', this.closeOptions);
      }
    },
  },
  mounted() {
    this.customHeight = window.innerHeight * 0.5;
    this.customWidth = window.innerWidth * 0.5;
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeOptions);
  },
};
</script>

<style lang="scss" scoped>
.convo-custom-device-selector {
  position: relative;

  .activator {
    color: $primary-text-color;
    cursor: pointer;
    display: flex;
    font-size: 24px;

    .selected {
      color: $secondary-color;
    }
    .convo-icon-down {
      font-size: 6px;
      margin-left: 8px;
    }
  }

  .options {
    background-color: $primary-text-color;
    border-radius: 8px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    color: $primary-color;
    font-size: 16px;
    left: 0;
    padding: 4px;
    position: absolute;
    top: 32px;
    z-index: 1;

    .option {
      display: flex;
      margin: 4px;
      padding: 4px;

      input {
        background-color: $convo-blue-2;
        border: none;
        margin: 0 4px;
        outline: none;
        text-align: center;
        width: 40px;
      }
    }
  }
}

@media (max-width: 1280px) {
  .convo-custom-device-selector {
    .activator {
      font-size: 18px;
    }
    .options {
      top: 24px;
      padding: 0;
      font-size: 13px;
    }
  }
}
</style>
