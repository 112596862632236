import jsyaml from 'js-yaml';
import store from '@/common/store';

/**
 * This service encapsulates all functionality required to load CPDs to and from the store.
 */
const CpdStoreToYamlService = (() => {
  /**
   * Maps an id to the corresponding cpd object.
   * All ids contained in the object are also mapped recursively.
   *
   * @param {String} id the id of the object to be returned
   * @returns the cpd representation of the given id
   */
  function render(id) {
    const obj = { id }; // the id isn't part of the store objects because it's their key
    const storedObj = store.getters.componentById(id);
    if (!storedObj) {
      // no corresponding store object means it isn't an id,
      // just a normal string which doesn't need a mapping
      return id;
    }
    // all object attributes that are arrays have to be mapped recursively
    Object.keys(storedObj).forEach((key) => {
      const value = storedObj[key] !== undefined ? storedObj[key] : null; // prevent writing undefined in yaml
      obj[key] = Array.isArray(value) ? value.filter((listItem) => listItem !== null).map((listItem) => render(listItem)) : value;
    });
    return obj;
  }

  return {
    /**
     * Creates a yaml for the given cpd version.
     *
     * @param {String} uniqueCpdId (cpdId + v + versionNumber) to be build
     * @returns the corresponding yaml
     */
    buildCpdYamlFromStoreValues(uniqueCpdId) {
      const cpd = {};
      const storedObj = store.getters.componentById(uniqueCpdId);
      if (!storedObj) {
        console.error('No CPD in Store');
        return {};
      }
      // set all first level attributes except start component, end component and children
      Object.keys(storedObj)
        .filter((key) => key !== 'control_start' && key !== 'control_end' && key !== 'children')
        .forEach((key) => {
          cpd[key] = storedObj[key];
        });
      // combine start component, end component and children in the definition object
      // the ids are mapped to the corresponding object using the render method
      cpd.definition = {};
      cpd.definition.control_start = render(storedObj.control_start);
      cpd.definition.control_end = render(storedObj.control_end);
      cpd.definition.children = storedObj.children.map((c) => render(c));

      // map the js object to a yaml object
      return jsyaml.dump(cpd);
    },
  };
})();

export default CpdStoreToYamlService;
