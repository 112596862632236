import Vue from 'vue';

const getDefaultState = () => ({
  copiedId: undefined,
  elements: {},
  translations: {},
});

export default function keyboardControl() {
  const initialState = getDefaultState();

  const getters = {
    clipboard: (state) => state,
  };

  const mutations = {
    overwriteClipboard(state, { copiedId, elements, translations }) {
      Object.entries({ copiedId, elements, translations }).forEach(([key, value]) => {
        Vue.set(state, key, value);
      });
    },
    clear(state) {
      // Make it reactive
      Object.entries(getDefaultState()).forEach(([key, value]) => {
        Vue.set(state, key, value);
      });
    },
  };

  return {
    state: initialState,
    getters,
    mutations,
  };
}
