const CelReturnType = Object.freeze({
  STRING: 'enum.cel_return_type.string',
  NUMBER: 'enum.cel_return_type.number',
  BOOLEAN: 'enum.cel_return_type.boolean',
  DATE: 'enum.cel_return_type.date',
  CURRENCY: 'enum.cel_return_type.currency',
  FILE: 'enum.cel_return_type.file',
  LIST: 'enum.cel_return_type.list',
  GENERIC: 'enum.cel_return_type.generic',
  PARENT: 'enum.cel_return_type.parent',
  NONE: 'enum.cel_return_type.none',
});

export default CelReturnType;

export function getName(returnType) {
  return returnType.substring(returnType.lastIndexOf('.') + 1);
}
