import MagicVariablesService from '@/common/services/MagicVariablesService';
import MessageService from '@/common/services/MessageService';
import CpdModificationService from '@/common/services/CpdModificationService';
import RouterMixin from '@/common/mixins/RouterMixin';
import MessageType from '@/common/enums/MessageType';
import InvalidReferenceType from '@/common/enums/InvalidReferenceType';

/**
 * This Mixin adds a watcher which add/deletes messages on invalid references changes.
 */
export default {
  mixins: [RouterMixin],
  computed: {
    invalidReferences() {
      return MagicVariablesService.getInvalidReferences(`${this.$route.params.cpd}v${this.$route.params.version}`);
    },
  },
  watch: {
    invalidReferences(newValue, oldValue) {
      const addedReferences = this.getDelta(newValue, oldValue);
      const removedReferences = this.getDelta(oldValue, newValue);
      addedReferences.forEach((reference) =>
        MessageService.addMessage(
          MessageType.ERROR,
          this.getMessageHandler(reference),
          this.getActionHandler(reference),
          this.getReferenceId(reference)
        )
      );
      removedReferences.forEach((reference) => MessageService.deleteMessage(this.getReferenceId(reference)));
    },
  },
  methods: {
    getDelta(referenceListA, referenceListB) {
      const isEqual = (a, b) => a.elementId === b.elementId && a.fieldKey === b.fieldKey && a.magicVariableId === b.magicVariableId;
      const distinct = (reference, index, self) => self.findIndex((ref) => isEqual(ref, reference)) === index;
      return referenceListA.filter(distinct).filter((a) => referenceListB.filter(distinct).every((b) => !isEqual(a, b)));
    },
    getFieldName(reference) {
      const elementType = CpdModificationService.getElementField(reference.elementId, 'type');

      return elementType
        ? this.$t(`${elementType}.${reference.fieldKey}.label`)
        : CpdModificationService.getElementField(reference.elementId, 'field_identifier') ||
            CpdModificationService.getElementField(reference.elementId, 'name');
    },
    getMessageHandler(reference) {
      return () => {
        const elementId = CpdModificationService.getElementField(reference.elementId, 'type')
          ? reference.elementId
          : this.getParentId(reference.elementId, `${this.$route.params.cpd}v${this.$route.params.version}`);
        const elementName = CpdModificationService.getElementField(elementId, 'name') || this.$t('elementNamePlaceholder');
        const fieldName = this.getFieldName(reference);

        switch (reference.invalidReferenceType) {
          case InvalidReferenceType.DELETED_MV:
            return this.$t('messages.error.invalidReferences.deletedMv', {
              fieldName,
              elementName,
            });
          case InvalidReferenceType.INVALID_ORDER:
            return this.$t('messages.error.invalidReferences.invalidOrder', {
              fieldName,
              elementName,
            });
          default:
            // eslint-disable no-unused-vars no-console
            console.error(`No message text for ${reference.invalidReferenceType} defined`);
            return '';
        }
      };
    },
    getActionHandler(reference) {
      return () => {
        this.navigateToInspectorPanel(reference.elementId, reference.fieldKey);
      };
    },
    getReferenceId({ elementId, fieldKey, magicVariableId }) {
      return `${elementId}.${fieldKey}.${magicVariableId}`;
    },
    getParentId(childId, cpdId) {
      const findParentRecursively = (idCandidate, idToFind) => {
        const children = CpdModificationService.getElementField(idCandidate, 'children') || [];
        const choiceItems = CpdModificationService.getElementField(idCandidate, 'choice_items') || [];
        const fields = CpdModificationService.getElementField(idCandidate, 'fields') || [];
        return children.concat(choiceItems).concat(fields).includes(idToFind)
          ? idCandidate
          : children.map((child) => findParentRecursively(child, idToFind)).reduce((prev, curr) => curr || prev, undefined);
      };
      return findParentRecursively(cpdId, childId);
    },
  },
};
