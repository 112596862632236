<template>
  <div class="convo-recipient-input">
    <div class="input-container">
      <div class="prefix">{{ recipientLabel }}</div>
      <div class="recipient" v-for="(recipient, index) in recipients" :key="index">
        <convo-recipient-mv-placeholder
          v-if="isConvoExpressionLanguage(recipient)"
          :parent-id="parentId"
          :expression-heading="expressionHeading"
          :field-key="getRecipientKey(index)"
          :value="recipient"
        />
        <span v-else>{{ recipient }}</span>
        <i class="convo-icon-cancel" @click="removeRecipient(index)" />
      </div>
      <el-input v-model="input" :disabled="disabled || limitReached" @change="addRecipient"></el-input>
    </div>
    <el-button size="mini" tabindex="-1" :class="['mv-button', { disabled: disabled || limitReached }]" @click="openCelDialog">
      <i class="convo-icon-function-add-circle" />
    </el-button>
    <convo-expression-language-dialog
      :visible="celDialogVisible"
      :value="null"
      :type="CelReturnType.STRING"
      @update="handleCelUpdate"
      @close="closeCelDialog"
      :parent-id="parentId"
      :field-key="currentRecipientKey"
      :expression-heading="expressionHeading"
    />
  </div>
</template>

<script>
import ConvoExpressionLanguageService from '@/common/services/ConvoExpressionLanguageService';
import CelReturnType from '@/common/enums/CelReturnType';
import ConvoExpressionLanguageDialog from '../convo_expression_language_dialog/ConvoExpressionLanguageDialog.vue';
import ConvoRecipientMvPlaceholder from './ConvoRecipientMvPlaceholder.vue';

export default {
  name: 'ConvoRecipientInput',
  components: {
    ConvoRecipientMvPlaceholder,
    ConvoExpressionLanguageDialog,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    recipientLabel: {
      type: String,
      required: true,
    },
    expressionHeading: {
      type: String,
      required: true,
    },
    parentId: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    limitReached: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      input: '',
      celDialogVisible: false,
      CelReturnType,
    };
  },
  computed: {
    recipients: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('update', newValue);
      },
    },
    currentRecipientKey() {
      return this.getRecipientKey(this.recipients.length);
    },
  },
  methods: {
    addRecipient() {
      if (this.input) {
        (this.input.match(/[a-zA-Z0-9_!#$%&'*+/=?`{|}~^.-]+@[a-zA-Z0-9.-]+/g) || []).forEach((mail) => {
          this.recipients.push(mail);
          this.input = this.input.replace(mail, '');
        });
      }
    },
    removeRecipient(index) {
      if (!this.disabled) {
        this.recipients.splice(index, 1);
      }
    },
    isConvoExpressionLanguage(value) {
      return ConvoExpressionLanguageService.isConvoExpressionLanguage(value);
    },
    getRecipientKey(index) {
      return this.recipientLabel + index;
    },
    handleCelUpdate(value) {
      if (value) {
        this.recipients.push(value);
      }
      this.celDialogVisible = false;
    },
    openCelDialog() {
      if (!this.disabled && !this.limitReached) {
        this.celDialogVisible = true;
      }
    },
    closeCelDialog() {
      this.celDialogVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
.convo-recipient-input {
  display: flex;
  align-items: center;

  .input-container {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    padding: 0 2px;
    width: 100%;
    border: 1px solid $border-color;
    border-radius: 4px;
    overflow: hidden;

    &:focus-within {
      border-color: $primary-color;
    }

    .prefix {
      display: flex;
      align-items: center;
      padding-left: 12px;
      padding-right: 12px;
      color: $primary-color;
      font-weight: bold;
      word-break: keep-all;
    }

    .recipient {
      display: flex;
      margin: 4px;
      padding: 4px 8px;
      align-items: center;
      border-radius: 4px;
      word-break: keep-all;
      font-size: 12px;
      color: $primary-color;
      background-color: $convo-blue-2;

      .convo-icon-cancel {
        margin-left: 8px;
        font-size: 8px;
        cursor: pointer;
      }
    }

    .el-input {
      flex-grow: 1;
      flex-basis: content;

      ::v-deep input {
        padding: 0 4px;
        border: none;
      }
    }
  }
  .mv-button {
    border: none;
    padding: 2px;
    color: $primary-color;
    font-size: 24px;
    margin-left: 4px;
    cursor: pointer;

    &.disabled {
      color: $disabled-color;
      cursor: not-allowed;
    }
  }
}
</style>
