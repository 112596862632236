import { render, staticRenderFns } from "./ConvoTrashbin.vue?vue&type=template&id=b788a99c&scoped=true&"
import script from "./ConvoTrashbin.vue?vue&type=script&lang=js&"
export * from "./ConvoTrashbin.vue?vue&type=script&lang=js&"
import style0 from "./ConvoTrashbin.vue?vue&type=style&index=0&id=b788a99c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b788a99c",
  null
  
)

export default component.exports