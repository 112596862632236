<template>
  <div></div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'OidcCallback',
  computed: {
    ...mapGetters('oidc', ['oidcUser']),
  },
  methods: {
    ...mapActions('oidc', ['oidcSignInCallback', 'signOutOidc']),
  },
  mounted() {
    this.oidcSignInCallback()
      .then((redirectPath) => {
        if (this.oidcUser.realm_access.roles.includes('studio-access')) {
          this.$router.push(redirectPath);
        } else {
          this.signOutOidc({ post_logout_redirect_uri: `${window.location.origin}/start?error=403` });
        }
      })
      .catch((err) => {
        console.error(err);
        // Handle errors any way you want
      });
  },
};
</script>

<style lang="scss" scoped></style>
