import CpdApiService from '@/common/services/CpdApiService';
import SpinnerEnum from '@/common/enums/SpinnerEnum';
import EventBus from '@/common/event-bus';
import ConvoConfirmationDialog from '@/common/components/ConvoConfirmationDialog.vue';

export default {
  components: { ConvoConfirmationDialog },
  data() {
    return {
      showLeavingConfirmDialog: false,
      initialLoadedCpd: null,
    };
  },
  methods: {
    handleNavigateToDashboard() {
      if ((this.isStudioEditor || this.isEditorialView) && this.$store.getters.cpdEditable && !this.isDemo) {
        const { cpd, version } = this.$route.params;
        const currentCpdDefinitionAndTranslations = CpdApiService.getConvoProcessDto(cpd + 'v' + version);
        const initialLoadedCpdDefinitionAndTranslations = {
          definition: this.initialLoadedCpd.definition,
          translations: this.initialLoadedCpd.translations,
          referencedPdfs: this.initialLoadedCpd.referencedPdfs,
          mailConfigs: this.initialLoadedCpd.mailConfigs,
        };
        if (JSON.stringify(currentCpdDefinitionAndTranslations) !== JSON.stringify(initialLoadedCpdDefinitionAndTranslations)) {
          this.showLeavingConfirmDialog = true;
        } else {
          CpdApiService.deleteCpdLock(cpd, version).then(() => this.navigateToDashboard());
        }
      } else {
        this.navigateToDashboard();
      }
    },
    leaveWithSave() {
      const { cpd, version } = this.$route.params;
      CpdApiService.overwriteCpd(cpd, version, SpinnerEnum.STUDIO).then(
        () => {
          EventBus.emitToast('toast.cpdSaved');
          CpdApiService.deleteCpdLock(cpd, version).then(
            () => this.navigateToDashboard(),
            () => window.location.reload()
          );
        },
        () => EventBus.emitToast('toast.cpdSaveFailed', true)
      );
      this.showLeavingConfirmDialog = false;
    },
    leaveWithoutSave() {
      const { cpd, version } = this.$route.params;
      this.showLeavingConfirmDialog = false;
      CpdApiService.deleteCpdLock(cpd, version).then(() => this.navigateToDashboard());
    },
  },
  mounted() {
    this.initialLoadedCpd = this.$store.getters.cpdWithoutChanges;
    EventBus.onCpdLoaded((cpd) => {
      this.initialLoadedCpd = cpd;
    });
  },
};
