<template>
  <div class="draggable-ghost-style" :class="isDraggable ? 'is-draggable' : 'not-draggable'">
    <div :id="`toolbox-element-${type}`" class="convo-toolbox-brick">
      {{ name }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConvoBrick',
  props: {
    type: {
      type: String,
      required: true,
    },
    isDraggable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    name() {
      return this.$t(`${this.type}.elementName`);
    },
  },
};
</script>

<style lang="scss" scoped>
.draggable-ghost-style {
  &.is-draggable {
    cursor: move;

    &:active .convo-toolbox-brick {
      background-color: $convo-moving-blue;
      box-shadow: 3px 3px 9px $primary-color;
      color: #fff;
    }

    &.ghost:hover .convo-toolbox-brick {
      box-shadow: none;
    }

    &:hover .convo-toolbox-brick {
      box-shadow: 3px 3px 9px $primary-color;
    }
  }

  &.not-draggable {
    cursor: not-allowed;
  }

  &.ghost .convo-toolbox-brick {
    background-color: $convo-ghost-blue;
    color: #fff;
  }

  &.moving .convo-toolbox-brick {
    background-color: $convo-moving-blue;
    color: #fff;
  }

  .convo-toolbox-brick {
    display: flex;
    align-items: center;
    height: 60px;
    border-radius: 10px;
    background-color: $convo-blue-2;
    padding: 16px 32px;
    color: $primary-color;
    font-size: 16px;
    font-weight: 600;
    opacity: 1 !important; // Reversing ghost effects for bricks
  }
}

@media (max-width: 1600px) {
  .draggable-ghost-style .convo-toolbox-brick {
    font-size: 18px;
    padding: 12px 24px;
  }
}
@media (max-width: 1400px) {
  .draggable-ghost-style .convo-toolbox-brick {
    font-size: 14px;
    padding: 8px 16px;
  }
}

@media (max-width: 1280px) {
  .draggable-ghost-style .convo-toolbox-brick {
    padding: 4px 8px;
    height: 44px;
  }
}
</style>
