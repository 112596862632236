<template>
  <div class="convo-object-input">
    <template v-for="(value, index) in values">
      <convo-key-object-input
        v-if="value.type === EntryType.KEY_OBJECT"
        :editable="editable"
        :i18n-prefix="i18nPrefix"
        :identifier="`${index + 1}`"
        :key="index"
        :level="1"
        :parent-id="parentId"
        :value-key.sync="value.key"
        :value.sync="value.value.value"
        @remove="remove(index)"
      />
      <convo-key-list-input
        v-else-if="value.type === EntryType.KEY_LIST"
        :editable="editable"
        :i18n-prefix="i18nPrefix"
        :identifier="`${index + 1}`"
        :key="index"
        :level="1"
        :parent-id="parentId"
        :value-key.sync="value.key"
        :value.sync="value.value.value"
        @remove="remove(index)"
      />
      <convo-key-value-input
        v-else
        :key="index"
        :editable="editable"
        :i18n-prefix="i18nPrefix"
        :identifier="`${index + 1}`"
        :level="1"
        :parent-id="parentId"
        :recursion-disabled="recursionDisabled"
        :value-key.sync="value.key"
        :value.sync="value.value.value"
        @remove="remove(index)"
      />
    </template>
    <convo-add-entry-dropdown
      identifier="root"
      :editable="editable"
      :i18n-prefix="i18nPrefix"
      :recursion-disabled="recursionDisabled"
      @key-list="addKeyList"
      @key-object="addKeyObject"
      @key-value="addKeyValue"
    />
  </div>
</template>

<script>
import CpdModificationService from '@/common/services/CpdModificationService';
import ObjectConversionService from '@/studio/services/ObjectConversionService';
import EntryType from '@/studio/enums/EntryType';
import ConvoKeyValueInput from './ConvoKeyValueInput.vue';
import ConvoKeyObjectInput from './ConvoKeyObjectInput.vue';
import ConvoKeyListInput from './ConvoKeyListInput.vue';
import ConvoAddEntryDropdown from './ConvoAddEntryDropdown.vue';

export default {
  name: 'ConvoObjectInput',
  components: { ConvoAddEntryDropdown, ConvoKeyValueInput, ConvoKeyObjectInput, ConvoKeyListInput },
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
    fieldKey: {
      type: String,
      required: true,
    },
    i18nPrefix: {
      type: String,
      required: true,
    },
    parentId: {
      type: String,
      required: true,
    },
    recursionDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      key: '',
      values: [],
      EntryType,
    };
  },
  created() {
    this.values = ObjectConversionService.objectToList(CpdModificationService.getElementField(this.parentId, this.fieldKey));
  },
  watch: {
    values: {
      deep: true,
      handler(newValue) {
        CpdModificationService.updateElementField(this.parentId, this.fieldKey, ObjectConversionService.listToObject(newValue));
      },
    },
  },
  methods: {
    addKeyObject() {
      this.values.push(ObjectConversionService.getEmptyKeyObjectEntry());
    },
    addKeyList() {
      this.values.push(ObjectConversionService.getEmptyKeyListEntry());
    },
    addKeyValue() {
      this.values.push(ObjectConversionService.getEmptyKeyValueEntry());
    },
    remove(index) {
      this.values.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped></style>
