<template>
  <div class="convo-function-placeholder" @click="$emit('open-function')">
    <div class="icon-holder"><i class="convo-icon-function" /></div>
    <div class="body">{{ $t(translationKey) }}</div>
    <i v-if="!isValid" class="convo-icon-warning" />
    <convo-function-trashbin v-if="!disabled" class="delete" @delete="$emit('delete')" />
  </div>
</template>

<script>
import ConvoExpressionLanguageService from '@/common/services/ConvoExpressionLanguageService';
import ConvoFunctionTrashbin from '@/studio/components/right_sidepanel/inspector_panel/convo_expression_language_dialog/expression/ConvoFunctionTrashbin.vue';

export default {
  name: 'ConvoFunctionPlaceholder',
  components: { ConvoFunctionTrashbin },
  props: {
    id: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    operator() {
      return ConvoExpressionLanguageService.getExpressionField(this.id, 'operator');
    },
    translationKey() {
      return `operators.${this.operator.toLowerCase()}.name`;
    },
    isValid() {
      return ConvoExpressionLanguageService.isExpressionValid(this.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.convo-function-placeholder {
  background-color: white;
  border: 1px solid $primary-color;
  border-radius: 5px;
  color: $primary-text-color;
  cursor: zoom-in;
  display: flex;
  height: 60px;
  overflow: hidden;

  .icon-holder {
    align-items: center;
    background-color: $primary-color;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 40px;
  }

  .body {
    align-items: center;
    color: $primary-color;
    display: flex;
    height: 100%;
    justify-content: center;
    text-align: center;
    width: calc(100% - 72px);
    word-break: keep-all;
  }

  .delete {
    color: $primary-color;
    cursor: pointer;
    font-size: 16px;
    margin-top: 8px;
    ::v-deep.convo-icon-delete {
      display: none;
    }
    &:hover {
      color: $secondary-color;
    }
  }

  .convo-icon-warning {
    color: $secondary-color;
    font-size: 24px;
    margin-right: 8px;
    margin-top: 16px;
  }

  &:hover {
    .delete ::v-deep.convo-icon-delete {
      display: block;
    }
  }
}

@media (max-width: 1280px) {
  .convo-function-placeholder {
    .convo-icon-warning {
      font-size: 18px;
      margin-top: 14px;
    }
  }
}
</style>
